<div class="container" [ngClass.gt-sm]="'cardpadding2'" [ngClass.lt-md]="'cardpadding'" fxLayout="column"
  fxLayoutGap="20px" fxHide.lt-sm>
  <div>
    <p class="mat-headline">Hello. What can we help you with?</p>
  </div>
  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px" fxLayoutGap.lt-sm="0px">
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between" fxFlex>
      <div class="card-border cardpadding" fxLayout="row" fxFlex="32" fxLayoutGap="20px" routerLink="/returns-orders">
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="30">
          <img src="assets/images/customer/1.png" alt="" class="full-width">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
          <p class="mat-title">Your Orders</p>
          <p class="mat-small">Track Packages</p>
          <p class="mat-small">Edit or cancel orders</p>
        </div>
      </div>
      <div class="card-border cardpadding" fxLayout="row" fxFlex="32" fxLayoutGap="20px" routerLink="/returns-orders">
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="30" routerLink="/returns-orders">
          <img src="assets/images/customer/2.png" alt="" class="full-width">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
          <p class="mat-title">Returns & Refunds</p>
          <p class="mat-small">Return or replace items</p>
          <p class="mat-small">Print return mailing labels</p>
        </div>
      </div>
      <div class="card-border cardpadding" fxLayout="row" fxFlex="32" fxLayoutGap="20px" routerLink="/add-address">
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="30">
          <img src="assets/images/customer/3.png" alt="" class="full-width">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
          <p class="mat-title">Manage Addresss</p>
          <p class="mat-small">Update your addresss</p>
          <p class="mat-small">Add or edit address details</p>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between" fxFlex>
      <div class="card-border cardpadding" fxLayout="row" fxFlex="32" fxLayoutGap="20px" routerLink="/payment">
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="30">
          <img src="assets/images/customer/5.png" alt="" class="full-width">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
          <p class="mat-title">Payment Settings</p>
          <p class="mat-small">Add or edit payment methods</p>
          <p class="mat-small">Edit expired debit, credit card</p>
        </div>
      </div>
      <div class="card-border cardpadding" fxLayout="row" fxFlex="32" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="30">
          <img src="assets/images/customer/6.png" alt="" class="full-width">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
          <p class="mat-title">Account Settings</p>
          <p class="mat-small">Change your email, password</p>
          <p class="mat-small">Update login informations</p>
        </div>
      </div>
      <div class="card-border cardpadding" fxLayout="row" fxFlex="32" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="30">
          <img src="assets/images/customer/8.png" alt="" class="full-width">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
          <p class="mat-title">Digital Service and Device Support</p>
          <p class="mat-small">Find device help & support</p>
          <p class="mat-small">Troubleshoot device issue</p>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start" fxLayoutGap="20px" fxLayoutGap.lt-sm="0px" fxFlex
      fxHide>
      <div class="card-border cardpadding" fxLayout="row" fxFlex="32" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="30">
          <img src="assets/images/customer/7.png" alt="" class="full-width">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
          <p class="mat-title">COVID-19 and Global Local</p>
          <p class="mat-small">FAQs about the impact on ordering</p>
        </div>
      </div>
      <div class="card-border cardpadding" fxLayout="row" fxFlex="32" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="30">
          <img src="assets/images/customer/8.png" alt="" class="full-width">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
          <p class="mat-title">Digital Service and Device Support</p>
          <p class="mat-small">Find device help & support</p>
          <p class="mat-small">Troubleshoot device issue</p>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-accordion class="example-headers-align" multi fxShow.lt-sm fxHide>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-around center">
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <img src="assets/images/customer/1.png" alt="">
          <p class="mat-h3">Your Order</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover" routerLink="/returns-orders">Track Packages</p>
        <p class="mat-h4 color-accent color-hover" routerLink="/returns-orders">Edit or cancel orders</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header>
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <img src="assets/images/customer/2.png" alt="">
          <p class="mat-h3">Returns & Refunds</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover" routerLink="/returns-orders">Return or replace items</p>
        <p class="mat-h4 color-accent color-hover" routerLink="/returns-orders">Print return mailing labels</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header>
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <img src="assets/images/customer/3.png" alt="">
          <p class="mat-h3">Manage Addresss</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover">Update your addresss</p>
        <p class="mat-h4 color-accent color-hover">Add or edit address details</p>
      </div>
    </mat-expansion-panel>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-around center">
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <img src="assets/images/customer/4.png" alt="">
          <p class="mat-h3">Manage Prime</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover">View your benefits</p>
        <p class="mat-h4 color-accent color-hover">membership details</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header>
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <img src="assets/images/customer/5.png" alt="">
          <p class="mat-h3">Payment Settings</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover">Add or edit payment methods</p>
        <p class="mat-h4 color-accent color-hover">Edit expired debit, credit card</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header>
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <img src="assets/images/customer/6.png" alt="">
          <p class="mat-h3">Account Settings</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover">Change your email, password</p>
        <p class="mat-h4 color-accent color-hover">Update login informations</p>
      </div>
    </mat-expansion-panel>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-around center">
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <img src="assets/images/customer/7.png" alt="">
          <p class="mat-h3">COVID-19 and Global Local</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover">FAQs about the impact on ordering</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header>
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <img src="assets/images/customer/8.png" alt="">
          <p class="mat-h3">Digital Service and Device Support</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover">Find device help & support</p>
        <p class="mat-h4 color-accent color-hover">Troubleshoot device issue</p>
      </div>
    </mat-expansion-panel>
  </div>
</mat-accordion>