<div fxLayout="column" fxLayoutAlign="center center" class="row-height">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="15px">
    <mat-card class="container card-shadow">
      <mat-card-title class="padding-top">
        <span fxLayoutAlign="center">Login</span>
      </mat-card-title>
      <!-- <mat-error fxLayoutAlign="center center">{{ this.attamp_error}}</mat-error> -->
      <mat-card-content class="cardpadding margin-top">
        <form [formGroup]="loginFormGroup" (submit)="login()" autocomplete="off">
          <!-- Email field -->
          <mat-form-field appearance="outline">
            <mat-label>Email or Mobile Number</mat-label>
            <input matInput formControlName="email" maxlength="256" type="text" id="email" required>
            <mat-error *ngIf="txtEmail.errors?.required"> Email or Mobile
              Number is
              required. </mat-error>
            <mat-error *ngIf="txtEmail.errors?.pattern">Invalid Email or Mobile
              Number.
            </mat-error>
          </mat-form-field>
          <!-- Password field -->
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input formControlName="password" [type]="hide ? 'password' : 'text'" maxlength="256" id="password" matInput
              required />
            <mat-icon class="color-secondary" matSuffix (click)="hide = !hide">
              {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="txtPassword.errors?.required">Password is required.
            </mat-error>
          </mat-form-field>
          <div fxLayoutAlign="center center" *ngIf="error">
            <!-- <mat-error>{{this.error}}</mat-error> -->
          </div>
        </form>
        <div class="margin-top">
          <!-- Remember me Checkbox-->
          <span fxFlex="45">
            <mat-checkbox color="primary"></mat-checkbox><span class="color-secondary">
              Remember me
            </span>
          </span>
          <!-- Forgot password link-->
          <span fxFlex="55" fxLayoutAlign="end">
            <a routerLink="/forgot-password" class="color-primary">
              Forgot password?</a>
          </span>
        </div>
      </mat-card-content>
      <mat-card-actions fxLayout="column">
        <!-- Login account button -->
        <div fxLayoutAlign="center">
          <button mat-button mat-raised-button color="primary" fxFlex="86" [disabled]="loginFormGroup.invalid"
            (click)="login()">Login</button>
        </div>
        <div class="cardpadding" fxLayoutAlign="center">
          <p class="mat-small">By continuing, you agree to
            <a class="color-accent"> Conditions of Use</a> and <a class="color-accent">Privacy Notice</a>
          </p>
        </div>
        <!--SignUp account button -->
        <div fxLayout="column" fxLayoutAlign="center center">
          <p class="mat-h4">New to Here?</p>
          <div fxLayoutAlign="center">
            <button mat-raised-button routerLink="/signup" class="padding-top" color="primary">Create your
              account</button>
          </div>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
  <!-- Copyright -->
  <!-- <div class="color-secondary mat-small" fxLayoutAlign="center end"> Copyright &copy; 2020 All Rights
    Reserved. </div> -->
</div>