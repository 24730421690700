<div class="container" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column">
    <div class="cardpadding" fxLayout="column" fxLayoutAlign="center center">
      <p class="mat-headline">Daily Essentials</p>
      <p class="mat-title text-center">Global Local Brands & more</p>
    </div>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxLayoutGap="20px" fxHide.lt-sm fxShow>
      <div fxLayout="column" fxLayoutGap="5px">
        <p class="mat-small font-bold">Department</p>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Baby</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Health & Personal Care</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Grocery & Gourment Foods</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Beauty</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Home & Kitchen</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Pet Supplies</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Industrial & Scientific</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Luggage & Bags</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover" routerLink="/electronics">Electronics</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Clothing & Accessories</p>
        </div>
      </div>
    </div>
    <div>
      <mat-divider [vertical]="true" fxFlex="100"></mat-divider>
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
      <div class="card slider-items2">
        <p class="mat-h2 padding-top">Save more with coupons</p>
        <div>
          <owl-carousel-o [options]="customOptions9">
            <ng-template carouselSlide *ngFor="let slide of slidesStore9" [id]="slide.id">
              <div class="hover-border1" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px" fxFlex>
                <div fxLayout="row" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div class="cardpadding" fxLayout="column" fxLayoutAlign="start start">
                  <p class="mat-title color-green">{{slide.save}} </p>
                  <p class="mat-small color-accent overflow-hidden" (click)="products()">{{slide.name}}</p>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start">
                  <div fxLayout="row">
                    <button mat-raised-button color="primary" fxFlex.lt-sm="96">{{slide.button}}</button>
                  </div>
                  <p class="mat-small color-secondary">Discount at checkout</p>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <!-- <div class="card slider-items1" fxHide fxShow.lt-md>
        <p class="mat-h2 padding-top">Save more with coupons</p>
        <div>
          <owl-carousel-o [options]="customOptions10">
            <ng-template carouselSlide *ngFor="let slide of slidesStore10" [id]="slide.id">
              <div class="hover-border1" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px" fxFlex>
                <div fxLayout="row">
                  <img [src]="slide.src">
                </div>
                <div class="cardpadding" fxLayout="column" fxLayoutAlign="start start">
                  <p class="mat-title color-green">{{slide.save}} </p>
                  <p class="mat-small color-accent overflow-hidden">{{slide.name}}</p>
                </div>
                <div fxLayout="column" fxLayoutAlign="start stretch">
                  <button mat-raised-button color="primary">{{slide.button}}</button>
                  <p class="mat-small color-secondary">Discount at checkout</p>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div> -->
      <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
        <p class="mat-h2 padding-top">Oils</p>
        <mat-divider class="full-width"></mat-divider>
        <div>
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
              <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px">
                <div class="image-height" fxLayoutAlign="center center" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div class="hover-border" fxLayout="column" fxLayoutAlign="start start">
                  <p class="color-hover overflow-hidden" (click)="products()">{{slide.name}}</p>
                  <p class="mat-small">{{slide.brand}}</p>
                  <p class="mat-h2">{{slide.price}} <span class="mat-small text-line-through">{{slide.oldprice}}</span>
                  </p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{slide.reviewno}}</p>
                  </div>

                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
        <p class="mat-h2 padding-top">Cereals, dry fruits & more</p>
        <mat-divider class="full-width"></mat-divider>
        <div>
          <owl-carousel-o [options]="customOptions1">
            <ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
              <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px" fxFlex>
                <div class="image-height" fxLayoutAlign="center center" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div class="hover-border" fxLayout="column" fxLayoutAlign="start start">
                  <p class="color-hover overflow-hidden" (click)="products()">{{slide.name}}</p>
                  <p class="mat-small">{{slide.brand}}</p>
                  <p class="mat-h2">{{slide.price}} <span class="mat-small text-line-through">{{slide.oldprice}}</span>
                  </p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{slide.reviewno}}</p>
                  </div>

                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
        <p class="mat-h2 padding-top">Sanitizers & handwash</p>
        <mat-divider class="full-width"></mat-divider>
        <div>
          <owl-carousel-o [options]="customOptions2">
            <ng-template carouselSlide *ngFor="let slide of slidesStore2" [id]="slide.id">
              <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px" fxFlex>
                <div class="image-height" fxLayoutAlign="center center" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div class="hover-border" fxLayout="column" fxLayoutAlign="start start">
                  <p class="color-hover overflow-hidden" (click)="products()">{{slide.name}}</p>
                  <p class="mat-small">{{slide.brand}}</p>
                  <p class="mat-h2">{{slide.price}} <span class="mat-small text-line-through">{{slide.oldprice}}</span>
                  </p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{slide.reviewno}}</p>
                  </div>

                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
        <p class="mat-h2 padding-top">Diapers & wipes</p>
        <mat-divider class="full-width"></mat-divider>
        <div>
          <owl-carousel-o [options]="customOptions3">
            <ng-template carouselSlide *ngFor="let slide of slidesStore3" [id]="slide.id">
              <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px" fxFlex>
                <div class="image-height" fxLayoutAlign="center center" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div class="hover-border" fxLayout="column" fxLayoutAlign="start start">
                  <p class="color-hover overflow-hidden" (click)="products()">{{slide.name}}</p>
                  <p class="mat-small">{{slide.brand}}</p>
                  <p class="mat-h2">{{slide.price}} <span class="mat-small text-line-through">{{slide.oldprice}}</span>
                  </p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{slide.reviewno}}</p>
                  </div>

                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
        <p class="mat-h2 padding-top">Household cleaners</p>
        <mat-divider class="full-width"></mat-divider>
        <div>
          <owl-carousel-o [options]="customOptions4">
            <ng-template carouselSlide *ngFor="let slide of slidesStore4" [id]="slide.id">
              <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px" fxFlex>
                <div class="image-height" fxLayoutAlign="center center" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div class="hover-border" fxLayout="column" fxLayoutAlign="start start">
                  <p class="color-hover overflow-hidden" (click)="products()">{{slide.name}}</p>
                  <p class="mat-small">{{slide.brand}}</p>
                  <p class="mat-h2">{{slide.price}} <span class="mat-small text-line-through">{{slide.oldprice}}</span>
                  </p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{slide.reviewno}}</p>
                  </div>

                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
        <p class="mat-h2 padding-top">Skin, hair & body care</p>
        <mat-divider class="full-width"></mat-divider>
        <div>
          <owl-carousel-o [options]="customOptions5">
            <ng-template carouselSlide *ngFor="let slide of slidesStore5" [id]="slide.id">
              <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px" fxFlex>
                <div class="image-height" fxLayoutAlign="center center" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div class="hover-border" fxLayout="column" fxLayoutAlign="start start">
                  <p class="color-hover overflow-hidden" (click)="products()">{{slide.name}}</p>
                  <p class="mat-small">{{slide.brand}}</p>
                  <p class="mat-h2">{{slide.price}} <span class="mat-small text-line-through">{{slide.oldprice}}</span>
                  </p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{slide.reviewno}}</p>
                  </div>

                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
        <p class="mat-h2 padding-top">Pet accessories</p>
        <mat-divider class="full-width"></mat-divider>
        <div>
          <owl-carousel-o [options]="customOptions6">
            <ng-template carouselSlide *ngFor="let slide of slidesStore6" [id]="slide.id">
              <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px" fxFlex>
                <div class="image-height" fxLayoutAlign="center center" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div class="hover-border" fxLayout="column" fxLayoutAlign="start start">
                  <p class="color-hover overflow-hidden" (click)="products()">{{slide.name}}</p>
                  <p class="mat-small">{{slide.brand}}</p>
                  <p class="mat-h2">{{slide.price}} <span class="mat-small text-line-through">{{slide.oldprice}}</span>
                  </p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{slide.reviewno}}</p>
                  </div>

                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="40px">
        <div fxLayout="row" class="card-shadow cardpadding">
          <p class="mat-small">1-24 of over 50,000 results for : <span class="font-bold color-primary"> All Categories
            </span></p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover">See Details</p>
              </div>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items1" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover">See Details</p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items2" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover">See Details</p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div *ngFor="let item of items3" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover">See Details</p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row">
        <button mat-raised-button class="color-accent bg-secondary" fxFlex="100">See all results</button>
      </div>
    </div>
  </div>
</div>


<div class="card-shadow" fxHide>
	<mat-card>
		<div class="cardpadding" fxLayout="column" fxLayoutGap="30px">
			<div class="slider-items1" fxLayout="column" fxLayoutGap="20px">
				<p class="mat-title">Inspired by your browsing history</p>
				<owl-carousel-o [options]="customOptions7" class="cardpadding">
					<ng-template carouselSlide *ngFor="let slide of slidesStore7" [id]="slide.id">
						<img [src]="slide.src">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="color-accent color-hover">{{slide.text}}</span>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star_border</mat-icon>
								<mat-icon>keyboard_arrow_down</mat-icon>
								<p class="color-accent color-hover">{{slide.reviewno}}</p>
							</div>
							<span class="color-danger">{{slide.price}}</span>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
			<div class="slider-items1" fxLayout="column" fxLayoutGap="20px">
				<p class="mat-title">Top subscription apps for you</p>
				<owl-carousel-o [options]="customOptions8" class="cardpadding">
					<ng-template carouselSlide *ngFor="let slide of slidesStore8" [id]="slide.id">
						<img [src]="slide.src">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="color-accent color-hover">{{slide.text}}</span>
							<span class="mat-small">{{slide.price}}</span>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star_border</mat-icon>
								<mat-icon>keyboard_arrow_down</mat-icon>
								<p class="color-accent color-hover">{{slide.reviewno}}</p>
							</div>
							<span class="color-danger">FREE</span>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
		</div>
	</mat-card>
</div>