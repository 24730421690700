import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refer-to-friend',
  templateUrl: './refer-to-friend.component.html',
  styleUrls: ['./refer-to-friend.component.scss']
})
export class ReferToFriendComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
    /* To copy Text from Textbox */
    copyInputMessage(inputElement){
      inputElement.select();
      document.execCommand('copy');
      inputElement.setSelectionRange(0, 0);
    }
}
