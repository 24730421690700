import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit {

  yourEmail: string;
  count: number = 0;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.yourEmail = localStorage.getItem('yourEmail')
  }

  // clickCount(event: any): void {
  //   this.count++
  //   console.log(this.count)
  //   event.target.disabled = true
  // }
  submit() {
    this.count++
    console.log(this.count)
    this._snackBar.open('Collected!!', '', {
      duration: 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['green-snackbar']
    });
  }

  name(slidesStore) {
    console.log(slidesStore.name)
  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 20,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      230: {
        items: 2
      },
      500: {
        items: 3
      },
      750: {
        items: 4
      },
      1000: {
        items: 5
      },
      1250: {
        items: 6
      },
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/images/coupen/carousel/1.jpg',
      save: 'Save ₹ 5,000',
      name: 'Save ₹ 5,000 on Save 5000 off on OP7T Pro',
      button: 'Collect Coupon'
    },
    {
      id: 2,
      src: './assets/images/coupen/carousel/2.jpg',
      save: 'Save ₹ 430',
      name: 'Tecno Spark 6 Air (Ocean Blue, 3GB RAM, 64GB Storage)',
      button: 'Collect Coupon'
    },
    {
      id: 3,
      src: './assets/images/coupen/carousel/3.jpg',
      save: 'Save ₹ 5,000',
      name: 'Save ₹ 5,000 on Oneplus 7T Pro',
      button: 'Collect Coupon'
    },
    {
      id: 4,
      src: './assets/images/coupen/carousel/4.jpg',
      save: 'Save ₹ 500',
      name: 'Samsung Galaxy A21s (Blue, 4GB RAM, 64GB Storage) with No Cost EMI/Additional Exchange Offers',
      button: 'Collect Coupon'
    },
    {
      id: 5,
      src: './assets/images/coupen/carousel/5.jpg',
      save: 'Save ₹ 500',
      name: 'Nokia 5.3 Android One Smartphone with Quad Camera, 6 GB RAM and 64 GB Storage - Sand',
      button: 'Collect Coupon'
    },
    {
      id: 6,
      src: './assets/images/coupen/carousel/6.jpg',
      save: 'Save ₹ 500',
      name: 'Honor 9X (Sapphire Blue, 4+128GB Storage)-Pop up Front Camera & 48MP Triple Rear Camera',
      button: 'Collect Coupon'
    },
    {
      id: 7,
      src: './assets/images/coupen/carousel/7.jpg',
      save: 'Save ₹ 500',
      name: 'Coolpad Cool 6 (Silver,4GB RAM,64GB Storage) -21MP Front Pop-up & 48MP Triple Rear Camera',
      button: 'Collect Coupon'
    },
    {
      id: 8,
      src: './assets/images/coupen/carousel/8.jpg',
      save: 'Save ₹ 500',
      name: 'Samsung Galaxy Note 20 Ultra 5G (Mystic Bronze, 12GB RAM, 256GB Storage) with No Cost EMI/Additional Exchange Offers',
      button: 'Collect Coupon'
    },
    {
      id: 9,
      src: './assets/images/coupen/carousel/9.jpg',
      save: 'Save ₹ 2,000',
      name: 'OPPO A12 (Blue, 4GB RAM, 64GB Storage)',
      button: 'Collect Coupon'
    },
    {
      id: 10,
      src: './assets/images/coupen/carousel/10.jpg',
      save: 'Save ₹ 500',
      name: ' Samsung Galaxy Note10 Lite (Aura Glow, 8GB RAM, 128GB Storage) with No Cost EMI/Additional Exchange Offers',
      button: 'Collect Coupon'
    },
    {
      id: 11,
      src: './assets/images/coupen/carousel/11.jpg',
      save: 'Save ₹ 500',
      name: ' Samsung Galaxy A21s (White, 4GB RAM, 64GB Storage) with No Cost EMI/Additional Exchange Offers',
      button: 'Collect Coupon'
    },
    {
      id: 12,
      src: './assets/images/coupen/carousel/12.jpg',
      save: 'Save ₹ 300',
      name: 'itel A48 (Gradation Purple, 2GB RAM, 32GB Storage)',
      button: 'Collect Coupon'
    },
  ]

  customOptions1: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 20,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      230: {
        items: 2
      },
      500: {
        items: 3
      },
      750: {
        items: 4
      },
      1000: {
        items: 5
      },
      1250: {
        items: 6
      },
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/coupen/carousel1/1.jpg',
      save: 'Save 10%',
      name: 'Mamaearth Moisturising Daily Lotion White - 400 ml',
      button: 'Collect Coupon'
    },
    {
      id: 2,
      src: './assets/images/coupen/carousel1/2.jpg',
      save: 'Save 10%',
      name: 'Mamaearth Gentle Cleansing Shampoo For Babies - 400 ml',
      button: 'Collect Coupon'
    },
    {
      id: 3,
      src: './assets/images/coupen/carousel1/3.jpg',
      save: 'Save 10%',
      name: 'Mamaearth Plant Based Baby Laundry Liquid Detergent - 1000 ml',
      button: 'Collect Coupon'
    },
    {
      id: 4,
      src: './assets/images/coupen/carousel1/4.jpg',
      save: 'Save 10%',
      name: 'Pack of 2, Gentle Cleansing Baby Shampoo -200ml with Mamaearth Deeply Nourishing Baby wash-200ml-Age Group (0-5 Yrs)',
      button: 'Collect Coupon'
    },
    {
      id: 5,
      src: './assets/images/coupen/carousel1/5.jpg',
      save: 'Save 5%',
      name: 'Deal on PeeBuddy Toilet Seat Covers',
      button: 'Collect Coupon'
    },
    {
      id: 6,
      src: './assets/images/coupen/carousel1/6.jpg',
      save: 'Save 5%',
      name: 'Deal on Sirona Underarm Sweat Pads',
      button: 'Collect Coupon'
    },
    {
      id: 7,
      src: './assets/images/coupen/carousel1/7.jpg',
      save: 'Save 5%',
      name: 'Deal on Sirona Period Pain Relief Patches',
      button: 'Collect Coupon'
    },
    {
      id: 8,
      src: './assets/images/coupen/carousel1/8.jpg',
      save: 'Save 5%',
      name: 'Save 5% on IP Camera',
      button: 'Collect Coupon'
    },
    {
      id: 9,
      src: './assets/images/coupen/carousel1/9.jpg',
      save: 'Save 5%',
      name: 'B082VSP8S8',
      button: 'Collect Coupon'
    },
    {
      id: 10,
      src: './assets/images/coupen/carousel1/10.jpg',
      save: 'Save 5%',
      name: 'B082VSMS3N',
      button: 'Collect Coupon'
    },
    {
      id: 11,
      src: './assets/images/coupen/carousel1/11.jpg',
      save: 'Save 5%',
      name: 'Deal on PeeBuddy Toilet Seat Covers',
      button: 'Collect Coupon'
    },
    {
      id: 12,
      src: './assets/images/coupen/carousel1/12.jpg',
      save: 'Save 5%',
      name: 'Save 5% on A2',
      button: 'Collect Coupon'
    },
  ]

  customOptions2: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 20,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      230: {
        items: 2
      },
      500: {
        items: 3
      },
      750: {
        items: 4
      },
      1000: {
        items: 5
      },
      1250: {
        items: 6
      },
    },
  }
  slidesStore2 = [
    {
      id: 1,
      src: './assets/images/coupen/carousel2/1.jpg',
      save: 'Save 5%',
      name: 'Save 5% on WOW Skin Science Products',
      button: 'Collect Coupon'
    },
    {
      id: 2,
      src: './assets/images/coupen/carousel2/2.jpg',
      save: 'Save 5%',
      name: 'Save 5% on WOW Skin Science',
      button: 'Collect Coupon'
    },
    {
      id: 3,
      src: './assets/images/coupen/carousel2/3.jpg',
      save: 'Save 15%',
      name: 'Sugar Release Control Atta Promotion',
      button: 'Collect Coupon'
    },
    {
      id: 4,
      src: './assets/images/coupen/carousel2/4.jpg',
      save: 'Save 7%',
      name: 'Save 7% on AYURVEDIC OIL',
      button: 'Collect Coupon'
    },
    {
      id: 5,
      src: './assets/images/coupen/carousel2/5.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Great India Festival Sale 2020',
      button: 'Collect Coupon'
    },
    {
      id: 6,
      src: './assets/images/coupen/carousel2/6.jpg',
      save: 'Save ₹ 10.00',
      name: 'Super Saver Offer',
      button: 'Collect Coupon'
    },
    {
      id: 7,
      src: './assets/images/coupen/carousel2/7.jpg',
      save: 'Save 5%',
      name: 'Save 5% on WOW Skin Science Products',
      button: 'Collect Coupon'
    },
    {
      id: 8,
      src: './assets/images/coupen/carousel2/8.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Jan Deal',
      button: 'Collect Coupon'
    },
    {
      id: 9,
      src: './assets/images/coupen/carousel2/9.jpg',
      save: 'Save 5%',
      name: 'Himalaya Total Care Baby Pants Diapers, Extra Large, 74 Count',
      button: 'Collect Coupon'
    },
    {
      id: 10,
      src: './assets/images/coupen/carousel2/10.jpg',
      save: 'Save 10%',
      name: 'Mamaearth Moisturising Daily Lotion White - 400 ml',
      button: 'Collect Coupon'
    },
    {
      id: 11,
      src: './assets/images/coupen/carousel2/11.jpg',
      save: 'Save 5%',
      name: 'Pears Pure & Gentle BB 125g pack of 8',
      button: 'Collect Coupon'
    },
    {
      id: 12,
      src: './assets/images/coupen/carousel2/12.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Advance Smart Scales',
      button: 'Collect Coupon'
    },
  ]
}
