import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeComponent } from './components/home/home.component';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LayoutModule } from './layout/layout.module';
import { CountdownModule } from 'ngx-countdown';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { GiftCardComponent } from './components/gift-card/gift-card.component';
import { BestSellerComponent } from './components/best-seller/best-seller.component';
import { TodaysDealComponent } from './components/todays-deal/todays-deal.component';
import { NewReleasesComponent } from './components/new-releases/new-releases.component';
import { ElectronicsComponent } from './components/electronics/electronics.component';
import { LoginComponent } from './components/login/login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SignupComponent } from './components/signup/signup.component';
import { ForgotPasswordComponent } from './shared/components/pages/forgot-password/forgot-password.component';
import { PhoneVerifyComponent } from './shared/components/pages/phone-verify/phone-verify.component';
import { ChangePasswordComponent } from './shared/components/pages/change-password/change-password.component';
import { EmailVerifyComponent } from './shared/components/pages/email-verify/email-verify.component';
import { ComputerLaptopComponent } from './components/electronics/computer-laptop/computer-laptop.component';
import { FashionComponent } from './components/fashion/fashion.component';
import { BirthdayComponent } from './components/gift-card/birthday/birthday.component';
import { AudioComponent } from './components/electronics/audio/audio.component';
import { CameraComponent } from './components/electronics/camera/camera.component';
import { HomeEntertainmentComponent } from './components/electronics/home-entertainment/home-entertainment.component';
import { MobilePhonesComponent } from './components/electronics/mobile-phones/mobile-phones.component';
import { MusicInstrumentsComponent } from './components/electronics/music-instruments/music-instruments.component';
import { StationeryComponent } from './components/electronics/stationery/stationery.component';
import { AirConditionersComponent } from './components/gl-home/air-conditioners/air-conditioners.component';
import { WashingMachinesComponent } from './components/gl-home/washing-machines/washing-machines.component';
import { RefrigeratorsComponent } from './components/gl-home/refrigerators/refrigerators.component';
import { HomeEssentialsComponent } from './components/gl-home/home-essentials/home-essentials.component';
import { KitchenDiningComponent } from './components/gl-home/kitchen-dining/kitchen-dining.component';
import { DailyEssentialsComponent } from './components/gl-home/daily-essentials/daily-essentials.component';
import { ClothingEssentialsComponent } from './components/gl-home/clothing-essentials/clothing-essentials.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuthInterceptor } from './shared/_helpers/basic-auth.interceptor';
import { ErrorInterceptor } from './shared/_helpers/error.interceptor';
import { fakeBackendProvider } from './shared/_helpers/fake-backend';
import { HttpConfigInterceptor } from './shared/_helpers/httpconfig.interceptor';
import { AddToCartComponent } from './shared/components/pages/add-to-cart/add-to-cart.component';
import { ReturnOrdersComponent } from './shared/components/pages/return-orders/return-orders.component';
import { ProductsComponent } from './shared/components/pages/products/products.component';
import { CategoryComponent } from './shared/components/pages/category/category.component';
import { YourAccountComponent } from './shared/components/pages/your-account/your-account.component';
import { CustomerServiceComponent } from './shared/components/pages/customer-service/customer-service.component';
import { ContactUsComponent } from './shared/components/pages/contact-us/contact-us.component';
import { AgmCoreModule } from '@agm/core';
import { InquiryComponent } from './shared/components/pages/inquiry/inquiry.component';
import { ThankyouComponent } from './shared/components/pages/inquiry/thankyou/thankyou.component';
import { WishListComponent } from './shared/components/pages/wish-list/wish-list.component';
import { ErrorComponent } from './shared/components/pages/error/error.component';
import { CheckoutComponent } from './shared/components/pages/checkout/checkout.component';
import { PaymentComponent } from './shared/components/pages/checkout/payment/payment.component';
import { AddaddressComponent } from './shared/components/pages/products/addaddress/addaddress.component';
import { NgxStripeModule } from 'ngx-stripe';
import { ProductReviewComponent } from './shared/components/pages/products/product-review/product-review.component';
import { FaqComponent } from './shared/components/pages/faq/faq.component';
import { TermsandconditionComponent } from './shared/components/pages/termsandcondition/termsandcondition.component';
import { PrivacyPolicyComponent } from './shared/components/pages/privacy-policy/privacy-policy.component';
import { ReturnPolicyComponent } from './shared/components/pages/return-policy/return-policy.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PlaceOrderComponent } from './shared/components/pages/checkout/place-order/place-order.component';
import { ReferToFriendComponent } from './shared/components/pages/refer-to-friend/refer-to-friend.component';
import { ComboOfferComponent } from './components/combo-offer/combo-offer.component';
import { CouponsComponent } from './components/coupons/coupons.component';
import { ProductCompareComponent } from './shared/components/pages/products/product-compare/product-compare.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent, 
    MobilePhonesComponent, 
    GiftCardComponent, 
    BestSellerComponent, 
    TodaysDealComponent, 
    NewReleasesComponent, 
    ElectronicsComponent, 
    CustomerServiceComponent, 
    LoginComponent, 
    SignupComponent, 
    ForgotPasswordComponent, 
    PhoneVerifyComponent, 
    ChangePasswordComponent, 
    EmailVerifyComponent, 
    HomeEntertainmentComponent, 
    FashionComponent, 
    BirthdayComponent, 
    ComputerLaptopComponent, 
    AudioComponent, 
    CameraComponent, 
    MusicInstrumentsComponent, 
    StationeryComponent, 
    AirConditionersComponent, 
    WashingMachinesComponent, 
    RefrigeratorsComponent, 
    HomeEssentialsComponent, 
    KitchenDiningComponent, 
    DailyEssentialsComponent, 
    ClothingEssentialsComponent, 
    AboutUsComponent, 
    ReturnOrdersComponent, 
    AddToCartComponent, 
    ProductsComponent, 
    CategoryComponent, 
    YourAccountComponent,
    ContactUsComponent,
    InquiryComponent,
    ThankyouComponent,
    WishListComponent,
    ErrorComponent,
    CheckoutComponent,
    PaymentComponent,
    AddaddressComponent,
    ProductReviewComponent,
    FaqComponent,
    TermsandconditionComponent,
    PrivacyPolicyComponent,
    ReturnPolicyComponent,
    PlaceOrderComponent,
    ReferToFriendComponent,
    ComboOfferComponent,
    CouponsComponent,
    ProductCompareComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    MatCarouselModule.forRoot(),
    CarouselModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    NgOtpInputModule,
    CountdownModule,
    NgxGalleryModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDrnaWzPZzYHp0FqTs-2UZTrBrS1Vv_UIQ',
      libraries: ['places']
    }),
    NgxStripeModule.forRoot('pk_test_51HD2Y1H87iVI59Hj3vSJK9KKs496mkyFi7QkDnATIN6LSb5yQ80HwHNAKdCyHtAPOWRczk5lMLfu6oNa0yMFC3eP003uM3j0z1')

  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
     { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
     // provider used to create fake backend
    //  fakeBackendProvider,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
