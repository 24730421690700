<div fxLayout="row wrap" fxLayout="column" fxLayoutAlign="center center" class="row-height">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayoutGap.xs="15px">
        <mat-card class="cardsize card-shadow">
            <mat-card-title class="padding-top">
                <span fxLayoutAlign="center">Phone Verification</span>
            </mat-card-title>
            <br>
            <!-- Edit phone number -->
            <mat-card-subtitle fxLayoutAlign="center center">
                <span class="aligned-with-icon-relative">A text with a One Time Password (OTP) has been sent to your
                    mobile number: {{this.mobileno}}
                </span>
                <mat-icon color="primary">edit</mat-icon>
            </mat-card-subtitle>
            <!-- OTP input -->
            <mat-card-content class="spacediv" fxLayoutAlign="center center">
                <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="{length:5}"></ng-otp-input>
            </mat-card-content>
            <!-- Countdown -->
            <mat-card-actions>
                <span fxLayoutAlign="center" class="spacediv color-primary">
                    <countdown #countdown (event)="onEvent($event)" [config]="{ leftTime: 30, format: 'mm:ss' }">
                    </countdown>
                </span>
                <!-- Resend Button -->
                <div fxLayoutAlign="center">
                    <button mat-button mat-raised-button color="primary" fxFlex="90" (click)="Send()"
                        [disabled]="this.selectedValue != 0">
                        Resend</button>
                </div>
            </mat-card-actions>
        </mat-card>
    </div>
    <!-- Copyright -->
    <!-- <div class="color-secondary mat-small" fxLayoutAlign="center center"> Copyright &copy; 2020 All
        Rights Reserved.</div> -->
</div>