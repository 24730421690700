<div fxLayout="row wrap" fxLayout="column" fxLayoutAlign="center center" class="row-height">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayoutGap.xs="15px">
        <mat-card class="cardsize card-shadow">
            <div class="cardpadding">
                <mat-card-title class="padding-top">
                    <span fxLayoutAlign="center">Forgot password?</span>
                </mat-card-title>
                <mat-card-subtitle>
                    <span fxLayoutAlign="start" class="spacediv">Enter the email address or
                        mobile phone number associated with your Amazon account.</span>
                </mat-card-subtitle>
                <mat-card-content class="spacediv">
                    <form [formGroup]="forgotForm" (submit)="Send()" autocomplete="off" (keyup.enter)="Send()">
                        <!-- Enter email id or mobile number field -->
                        <mat-form-field appearance="outline">
                            <mat-label>Email Id or Mobile no</mat-label>
                            <input matInput formControlName="email" (change)="onInputChange($event)" type="text"
                                id="email" required>
                            <mat-error *ngIf="email.errors?.required"> Email Id or Mobile
                                No is
                                required. </mat-error>
                            <mat-error *ngIf="email.errors?.pattern">Invalid Email Id or
                                Mobile No.
                            </mat-error>
                        </mat-form-field>
                        <!-- Send button -->
                        <div class="spacediv" fxLayoutAlign="center">
                            <button mat-button mat-raised-button color="primary" [disabled]="forgotForm.invalid"
                                fxFlex="90">Send</button>
                        </div>
                    </form>
                </mat-card-content>
            </div>
        </mat-card>
    </div>
    <!-- Copyright -->
    <!-- <div class="color-secondary mat-small" fxLayoutAlign="center center"> Copyright &copy; 2020 All
        Rights Reserved.</div> -->
</div>