<div class="row-height container" fxLayout="column" fxLayoutAlign="center center">
    <mat-card>
        <div fxLayout="row" fxLayoutAlign="center center">
            <p class="mat-headline">C Logo</p>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
            <p class="mat-title text-center">Hello,<span> {{this.yourEmail}}</span></p>
            <p class="mat-display-2">404</p>
            <p class="mat-display-2 text-center">Sorry, but there's nothing here</p>
            <a (click)="gotohome()" class="color-accent text-center"> Go to Home Page</a>
        </div>
    </mat-card>
</div>