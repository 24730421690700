<div class="container" fxLayout="column">
  <div class="cardpadding" fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="center center">
    <p class="mat-headline">Amazon Bestsellers</p>
    <p class="color-secondary">Our most popular products based on sales. Updated hourly.</p>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25"  fxFlex.lt-sm="100" fxLayoutGap="20px">
      <div fxLayout="column">
        <div>
          <p class="mat-small font-bold">Any Department</p>
        </div>
        <div class="padding-side" fxLayout="column">
          <p class="mat-small color-hover">Amazon Launchpad</p>
          <p class="mat-small color-hover">App for Android</p>
          <p class="mat-small color-hover">Audible Audiobooks & Originals</p>
          <p class="mat-small color-hover">Baby Products</p>
          <p class="mat-small color-hover">Bags, Wallets and Luggages</p>
          <p class="mat-small color-hover">Beauty</p>
          <p class="mat-small color-hover">Books</p>
          <p class="mat-small color-hover">Car & Motorbike</p>
          <p class="mat-small color-hover">Clothings & Accessories</p>
          <p class="mat-small color-hover">Computers & Accessories</p>
          <p class="mat-small color-hover">Electronics</p>
          <p class="mat-small color-hover">Garden & Outdoors</p>
          <p class="mat-small color-hover">Gift Cards</p>
          <p class="mat-small color-hover">Grocery & Gourment Foods</p>
          <p class="mat-small color-hover">Health & personal Care</p>
          <p class="mat-small color-hover">Home & Kitchen</p>
          <p class="mat-small color-hover">Home Improvement</p>
          <p class="mat-small color-hover">Industrial & Scientific</p>
          <p class="mat-small color-hover">Jewellery</p>
          <p class="mat-small color-hover">Kindle Store</p>
          <p class="mat-small color-hover">Movie & TV Shows</p>
          <p class="mat-small color-hover">Music</p>
          <p class="mat-small color-hover">Music Instruments</p>
          <p class="mat-small color-hover">Office Products</p>
          <p class="mat-small color-hover">Pet Supplies</p>
          <p class="mat-small color-hover">Shoes & Handbags</p>
          <p class="mat-small color-hover">Software</p>
          <p class="mat-small color-hover">Sports, Fitness & Outdoors</p>
          <p class="mat-small color-hover">Toys & Games</p>
          <p class="mat-small color-hover">Video Games</p>
          <p class="mat-small color-hover">Watches</p>
        </div>
      </div>
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px"  fxHide.lt-sm fxShow>
      <div fxLayout="column" fxLayoutGap="40px">
        <div fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="column">
            <p class="mat-small font-bold">Gift Cards</p>
            <p class="mat-small ">> <span class="color-accent text-underline">See more Bestsellers in Gift Cards </span></p>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
            <div fxLayoutGap="20px" *ngFor="let item of items1" fxFlex="33" fxFlex.lt-sm="100">
              <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px">
                <div class="details-card" fxFlex.lt-sm="30">
                  <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
                </div>
                <div fxFlex.lt-sm="70">
                  <p class="mat-h3 color-accent color-hover">{{item.name}}</p>
                  <p class="mat-small">by {{item.company}}</p>
                  <p class="font-bold color-danger"> {{item.price}}</p>
                  <p class="mat-small">In Stock</p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{item.reviewno}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start start">
        <p class="mat-h4 font-bold">About Hot New Releases</p>
        <p class="mat-small">These lists, Updated hourly, contain bestselling items.</p>
      </div>
    </div>
  </div>
</div>