import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-todays-deal',
  templateUrl: './todays-deal.component.html',
  styleUrls: ['./todays-deal.component.scss']
})
export class TodaysDealComponent implements OnInit {
  
  constructor( public router: Router,) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }
  
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/images/today-deal/carousel1.jpg',
      text: 'Min 65% Off on Sweatshirts and Jackets from Amazon Brand - Sy...',
      reviewno: '3',
      price: '₹ 491 - ₹ 1,599'
    },
    {
      id: 2,
      src: './assets/images/today-deal/carousel2.jpg',
      text: 'Up to 60% Off on Home Furnishing Products from Amazon Brands an...',
      reviewno: '41',
      price: '₹ 239 - ₹ 8,549'
    },
    {
      id: 3,
      src: './assets/images/today-deal/carousel3.jpg',
      text: 'Up to 50% off Juicer Mixer grinder',
      reviewno: '107',
      price: '₹ 1,749 - ₹ 14,499'
    },
    {
      id: 4,
      src: './assets/images/today-deal/carousel4.jpg',
      text: 'Min 60% off - Men Jeans - Lee, Killer & more',
      reviewno: '',
      price: '₹ 489 - ₹ 1,574'
    },
    {
      id: 5,
      src: './assets/images/today-deal/carousel5.jpg',
      text: 'Up to 70% Off on Wallets & Travel Accessories - Tommy Hilfiger, UC...',
      reviewno: '1',
      price: '₹ 141 - ₹ 14,518'
    },
    {
      id: 6,
      src: './assets/images/today-deal/carousel6.jpg',
      text: 'Min 60% OFF Casual Shoes from Centrino, Amazon Brand - Symbol...',
      reviewno: '1247',
      price: '₹ 349 - ₹ 1,349'
    },
    {
      id: 7,
      src: './assets/images/today-deal/carousel7.jpg',
      text: 'Up to 50% off | Dry fruits & nuts from Made for Amazon brands',
      reviewno: '1796',
      price: '₹ 179 - ₹ 2,299'
    },
    {
      id: 8,
      src: './assets/images/today-deal/carousel8.jpg',
      text: 'Upto 40% off on Sports Nutrition',
      reviewno: '3',
      price: '₹ 291 - ₹ 9,098'
    },
    {
      id: 9,
      src: './assets/images/today-deal/carousel9.jpg',
      text: 'Sports Bestsellers | Starting at Rs. 225',
      reviewno: '137',
      price: '₹ 358 - ₹ 1,890'
    },
    {
      id: 10,
      src: './assets/images/today-deal/carousel10.jpg',
      text: 'Upto 25% off on Eyes and Face makeup',
      reviewno: '95',
      price: '₹ 135 - ₹ 1,349'
    },
  ]
  checkboxes = [
    { name: 'Appliances' }, { name: 'Artwork' }, { name: 'Baby Products' }, { name: 'Beauty' }, { name: 'Bussiness Industrial' }, { name: 'Camera Accessories' }, { name: 'Cameras & Photography' }, { name: 'Car & Motorbike Accessories' }, { name: 'Chimneys-Kitchen' },
    { name: 'Clothings (Baby)' }, { name: 'Clothings (Boys)' }, { name: 'Clothings (Girl)' }, { name: 'Clothings (Men)' }, { name: 'Clothing (Womens)' }, { name: 'Clothing & Accessories' }, { name: 'Computer Components' }, { name: 'Computers & Accessories' }, { name: 'Data Storage & External Devices' },
    { name: 'Desktops' }, { name: 'Electronics' }, { name: 'Electronics Accessories' }, { name: 'Furniture' }, { name: 'Garden & Outdoors' }, { name: 'Gift Cards' }, { name: 'Grocery' }, { name: 'Handbags' }, { name: 'Headphones' },
    { name: 'Health & Personal Care' }, { name: 'Home & Decor' }, { name: 'Home & Kitchen' }, { name: 'Home Audio & Accessories' }, { name: 'Home Entertainment Systems' }, { name: 'Home Furnishing' }, { name: 'Home Improvement' }, { name: 'Home Storage & Organization' }, { name: 'Household Supplies' },
    { name: 'Indoor Lighting' }, { name: 'Jewellery' }, { name: 'Keyboards, Mice & Input Devices' }, { name: 'Kindle eBooks' }, { name: 'Kitchen & Dining' }, { name: 'Laptops' }, { name: 'Large Appliances' }, { name: 'Luggage & Bags' }, { name: 'Mobile Accessories' },
    { name: 'Mobiles' }, { name: 'Movies & TV Shows' }, { name: 'Music' }, { name: 'Musical Instruments' }, { name: 'Networking Devices' }, { name: 'Office & School Supplies' }, { name: 'Personal Care' }, { name: 'Pet Supplies' }, { name: 'Refrigerators' },
    { name: 'Shoe Care & Accessories' }, { name: 'Shoes (Boys)' }, { name: 'Shoes (Mens)' }, { name: 'Shoes (Womens)' }, { name: 'Showers' }, { name: 'Software' }, { name: 'Speakers' }, { name: 'Sporting Goods' }, { name: 'Sunglasses (Boys)' },
    { name: 'Sunglasses (Girls)' }, { name: 'Sunglasses (Mens)' }, { name: 'Sunglasses (Womens)' }, { name: 'Tablets' }, { name: 'Televisions' }, { name: 'Toys' }, { name: 'Video Games' }, { name: 'Watches' },
    { name: 'Watches (Kids)' }, { name: 'Watches (Mens)' }, { name: 'Watches (Womens)' },

  ]

  items1 = [
    { image: 'assets/images/today-deal/card/1.jpg', name: 'Flat 44% off on All New Fire TV Stick Lite with Alexa Voice Remot...', newprice: '₹ 2,249', oldprice: 'M.R.P.: ₹ 3,999', saving: '(44% off)', },
    { image: 'assets/images/today-deal/card/2.jpg', name: 'Sports Prime exclusive DOTD', newprice: '₹ 243 - ₹ 57,499', oldprice: '', saving: '', },
    { image: 'assets/images/today-deal/card/3.jpg', name: 'Up To 50% Off on Dry Fruits & Chocolates', newprice: '₹ 99 - ₹ 978', oldprice: '', saving: '', },
    { image: 'assets/images/today-deal/card/4.jpg', name: 'All-new Fire TV Stick with Alexa Voice Remote (includes TV contr...', newprice: '₹ 2,999', oldprice: 'M.R.P.: ₹ 4,999', saving: '(40% off)', },
  ];
  items2 = [
    { image: 'assets/images/today-deal/card/5.jpg', name: 'Fire TV Stick 4K with Alexa Voice Remote | Stream in 4K resolution', newprice: '₹ 4,499', oldprice: 'M.R.P.: ₹ 5,999', saving: '(25% off)', },
    { image: 'assets/images/today-deal/card/6.jpg', name: 'Upto 60% off - Hidesign, Van heusen, Baggit & more', newprice: '₹ 342 - ₹ 3,318', oldprice: '', saving: '', },
    { image: 'assets/images/today-deal/card/7.jpg', name: 'Min 65% Off on Sweatshirts and Jackets from Amazon Brand - Sy...', newprice: '₹ 387 - ₹ 1,149', oldprice: '', saving: '', },
    { image: 'assets/images/today-deal/card/8.jpg', name: 'Sarees less than 399', newprice: '₹ 342 - ₹ 391', oldprice: '', saving: '', },
  ];
  items3 = [
    { image: 'assets/images/today-deal/card/9.jpg', name: 'Upto 60% off on Furniture exclusively for Prime members!', newprice: '₹ 1,139 - ₹ 114,960', oldprice: '', saving: '', },
    { image: 'assets/images/today-deal/card/10.jpg', name: '55-65% off - Mens Jeans - Levis, Pepe Jeans & more', newprice: '₹ 1,048 - ₹ 1,186', oldprice: 'M.R.P.: ₹ 2,995 - ₹ 3,399', saving: '(65% off)', },
    { image: 'assets/images/today-deal/card/11.jpg', name: 'Mobile Accessories | Starting INR.249', newprice: '₹ 399 - ₹ 5,490', oldprice: '', saving: '', },
    { image: 'assets/images/today-deal/card/12.jpg', name: 'Earrings, bracelets, anklets and pendants starting 199', newprice: '₹ 170 - ₹ 3,499', oldprice: '', saving: '', },
  ];
  items4 = [
    { image: 'assets/images/today-deal/card/13.jpg', name: 'Min 65% Off on Kid’s Winterwear from Amazon Brand Jam & Honey...', newprice: '₹ 310 - ₹ 589', oldprice: '', saving: '', },
    { image: 'assets/images/today-deal/card/14.jpg', name: 'Min 60% off on Plus Size Womens Wear', newprice: '₹ 349 - ₹ 959', oldprice: '', saving: '', },
    { image: 'assets/images/today-deal/card/15.jpg', name: 'Upto 30% off on top brands', newprice: '₹ 565 - ₹ 10,915', oldprice: '', saving: '', },
    { image: 'assets/images/today-deal/card/16.jpg', name: 'Upto 75% off - Bata, Hush Puppies, Red tape', newprice: '₹ 999 - ₹ 4,499', oldprice: '', saving: '', },
  ];
}
