<div class="container" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column">
    <div class="cardpadding" fxLayout="column" fxLayoutAlign="center center" fxHide fxShow.gt-sm>
      <p class="mat-title">The Gift Cards Store | A gift of choice</p>
      <p class="text-center">Celebrate every occasion with your loved ones in a special way. Amazon India brings
        to you amazing
        <a class="color-accent text-underline">email gift cards</a>, <a class="color-accent text-underline">gift
          boxes</a>, <a class="color-accent text-underline"> greeting cards</a> and
        <a class="color-accent text-underline">other easy gifting ideas</a> for every occasion such as
        <a class="color-accent text-underline">anniversaries</a>, <a class="color-accent text-underline">birthdays</a>,
        <a class="color-accent text-underline">weddings</a>,
        <a class="color-accent text-underline">festivals and more</a>.</p>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around center" fxHide fxShow.gt-sm>
      <img src="assets/images/gift-card/1.jpg" alt="" class="full-width" fxFlex="23">
      <img src="assets/images/gift-card/2.jpg" alt="" class="full-width" fxFlex="23">
      <img src="assets/images/gift-card/3.jpg" alt="" class="full-width" fxFlex="23">
      <img src="assets/images/gift-card/4.jpg" alt="" class="full-width" fxFlex="23">
    </div>
    <div fxLayout="row" fxHide fxShow.lt-sm fxFlex>
      <img src="assets/images/gift-card/logoDesktop.png" alt="" fxFlex="100">
    </div>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxLayoutGap="20px" fxHide.lt-sm fxShow>
      <div fxLayout="column" fxLayoutGap="10px">
        <div>
          <img src="assets/images/gift-card/addto.jpg" alt="">
        </div>
        <p class="color-secondary mat-h3">Show results for</p>
        <div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Gift Cards</p>
          </div>
          <div class="padding-side" fxLayout="column">
            <p class="mat-small color-hover">Gift Cards Holders</p>
            <p class="mat-small color-hover">Gift Cards & Gift Vouchers</p>
          </div>
        </div>
      </div>
      <div>
        <mat-divider></mat-divider>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        <p class="color-secondary mat-h3">Refind by</p>
        <div fxLayout="column">
          <p class="mat-small font-bold">Amazon Prime</p>
          <mat-checkbox fxLayoutAlign="start center"><img src="assets/images/checkbox/1.gif" alt="">
          </mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Occasion</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Anniversary</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Birthday</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Father's Day</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Just Because</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Mother's Day</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Valentine's Day</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Women's Day</mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Recipient</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Brother</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Daughter</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">For Her</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">For Him</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Friend</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Sister</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Wife</mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Brands</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Lifestyle</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Bosch</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Pantaloons</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Titan</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Starbucks</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Forindia</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Osram</mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Delievery Types</p>
          <div fxLayout="column" fxLayoutAlign="start start">
            <p class="mat-small color-hover">eGifts Cards</p>
            <p class="mat-small color-hover">Physical Gift Cards</p>
          </div>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Language</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">English</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Bengali</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Gujarati</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Hindi</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Kannada</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Malyalam</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Marathi</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Punjabi</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Tamil</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Telugu</mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Categories</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Amazon Gift Cards</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Apps</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Clothing</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Fashion Accessories</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Jewellery</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Stores</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Travel & Leisure</mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Availability</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Include Out of Stock
          </mat-checkbox>
        </div>

        <div fxLayout="column">
          <p class="mat-small font-bold">Bestseller Brands</p>
          <p class="mat-small color-hover">MakeMyTrip Gift Voucher</p>
          <p class="mat-small color-hover">BookMyShow Gift Voucher</p>
          <p class="mat-small color-hover">Joyalukkas Gift Voucher</p>
          <p class="mat-small color-hover">Cleantrip</p>
          <p class="mat-small color-hover">Big Bazaar Gift Card</p>
          <p class="mat-small color-hover">Shoppers Stop</p>
          <p class="mat-small color-hover">PVR Gift Voucher</p>
          <p class="mat-small color-hover">Wetside Gift Voucher</p>
          <p class="mat-small color-hover">Lifestyle Gift Voucher</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Occasions & Festivals</p>
          <p class="mat-small color-hover">Birthday</p>
          <p class="mat-small color-hover">Weddings & Engagement</p>
          <p class="mat-small color-hover">For the Workshop</p>
          <p class="mat-small font-bold color-hover">All Occasion</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">E-mail Gift Cards - Instant Delivery</p>
          <p class="mat-small color-hover">Amazon.in</p>
          <p class="mat-small color-hover">MakeMyTrip</p>
          <p class="mat-small color-hover">Shoppers Stop</p>
          <p class="mat-small color-hover">PVR Cinemas</p>
          <p class="mat-small font-bold color-hover">All Brands</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Customer Testimonials</p>
          <p class="mat-small color-hover">See more</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Amazon.in Gift Cards - Home Delivery</p>
          <p class="mat-small color-hover">Gift Box</p>
          <p class="mat-small color-hover">Shagun Envelope</p>
          <p class="mat-small color-hover">Bloom Gift Boxes</p>
          <p class="mat-small color-hover">Greeting Card</p>
          <p class="mat-small color-hover">Shine Box & Envelope</p>
          <p class="mat-small color-hover">Green Gifting Envelope</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Fulfilled by Amazon</p>
          <p class="mat-small color-hover">See all</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Othe Gift Card Brands</p>
          <p class="mat-small color-hover">Resturants</p>
          <p class="mat-small color-hover">Fashion</p>
          <p class="mat-small color-hover">Spas & Salons</p>
          <p class="mat-small font-bold color-hover">All Brands</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Corporator Sales</p>
          <p class="mat-small color-hover">Corporator Gifting</p>
        </div>
      </div>
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
      <div fxLayout="row" fxHide fxShow.gt-sm fxFlex>
        <img src="assets/images/gift-card/logoDesktop.png" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/occasion.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/recipient.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/helppage.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/beaware.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/quadcard1.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/quadcard2.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/quadcard3.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/quadcard4.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row">
        <img src="assets/images/gift-card/birthdaybanner.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/birthday1.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/birthday2.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/birthday3.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/birthday4.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/birthday5.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/birthday6.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row">
        <img src="assets/images/gift-card/colleaguebanner.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/colleague1.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/colleague2.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/colleague3.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/colleague4.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/colleague5.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/colleague6.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row">
        <img src="assets/images/gift-card/weddingbanner.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/wedding1.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/wedding2.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/wedding3.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/wedding4.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/wedding5.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/wedding6.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row">
        <img src="assets/images/gift-card/corporatebanner.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row">
        <img src="assets/images/gift-card/covidbanner.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row">
        <img src="assets/images/gift-card/sayitbanner.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/sayit1.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/sayit2.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/sayit3.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/sayit4.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/sayit5.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/sayit6.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row">
        <img src="assets/images/gift-card/anniversarybanner.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/anniversary1.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/anniversary2.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/anniversary3.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/anniversary4.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/anniversary5.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/anniversary6.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row">
        <img src="assets/images/gift-card/justbcozbanner.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/justbcoz1.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/justbcoz2.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/justbcoz3.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/justbcoz4.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/justbcoz5.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/justbcoz6.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row">
        <img src="assets/images/gift-card/morebanner.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/more1.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/more2.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/more3.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/more4.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/more5.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/more6.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row">
        <img src="assets/images/gift-card/deliverybanner.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gift-card/delivery1.jpg" alt="" fxFlex="32">
        <img src="assets/images/gift-card/delivery2.jpg" alt="" fxFlex="32">
        <img src="assets/images/gift-card/delivery3.jpg" alt="" fxFlex="32">
      </div>
      <div fxLayout="row">
        <img src="assets/images/gift-card/giftcardbanner.jpg" alt="" class="full-width">
      </div>
      <div class="slider">
        <div class="slider-img">
          <mat-carousel timings="250ms ease-in-out" [autoplay]="true" interval="3000" proportion="25"
            [useKeyboard]="true" orientation="ltr">
            <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides; let i = index" [image]="slide.image"
              fxLayoutAlign="center center">
            </mat-carousel-slide>
          </mat-carousel>
        </div>
      </div>
      <div>
        <mat-divider></mat-divider>
      </div>
      <div fxLayout="row">
        <img src="assets/images/gift-card/shopbyoccasionbanner.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/shopbyoccasion1.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/shopbyoccasion2.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/shopbyoccasion3.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/shopbyoccasion4.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/shopbyoccasion5.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/shopbyoccasion6.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/shopbyoccasion7.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/shopbyoccasion8.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row">
        <img src="assets/images/gift-card/uptobanner.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/brand1.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/brand2.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/brand3.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/brand4.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/brand5.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/brand6.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gift-card/brand7.jpg" alt="" fxFlex="48">
          <img src="assets/images/gift-card/brand8.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row">
        <img src="assets/images/gift-card/uptobanner1.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="column" fxLayoutGap="40px">
        <div fxLayout="row" class="card-shadow cardpadding">
          <p class="mat-small">1-24 of over 2,000 results for: <span class="font-bold color-primary"> Gift Cards </span></p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div class="details-card" fxFlex.lt-sm="30">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h3 color-accent color-hover">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <p class="font-bold color-danger"> {{item.price}}</p>
                <p class="mat-small">In Stock</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items2" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div class="details-card" fxFlex.lt-sm="30">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h3 color-accent color-hover">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <p class="font-bold color-danger"> {{item.price}}</p>
                <p class="mat-small">In Stock</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div><div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items3" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div class="details-card" fxFlex.lt-sm="30">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h3 color-accent color-hover">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <p class="font-bold color-danger"> {{item.price}}</p>
                <p class="mat-small">In Stock</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div><div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items4" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div class="details-card" fxFlex.lt-sm="30">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h3 color-accent color-hover">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <p class="font-bold color-danger"> {{item.price}}</p>
                <p class="mat-small">In Stock</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card-shadow" fxHide.lt-sm>
  <mat-card>
    <div class="cardpadding" fxLayout="column" fxLayoutGap="30px">
      <div class="slider-items" fxLayout="column" fxLayoutGap="20px">
        <p class="mat-title">Inspired by your browsing history</p>
        <owl-carousel-o [options]="customOptions" class="cardpadding">
          <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
            <img [src]="slide.src">
            <div fxLayout="column" fxLayoutAlign="start start">
              <span class="color-accent color-hover">{{slide.text}}</span>
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star_border</mat-icon>
                <mat-icon>keyboard_arrow_down</mat-icon>
                <p class="color-accent color-hover">{{slide.reviewno}}</p>
              </div>
              <span class="color-danger">{{slide.price}}</span>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
      <div class="slider-items" fxLayout="column" fxLayoutGap="20px">
        <p class="mat-title">Top subscription apps for you</p>
        <owl-carousel-o [options]="customOptions1" class="cardpadding">
          <ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
            <img [src]="slide.src">
            <div fxLayout="column" fxLayoutAlign="start start">
              <span class="color-accent color-hover">{{slide.text}}</span>
              <span class="mat-small">{{slide.price}}</span>
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star_border</mat-icon>
                <mat-icon>keyboard_arrow_down</mat-icon>
                <p class="color-accent color-hover">{{slide.reviewno}}</p>
              </div>
              <span class="color-danger">FREE</span>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </mat-card>
</div>