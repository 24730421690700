import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.scss']
})
export class PlaceOrderComponent implements OnInit {


  items = [
    { price: '₹ 12,999', oldPrice: '₹ 17,100', save: '₹ 4,100(21%)', img: 'assets/images/mobile-phone/card/1.webp', name: 'Samsung Galaxy M30s (Opal Black, 4GB RAM, 64GB Storage)', sold: 'Cloudtail India' },
    { price: '₹ 13,100', oldPrice: '₹ 18,100', save: '₹ 4,000(20%)', img: 'assets/images/gl-home/refrigerator/carousel1/8.jpg', name: 'r 320 L 2 Star Inverter Frost-Free Double Door Refrigerator (HRB-3404BMS-E, Moon Silver,Bottom Freezer)', sold: 'Cloudtail India' },
    // { price: '₹ 23,790', img: 'assets/images/order/3.jpg', name: 'LG 260 L 3 Star Frost Free Double Door Refrigerator (GL-I292RPZL, Shiny Steel, Smart Inverter Compressor)' },
    // { price: '₹ 36,900', img: 'assets/images/order/4.jpg', name: 'Mi TV 4X 138.8 cm (55 Inches) Ultra HD Android LED TV (Black)' },

  ];

  fullName: any;
  flat: any;
  area: any;
  Landmark: any;
  City: any;
  pin: any;
  State: any;
  PhoneNumber: any;
  country: any;
  lastFour: any;
  cardBrand: any;

  constructor() { }

  ngOnInit(): void {
    this.fullName = localStorage.getItem("fullName");
    this.flat = localStorage.getItem("flat");
    this.area = localStorage.getItem("area");
    this.Landmark = localStorage.getItem("Landmark");
    this.City = localStorage.getItem("City");
    this.pin = localStorage.getItem("pin");
    this.State = localStorage.getItem("State");
    this.PhoneNumber = localStorage.getItem("phoneNumber");
    this.country = localStorage.getItem("country");
    this.lastFour = localStorage.getItem("lastFour");
    this.cardBrand = localStorage.getItem("cardBrand");
  }

}
