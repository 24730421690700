import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-washing-machines',
  templateUrl: './washing-machines.component.html',
  styleUrls: ['./washing-machines.component.scss']
})
export class WashingMachinesComponent implements OnInit {

  items = [
    { image: 'assets/images/gl-home/washing-machine/card/1.jpg', name: 'Whirlpool 7 Kg 5 Star Semi-Automatic Top Loading Washing Machine (SUPERB ATOM 7.0, Grey Dazzle, TurboScrub Technology)', company: 'Whirlpool', newprice: '₹ 9,190', oldprice: '₹ 11,250', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '3.064' },
    { image: 'assets/images/gl-home/washing-machine/card/2.jpg', name: 'IFB 6 kg 5 Star Fully-Automatic Front Loading Washing Machine (Diva Aqua SX, Silver, Inbuilt Heater, Aqua Energie water softener)', company: 'IFB', newprice: '₹ 21,890', oldprice: '₹ 25,290', freedelivery: 'FREE Scheduled Delivery', upto: '.', deliverydate: '', reviewno: '2,264' },
    { image: 'assets/images/gl-home/washing-machine/card/3.jpg', name: 'LG 7.0 Kg 5 Star Smart Inverter Fully-Automatic Top Loading Washing Machine (T70SKSF1Z, Middle Free Silver)', company: 'LG', newprice: '₹ 17,990', oldprice: '₹ 20,990', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '131' },
  ];
  items1 = [
    { image: 'assets/images/gl-home/washing-machine/card/4.jpg', name: 'LG 7 Kg 4 Star Semi-Automatic Top Loading Washing Machine (P7020NGAY, Dark Gray, Collar scrubber)', company: 'LG', newprice: '₹ 10,890', oldprice: '₹ 13,490', freedelivery: 'FREE Scheduled Delivery', upto: '.', deliverydate: '', reviewno: '377' },
    { image: 'assets/images/gl-home/washing-machine/card/5.jpg', name: 'LG 7.0 Kg 5 Star Inverter Fully-Automatic Front Loading Washing Machine (FHM1207ZDL, Luxury Silver, 6 Motion Technology)', company: 'LG', newprice: '₹ 29,490', oldprice: '₹  38,990', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '596' },
    { image: 'assets/images/gl-home/washing-machine/card/6.jpg', name: 'LG 8 Kg 5 Star Semi-Automatic Top Loading Washing Machine (P8035SGMZ, Grey, Collar Scrubber)', company: 'LG', newprice: '₹ 13,200', oldprice: '₹ 16,990', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '267' },
  ];
  items2 = [
    { image: 'assets/images/gl-home/washing-machine/card/7.jpg', name: 'LG 6.5 Kg 5 Star Smart Inverter Fully-Automatic Top Loading Washing Machine (T65SNSF1Z, Middle Free Silver, TurboDrum)', company: 'LG', newprice: '₹ 18,980', oldprice: '₹ 22,990', freedelivery: 'FREE Delivery', upto: '', deliverydate: '', reviewno: '230' },
    { image: 'assets/images/gl-home/washing-machine/card/8.jpg', name: 'LG 6.5 Kg 4 Star Semi-Automatic Top Loading Washing Machine (P6510NBAY, Dark Blue, Rat Away Technology)', company: 'LG', newprice: '₹ 9,390', oldprice: '₹ 12,090', freedelivery: 'FREE Delivery', upto: '', deliverydate: '', reviewno: '1,188' },
    { image: 'assets/images/gl-home/washing-machine/card/9.jpg', name: 'Whirlpool 10.5 kg 5 Star Semi-Automatic Top Loading Washing Machine (ACE XL 10.5, Graphite Grey, 3D Scrub Technology)', company: 'Whirlpool', newprice: '₹ 14,600', oldprice: '₹ 19,450', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '667' },
  ];
  items3 = [
    { image: 'assets/images/gl-home/washing-machine/card/10.jpg', name: 'LG 9.0 Kg 5 Star Smart Inverter Fully-Automatic Top Loading Washing Machine (T90SJSF1Z, Middle Free Silver, Jet Spray+)', company: 'LG', newprice: '₹ 24,990', oldprice: '₹ 33,990', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '89' },
    { image: 'assets/images/gl-home/washing-machine/card/11.jpg', name: 'Whirlpool 7.2 Kg Semi-Automatic Top Loading Washing Machine (ACE SUPREME PLUS 7.2, Coral Red, Ace Wash Station)', company: 'Whirlpool', newprice: '₹ 9,399', oldprice: '₹ 11,750', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '1,001' },
    { image: 'assets/images/gl-home/washing-machine/card/12.jpg', name: 'IFB 8kg 5 Star Fully-Automatic Front Loading Washing Machine (Senator WXS, Silver, Inbuilt Heater, Aqua Energie water softener)', company: 'IFB', newprice: '₹ 34,990', oldprice: '₹ 40,990', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '617' },
  ];

  
  constructor( public router: Router,) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }

  
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 50,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/images/gl-home/washing-machine/carousel/1.jpg',
      price: '₹ 21,899',
      oldprice: '₹ 24,690',
      off: '(11% off)',
      name: 'IFB 6 kg 5 Star Fully-Automatic Front Loading Washing Machine (...',
      brand: 'Sold by Cloudtail India and Fulfilled by Amazon.',
      reviewno: '2264'
    },
    {
      id: 2,
      src: './assets/images/gl-home/washing-machine/carousel/2.jpg',
      price: '₹ 13,200',
      oldprice: '₹ 16,990',
      off: '(22% off)',
      name: 'LG 8 Kg 5 Star Semi-Automatic Top Loading Washing Machine (P8035...',
      brand: 'Sold by Cloudtail India and Fulfilled by Amazon.',
      reviewno: '267'
    },
    {
      id: 3,
      src: './assets/images/gl-home/washing-machine/carousel/3.jpg',
      price: '₹ 6,999',
      oldprice: '₹ 13,990',
      off: '(50% off)',
      name: 'Foxsky 6.8 kg Semi-Automatic Top Loading Washing Machine (FOXS...',
      brand: 'Sold by Cloudtail India and Fulfilled by Amazon.',
      reviewno: '30'
    },
    {
      id: 4,
      src: './assets/images/gl-home/washing-machine/carousel/4.jpg',
      price: '₹ 11,825',
      oldprice: '₹ 14,190',
      off: '(17% off)',
      name: 'LG 7 Kg Semi-Automatic Top Loading Washing Machine (P702...',
      brand: 'Sold by Cloudtail India and Fulfilled by Amazon.',
      reviewno: '25'
    },
    {
      id: 5,
      src: './assets/images/gl-home/washing-machine/carousel/5.jpg',
      price: '₹ 37,990',
      oldprice: '₹ 45,490',
      off: '(16% off)',
      name: 'LG 7 Kg 5 Star Inverter Wi-Fi Fully-Automatic Front Loading Washin...',
      brand: 'Sold by Cloudtail India and Fulfilled by Amazon.',
      reviewno: '70'
    },
    {
      id: 6,
      src: './assets/images/gl-home/washing-machine/carousel/6.jpg',
      price: '₹ 8,999',
      oldprice: '₹ 18,990',
      off: '(53% off)',
      name: 'Foxsky 8.6 kg Semi-Automatic Top Loading Washing Machine (FOXS...',
      brand: 'Sold by Cloudtail India and Fulfilled by Amazon.',
      reviewno: '5'
    },
    {
      id: 7,
      src: './assets/images/gl-home/washing-machine/carousel/7.jpg',
      price: '₹ 10,525',
      oldprice: '₹ 14,500',
      off: '(27% off)',
      name: 'Haier 8 Kg Semi-Automatic Top Loading Washing Machine (HTW8...',
      brand: 'Sold by Cloudtail India and Fulfilled by Amazon.',
      reviewno: '21'
    },
    {
      id: 8,
      src: './assets/images/gl-home/washing-machine/carousel/8.jpg',
      price: '₹ 25,390',
      oldprice: '₹ 29,490',
      off: '(14% off)',
      name: 'IFB 6 Kg 5 Star Fully-Automatic Front Loading Washing Machine (...',
      brand: 'Sold by Cloudtail India and Fulfilled by Amazon.',
      reviewno: '22'
    },
  ]

  customOptions1: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/gl-home/washing-machine/carousel1/1.jpg',
      price: '₹ 14,000',
      oldprice: '₹ 16,800',
      name: 'Samsung 6.5 kg Fully-Automatic Top Loading',
      brand: 'Samsung',
      reviewno: '10,121'
    },
    {
      id: 2,
      src: './assets/images/gl-home/washing-machine/carousel1/2.jpg',
      price: '₹ 21,899',
      oldprice: '₹ 24,690',
      name: 'IFB 6 kg 5 Star Fully-Automatic Front Loading ',
      brand: 'IFB',
      reviewno: '2,264'
    },
    {
      id: 3,
      src: './assets/images/gl-home/washing-machine/carousel1/3.jpg',
      price: '₹ 14,190',
      oldprice: '₹ 18,990',
      name: 'LG 6.5 Kg Inverter Fully-Automatic Top Loading',
      brand: 'LG',
      reviewno: '876'
    },
    {
      id: 4,
      src: './assets/images/gl-home/washing-machine/carousel1/4.jpg',
      price: '₹ 17,000',
      oldprice: '₹ 19,800',
      name: 'Samsung 7 kg Fully-Automatic Top Loading',
      brand: 'Samsung',
      reviewno: '49'
    },
    {
      id: 5,
      src: './assets/images/gl-home/washing-machine/carousel1/5.jpg',
      price: '₹ 29,470',
      oldprice: '₹ 32,490',
      name: 'IFB 7 Kg 5 Star Fully-Automatic Front Loading',
      brand: 'IFB',
      reviewno: '139'
    },
    {
      id: 6,
      src: './assets/images/gl-home/washing-machine/carousel1/6.jpg',
      price: '₹ 13,200',
      oldprice: '₹ 16,990',
      name: 'LG 8 Kg 5 Star Semi-Automatic Top Loading',
      brand: 'LG',
      reviewno: '267'
    },
    {
      id: 7,
      src: './assets/images/gl-home/washing-machine/carousel1/7.jpg',
      price: '₹ 21,890',
      oldprice: '₹ 25,290',
      name: 'IFB 6 kg 5 Star Fully-Automatic Front Loading',
      brand: 'IFB',
      reviewno: '2,264'
    },
    {
      id: 8,
      src: './assets/images/gl-home/washing-machine/carousel1/8.jpg',
      price: '₹ 13,100',
      oldprice: '₹ 15,100',
      name: 'Samsung 6.2 kg Fully-Automatic Top load',
      brand: 'Samsung',
      reviewno: '10,121'
    },
    {
      id: 9,
      src: './assets/images/gl-home/washing-machine/carousel1/9.jpg',
      price: '₹ 37,490',
      oldprice: '₹ 45,590',
      name: 'Samsung 7.0 kg / 5.0 kg Inverter Fully Automatic',
      brand: 'Samsung',
      reviewno: '79'
    },
    {
      id: 10,
      src: './assets/images/gl-home/washing-machine/carousel1/10.jpg',
      price: '₹ 8,240',
      oldprice: '₹ 9,900',
      name: 'Samsung 6.0 Kg 5 Star Semi-Automatic Top',
      brand: 'Samsung',
      reviewno: '490'
    },
  ]
  customOptions2: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore2 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel1/1.jpg',
      text: 'Emporium Car Back Pain Relief Lower Back Support for Chair Back Rest for Office Chair Lumbar…',
      reviewno: '242',
      price: '₹ 349.00'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel1/2.jpg',
      text: 'BLUEWUD Clonard Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '242',
      price: '₹ 2,899.00'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel1/3.jpg',
      text: 'BLUEWUD Mallium Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '331',
      price: '₹ 3,339.00'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel1/4.jpg',
      text: 'OGU Om Cotton Round Batti Wicks for Pooja Aarti (White) -2100 Pieces',
      reviewno: '61',
      price: '₹ 250'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel1/5.jpg',
      text: 'pTron Bassbuds Pro in-Ear True Wireless Bluetooth 5.0 Headphones with Deep Bass, Touch Control…',
      reviewno: '8,370',
      price: '₹ 1,299.00'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel1/6.jpg',
      text: 'HealthSense Soft-Spot BC 21 Orthopedic Backrest Cushion with Memory Foam for Study, Home,…',
      reviewno: '931',
      price: '₹ 1,399.00'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel1/7.jpg',
      text: 'Nutriplato-enriching lives Roasted Salted Flax Seeds, 500 g',
      reviewno: '325',
      price: '₹ 199.00'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel1/8.jpg',
      text: 'ADOC Chairs Oren Medium Back Ergonomic Office Chair with Mesh Back, Fixed Comfortable…',
      reviewno: '58',
      price: '₹ 3,049.00'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel1/9.jpg',
      text: 'Angel Bear 1 Cup Toothbrush Toothpaste Stand Holder Bathroom Storage Organizer,Plastic',
      reviewno: '379',
      price: '₹ 249.00'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel1/10.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '165',
      price: '₹ 3,999.00'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel1/11.jpg',
      text: 'TIZUM High Speed HDMI Cable with Ethernet - Supports 3D, 4K and Audio Return (1.8 Meter/ 6 Feet)',
      reviewno: '2,281',
      price: '₹ 145.00'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel1/12.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '30',
      price: '₹ 3,799.00'
    },
  ]
  customOptions3: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore3 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel2/1.jpeg',
      text: 'VPN Software & VPN free unlimited (Fire TV, 4k sticks)',
      reviewno: '1,108',
      price: 'BackySoft'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel2/2.jpg',
      text: 'AirScreen - AirPlay & Google Cast & Miracast & DLNA',
      reviewno: '34,550',
      price: '重庆爱奥尼科技有限公司'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel2/3.jpeg',
      text: 'Trashy Movies Channel',
      reviewno: '1,561',
      price: 'POV Horror'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel2/4.jpeg',
      text: 'Wicked Horror TV',
      reviewno: '3,961',
      price: 'POV Horror'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel2/5.jpeg',
      text: 'Trashy Horror Movies',
      reviewno: '1,375',
      price: 'POV Horror'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel2/6.jpeg',
      text: 'B-Movie Club',
      reviewno: '738',
      price: 'POV Horror'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel2/7.jpeg',
      text: 'VPN Lite & Full Privacy (RVPN)',
      reviewno: '358',
      price: 'BackySoft'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel2/8.jpg',
      text: 'Plex: Stream Movies, Shows, Live TV, Music, and More',
      reviewno: '51,727',
      price: 'Plex'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel2/9.jpeg',
      text: 'Martial Arts and Kung-Fu Movie Channel',
      reviewno: '4,629',
      price: 'POV Horror'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel2/10.jpg',
      text: 'NordVPN - Fast, Secure and Unlimited VPN app for Android. Stay secure and…',
      reviewno: '21,882',
      price: 'nordvpn s.a.'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel2/11.jpeg',
      text: 'Thriller Movie Channel',
      reviewno: '232',
      price: 'POV Horror'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel2/12.jpeg',
      text: 'Cleaner for Fire Tablets & TV (Cleanza)',
      reviewno: '6,219',
      price: 'Quickla Soft'
    },
  ]
}
