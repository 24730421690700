<div class="container" [ngClass.gt-sm]="'cardpadding2'" [ngClass.lt-md]="'cardpadding'">
  <div [ngClass.gt-sm]="'cardpadding2'" fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="20px" fxFlex>
    <div fxLayout="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
      <div>
        <p class="mat-title">LOCATION & DETAILS</p>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <mat-icon color="primary">location_on</mat-icon>
        <p class="mat-h3 color-secondary color-hover">123 Main Street, Anytown, CA 12345 - USA.</p>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <mat-icon color="primary">email</mat-icon>
        <div fxLayout="column">
          <p class="mat-h3 color-secondary color-hover">info@localglobal.com</p>
          <p class="mat-h3 color-secondary color-hover"> support@localglobal.com</p>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <mat-icon color="primary">phone</mat-icon>
        <p class="mat-h3 color-secondary color-hover">+(000) 800 456 789</p>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <mat-icon color="primary">public</mat-icon>
        <p class="mat-h3 color-secondary color-hover">www.localglobal.com</p>
      </div>
      <div fxLayout="column">
        <div>
          <p class="mat-title">Get in Touch:</p>
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
          <div class="bg-secondary round-icon" fxLayoutAlign="center center">
            <i class="fa fa-twitter color-primary" aria-hidden="true"></i>
          </div>
          <div class="bg-secondary round-icon" fxLayoutAlign="center center">
            <i class="fa fa-google-plus color-primary" aria-hidden="true"></i>
          </div>
          <div class="bg-secondary round-icon" fxLayoutAlign="center center">
            <i class="fa fa-facebook color-primary" aria-hidden="true"></i>
          </div>
          <div class="bg-secondary round-icon" fxLayoutAlign="center center">
            <i class="fa fa-youtube color-primary" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="60" fxHide.lt-sm>
      <agm-map [latitude]="lat" [longitude]="lng" [mapTypeControl]='true' [zoom]="zoom">
        <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
      </agm-map>
    </div>
  </div>
</div>
<div [ngClass.lt-sm]="'cardpadding'" fxShow.lt-sm fxHide>
  <agm-map [latitude]="lat" [longitude]="lng" [mapTypeControl]='true' [zoom]="zoom">
    <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
  </agm-map>
</div>