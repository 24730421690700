<div class="container" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column">
    <div class="cardpadding" fxLayout="column" fxLayoutAlign="center center">
      <p class="mat-title">Washing Machines</p>
      <p class="text-center">Looking to buy a new washing machine catered to your specific requirements? Choose from a
        wide range of semi-automatic, front-loading and top-loading washing machines from IFB, Haier, Whirlpool,
        Samsung, Bosch, BPL, LG and other top brands at and enjoy attractive discounts.</p>
    </div>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxLayoutGap="20px" fxHide.lt-sm fxShow>
      <div fxLayout="column" fxLayoutGap="5px">
        <div fxLayout="column">
          <p class="mat-small font-bold">Read Our FAQs</p>
          <p class="mat-small color-hover">Installation</p>
          <p class="mat-small color-hover">Returns</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Need Help Buying?</p>
          <p class="mat-small color-hover">Read our Washing Machines</p>
          <p class="mat-small color-hover">Buying Guides</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Large Appliances</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Top Brands</mat-checkbox>
        </div>
        <p class="mat-small font-bold">Department</p>
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Home & Kitchen</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Large Appliances</p>
        </div>
        <div class="padding-side" fxLayout="column">
          <p class="mat-small padding-side font-bold">Washing Machines & Dryers</p>
        </div>
        <div fxLayout="column" fxLayoutGap="5px">
          <div fxLayout="column">
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Single & Couple</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Family of 3</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Family of 4</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Large Families</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">price</p>
            <p class="mat-small color-hover">Under ₹10,000</p>
            <p class="mat-small color-hover">₹10,000 - ₹15,000</p>
            <p class="mat-small color-hover">₹15,000 - ₹20,000</p>
            <p class="mat-small color-hover">₹20,000 - ₹30,000</p>
            <p class="mat-small color-hover">Over ₹30,000</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Operation</p>
            <p class="mat-small color-hover">Fully Automatic</p>
            <p class="mat-small color-hover">Semi Automatic</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Loading</p>
            <p class="mat-small color-hover">Front-Loading</p>
            <p class="mat-small color-hover">Top-Loading</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Spin Speed</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Up to 799 RPM</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">800 to 999 RPM</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">1200 to 1399 RPM</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">1400 RPM & Above</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Brands</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Whirlpool</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">IFB</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">LG</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">DMR</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Generic</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Samsung</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Haier</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Capacity</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Up to 5.9 kg</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">6 - 6.9 kg</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">7 - 7.9 kg</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">8 kg & Above</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Suitable Room Size</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Up to 110 sqft</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">110 to 150 sqft</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">150 to 200 sqft</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Capacity</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Less than 1 Ton</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">1 to 1.3 Tons</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">1.4 to 1.5 Tons</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">1.6 to 1.9 Tons</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">2 Tons & Above</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Energy Star Rating</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">1 Star</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">2 Star</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">3 Star</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">4 Star</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">5 Star</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Coil Material</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Aluminium & Alloy</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cooper</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Features</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Air Purifier</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Antibacterial Coating
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Dehumidifier</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Dust Filter</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Headting & cooling</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">High Density Filter</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Inverter Compressor</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">WiFi</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Avg. Customers Reviews</p>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">New Arrivals</p>
            <p class="mat-small color-hover">Last 30 days</p>
            <p class="mat-small color-hover">Last 90 days</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Discount</p>
            <p class="mat-small color-hover">10% off or more</p>
            <p class="mat-small color-hover">25% off or more</p>
            <p class="mat-small color-hover">35% off or more</p>
            <p class="mat-small color-hover">50% off or more</p>
          </div>
          <!-- <div fxLayout="column">
            <p class="mat-small font-bold">Seller</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Infinite E-sales
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Bluebird_1</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">E-SALES India</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Great Indian Sales</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">TT-Enterprise</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Croma-A TATA Enterprise (Nagpur)
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">COOL ZONE</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">RK VIDEO</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Harshil Home Appliances
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Onsite Electro Service Pvt. Ltd.
            </mat-checkbox>
          </div> -->
          <div fxLayout="column">
            <p class="mat-small font-bold">Availability</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Include Out of Stock
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div>
      <mat-divider [vertical]="true" fxFlex="100"></mat-divider>
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/washing-machine/banner1.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/washing-machine/banner2.png" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/washing-machine/banner3.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gl-home/washing-machine/1.jpg" alt="" fxFlex="48">
          <img src="assets/images/gl-home/washing-machine/2.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gl-home/washing-machine/3.jpg" alt="" fxFlex="48">
          <img src="assets/images/gl-home/washing-machine/4.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div class="card slider-items" fxShow.gt-sm fxHide>
        <p class="mat-h2 padding-top">Top deals on storage devices</p>
        <mat-divider class="full-width"></mat-divider>
        <div>
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
              <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px" fxFlex>
                <div fxLayout="row" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div class="hover-border" fxLayout="column" fxLayoutAlign="start start">
                  <p class="mat-h4 color-danger">{{slide.price}}</p>
                  <div fxLayout="row">
                    <p class="font-bold">M.R.P.:<span class="mat-small text-line-through">{{slide.oldprice}}</span></p>
                    <p class="mat-small">{{slide.off}}</p>
                  </div>
                  <p class="mat-small color-accent" (click)="products()">{{slide.name}}</p>
                  <p class="mat-small">{{slide.brand}}</p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{slide.reviewno}}</p>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start stretch">
                  <button mat-raised-button color="primary" fxFlex="100">Add to cart</button>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
        <p class="mat-h2 padding-top">AC | Season’s top picks</p>
        <mat-divider class="full-width"></mat-divider>
        <div>
          <owl-carousel-o [options]="customOptions1">
            <ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
              <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px" fxFlex>
                <div (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div class="hover-border" fxLayout="column" fxLayoutAlign="start start">
                  <p class="color-hover" (click)="products()">{{slide.name}}</p>
                  <p class="mat-small">{{slide.brand}}</p>
                  <p class="mat-h2">{{slide.price}} <span class="mat-small text-line-through">{{slide.oldprice}}</span>
                  </p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{slide.reviewno}}</p>
                  </div>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap.lt-sm="10px" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <p class="mat-title">Top picks in washing machines</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gl-home/washing-machine/top/1.jpg" alt="" fxFlex="33">
          <img src="assets/images/gl-home/washing-machine/top/2.jpg" alt="" fxFlex="33">
          <img src="assets/images/gl-home/washing-machine/top/3.jpg" alt="" fxFlex="33">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gl-home/washing-machine/top/4.jpg" alt="" fxFlex="33">
          <img src="assets/images/gl-home/washing-machine/top/5.png" alt="" fxFlex="33">
          <img src="assets/images/gl-home/washing-machine/top/6.jpg" alt="" fxFlex="33">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gl-home/washing-machine/top/7.jpg" alt="" fxFlex="33">
          <img src="assets/images/gl-home/washing-machine/top/8.jpg" alt="" fxFlex="33">
          <img src="assets/images/gl-home/washing-machine/top/9.png" alt="" fxFlex="33">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/washing-machine/banner4.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <p class="mat-title">Shop on EMI with no extra cost</p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/washing-machine/emi1.jpeg" alt="" fxFlex="48">
            <img src="assets/images/gl-home/washing-machine/emi2.jpeg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/washing-machine/emi3.jpeg" alt="" fxFlex="48">
            <img src="assets/images/gl-home/washing-machine/emi4.jpeg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/washing-machine/banner5.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="column" fxLayoutGap="40px">
        <div fxLayout="row" class="card-shadow cardpadding">
          <p class="mat-small">1-24 of over 50,000 results for : <span class="font-bold color-primary"> Washing Machines
              & Dryers </span></p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
        
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover" (click)="products()">See Details</p>
              </div>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items1" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
        
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover" (click)="products()">See Details</p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items2" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
        
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover" (click)="products()">See Details</p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div *ngFor="let item of items3" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
        
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover" (click)="products()">See Details</p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row">
        <button mat-raised-button class="color-accent bg-secondary" fxFlex="100">See all results</button>
      </div>
    </div>
  </div>
  <div class="cardpadding" fxLayout="column" fxLayoutGap="10px" fxHide.lt-sm>
    <div fxLayout="column" fxLayoutGap="5px">
      <p class="mat-title color-primary">More About Washing Machines at</p>
      <p class="fomt-bold">Shop online for Washing Machines</p>
      <p class="mat-small">If your laundry room is one of those spots in your home that you dislike to enter, it is
        probably missing out on one of the very essential appliances, a washing machine! Let not your laundry room be
        incomplete without one. Find the right one for your home at Global Local’s Home Appliances section. This segment
        houses a wide assortment of washing machines from renowned manufacturers like Samsung, Whirlpool, Haier, Bosch,
        LG, Godrej, Electrolux, Panasonic and IFB that have carved a niche in the field of home appliances. From
        front-loading and top-loading ones, to automatic and semi-automatic models, Global Local India has every kind of
        washing machine to suit your needs, styles and preferences. They have all or most of the useful features like a
        number of wash cycles, electronic controls, foam control, speed options, anti crease, child lock and rinse hold
      </p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Front Load</p>
      <p class="mat-small">Models like LG F1091MDL2, IFB Elite Aqua SX, Samsung WF1650NCW/TL, Bosch WAX 20168IN and IFB
        Senorita Aqua VX are some of the washing machines that make your laundry tasks easy with their front-loading
        capability. Since they allow you to stack other items up on them, front-load washers are ideal for small laundry
        rooms and homes that do not have a separate laundry area. They are also energy efficient than their top-loading
        counterparts</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Top Load</p>
      <p class="mat-small">Top-loading washing machines are easy on your back, as they don’t require you to bend on your
        knees to load or unload the washer. Global Local houses top-loading washing machines to make laundry an effortless
        task with its range of products like Onida WO65TSPLDD1, Electrolux WM ES65LAEB-DDN, Panasonic NA-F62HS3RRB, LG
        P7853R3SBG and Godrej GWS 8502</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Semi Automatic</p>
      <p class="mat-small">If you want to invest in a washer and you are tight on your budget, Global Local keeps your needs
        covered with its budget-friendly semi-automatic models. Models like Onida WO62SBC1, LG P8540R3FDB, Onida
        WO75SBX1, LG P8837R3S and Electrolux WM ES80EEWH-CLS fall under this category. Semi automatic ones are perfect
        for you, if you are unsure of affording continuous water supply to the washer. They feature a twin-tub design,
        where you have to spend manual effort to shift the clothes from the wash tub to the drying tub.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Fully Automatic</p>
      <p class="mat-small">Brands like IFB, LG and Samsung offer fully automatic washing machines that perform all the
        functions without your assistance. Simply load the laundry, set the wash cycle and other settings according to
        the clothes that need to be washed and wait until the alarm calls for your attention. Ta-dah! Your clothes are
        clean, fresh and dry. Models like Onida WO70TSPLST1, LG F10B5NDL2, Godrej WT Eon 700 PF, LG T80FRF21P and LG
        T10RRF21V are fully automatic and make the perfect addition for homes with working women.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Capacity</p>
      <p class="mat-small">At India, you will find washing machines from capacity starting at or below 6 kgs to
        more than 8 kg to suit the size of your family. A 2kg model will be just enough for a family of two and for
        those who wash only whites and light-coloured clothes in the machine, whereas, a family of four or more might
        need a washer with a capacity of 6kg, 8kg or more.</p>
    </div>
  </div>
</div>

<div class="card-shadow" fxHide>
	<mat-card>
		<div class="cardpadding" fxLayout="column" fxLayoutGap="30px">
			<div class="slider-items1" fxLayout="column" fxLayoutGap="20px">
				<p class="mat-title">Inspired by your browsing history</p>
				<owl-carousel-o [options]="customOptions2" class="cardpadding">
					<ng-template carouselSlide *ngFor="let slide of slidesStore2" [id]="slide.id">
						<img [src]="slide.src">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="color-accent color-hover">{{slide.text}}</span>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star_border</mat-icon>
								<mat-icon>keyboard_arrow_down</mat-icon>
								<p class="color-accent color-hover">{{slide.reviewno}}</p>
							</div>
							<span class="color-danger">{{slide.price}}</span>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
			<div class="slider-items1" fxLayout="column" fxLayoutGap="20px">
				<p class="mat-title">Top subscription apps for you</p>
				<owl-carousel-o [options]="customOptions3" class="cardpadding">
					<ng-template carouselSlide *ngFor="let slide of slidesStore3" [id]="slide.id">
						<img [src]="slide.src">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="color-accent color-hover">{{slide.text}}</span>
							<span class="mat-small">{{slide.price}}</span>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star_border</mat-icon>
								<mat-icon>keyboard_arrow_down</mat-icon>
								<p class="color-accent color-hover">{{slide.reviewno}}</p>
							</div>
							<span class="color-danger">FREE</span>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
		</div>
	</mat-card>
</div>