import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-kitchen-dining',
  templateUrl: './kitchen-dining.component.html',
  styleUrls: ['./kitchen-dining.component.scss']
})
export class KitchenDiningComponent implements OnInit {

  items = [
    { image: 'assets/images/gl-home/kitchen/card/1.jpg', name: 'Amazon Brand - Solimo Revolving Spice Rack set (16 pieces)', company: 'Amazon Brand - Solimo', newprice: '₹ 599', oldprice: '₹ 1,100', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '4,016' },
    { image: 'assets/images/gl-home/kitchen/card/2.jpg', name: 'Amazon Brand - Solimo Non Stick Tawa (26cm, Induction and Gas Stove Compatible)', company: 'Amazon Brand - Solimo', newprice: '₹ 449', oldprice: '₹ 800', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '1,984' },
    { image: 'assets/images/gl-home/kitchen/card/3.jpg', name: 'Amazon Brand - Solimo Stainless Steel Tope Set (5 pieces, 420 ml, 550 ml, 840 ml, 1150 ml and 1550 ml, Induction and Gas compatible)', company: 'Amazon Brand - Solimo', newprice: '₹ 569', oldprice: '₹ 800', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '2,356' },
  ];
  items1 = [
    { image: 'assets/images/gl-home/kitchen/card/4.jpg', name: 'Amazon Brand - Solimo Plastic Cutting/Chopping Board', company: 'Amazon Brand - Solimo', newprice: '₹ 235', oldprice: '₹ 250', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '1,687' },
    { image: 'assets/images/gl-home/kitchen/card/5.jpg', name: 'Amazon Brand - Solimo Spice Jar, 200 ml, Set of 8, Black', company: 'Amazon Brand - Solimo', newprice: '₹ 249', oldprice: '₹ 400', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '2,158' },
    { image: 'assets/images/gl-home/kitchen/card/6.jpg', name: 'Amazon Brand - Solimo Plastic Container Set, 20-Pieces, Silver', company: 'Amazon Brand - Solimo', newprice: '₹ 1,089', oldprice: '₹ 2,000', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '2,210' },
  ];
  items2 = [
    { image: 'assets/images/gl-home/kitchen/card/7.jpg', name: 'Amazon Brand - Solimo Double Walled Insulated Stainless Steel Flask (1000 ml)', company: 'Amazon Brand - Solimo', newprice: '₹ 729', oldprice: '₹ 1,350', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '1,072' },
    { image: 'assets/images/gl-home/kitchen/card/8.jpg', name: 'Amazon Brand - Solimo Checkered Jar Set of 18', company: 'Amazon Brand - Solimo', newprice: '₹ 549', oldprice: '₹ 850', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '5,462' },
    { image: 'assets/images/gl-home/kitchen/card/9.jpg', name: 'Amazon Brand - Solimo Stainless Steel Induction Bottom Steamer/Modak/Momo Maker with Glass Lid (2 litres)', company: 'Amazon Brand - Solimo', newprice: '₹ 759', oldprice: '₹ 1,100', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '2,034' },
  ];
  items3 = [
    { image: 'assets/images/gl-home/kitchen/card/10.jpg', name: 'Amazon Brand - Solimo Fridge Organizer Set (4 pieces)', company: 'Amazon Brand - Solimo', newprice: '₹ 279', oldprice: '₹ 425', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '1,755' },
    { image: 'assets/images/gl-home/kitchen/card/11.jpg', name: 'Amazon Brand - Solimo 3-Piece Non-Stick Cookware Set (Induction and Gas Compatible)', company: 'Amazon Brand - Solimo', newprice: '₹ 1,299', oldprice: '₹ 2,200', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '234' },
    { image: 'assets/images/gl-home/kitchen/card/12.jpg', name: 'Amazon Brand - Solimo Stainless Steel Induction Bottom Dutch Oven with Glass Lid (20cm, 3 litres)', company: 'Amazon Brand - Solimo', newprice: '₹ 699', oldprice: '₹ 950', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '2,291' },
  ];

  constructor( public router: Router,) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 15,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/images/gl-home/kitchen/carousel/1.jpg',
      price: '₹ 279',
      oldprice: '₹ 400',
      name: 'Amazon Brand - Solimo Vegetable Chopper (Larg…',
      brand: 'Amazon Brand - Solimo',
      reviewno: '3,683'
    },
    {
      id: 2,
      src: './assets/images/gl-home/kitchen/carousel/2.jpg',
      price: '₹ 1,799',
      oldprice: '₹ 3,499',
      name: 'Amazon Brand - Solimo 500W Mixer Grinder (ISI certified) with 3 Jars',
      brand: 'Amazon Brand - Solimo',
      reviewno: '88'
    },
    {
      id: 3,
      src: './assets/images/gl-home/kitchen/carousel/3.jpg',
      price: '₹ 1,799',
      oldprice: '₹ 3,500',
      name: 'AmazonBasics 500-Watt Mixer Grinder with 3 Jars',
      brand: 'AmazonBasics',
      reviewno: '84'
    },
    {
      id: 4,
      src: './assets/images/gl-home/kitchen/carousel/4.jpg',
      price: '₹ 2,565',
      oldprice: '₹ 5,500',
      name: 'AmazonBasics Premium 750W Mixer Grinder with 3 Stainless Steel Jar + 1 Juicer Jar',
      brand: 'AmazonBasics',
      reviewno: '7'
    },
    {
      id: 5,
      src: './assets/images/gl-home/kitchen/carousel/5.jpg',
      price: '₹ 1,709',
      oldprice: '₹ 3,300',
      name: 'AmazonBasics Induction Cooktop 1900 Watt',
      brand: 'AmazonBasics',
      reviewno: '28'
    },
    {
      id: 6,
      src: './assets/images/gl-home/kitchen/carousel/6.jpg',
      price: '₹ 1,615',
      oldprice: '₹ 2,900',
      name: 'AmazonBasics Electric Rice Cooker 1.8 L (700 W) with 2 Aluminum Pans, Measuring Cup and Rice Scoop - Maroon',
      brand: 'AmazonBasics',
      reviewno: '6'
    },
    {
      id: 7,
      src: './assets/images/gl-home/kitchen/carousel/7.jpg',
      price: '₹ 999',
      oldprice: '₹ 1,999',
      name: 'Amour Portable Blender, Smoothie Juicer Cup - Six Blades in 3D, 13oz Fruit Mixing Machine with 1500mAh USB Rechargeable Batteries, Ice Tray, Detachable Cup (Green)',
      brand: 'Amour',
      reviewno: '1'
    },
    {
      id: 8,
      src: './assets/images/gl-home/kitchen/carousel/8.jpg',
      price: '₹ 2,999',
      oldprice: '₹ 5,499',
      name: 'HOMETRONICS 750W Wega Series 6 Jar Juicer Mixer Blender (Multicolour)',
      brand: 'HOMETRONICS',
      reviewno: '25'
    },
    {
      id: 9,
      src: './assets/images/gl-home/kitchen/carousel/9.jpg',
      price: '₹ 315',
      oldprice: '₹ 599',
      name: 'MAXABLE Magic Fold able Plastic Whisk Beater Hand Blender Mixer Froth Whisker Latte Maker for Milk Coffee Egg Beater Juicer (Multi Color) Set of 2',
      brand: 'MAXABLE',
      reviewno: '4'
    },
    {
      id: 10,
      src: './assets/images/gl-home/kitchen/carousel/10.jpg',
      price: '₹ 525',
      oldprice: '₹ 1,399',
      name: 'SWIYAM 100% Makrana Marble Chakla/Rolling Board/Roti Maker (Premium Finish) (White + Wooden Belan,) 9 Inch',
      brand: 'SWIYAM',
      reviewno: '18'
    },
  ]
  customOptions1: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 15,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/gl-home/kitchen/carousel1/1.jpg',
      price: '₹ 249',
      oldprice: '₹ 600',
      name: 'Amazon Brand - Solimo 500 ml Large Vegetable Chopper with 3 Blades, Blue',
      brand: 'Amazon Brand - Solimo',
      reviewno: '7,367'
    },
    {
      id: 2,
      src: './assets/images/gl-home/kitchen/carousel1/2.jpg',
      price: '₹ 149',
      oldprice: '₹ 300',
      name: 'Amazon Brand - Solimo Plastic Kitchen Peeler - Green',
      brand: 'Amazon Brand - Solimo',
      reviewno: '1,264'
    },
    {
      id: 3,
      src: './assets/images/gl-home/kitchen/carousel1/3.jpg',
      price: '₹ 169',
      oldprice: '₹ 225',
      name: 'Amazon Brand - Solimo Food Grade Stainless Steel Lemon Squeezer',
      brand: 'Amazon Brand - Solimo',
      reviewno: '1,789'
    },
    {
      id: 4,
      src: './assets/images/gl-home/kitchen/carousel1/4.jpg',
      price: '₹ 235',
      oldprice: '₹ 600',
      name: 'Amazon Brand - Solimo 500 ml Large Vegetable Chopper with 3 Blades, Green',
      brand: 'Amazon Brand - Solimo',
      reviewno: '4,193'
    },
    {
      id: 5,
      src: './assets/images/gl-home/kitchen/carousel1/5.jpg',
      price: '₹ 235',
      oldprice: '₹ 250',
      name: 'Amazon Brand - Solimo Plastic Cutting/Chopping Board',
      brand: 'Amazon Brand - Solimo',
      reviewno: '1,648'
    },
    {
      id: 6,
      src: './assets/images/gl-home/kitchen/carousel1/6.jpg',
      price: '₹ 499',
      oldprice: '₹ 1,000',
      name: 'Amazon Brand - Solimo Premium Stainless Steel Chefs Knife, Silver',
      brand: 'Amazon Brand - Solimo',
      reviewno: '2,267'
    },
    {
      id: 7,
      src: './assets/images/gl-home/kitchen/carousel1/7.jpg',
      price: '₹ 329',
      oldprice: '₹ 500',
      name: 'Amazon Brand - Solimo Plastic Atta/Dough Maker',
      brand: 'Amazon Brand - Solimo',
      reviewno: '996'
    },
    {
      id: 8,
      src: './assets/images/gl-home/kitchen/carousel1/8.jpg',
      price: '₹ 109',
      oldprice: '₹ 200',
      name: 'Amazon Brand - Solimo Silicone Rubber Funnel for Kitchen, Green',
      brand: 'Amazon Brand - Solimo',
      reviewno: '1,221'
    },
    {
      id: 9,
      src: './assets/images/gl-home/kitchen/carousel1/9.jpg',
      price: '₹ 179',
      oldprice: '₹ 300',
      name: 'Amazon Brand - Solimo Stainless Steel Cheese Grater, Black',
      brand: 'Amazon Brand - Solimo',
      reviewno: '639'
    },
    {
      id: 10,
      src: './assets/images/gl-home/kitchen/carousel1/10.jpg',
      price: '₹ 249',
      oldprice: '₹ 349',
      name: 'Amazon Brand - Solimo Compact Vegetable Chopper (350 ml, Green)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '5,937'
    },
  ]
  customOptions2: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 15,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore2 = [
    {
      id: 1,
      src: './assets/images/gl-home/kitchen/carousel2/1.jpg',
      price: '₹ 449',
      oldprice: '₹ 800',
      name: 'Amazon Brand - Solimo Non Stick Tawa (26cm, Induction and Gas Stove Compatible)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '1,959'
    },
    {
      id: 2,
      src: './assets/images/gl-home/kitchen/carousel2/2.jpg',
      price: '₹ 569',
      oldprice: '₹ 800',
      name: 'Amazon Brand - Solimo Stainless Steel Tope Set (5 pieces, 420 ml, 550 ml, 840 ml, 1150 ml and 1550 ml, Induction and Gas compatible)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '2,363'
    },
    {
      id: 3,
      src: './assets/images/gl-home/kitchen/carousel2/3.jpg',
      price: '₹ 759',
      oldprice: '₹ 1,100',
      name: 'Amazon Brand - Solimo Stainless Steel Induction Bottom Steamer/Modak/Momo Maker with Glass Lid (2 litres)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '2,169'
    },
    {
      id: 4,
      src: './assets/images/gl-home/kitchen/carousel2/4.jpg',
      price: '₹ 1,299',
      oldprice: '₹ 2,200',
      name: 'Amazon Brand - Solimo 3-Piece Non-Stick Cookware Set (Induction and Gas Compatible)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '234'
    },
    {
      id: 5,
      src: './assets/images/gl-home/kitchen/carousel2/5.jpg',
      price: '₹ 699',
      oldprice: '₹ 950',
      name: 'Amazon Brand - Solimo Stainless Steel Induction Bottom Dutch Oven with Glass Lid (20cm, 3 litres)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '2,280'
    },
    {
      id: 6,
      src: './assets/images/gl-home/kitchen/carousel2/6.jpg',
      price: '₹ 699',
      oldprice: '₹ 950',
      name: 'Amazon Brand - Solimo Stainless Steel Induction Bottom Dutch Oven with Glass Lid (20cm, 3 litres)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '2,221'
    },
    {
      id: 7,
      src: './assets/images/gl-home/kitchen/carousel2/7.jpg',
      price: '₹ 1,099',
      oldprice: '₹ 4,000',
      name: 'AmazonBasics Pre-Seasoned Cast Iron Skillet Pan - L (10.25 inch, 2.18 Kgs)',
      brand: 'AmazonBasics',
      reviewno: '5,264'
    },
    {
      id: 8,
      src: './assets/images/gl-home/kitchen/carousel2/8.jpg',
      price: '₹ 909',
      oldprice: '₹ 1,800',
      name: 'Amazon Brand - Solimo Pre-Seasoned Cast Iron Dosa Tawa, 12 Inches (30 cm)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '45'
    },
    {
      id: 9,
      src: './assets/images/gl-home/kitchen/carousel2/9.jpg',
      price: '₹ 3,699',
      oldprice: '₹ 6,000',
      name: 'AmazonBasics 15-Piece Non-Stick Cookware Set',
      brand: 'AmazonBasics',
      reviewno: '8,867'
    },
    {
      id: 10,
      src: './assets/images/gl-home/kitchen/carousel2/10.jpg',
      price: '₹ 949',
      oldprice: '₹ 1,530',
      name: 'Amazon Brand - Solimo Non Stick Handi with Stainless Steel Lid (4.25L, Hammertone finish, 3 coat, 2.9mm thickness)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '38'
    },
    
  ]
  customOptions3: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 15,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore3 = [
    {
      id: 1,
      src: './assets/images/gl-home/kitchen/carousel3/1.jpg',
      price: '₹ 865',
      oldprice: '₹ 1,800',
      name: 'Amazon Brand - Solimo Pre-Seasoned Cast Iron Dosa Tawa, 12 Inches (30 cm)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '49'
    },
    {
      id: 2,
      src: './assets/images/gl-home/kitchen/carousel3/2.jpg',
      price: '₹ 299',
      oldprice: '₹ 799',
      name: 'SKAFA Shesham Wood Cooking Spoon, Set of 7, Brown',
      brand: '',
      reviewno: '24'
    },
    {
      id: 3,
      src: './assets/images/gl-home/kitchen/carousel3/3.jpg',
      price: '₹ 309',
      oldprice: '₹ 700',
      name: 'Amazon Brand - Solimo Borosilicate Glass Water Bottle with Sleeve, 500 ml',
      brand: 'Amazon Brand - Solimo',
      reviewno: '35'
    },
    {
      id: 4,
      src: './assets/images/gl-home/kitchen/carousel3/4.jpg',
      price: '₹ 519',
      oldprice: '₹ 1,050',
      name: 'Amazon Brand – Solimo Stainless Steel Insulated Roti Server, 1.1 Litres',
      brand: 'Amazon Brand - Solimo',
      reviewno: '20'
    },
    {
      id: 5,
      src: './assets/images/gl-home/kitchen/carousel3/5.jpg',
      price: '₹ 605',
      oldprice: '₹ 1,200',
      name: 'Amazon Brand - Solimo Stainless Steel T Pan, Induction Base, 1.5 Litre',
      brand: 'Amazon Brand - Solimo',
      reviewno: '16'
    },
    {
      id: 6,
      src: './assets/images/gl-home/kitchen/carousel3/6.jpg',
      price: '₹ 369',
      oldprice: '₹ 850',
      name: 'Amazon Brand - Solimo Borosilicate Glass Water Bottle, 1 Litre',
      brand: 'Amazon Brand - Solimo',
      reviewno: '22'
    },
    {
      id: 7,
      src: './assets/images/gl-home/kitchen/carousel3/7.jpg',
      price: '₹ 539',
      oldprice: '₹ 1,100',
      name: 'Amazon Brand - Solimo Stainless Steel Saucepan with Glass Lid, Induction Base (16 cm, 1.5L)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '10'
    },
    {
      id: 8,
      src: './assets/images/gl-home/kitchen/carousel3/8.jpg',
      price: '₹ 749',
      oldprice: '₹ 1,500',
      name: 'Amazon Brand - Solimo Aluminium Outer Lid Pressure Cooker 2 L (Non- Induction Base)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '12'
    },
    {
      id: 9,
      src: './assets/images/gl-home/kitchen/carousel3/9.jpg',
      price: '₹ 669',
      oldprice: '₹ 1,300',
      name: 'Amazon Brand - Solimo Inner Steel Casserole, Set of 3 (450/900/1450 ml), Brown',
      brand: 'Amazon Brand - Solimo',
      reviewno: '96'
    },
    {
      id: 10,
      src: './assets/images/gl-home/kitchen/carousel3/10.jpg',
      price: '₹ 549',
      oldprice: '₹ 1,100',
      name: 'Amazon Brand - Solimo Inner Steel Casserole, Set of 3 (450/900/1450ml), Blue',
      brand: 'Amazon Brand - Solimo',
      reviewno: '9'
    },
  ]
  customOptions7: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore7 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel1/1.jpg',
      text: 'Emporium Car Back Pain Relief Lower Back Support for Chair Back Rest for Office Chair Lumbar…',
      reviewno: '242',
      price: '₹ 349.00'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel1/2.jpg',
      text: 'BLUEWUD Clonard Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '242',
      price: '₹ 2,899.00'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel1/3.jpg',
      text: 'BLUEWUD Mallium Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '331',
      price: '₹ 3,339.00'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel1/4.jpg',
      text: 'OGU Om Cotton Round Batti Wicks for Pooja Aarti (White) -2100 Pieces',
      reviewno: '61',
      price: '₹ 250'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel1/5.jpg',
      text: 'pTron Bassbuds Pro in-Ear True Wireless Bluetooth 5.0 Headphones with Deep Bass, Touch Control…',
      reviewno: '8,370',
      price: '₹ 1,299.00'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel1/6.jpg',
      text: 'HealthSense Soft-Spot BC 21 Orthopedic Backrest Cushion with Memory Foam for Study, Home,…',
      reviewno: '931',
      price: '₹ 1,399.00'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel1/7.jpg',
      text: 'Nutriplato-enriching lives Roasted Salted Flax Seeds, 500 g',
      reviewno: '325',
      price: '₹ 199.00'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel1/8.jpg',
      text: 'ADOC Chairs Oren Medium Back Ergonomic Office Chair with Mesh Back, Fixed Comfortable…',
      reviewno: '58',
      price: '₹ 3,049.00'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel1/9.jpg',
      text: 'Angel Bear 1 Cup Toothbrush Toothpaste Stand Holder Bathroom Storage Organizer,Plastic',
      reviewno: '379',
      price: '₹ 249.00'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel1/10.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '165',
      price: '₹ 3,999.00'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel1/11.jpg',
      text: 'TIZUM High Speed HDMI Cable with Ethernet - Supports 3D, 4K and Audio Return (1.8 Meter/ 6 Feet)',
      reviewno: '2,281',
      price: '₹ 145.00'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel1/12.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '30',
      price: '₹ 3,799.00'
    },
  ]
  customOptions8: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore8 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel2/1.jpeg',
      text: 'VPN Software & VPN free unlimited (Fire TV, 4k sticks)',
      reviewno: '1,108',
      price: 'BackySoft'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel2/2.jpg',
      text: 'AirScreen - AirPlay & Google Cast & Miracast & DLNA',
      reviewno: '34,550',
      price: '重庆爱奥尼科技有限公司'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel2/3.jpeg',
      text: 'Trashy Movies Channel',
      reviewno: '1,561',
      price: 'POV Horror'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel2/4.jpeg',
      text: 'Wicked Horror TV',
      reviewno: '3,961',
      price: 'POV Horror'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel2/5.jpeg',
      text: 'Trashy Horror Movies',
      reviewno: '1,375',
      price: 'POV Horror'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel2/6.jpeg',
      text: 'B-Movie Club',
      reviewno: '738',
      price: 'POV Horror'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel2/7.jpeg',
      text: 'VPN Lite & Full Privacy (RVPN)',
      reviewno: '358',
      price: 'BackySoft'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel2/8.jpg',
      text: 'Plex: Stream Movies, Shows, Live TV, Music, and More',
      reviewno: '51,727',
      price: 'Plex'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel2/9.jpeg',
      text: 'Martial Arts and Kung-Fu Movie Channel',
      reviewno: '4,629',
      price: 'POV Horror'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel2/10.jpg',
      text: 'NordVPN - Fast, Secure and Unlimited VPN app for Android. Stay secure and…',
      reviewno: '21,882',
      price: 'nordvpn s.a.'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel2/11.jpeg',
      text: 'Thriller Movie Channel',
      reviewno: '232',
      price: 'POV Horror'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel2/12.jpeg',
      text: 'Cleaner for Fire Tablets & TV (Cleanza)',
      reviewno: '6,219',
      price: 'Quickla Soft'
    },
  ]
}
