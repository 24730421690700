<div class="container" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column">
    <div class="cardpadding" fxLayout="column" fxLayoutAlign="center center">
      <p class="mat-title">Refrigerators</p>
      <p class="text-center">Planning on buying a new
        air conditioner that suits your needs? Choose from a wide selection of
        <a class="color-accent text-underline">Single-door</a>,
        <a class="color-accent text-underline">double-door</a>, 
        <a class="color-accent text-underline">side-by-side</a>, 
        <a class="color-accent text-underline">multi-door</a>, 
        <a class="color-accent text-underline">mini refrigerators</a>, 
        <a class="color-accent text-underline">frost-free</a>, and
        <a class="color-accent text-underline">direct-cool</a> refrigerators from 
        <a class="color-accent text-underline">LG</a>, 
        <a class="color-accent text-underline">Samsung</a>,
        <a class="color-accent text-underline">Haier</a>,
        <a class="color-accent text-underline">Whirlpool</a>, 
        <a class="color-accent text-underline">Godrej</a>, 
        <a class="color-accent text-underline">BPL</a>, 
        <a class="color-accent text-underline">Mitashi</a>, 
        <a class="color-accent text-underline">Hitachi</a>, 
        <a class="color-accent text-underline">Bosch</a> and other top brands at
        <a class="color-accent text-underline"> Global local</a>.
      </p>
    </div>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxLayoutGap="20px" fxHide.lt-sm fxShow>
      <div fxLayout="column" fxLayoutGap="5px">
        <div fxLayout="column">
          <p class="mat-small font-bold">Large Appliances</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Top Brands</mat-checkbox>
        </div>
        <p class="mat-small font-bold">Department</p>
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Home & Kitchen</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Large Appliances</p>
        </div>
        <div class="padding-side" fxLayout="column">
          <p class="mat-small padding-side font-bold">Refrigerators</p>
        </div>
        <div fxLayout="column" fxLayoutGap="5px">
          <div fxLayout="column">
            <p class="mat-small font-bold">Brands</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">LG</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Whirlpool</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Samsung</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">CROMA</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Haier</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">ROCKWELL</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Rockwell</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">price</p>
            <p class="mat-small color-hover">Under ₹10,000</p>
            <p class="mat-small color-hover">₹10,000 - ₹15,000</p>
            <p class="mat-small color-hover">₹15,000 - ₹20,000</p>
            <p class="mat-small color-hover">₹20,000 - ₹30,000</p>
            <p class="mat-small color-hover">Over ₹30,000</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Door Style</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Door-in-door</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Multi-door</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Side-by-side</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Standard Double Door</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Standard Single Door</mat-checkbox>          
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Capacity</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Up to 120 l</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">121 - 200 l</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">201 - 230 l</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">230 - 300 l</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">301 - 400 l</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">401 l & Above</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Energy Star Rating</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">1 Star</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">2 Star</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">3 Star</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">4 Star</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">5 Star</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Features</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Convertible</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Coolpad</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Door Lock</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Inverter Compressor</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Base stand with Drawer</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Stabilizer Free Operation</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Temprature Control</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Water Dispensor</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Wi-Fi Control</mat-checkbox>            
          </div>
          <div fxLayout="column">
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Single & Couple</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Family of 3</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Family of 4</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Large Families</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Defrost System</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Direct Cool</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Frost Free</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Door Pattern</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Floral</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Solid</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Shelf Type</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Toughened Glass</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Avg. Customers Reviews</p>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">New Arrivals</p>
            <p class="mat-small color-hover">Last 30 days</p>
            <p class="mat-small color-hover">Last 90 days</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Discount</p>
            <p class="mat-small color-hover">10% off or more</p>
            <p class="mat-small color-hover">25% off or more</p>
            <p class="mat-small color-hover">35% off or more</p>
            <p class="mat-small color-hover">50% off or more</p>
          </div>
          <!-- <div fxLayout="column">
            <p class="mat-small font-bold">Seller</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Infinite E-sales
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Bluebird_1</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">E-SALES India</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Great Indian Sales</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">TT-Enterprise</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Croma-A TATA Enterprise (Nagpur)
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">COOL ZONE</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">RK VIDEO</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Harshil Home Appliances
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Onsite Electro Service Pvt. Ltd.
            </mat-checkbox>
          </div> -->
          <div fxLayout="column">
            <p class="mat-small font-bold">Availability</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Include Out of Stock
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div>
      <mat-divider [vertical]="true" fxFlex="100"></mat-divider>
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/refrigerator/banner1.png" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/refrigerator/banner2.png" alt="" class="full-width">
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <p class="mat-title">In the spotlight</p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/refrigerator/spot1.png" alt="" fxFlex="48">
            <img src="assets/images/gl-home/refrigerator/spot2.png" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/refrigerator/spot3.jpg" alt="" fxFlex="48">
            <img src="assets/images/gl-home/refrigerator/spot4.png" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div class="card slider-items" fxShow.gt-sm fxHide>
        <p class="mat-h2 padding-top">Top deals on storage devices</p>
        <mat-divider class="full-width"></mat-divider>
        <div>
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
              <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px" fxFlex>
                <div fxLayout="row" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div class="hover-border" fxLayout="column" fxLayoutAlign="start start">
                  <p class="mat-h4 color-danger">{{slide.price}}</p>
                  <div fxLayout="row">
                    <p class="font-bold">M.R.P.:<span class="mat-small text-line-through">{{slide.oldprice}}</span></p>
                    <p class="mat-small">{{slide.off}}</p>
                  </div>
                  <p class="mat-small color-accent" (click)="products()">{{slide.name}}</p>
                  <p class="mat-small">{{slide.brand}}</p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{slide.reviewno}}</p>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center stretch">
                  <button mat-raised-button color="primary" fxFlex="80">Add to cart</button>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <p class="mat-headline">Choose the right type of refrigerator for your home</p>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/refrigerator/right1.png" alt="" fxFlex="48">
            <img src="assets/images/gl-home/refrigerator/right2.png" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/refrigerator/right3.png" alt="" fxFlex="48">
            <img src="assets/images/gl-home/refrigerator/right4.png" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/refrigerator/banner3.jpg" alt="" class="full-width">
      </div>
      <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
        <p class="mat-h2 padding-top">AC | Season’s top picks</p>
        <mat-divider class="full-width"></mat-divider>
        <div>
          <owl-carousel-o [options]="customOptions1">
            <ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
              <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px" fxFlex>
                <div fxLayoutAlign="center center" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div class="hover-border" fxLayout="column" fxLayoutAlign="start start">
                  <p class="color-hover overflow-hidden color-hover" (click)="products()"> {{slide.name}}</p>
                  <p class="mat-small">{{slide.brand}}</p>
                  <p class="mat-h2">{{slide.price}} <span class="mat-small text-line-through">{{slide.oldprice}}</span>
                  </p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{slide.reviewno}}</p>
                  </div>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/refrigerator/banner4.png" alt="" class="full-width">
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <p class="mat-headline">Choose a refrigerator that fits your budget</p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/refrigerator/budget1.jpeg" alt="" fxFlex="48">
            <img src="assets/images/gl-home/refrigerator/budget2.jpeg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/refrigerator/budget3.jpeg" alt="" fxFlex="48">
            <img src="assets/images/gl-home/refrigerator/budget4.jpeg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <p class="mat-headline">Choose a refrigerator as per your family size</p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/refrigerator/family1.png" alt="" fxFlex="48">
            <img src="assets/images/gl-home/refrigerator/family2.png" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/refrigerator/family3.png" alt="" fxFlex="48">
            <img src="assets/images/gl-home/refrigerator/family4.png" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <p class="mat-headline">Reasons to buy from us</p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/refrigerator/reason1.png" alt="" fxFlex="31">
            <img src="assets/images/gl-home/refrigerator/reason2.png" alt="" fxFlex="31">
            <img src="assets/images/gl-home/refrigerator/reason3.png" alt="" fxFlex="31">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <p class="mat-headline">Make your purchase more affordable</p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/refrigerator/purchase1.png" alt="" fxFlex="48">
            <img src="assets/images/gl-home/refrigerator/purchase2.png" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/refrigerator/purchase3.png" alt="" fxFlex="48">
            <img src="assets/images/gl-home/refrigerator/purchase4.png" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
        <p class="mat-title" fxLayoutAlign="center center">Explore Top Brands</p>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/refrigerator/brand1.png" alt="" fxFlex="48">
            <img src="assets/images/gl-home/refrigerator/brand2.png" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/refrigerator/brand3.png" alt="" fxFlex="48">
            <img src="assets/images/gl-home/refrigerator/brand4.png" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/refrigerator/brand5.png" alt="" fxFlex="48">
            <img src="assets/images/gl-home/refrigerator/brand6.png" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/refrigerator/brand7.png" alt="" fxFlex="48">
            <img src="assets/images/gl-home/refrigerator/brand8.png" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/refrigerator/banner5.png" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/refrigerator/banner6.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="column" fxLayoutGap="40px">
        <div fxLayout="row" class="card-shadow cardpadding">
          <p class="mat-small">1-24 of over 50,000 results for : <span class="font-bold color-primary"> Refrigerators </span></p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold mat-h3"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
              </div>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items1" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold mat-h3"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items2" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold mat-h3"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div *ngFor="let item of items3" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold mat-h3"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row">
        <button mat-raised-button class="color-accent bg-secondary" fxFlex="100">See all results</button>
      </div>
    </div>
  </div>
</div>

<div class="card-shadow" fxHide>
	<mat-card>
		<div class="cardpadding" fxLayout="column" fxLayoutGap="30px">
			<div class="slider-items1" fxLayout="column" fxLayoutGap="20px">
				<p class="mat-title">Inspired by your browsing history</p>
				<owl-carousel-o [options]="customOptions2" class="cardpadding">
					<ng-template carouselSlide *ngFor="let slide of slidesStore2" [id]="slide.id">
						<img [src]="slide.src">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="color-accent color-hover">{{slide.text}}</span>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star_border</mat-icon>
								<mat-icon>keyboard_arrow_down</mat-icon>
								<p class="color-accent color-hover">{{slide.reviewno}}</p>
							</div>
							<span class="color-danger">{{slide.price}}</span>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
			<div class="slider-items1" fxLayout="column" fxLayoutGap="20px">
				<p class="mat-title">Top subscription apps for you</p>
				<owl-carousel-o [options]="customOptions3" class="cardpadding">
					<ng-template carouselSlide *ngFor="let slide of slidesStore3" [id]="slide.id">
						<img [src]="slide.src">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="color-accent color-hover">{{slide.text}}</span>
							<span class="mat-small">{{slide.price}}</span>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star_border</mat-icon>
								<mat-icon>keyboard_arrow_down</mat-icon>
								<p class="color-accent color-hover">{{slide.reviewno}}</p>
							</div>
							<span class="color-danger">FREE</span>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
		</div>
	</mat-card>
</div>