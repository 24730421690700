<div fxLayout="column" fxLayoutAlign="center center" class="row-height1">
  <div fxLayout fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayoutGap.xs="10px">
      <mat-card class="container card-shadow">
        <mat-card-title>
          <span fxLayoutAlign="center">Feedback</span>
        </mat-card-title>
        <mat-card-content class="padding-top">
          <form [formGroup]="inquiryFormGroup" autocomplete="off" novalidate>
            <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutAlign="flex-start">
              <!-- Name field -->
              <mat-form-field appearance="outline">
                <mat-label>Your Name</mat-label>
                <input formControlName="yourName" matInput placeholder="Your Name" maxlength="256" required>
                <mat-error>Name is required.</mat-error>
              </mat-form-field>
              <!-- Mobile number field -->
              <mat-form-field appearance="outline">
                <mat-label>Mobile number</mat-label>
                <input formControlName="phoneNumber" type="tel" id="mobileno" matInput placeholder="9876543210"
                  maxlength="10" required>
                <mat-error *ngIf="txtMobileno.errors?.required">Mobile Number is required.
                </mat-error>
                <mat-error *ngIf="txtMobileno.errors?.pattern">Invalid Mobile No.
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutAlign="flex-start">
              <!-- Email field -->
              <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input formControlName="email" [(ngModel)]="this.yourEmail" id="email" matInput maxlength="256" placeholder="your@example.com"
                  required>
                <mat-error *ngIf="txtEmail.errors?.required"> Email is required. </mat-error>
                <mat-error *ngIf="txtEmail.errors?.pattern">Invalid Email Id
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Subject</mat-label>
                <input formControlName="subject" matInput maxlength="256" type="text" id="subject"
                  placeholder="Subject">
              </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutGap="5px">
              <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutAlign="flex-start">
                <!-- Message field -->
                <mat-form-field appearance="outline">
                  <mat-label>Message</mat-label>
                  <textarea formControlName="message" matInput maxlength="256"></textarea>
                </mat-form-field>
              </div>
            </div>
          </form>
          <div *ngIf="error">
            <mat-error>{{this.error}}</mat-error>
          </div>
          <!-- Submit button -->
          <div fxLayoutAlign="center center" class="margin-top">
            <button mat-raised-button fxFlex="86" color="primary" (click)="inquiry()"
              [disabled]="inquiryFormGroup.invalid">Submit</button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>