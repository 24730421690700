<div class="container" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column">
    <div class="cardpadding" fxLayout="column" fxLayoutAlign="center center">
      <p class="mat-title">Stationery & Office Supplies</p>
      <p class="text-center"><a class="color-accent text-underline" routerLink="/best-seller">Bestsellers</a> | Browse
        through the wide
        range of
        <a class="color-accent text-underline">Pens & writing supplies</a>,
        <a class="color-accent text-underline">Pencils</a>,
        <a class="color-accent text-underline">Notebooks</a>,
        <a class="color-accent text-underline">Pads & Diaries</a>,
        <a class="color-accent text-underline">Craft supplies</a>,
        <a class="color-accent text-underline">Calculators</a>,
        <a class="color-accent text-underline">Printers & Inks</a> and much more at Global Local India. Shop for
        <a class="color-accent text-underline">Stationery products</a> by top brands such as
        <a class="color-accent text-underline">Cello</a>,
        <a class="color-accent text-underline">Camlin</a>,
        <a class="color-accent text-underline">Orpat</a>,
        <a class="color-accent text-underline">Portronics</a>,
        <a class="color-accent text-underline">JK</a>,
        <a class="color-accent text-underline">Pilot</a>, and many others at affordable prices on Global Local.
      </p>
    </div>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxLayoutGap="20px" fxHide.lt-sm fxShow>
      <div fxLayout="column" fxLayoutGap="5px">
        <p class="mat-small font-bold">Department</p>
        <div class="padding-side " fxLayout="column">
          <p class="mat-small font-bold">Office Products</p>
          <div class="padding-side" fxLayout="column">
            <p class="mat-small color-hover">Stationery</p>
            <p class="mat-small color-hover">Calendars, Planners & Personal Organisers</p>
            <p class="mat-small color-hover">Calculator</p>
            <p class="mat-small color-hover">Envelopers & Postal Supplies</p>
            <p class="mat-small color-hover">Office Electronics</p>
            <p class="mat-small color-hover">Office Paper Products</p>
            <p class="mat-small color-hover">Office Suppliess</p>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="5px">
          <div fxLayout="column">
            <p class="mat-small font-bold">Pay on Delivery</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Eligible for Pay On
              Delivery</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Brands</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">HP</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">MI</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Faber-Castell</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Camlin</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">GIZGA essentials</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">JK</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cello</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Avg. Customers Reviews</p>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">New Arrivals</p>
            <p class="mat-small color-hover">Last 30 days</p>
            <p class="mat-small color-hover">Last 90 days</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Item Condition <span class="mat-h5">(What's this?)</span></p>
            <p class="mat-small color-hover">New</p>
            <p class="mat-small color-hover">Renewed</p>
            <p class="mat-small color-hover">Used</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">price</p>
            <p class="mat-small color-hover">Under ₹1,000</p>
            <p class="mat-small color-hover">₹1,000 - ₹5,000</p>
            <p class="mat-small color-hover">₹5,000 - ₹10,000</p>
            <p class="mat-small color-hover">₹10,000 - ₹20,000</p>
            <p class="mat-small color-hover">Over ₹20,000</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Deals</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Today's Deals
            </mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Discount</p>
            <p class="mat-small color-hover">10% off or more</p>
            <p class="mat-small color-hover">25% off or more</p>
            <p class="mat-small color-hover">35% off or more</p>
            <p class="mat-small color-hover">50% off or more</p>
          </div>
          <!-- <div fxLayout="column">
            <p class="mat-small font-bold">Seller</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Appario Retail Private Ltd
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cart2India Online
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Global Klamp
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Card2India SLP
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">BENEDICTIONS
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">STS Shop
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cloudtail India
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Ondesk
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Creative Hands Art Material
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">coi
            </mat-checkbox>
          </div> -->
          <div fxLayout="column">
            <p class="mat-small font-bold">Availability</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Include Out of Stock
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div>
      <mat-divider [vertical]="true" fxFlex="100"></mat-divider>
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <p class="mat-headline">Shop by category</p>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/stationery/1.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/stationery/2.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/stationery/3.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/stationery/4.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/stationery/5.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/stationery/6.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/stationery/7.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/stationery/8.jpg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
        <p class="mat-h2 padding-top">Office & stationery coupons</p>
        <div>
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
              <div class="hover-border" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px" fxFlex>
                <div fxLayout="row" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div class="cardpadding" fxLayout="column" fxLayoutAlign="start start">
                  <p class="mat-title color-green">{{slide.save}} </p>
                  <p class="mat-small color-accent" (click)="products()">{{slide.name}}</p>
                </div>
                <div fxLayout="column" fxLayoutAlign="start stretch">
                  <button mat-raised-button color="primary">{{slide.button}}</button>
                  <p class="mat-small color-secondary">Discount at checkout</p>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div class="card slider-items1" fxHide fxShow.lt-sm>
        <p class="mat-h2 padding-top">Office & stationery coupons</p>
        <div>
          <owl-carousel-o [options]="customOptions1">
            <ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
              <div class="hover-border" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px" fxFlex>
                <div fxLayout="row" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div class="cardpadding" fxLayout="column" fxLayoutAlign="start start">
                  <p class="mat-title color-green">{{slide.save}} </p>
                  <p class="mat-small color-accent" (click)="products()">{{slide.name}}</p>
                </div>
                <div fxLayout="column" fxLayoutAlign="start stretch">
                  <button mat-raised-button color="primary">{{slide.button}}</button>
                  <p class="mat-small color-secondary">Discount at checkout</p>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px" fxHide.lt-sm>
        <img src="assets/images/electronics/stationery/banner1.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px" fxHide>
        <img src="assets/images/electronics/stationery/banner2.png" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px" fxHide.gt-sm>
        <img src="assets/images/electronics/stationery/banner1s.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px" fxHide>
        <img src="assets/images/electronics/stationery/banner2s.png" alt="" class="full-width">
      </div>
      <div fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
        <p class="mat-title" fxLayoutAlign="center center">Shop by Brand</p>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/stationery/brand1.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/stationery/brand2.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/stationery/brand3.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/stationery/brand4.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/stationery/brand5.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/stationery/brand6.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/stationery/brand7.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/stationery/brand8.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/stationery/brand9.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/stationery/brand10.jpg   " alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/stationery/brand11.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/stationery/brand12.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/stationery/brand13.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/stationery/brand14.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/stationery/brand15.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/stationery/brand16.jpg   " alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/stationery/brand17.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/stationery/brand18.jpg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="40px">
        <div fxLayout="row" class="card-shadow cardpadding">
          <p class="mat-small">1-24 of over 50,000 results for : <span class="font-bold color-primary"> Air Conditioners </span></p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-accent color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover">See Details</p>
              </div>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items1" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-accent color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover">See Details</p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items2" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-accent color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover">See Details</p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div *ngFor="let item of items3" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-accent color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover">See Details</p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row">
        <button mat-raised-button class="color-accent bg-secondary" fxFlex="100">See all results</button>
      </div>
    </div>
  </div>
  <div class="cardpadding" fxLayout="column" fxLayoutGap="10px" fxHide.lt-sm>
    <div fxLayout="column" fxLayoutGap="5px">
      <p class="mat-title color-primary">Buy Stationery and Office Products Online at Global Local India</p>
      <p class="mat-small">If you’re looking for stationery and writing supplies for your home or office, Global Local is
        the place for you. Explore the wide range of branded and durable stationery supplies and shop from this
        selection to avail amazing deals, discounts and offers at Global Local India.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Search for Stationery Products by Category</p>
      <p class="mat-small">Available in various colours, sizes, designs, patterns and brands, you will easily be able to
        filter your search according to your preference of stationery at Global Local. You will easily be able to select
        from pens, fountain pens, calculators, markers, writing pads, paper and much more at affordable prices. Whether
        you are searching for writing supplies or arts and craft products, you will be able to find all stationery
        essentials at Global Local India.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Explore the Bestselling Writing Supplies and Other Stationery at Global Local</p>
      <p class="mat-small">Whether it is for home or office use, you will be able to find a various stationery products
        and supplies at the best prices. Take notes, highlight important points and carry on with your daily tasks with
        ease by purchasing quality writing supplies and stationery online at Global Local.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Buy Branded and Quality Stationery Products Online at Global Local India</p>
      <p class="mat-small">Giving you brands such as Cello, Camlin, Orpat, Portronics, JK, Casio, Pilot and many others,
        you can be assured that you are purchasing the highest quality and durability. Enjoy a shopping experience like
        never before by logging on to Global Local and searching for the bestselling pencils, pens, writing supplies and
        stationery.</p>
    </div>
  </div>
</div>
<div class="card-shadow" fxHide.lt-sm>
	<mat-card>
		<div class="cardpadding" fxLayout="column" fxLayoutGap="30px">
			<div class="slider-items" fxLayout="column" fxLayoutGap="20px">
				<p class="mat-title">Inspired by your browsing history</p>
				<owl-carousel-o [options]="customOptions2" class="cardpadding">
					<ng-template carouselSlide *ngFor="let slide of slidesStore2" [id]="slide.id">
						<img [src]="slide.src">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="color-accent color-hover">{{slide.text}}</span>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star_border</mat-icon>
								<mat-icon>keyboard_arrow_down</mat-icon>
								<p class="color-accent color-hover">{{slide.reviewno}}</p>
							</div>
							<span class="color-danger">{{slide.price}}</span>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
			<div class="slider-items" fxLayout="column" fxLayoutGap="20px">
				<p class="mat-title">Top subscription apps for you</p>
				<owl-carousel-o [options]="customOptions3" class="cardpadding">
					<ng-template carouselSlide *ngFor="let slide of slidesStore3" [id]="slide.id">
						<img [src]="slide.src">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="color-accent color-hover">{{slide.text}}</span>
							<span class="mat-small">{{slide.price}}</span>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star_border</mat-icon>
								<mat-icon>keyboard_arrow_down</mat-icon>
								<p class="color-accent color-hover">{{slide.reviewno}}</p>
							</div>
							<span class="color-danger">FREE</span>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
		</div>
	</mat-card>
</div>