import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-music-instruments',
  templateUrl: './music-instruments.component.html',
  styleUrls: ['./music-instruments.component.scss']
})
export class MusicInstrumentsComponent implements OnInit {

  items = [
    { image: 'assets/images/electronics/music/card/1.jpg', name: 'Boya BYM1 Omnidirectional Lavalier Condenser Microphone with 20ft Audio Cable (Black)', company: 'Boya', newprice: '₹ 680', oldprice: '₹ 1,499', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Sunday, Dec 27', reviewno: '14,521' },
    { image: 'assets/images/electronics/music/card/2.jpg', name: 'Tygot Adjustable Aluminium Alloy Tripod Stand Holder for Mobile Phones & Camera, 360 mm -1050 mm, 1/4 inch Screw + Mobile Holder...', company: 'Tygot', newprice: '₹ 349', oldprice: '₹ 1,999', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Friday, Dec 25', reviewno: '9,758' },
    { image: 'assets/images/electronics/music/card/3.jpg', name: 'Duracell Ultra Alkaline AA Battery, 8 Pieces', company: 'Duracell', newprice: '₹ 294', oldprice: '₹ 499', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '6,337' },
  ];
  items1 = [
    { image: 'assets/images/electronics/music/card/4.jpg', name: 'Tygot 13 inch Flexible Gorillapod Tripod with Mobile Attachment for DSLR, Action Cameras & Smartphones', company: 'Tygot', newprice: '₹ 399', oldprice: '₹ 1.999', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Thursday, Dec 24', reviewno: '2,225' },
    { image: 'assets/images/electronics/music/card/5.jpg', name: 'Syvo WT 3130 Aluminum Tripod (50-Inch), Universal Lightweight Tripod with Mobile Phone Holder Mount & Carry Bag for All Smart Phones,...', company: 'Syvo', newprice: '₹ 849', oldprice: '₹ 3,990', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '3,062' },
    { image: 'assets/images/electronics/music/card/6.jpg', name: 'DIGITEK DTR 550 LW (67 Inch) Tripod For DSLR, Camera |Operating Height: 5.57 Feet | Load Capacity: 5kg | Portable Lightweight Aluminum ...', company: 'Digitek', newprice: '₹ 1,449', oldprice: '₹ 2,495', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '5,335' },
  ];
  items2 = [
    { image: 'assets/images/electronics/music/card/7.jpg', name: 'Logitech MK270r Wireless Keyboard and Mouse Combo for Windows, 2.4 GHz Wireless, Compact Wireless Mouse, 8 Multimedia & Shortcut Keys...', company: 'Logitech', newprice: '₹ 1,595', oldprice: '₹ 2,295', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '3,409' },
    { image: 'assets/images/electronics/music/card/8.jpg', name: 'Juârez Acoustic Guitar, 38 Inch Cutaway, 038C with Bag, Strings, Pick and Strap, Black', company: 'JUAREZ', newprice: '₹ 2,290', oldprice: '₹  6,990', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '6,901' },
    { image: 'assets/images/electronics/music/card/9.jpg', name: 'Juarez JRZ250 One Handed Trigger Guitar Metal Capo Quick Change For Ukulele, Electric And Acoustic Guitars, Black', company: 'JUAREZ', newprice: '₹ 149', oldprice: '₹ 990', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00', deliverydate: 'Thursday, Dec 24', reviewno: '1,786' },
  ];
  items3 = [
    { image: 'assets/images/electronics/music/card/10.jpg', name: 'Maono AU-100 Condenser Clip On Lavalier Microphone with 6 Meters Audio Cable', company: 'MAONO', newprice: '₹ 799', oldprice: '₹ 2,499', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '1,380' },
    { image: 'assets/images/electronics/music/card/11.jpg', name: 'Envie AA Rechargeable Batteries | High-Capacity Ni-MH | 1000 mAh | Low Self Discharge | (Pack of 4) (AA10004PLNi-CD)', company: 'Envie', newprice: '₹ 250', oldprice: '', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00 . ', deliverydate: 'Friday, Dec 25', reviewno: '6,659' },
    { image: 'assets/images/electronics/music/card/12.jpg', name: 'Maono AU-A03 Condenser Microphone Kit Podcast Mic with Boom Arm Microphone Stand (Black)', company: 'MAONO', newprice: '₹ 2,399', oldprice: '₹ 4,999', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '854' },
  ];

  constructor( public router: Router,) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }
  
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      },
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/images/electronics/camera/carousel1/1.jpg',
      save: 'Save 5% ',
      name: 'Save 5% on D3D Face Detection Cameras',
      button: 'Collect Coupon'
    },
    {
      id: 2,
      src: './assets/images/electronics/camera/carousel1/2.jpg',
      save: 'Save ₹ 197.00',
      name: 'Celestron 71256 G2 10x50 Upclose Wide-',
      button: 'Collect Coupen'
    },
    {
      id: 3,
      src: './assets/images/electronics/camera/carousel1/3.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Product',
      button: 'Collect Coupen'
    },
    {
      id: 4,
      src: './assets/images/electronics/camera/carousel1/4.jpg',
      save: 'Save ₹ 417.00',
      name: 'Celestron 71009 15x70 Skymaster Binocular',
      button: 'Collect Coupen'
    },
    {
      id: 5,
      src: './assets/images/electronics/camera/carousel1/5.jpg',
      save: 'Save 5%',
      name: 'Save 5% on special offer',
      button: 'Collect Coupen'
    },
    {
      id: 6,
      src: './assets/images/electronics/camera/carousel1/6.jpg',
      save: 'Save 5%',
      name: 'Save 5% on product of Bosig Mart',
      button: 'Collect Coupen'
    },
    {
      id: 7,
      src: './assets/images/electronics/camera/carousel1/7.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Extra on This Device',
      button: 'Collect Coupen'
    },
    {
      id: 8,
      src: './assets/images/electronics/camera/carousel1/8.jpg',
      save: 'Save 5%',
      name: 'Save 5% on all KF Concept ND and UV ',
      button: 'Collect Coupen'
    },
    {
      id: 9,
      src: './assets/images/electronics/camera/carousel1/9.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Crystal Clear Photography Ball',
      button: 'Collect Coupen'
    },
    {
      id: 10,
      src: './assets/images/electronics/camera/carousel1/10.jpg',
      save: 'Save ₹ 32.00',
      name: 'Photron STEDY 350 Tripod with Mobile ',
      button: 'Collect Coupen'
    },
    {
      id: 11,
      src: './assets/images/electronics/camera/carousel1/11.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Guru08',
      button: 'Collect Coupen'
    },
    {
      id: 12,
      src: './assets/images/electronics/camera/carousel1/12.jpg',
      save: 'Save ₹ 8.00',
      name: 'Everycom Camera Stand Clip Bracket',
      button: 'Collect Coupen'
    },
  ]
  customOptions1: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/electronics/computer/carousel/1.jpg',
      price: '₹ 5,999',
      oldprice: '₹ 14,399',
      name: 'Samsung T5 500GB Up to 540MB/s USB 3.1 Gen 2',
      brand: 'samsung',
      reviewno: '2'
    },
    {
      id: 2,
      src: './assets/images/electronics/computer/carousel/2.jpg',
      price: '₹ 4,299',
      oldprice: '₹ 5,899',
      name: 'Seagate Expansion 1.5 TB External HDD - USB 3.0',
      brand: 'Seagate',
      reviewno: '76,763'
    },
    {
      id: 3,
      src: './assets/images/electronics/computer/carousel/3.jpg',
      price: '₹ 10,999',
      oldprice: '₹ 23,999',
      name: 'Samsung T7 1TB Up to 1,050MB/s USB 3.2 Gen 2',
      brand: 'Samsung',
      reviewno: '1,151'
    },
    {
      id: 4,
      src: './assets/images/electronics/computer/carousel/4.jpg',
      price: '₹ 10,999',
      oldprice: '₹ 23,999',
      name: 'Samsung T7 1TB Up to 1,050MB/s USB 3.2 Gen 2',
      brand: 'Samsung',
      reviewno: '1,151'
    },
    {
      id: 5,
      src: './assets/images/electronics/computer/carousel/5.jpg',
      price: '₹ 8,199',
      oldprice: '₹ 11,200',
      name: 'Western Digital WD 4TB My Passport Portable ',
      brand: 'Western Digital',
      reviewno: '22,053'
    },
    {
      id: 6,
      src: './assets/images/electronics/computer/carousel/6.jpg',
      price: '₹ 9,999',
      oldprice: '₹ 28,599',
      name: 'Samsung T5 1TB Up to 540MB/s USB 3.1 Gen 2',
      brand: 'Samsung',
      reviewno: '1,729'
    },
    {
      id: 7,
      src: './assets/images/electronics/computer/carousel/7.jpg',
      price: '₹ 8,999',
      oldprice: '₹ 16,499',
      name: 'Seagate Backup Plus Portable 5 TB External ',
      brand: 'Seagate',
      reviewno: '12,681'
    },
    {
      id: 8,
      src: './assets/images/electronics/computer/carousel/8.jpg',
      price: '₹ 6,999',
      oldprice: '₹ 12,999',
      name: 'Samsung T7 500GB Up to 1,050MB/s USB 3.2 Gen 2',
      brand: 'Samsung',
      reviewno: '1,151'
    },
    {
      id: 9,
      src: './assets/images/electronics/computer/carousel/9.jpg',
      price: '₹ 10,999',
      oldprice: '₹ 23,999',
      name: 'Samsung T7 1TB Up to 1,050MB/s USB 3.2 Gen 2',
      brand: 'Samsung',
      reviewno: '1,151'
    },
    {
      id: 10,
      src: './assets/images/electronics/computer/carousel/10.jpg',
      price: '₹ 5,599',
      oldprice: '₹ 7,999',
      name: 'Seagate Backup Plus Ultra Touch 2 TB External Hard',
      brand: 'Seagate',
      reviewno: '246'
    },
  ]

  customOptions2: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore2 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel1/1.jpg',
      text: 'Emporium Car Back Pain Relief Lower Back Support for Chair Back Rest for Office Chair Lumbar…',
      reviewno: '242',
      price: '₹ 349.00'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel1/2.jpg',
      text: 'BLUEWUD Clonard Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '242',
      price: '₹ 2,899.00'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel1/3.jpg',
      text: 'BLUEWUD Mallium Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '331',
      price: '₹ 3,339.00'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel1/4.jpg',
      text: 'OGU Om Cotton Round Batti Wicks for Pooja Aarti (White) -2100 Pieces',
      reviewno: '61',
      price: '₹ 250'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel1/5.jpg',
      text: 'pTron Bassbuds Pro in-Ear True Wireless Bluetooth 5.0 Headphones with Deep Bass, Touch Control…',
      reviewno: '8,370',
      price: '₹ 1,299.00'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel1/6.jpg',
      text: 'HealthSense Soft-Spot BC 21 Orthopedic Backrest Cushion with Memory Foam for Study, Home,…',
      reviewno: '931',
      price: '₹ 1,399.00'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel1/7.jpg',
      text: 'Nutriplato-enriching lives Roasted Salted Flax Seeds, 500 g',
      reviewno: '325',
      price: '₹ 199.00'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel1/8.jpg',
      text: 'ADOC Chairs Oren Medium Back Ergonomic Office Chair with Mesh Back, Fixed Comfortable…',
      reviewno: '58',
      price: '₹ 3,049.00'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel1/9.jpg',
      text: 'Angel Bear 1 Cup Toothbrush Toothpaste Stand Holder Bathroom Storage Organizer,Plastic',
      reviewno: '379',
      price: '₹ 249.00'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel1/10.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '165',
      price: '₹ 3,999.00'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel1/11.jpg',
      text: 'TIZUM High Speed HDMI Cable with Ethernet - Supports 3D, 4K and Audio Return (1.8 Meter/ 6 Feet)',
      reviewno: '2,281',
      price: '₹ 145.00'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel1/12.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '30',
      price: '₹ 3,799.00'
    },
  ]
  customOptions3: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore3 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel2/1.jpeg',
      text: 'VPN Software & VPN free unlimited (Fire TV, 4k sticks)',
      reviewno: '1,108',
      price: 'BackySoft'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel2/2.jpg',
      text: 'AirScreen - AirPlay & Google Cast & Miracast & DLNA',
      reviewno: '34,550',
      price: '重庆爱奥尼科技有限公司'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel2/3.jpeg',
      text: 'Trashy Movies Channel',
      reviewno: '1,561',
      price: 'POV Horror'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel2/4.jpeg',
      text: 'Wicked Horror TV',
      reviewno: '3,961',
      price: 'POV Horror'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel2/5.jpeg',
      text: 'Trashy Horror Movies',
      reviewno: '1,375',
      price: 'POV Horror'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel2/6.jpeg',
      text: 'B-Movie Club',
      reviewno: '738',
      price: 'POV Horror'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel2/7.jpeg',
      text: 'VPN Lite & Full Privacy (RVPN)',
      reviewno: '358',
      price: 'BackySoft'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel2/8.jpg',
      text: 'Plex: Stream Movies, Shows, Live TV, Music, and More',
      reviewno: '51,727',
      price: 'Plex'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel2/9.jpeg',
      text: 'Martial Arts and Kung-Fu Movie Channel',
      reviewno: '4,629',
      price: 'POV Horror'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel2/10.jpg',
      text: 'NordVPN - Fast, Secure and Unlimited VPN app for Android. Stay secure and…',
      reviewno: '21,882',
      price: 'nordvpn s.a.'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel2/11.jpeg',
      text: 'Thriller Movie Channel',
      reviewno: '232',
      price: 'POV Horror'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel2/12.jpeg',
      text: 'Cleaner for Fire Tablets & TV (Cleanza)',
      reviewno: '6,219',
      price: 'Quickla Soft'
    },
  ]
}
