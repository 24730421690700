<div class="container cardpadding" [ngClass.gt-sm]="'cardpadding2'" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row">
    <p class="mat-headline">Review your order</p>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px" fxFlex>
    <div fxLayout="column" fxLayoutGap="20px" fxFlex="73" fxFlex.lt-md="100">
      <div class="card cardpadding">
        <div [ngClass.gt-sm]="'cardpadding'" fxLayout="row" fxLayoutGap="10px" fxFlex>
          <div class="important" fxLayout="row" fxLayoutAlign="center center">
            <i class="fa fa-exclamation-circle color-accent" aria-hidden="true"></i>
          </div>
          <div fxLayout="column">
            <p class="mat-h3">Important Message</p>
            <mat-checkbox class="mat-small">These delivery and payment options in this future</mat-checkbox>
          </div>
        </div>
      </div>
      <div class="card cardpadding">
        <div [ngClass.gt-sm]="'cardpadding'" fxLayout="row" fxLayout.lt-sm="column" fxFlex>
          <div class="cardpadding" fxLayout="column" fxLayoutGap="10px" fxFlex="31" fxFlex.lt-sm="100">
            <div fxLayout="row">
              <p class="font-bold">Shipping Address <span class="mat-small color-accent"
                  routerLink="/add-address">Change</span></p>
            </div>
            <div fxLayout="column">
              <p class="mat-h3 font-bold">{{this.fullName}}</p>
              <p class="mat-small">{{this.flat}}</p>
              <p class="mat-small">{{this.area}}</p>
              <p class="mat-small">{{this.Landmark}}</p>
              <p class="mat-small">{{this.City}}, <span>{{this.State}}, {{this.pin}}</span></p>
              <p class="mat-small">{{this.country}}</p>
              <p class="mat-small">Phone no: <span>{{this.PhoneNumber}}</span></p>
            </div>
          </div>
          <div class="cardpadding" fxLayout="column" fxLayoutGap="20px" fxFlex="31" fxFlex.lt-sm="100">
            <div fxLayout="column" fxLayoutGap="10px">
              <div fxLayout="row">
                <p class="font-bold">Payment Method <span class="mat-small color-accent"
                    routerLink="/checkout">Change</span></p>
              </div>
              <div fxLayout="column">
                <p class="mat-small">{{this.cardBrand}} ending in <span>{{this.lastFour}}</span></p>
                <p class="mat-small">EMI not available</p>
              </div>
            </div>
            <div fxLayout="column" fxLayoutGap="10px">
              <div fxLayout="row">
                <p class="font-bold">Billing Address <span class="mat-small color-accent"
                    routerLink="/checkout">Change</span></p>
              </div>
              <div fxLayout="column">
                <p class="mat-small">Same as delivery address</p>
              </div>
            </div>
          </div>
          <div class="cardpadding" fxLayout="column" fxLayoutGap="10px" fxFlex="31" fxFlex.lt-sm="100">
            <div fxLayout="row">
              <p class="font-bold">Gift cards, Voucher & Promotional codes</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
              <div fxFlex="70">
                <mat-form-field appearance="outline" fxFlex="100">
                  <input matInput placeholder="Enter Code">
                </mat-form-field>
              </div>
              <div fxFlex="25">
                <button mat-raised-button class="bg-secondary">Apply</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card cardpadding">
        <div [ngClass.gt-sm]="'cardpadding'" fxLayout="column">
          <div fxLayout="row">
            <p class="mat-title color-accent">Scheduled delivery time for this item: 8:00 AM - 5:00 PM 12/01/2021</p>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" fxFlex>
            <div fxLayout="column">
              <div fxLayout="column" fxLayoutGap="10px" fxFlex>
                <div fxLayout="column" fxLayoutGap="20px" *ngFor="let item of items" fxFlex>
                  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxFlex="85">
                    <div fxLayout="row" fxLayoutGap.gt-sm="15px" fxLayoutGap.lt-sm="5px" fxLayoutAlign="start" fxFlex>
                      <div fxLayout="row" [ngClass]="'card-image image-height'" fxLayoutAlign="center center"
                        fxFlex="30" fxFlex.lt-sm="40" (click)="products()">
                        <img src="{{item.img}}" alt="">
                      </div>
                      <div class="cardpadding" fxLayout="column" fxLayoutAlign="start start" fxFlex="70"
                        fxFlex.lt-sm="50">
                        <p class="mat-h3 hover-red" (click)="products()">{{item.name}}</p>
                        <p class="mat-h4"><span class="mat-small text-line-through">{{item.oldPrice}}</span> <span class="mat-h4 color-danger"> {{item.price}}</span></p>
                        <p>You Save: {{item.save}}</p>
                        <p>Quantity: 1</p>
                        <p class="color-secondary">Sold by: {{item.sold}}</p>
                      </div>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="27" fxFlex.lt-md="100">
      <div class="card cardpadding" >
        <div class="cardpadding" fxLayout="column" fxLayoutGap="10px">
          <div fxLayout="row">
            <button mat-raised-button color="primary" fxFlex="100">Place Your Order and Pay</button>
          </div>
          <div fxLayout="row">
            <mat-divider></mat-divider>
          </div>
          <div fxLayout="column">
            <p class="font-bold mat-h3">Order Summary</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between start">
            <p>Items: </p>
            <p>₹ 86,789.00</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between start">
            <p>Delivery: </p>
            <p>₹ 0.00</p>
          </div>
          <div fxLayout="row">
            <mat-divider fxFlex="100"></mat-divider>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between start">
            <p class="mat-h3 color-danger">Order Total: </p>
            <p class="mat-h3 color-danger">₹ 86,789.00</p>
          </div>
          <div fxLayout="row">
            <mat-divider fxFlex="100"></mat-divider>
          </div>
          <div fxLayout="row" fxLayoutAlign="start start">
            <p class="color-danger">Your Saving:
              <span>₹ 22,410.00(41%)</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>