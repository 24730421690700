import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  panelOpenState = false;
  constructor() { }

  ngOnInit(): void {
  }

  mobitems = [
    { link: 'mobile-phone', img: 'assets/images/category/mobile1.jpg', name: 'Mobiles', },
    { link: 'mobile-phone', img: 'assets/images/category/mobile2.jpg', name: 'Mobile Accessories', },
    { link: 'mobile-phone', img: 'assets/images/category/mobile3.jpg', name: 'Power Banks', },
    { link: 'mobile-phone', img: 'assets/images/category/mobile4.jpeg', name: 'Renewed Mobiles' },
    { link: 'computer-laptop', img: 'assets/images/category/mobile5.jpg', name: 'Laptops', },
    { link: 'mobile-phone', img: 'assets/images/category/mobile6.jpg', name: 'Tablets', },
    { link: 'computer-laptop', img: 'assets/images/category/mobile7.jpg', name: 'Computer & Accessories', },
    { link: 'computer-laptop', img: 'assets/images/category/mobile8.jpg', name: 'Pen Drives & Storage', },
    { link: 'stationery', img: 'assets/images/category/mobile9.jpg', name: 'Office Supplies & Stationery', },
    { link: 'computer-laptop', img: 'assets/images/category/mobile10.jpg', name: 'Wearable Device', },
  ];
  menitems = [
    { link: 'fashion', img: 'assets/images/category/men1.jpg', name: 'Amazon Fashion', },
    { link: 'fashion', img: 'assets/images/category/men2.jpg', name: 'Clothing', },
    { link: 'fashion', img: 'assets/images/category/men3.jpg', name: 'Footwear', },
    { link: 'fashion', img: 'assets/images/category/men4.jpg', name: 'Watches' },
    { link: 'fashion', img: 'assets/images/category/men5.jpg', name: 'Bags,Walletes & Luggages', },
    { link: 'fashion', img: 'assets/images/category/men6.jpg', name: 'Sunglasses', },
    { link: 'fashion', img: 'assets/images/category/men7.jpg', name: 'Accessories', },
    { link: 'fashion', img: 'assets/images/category/men8.jpg', name: 'Men\'s Sportwear', },
    { link: 'fashion', img: 'assets/images/category/men9.jpg', name: 'Designer Boutique', },
    { link: 'fashion', img: 'assets/images/category/men10.jpg', name: 'Fashion Sales & Deals', }
  ]
  womenitems = [
    { link: 'fashion', img: 'assets/images/category/women1.jpg', name: 'Amazon Fashion', },
    { link: 'fashion', img: 'assets/images/category/women2.jpg', name: 'Clothing', },
    { link: 'fashion', img: 'assets/images/category/women3.jpg', name: 'Footwear', },
    { link: 'fashion', img: 'assets/images/category/women4.jpg', name: 'Watches' },
    { link: 'fashion', img: 'assets/images/category/women5.jpg', name: 'Fashion & Silver Jewellery', },
    { link: 'fashion', img: 'assets/images/category/women6.jpg', name: 'Gold & Diamond Jewellery', },
    { link: 'fashion', img: 'assets/images/category/women7.jpg', name: 'Handbags & Clutches', },
    { link: 'fashion', img: 'assets/images/category/women8.jpg', name: 'Sunglasses', },
    { link: 'fashion', img: 'assets/images/category/women9.jpg', name: 'Women\'s SportWear', },
    { link: 'fashion', img: 'assets/images/category/women10.jpg', name: 'Designer Boutique', },
    { link: 'fashion', img: 'assets/images/category/men10.jpg', name: 'Fashion Sales & Deals', }
  ]
  homeitems = [
    { routerLink: 'kitchen-dining', img: 'assets/images/category/home1.jpg', name: 'Kitchen & Dining', },
    { routerLink: 'home-essentials', img: 'assets/images/category/home2.jpeg', name: 'Furniture', },
    { routerLink: 'home-essentials', img: 'assets/images/category/home3.jpg', name: 'Home Furnishing', },
    { routerLink: 'home-essentials', img: 'assets/images/category/home4.jpg', name: 'Home Decore' },
    { routerLink: 'home-essentials', img: 'assets/images/category/home5.jpg', name: 'Garden & Outdoors', },
    { routerLink: 'home-essentials', img: 'assets/images/category/home6.jpg', name: 'Home Storage & Organisation', },
    { routerLink: 'home-essentials', img: 'assets/images/category/home7.jpg', name: 'Indoor Lighting', },
    { routerLink: 'home-essentials', img: 'assets/images/category/home8.jpg', name: 'Tools and Home Improvement', },
    { routerLink: 'daily-essentials', img: 'assets/images/category/home9.jpg', name: 'All Home & Kitchen', },
    { routerLink: 'daily-essentials', img: 'assets/images/category/home10.jpeg', name: 'Pet Supplies', },
    { routerLink: 'kitchen-dining', img: 'assets/images/category/home11.jpg', name: 'Kitchen Appliances', },
    { routerLink: 'home-essentials', img: 'assets/images/category/home12.jpg', name: 'Home Appliances', },
    { routerLink: 'home-essentials', img: 'assets/images/category/home13.jpg', name: 'Art & Craft Supplies', }

  ]
  groceryitems = [
    { link: 'daily-essentials', img: 'assets/images/category/grocery1.jpg', name: 'Amazon Pantry', },
    { link: 'daily-essentials', img: 'assets/images/category/grocery2.jpg', name: 'Cooking Essentials', },
    { link: 'daily-essentials', img: 'assets/images/category/grocery3.jpg', name: 'Packaged Foods', },
    { link: 'daily-essentials', img: 'assets/images/category/grocery4.jpg', name: 'Gournment Foods' },
    { link: 'daily-essentials', img: 'assets/images/category/grocery5.jpg', name: 'Household Supplies', },
    { link: 'daily-essentials', img: 'assets/images/category/grocery6.jpeg', name: 'Subscribe & Save', },
    { link: 'daily-essentials', img: 'assets/images/category/grocery7.jpg', name: 'All Grocery', },

  ]
  beautyitems = [
    { img: 'assets/images/category/beauty1.jpg', name: ' Beauty & Grooming ', },
    { img: 'assets/images/category/beauty2.jpg', name: ' Luxury Beauty ', },
    { img: 'assets/images/category/beauty3.jpeg', name: ' Women\'s Styling Devices ', },
    { img: 'assets/images/category/beauty4.jpeg', name: ' Men\'s Grooming Devices ' },
    { img: 'assets/images/category/beauty5.jpg', name: ' Health & Personal Care ', },
    { img: 'assets/images/category/beauty6.jpeg', name: ' Healthcare Devices ', },
  ]
  bookitems = [
    { img: 'assets/images/category/book1.jpg', name: 'Books', },
    { img: 'assets/images/category/book2.jpg', name: 'Fiction Books', },
    { img: 'assets/images/category/book3.jpg', name: 'School Textbooks', },
    { img: 'assets/images/category/book4.jpg', name: 'Children\'s Books' },
    { img: 'assets/images/category/book5.jpg', name: 'Exam Central', },
    { img: 'assets/images/category/book6.jpg', name: 'Textbooks', },
    { img: 'assets/images/category/book7.jpg', name: 'Editor\'s Corner', },
    { img: 'assets/images/category/book8.jpg', name: 'Indian Language Books', },
    { img: 'assets/images/category/book9.jpg', name: 'Kindle eBooks', },
    { img: 'assets/images/category/book10.jpg', name: 'Audiable Audiobooks & more', },
    { img: 'assets/images/category/book11.jpg', name: 'Audiable Membership', }

  ]
  rechargeitems = [
    { img: 'assets/images/category/recharge1.jpeg', name: 'Prepade Mobile Recharge', },
    { img: 'assets/images/category/recharge2.jpeg', name: 'DTH Recharge', },
    { img: 'assets/images/category/recharge3.jpeg', name: 'Postpaid Mobile Bills', },
    { img: 'assets/images/category/recharge4.jpeg', name: 'Electricity Bills' },
    { img: 'assets/images/category/recharge5.jpeg', name: 'Piped Gas Bills', },
    { img: 'assets/images/category/recharge6.jpeg', name: 'Landline Bills', },
    { img: 'assets/images/category/recharge7.jpeg', name: 'Broadband Bills', },
    { img: 'assets/images/category/recharge8.jpeg', name: 'Water Bills', },

  ]
  giftitems = [
    { img: 'assets/images/category/gift1.jpeg', name: 'All Gift Cards', },
    { img: 'assets/images/category/gift2.jpeg', name: 'Popular Gift Cards', },
    { img: 'assets/images/category/gift3.jpeg', name: 'Gift BOxes, Tags, Greeting Cards', },
    { img: 'assets/images/category/gift4.jpeg', name: 'Gift Vouchers from Popular Brands' },
    { img: 'assets/images/category/gift5.jpeg', name: 'Birhtday Gift Cards', },
    { img: 'assets/images/category/gift6.jpeg', name: 'Wedding & Anniversary Gift Cards', },
    { img: 'assets/images/category/gift7.jpeg', name: 'Best Wishes & Thank You', },
    { img: 'assets/images/category/gift8.jpg', name: 'Corporate Gift Cards', },
    { img: 'assets/images/category/gift9.jpeg', name: 'Add a Gift Card', },

  ]
}
