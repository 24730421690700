<div class="container" [ngClass.gt-sm]="'cardpadding2'" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row" class="cardpadding">
    <div class="card">
      <p class="mat-headline">Select your payment method</p>
    </div>
  </div>

  <div fxLayout="row" class="cardpadding">
    <mat-accordion class="full-width">
      <mat-expansion-panel #panel1 hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-radio-button [checked]="panel1.expanded">Debit/Credit/ATM Card</mat-radio-button>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row" fxLayoutAlign="start">
          <div class="cardimage cardsize">
            <div fxLayout="column" fxLayoutGap="20px">
              <div fxLayout="column" fxLayoutGap="20px">
                <!-- <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <p class="mat-title color-primary"> Pay: </p>
              </div> -->
              </div>
              <div fxLayout="column" fxLayoutGap="30px">
                <form [formGroup]="stripeTest" autocomplete="off" novalidate>
                  <div fxLayout="column">
                    <mat-form-field appearance="outline" class="full-width">
                      <input formControlName="name" matInput placeholder="Name on Card" maxlength="256" required>
                      <mat-error>Name is required.</mat-error>
                    </mat-form-field>
                    <div class="card-details">
                      <ngx-stripe-card [options]="cardOptions" (change)="onChange($event)"
                        [elementsOptions]="elementsOptions">
                      </ngx-stripe-card>
                    </div>
                    <mat-error>{{this.error}}</mat-error>
                  </div>
                </form>
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                  <button mat-raised-button class="bg-secondary" (click)="back()">
                    Cancel
                  </button>
                  <button mat-raised-button color="primary" [disabled]="stripeTest.invalid ||this.error"
                    (click)="submit()">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel #panel2 hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-radio-button [checked]="panel2.expanded"> Net Banking</mat-radio-button>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field appearance="outline">
          <mat-label>Add a Bank</mat-label>
          <mat-select [(ngModel)]="selectedValue" name="bank">
            <mat-option *ngFor="let bank of banks" [value]="bank.value">
              {{bank.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </mat-expansion-panel>
      <mat-expansion-panel #panel3 hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-radio-button [checked]="panel3.expanded">Other UPI Apps</mat-radio-button>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column">
          <p class="mat-small">Please enter your UPI ID</p>
          <form [formGroup]="otherupiForm" autocomplete="off" novalidate>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
              <mat-form-field appearance="outline" fxFlex="70">
                <!-- <mat-label>Outline form field</mat-label> -->
                <input formControlName="upiid" matInput placeholder="Ex.: MobileNumber@upi">
                <mat-error>UPI ID is not valid.</mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <button mat-raised-button color="primary" [disabled]="otherupiForm.invalid">Verfiy</button>
            </div>
          </form>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel #panel4 hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-radio-button [checked]="panel4.expanded">EMI</mat-radio-button>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field appearance="outline">
          <mat-label>Select EMI options</mat-label>
          <mat-select [(ngModel)]="selectedValue" name="bank">
            <mat-option value="new card">
              Add a new Card
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-expansion-panel>
      <mat-expansion-panel #panel5 hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-radio-button [checked]="panel5.expanded">Pay On Delivery</mat-radio-button>
          </mat-panel-title>
        </mat-expansion-panel-header>
        Pay on delivery is available
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>