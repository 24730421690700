import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './sidenav/sidenav.component';
import { FooterComponent } from './footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { NavListComponent } from './nav-list/nav-list.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MaterialModule } from '../modules/material/material/material.module';
import { RouterModule } from '@angular/router';
import { CommonLayoutComponent } from './common-layout/common-layout.component';
import { BlankLayoutComponent } from './blank-layout/blank-layout.component';



@NgModule({
  declarations: [
    SidenavComponent,
    NavListComponent,
    MenuComponent,
    FooterComponent,
    CommonLayoutComponent,
    BlankLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    MatCarouselModule.forRoot(),
    CarouselModule,
  ],
  exports: [
    CommonLayoutComponent,
    BlankLayoutComponent
  ],
})
export class LayoutModule { }
