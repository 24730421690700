<div fxLayout="column" fxLayoutAlign="center center" class="row-height">
  <div fxLayout fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayoutGap.xs="10px">
      <mat-card class="container card-shadow">
        <mat-card-title>
          <span fxLayoutAlign="center">Create Account</span>
        </mat-card-title>
        <mat-card-content class="padding-top">
          <form [formGroup]="signupFormGroup" autocomplete="off" novalidate>
            <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutAlign="flex-start">
              <!-- Name field -->
              <mat-form-field appearance="outline">
                <mat-label>Your Name</mat-label>
                <input formControlName="yourName" matInput placeholder="Your Name" maxlength="256" required>
                <mat-error>Name is required.</mat-error>
              </mat-form-field>
            </div>
            <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutAlign="flex-start">
              <!-- Mobile number field -->
              <mat-form-field appearance="outline">
                <mat-label>Mobile number</mat-label>
                <input formControlName="phoneNumber" type="tel" id="mobileno" matInput placeholder="9876543210"
                  maxlength="10" required>
                <mat-error *ngIf="txtMobileno.errors?.required">Mobile Number is required.
                </mat-error>
                <mat-error *ngIf="txtMobileno.errors?.pattern">Invalid Mobile No.
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutAlign="flex-start">
              <!-- Email field -->
              <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input formControlName="email" id="email" matInput maxlength="256" placeholder="your@example.com"
                  required>
                <mat-error *ngIf="txtEmail.errors?.required"> Email is required. </mat-error>
                <mat-error *ngIf="txtEmail.errors?.pattern">Invalid Email Id
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutGap="5px">
              <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutAlign="flex-start">
                <!-- Password field -->
                <mat-form-field appearance="outline">
                  <mat-label>Password</mat-label>
                  <input formControlName="Password" matInput maxlength="256" type="password"
                    [type]="hide ? 'password' : 'text'" id="password" placeholder="Password" required>
                  <mat-icon class="color-secondary" matSuffix (click)="hide = !hide">
                    {{hide ? 'visibility_off' : 'visibility'}}
                  </mat-icon>
                  <!-- <mat-error>Password is required.isn't long enough.
                </mat-error> -->
                  <mat-error *ngIf="txtPassword.errors?.required"> Password is
                    required. </mat-error>
                  <mat-error *ngIf="txtPassword.errors?.pattern">At least 8 characters in length
                    Lowercase and
                    Uppercase letters
                    Numbers Special characters
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutAlign="flex-start">
                <!-- Street1 field -->
                <mat-form-field appearance="outline">
                  <mat-label>Street Address</mat-label>
                  <input formControlName="Street1" matInput maxlength="256" #search>
                  <mat-error>Street Address is required.</mat-error>
                </mat-form-field>
                <!-- City field -->
                <mat-form-field appearance="outline">
                  <mat-label>City</mat-label>
                  <input formControlName="City" matInput>
                  <mat-error>City is required.</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutAlign="flex-start">
              <!-- State field -->
              <mat-form-field appearance="outline">
                <mat-label>State</mat-label>
                <input formControlName="State" matInput>
                <mat-error>State is required.</mat-error>
              </mat-form-field>

              <!-- Zip field -->
              <mat-form-field appearance="outline">
                <mat-label>Zip</mat-label>
                <input formControlName="Zip" matInput>
                <mat-error>Zip is required.</mat-error>
              </mat-form-field>
            </div>
          </form>
          <div *ngIf="error">
            <mat-error>{{this.error}}</mat-error>
          </div>
          <!-- <div fxLayoutAlign="center center">
            <p class="mat-small">By clicking Sign Up, you agree to our.
              <a routerLink="/signup" class="color-primary"> Terms, Data Policy</a> <br>
              You may receive SMS & Email from us.
            </p>
          </div> -->
          <!-- Create account button -->
          <div fxLayoutAlign="center center" class="margin-top">
            <button mat-raised-button fxFlex="86" color="primary" (click)="register()"
              [disabled]="signupFormGroup.invalid">Sign Up</button>
          </div>
          <!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="primary" type="square-jelly-box"
            [fullScreen]="true">
            <p style="color: white"> Loading... </p>
          </ngx-spinner> -->
        </mat-card-content>
        <!-- Already signup button -->
        <mat-card-actions>
          <div fxLayoutAlign="center">
            <p>Already have an account? <a routerLink="/login" class="color-primary"> Sign in</a></p>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
  <!-- Copyright -->
  <!-- <div class="color-secondary mat-small" fxLayoutAlign="center end"> Copyright &copy; 2020 All Rights
    Reserved. </div> -->
</div>