<div class="container margin-top">
  <div [ngClass.gt-sm]="'cardpadding'" fxLayout="column" fxLayoutGap="20px">
    <div>
      <mat-tab-group mat-stretch-tabs>
        <mat-tab label="Saved Items">
          <ng-template matTabContent>
            <div class="cardpadding" fxLayout="column" fxLayoutGap="20px">
              <div fxLayout="row" fxLayoutAlign.lt-sm="start center">
                <p class="mat-headline">Saved items</p>
              </div>
              <div fxLayout="column" fxLayoutGap="10px">
                <p>4 items or products</p>
                <mat-divider fxFlex="100"></mat-divider>
              </div>
              <div fxLayout="row wrap" fxLayout.lt-sm="column">
                <div fxLayout="row" *ngFor="let item of items" fxFlex="25">
                  <mat-card fxFlex="100">
                    <div fxLayout="column" fxLayoutAlign="space-between" fxLayout.lt-sm="row" fxLayoutGap="15px" fxFlex>
                      <div fxLayout="row" [ngClass]="'card-image image-height'" fxLayoutAlign="center center"
                        fxFlex.lt-md="40" fxFlex="40" (click)="products()">
                        <img src="{{item.img}}" alt="">
                      </div>
                      <div fxLayout="column" fxLayoutAlign="space-between start" fxFlex="60" fxFlex.lt-md="60">
                        <div fxLayout="column" fxFlex="80">
                          <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                          <p class="mat-h4 color-danger">{{item.price}}</p>
                        </div>
                        <div fxLayout="row" fxFlex="20">
                          <button mat-raised-button color="primary" class="full-width" (click)="gotocart()">Add to
                            cart</button>
                        </div>
                      </div>
                    </div>
                  </mat-card>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <div class="line">
                  <mat-divider fxFlex="100"></mat-divider>
                </div>
                <div class="mat-small">End of saved items</div>
                <div class="line">
                  <mat-divider fxFlex="100"></mat-divider>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Your Lists" class="color-accent">
          <ng-template matTabContent>
            <div [ngClass.gt-sm]="'cardpadding'" fxLayout="column" fxLayoutGap="10px">
              <div class="cardpadding" fxLayout="column" fxLayoutGap="20px">
                <div fxLayout="row" fxLayoutAlign.lt-sm="start center">
                  <p class="mat-headline">Shopping List</p>
                </div>
                <div fxLayout="column" fxLayoutGap="10px">
                  <p>4 items or products</p>
                  <mat-divider fxFlex="100"></mat-divider>
                </div>
              </div>
              <div class="cardpadding" fxLayout="column" fxLayoutGap="20px" *ngFor="let item of items" fxFlex>
                <mat-card>
                  <div fxLayout="column">
                    <div class="cardpadding" fxLayout="column" fxLayoutGap="10px">
                      <div fxLayout="row" fxLayoutGap="20px" fxFlex>
                        <div [ngClass]="'card-image image-height'" fxLayoutAlign="center center" fxFlex="20"
                          fxFlex.lt-sm="40" (click)="products()"> 
                          <img src="{{item.img}}" alt="">
                        </div>
                        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around" fxFlex>
                          <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px" fxFlex="70"
                            fxFlex.lt-sm="60">
                            <p class="mat-h4 color-accent color-hover" (click)="products()">{{item.name}}</p>
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <mat-icon color="primary">star</mat-icon>
                              <mat-icon color="primary">star</mat-icon>
                              <mat-icon color="primary">star</mat-icon>
                              <mat-icon color="primary">star</mat-icon>
                              <mat-icon color="primary">star_border</mat-icon>
                              <mat-icon>keyboard_arrow_down</mat-icon>
                              <p class="color-accent color-hover">{{item.reviewno}}</p>
                            </div>
                            <p class="mat-h3 color-danger">{{item.price}}</p>
                          </div>
                          <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="10px" fxFlex="30">
                            <p class="mat-small">Item added {{item.date}}</p>
                            <button mat-raised-button color="primary" (click)="gotocart()">Add to cart</button>
                            <p class="color-accent">Remove Item</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <div class="line">
                  <mat-divider fxFlex="100"></mat-divider>
                </div>
                <div class="mat-small">End of list</div>
                <div class="line">
                  <mat-divider fxFlex="100"></mat-divider>
                </div>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Friends Lists">
          <ng-template matTabContent>
            <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
              <div class="cardpadding" fxLayout="column" fxLayoutAlign="start" class="cardpadding">
                <p class="mat-h3 font-bold"> You don't have any shared lists yet.</p>
                <p class="mat-small">Ask a friend to share their List with you</p>
              </div>
              <div class="cardpadding" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
                <div fxLayout="row" fxLayoutAlign="start">
                  <p class="mat-h3 font-bold">Message</p>
                </div>
                <div class="bg-secondary cardpadding" fxLayout="column" fxLayoutGap="20px">
                  <div fxLayout="column">
                    <p class="mat-h4">Hi, I was wondering if you had built a list on Global Local. I'd love to see what you
                      really want.</p>
                    <p class="mat-h4">Could you share your list with me?</p>
                  </div>
                  <div fxLayout="column">
                    <p class="mat-h4">Thanks!</p>
                  </div>
                  <div>
                    <p class="mat-h4">——</p>
                  </div>
                  <div>
                    <p class="mat-h4">To view and share your lists, visit <span class="color-accent"
                        routerLink="/wish-list"> Your Global Local Lists page</span>.</p>
                  </div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
                <button mat-raised-button class="bg-secondary">Copy message</button>
                <button mat-raised-button color="primary">Email this message</button>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>