import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { CartService } from 'src/app/shared/_services/cart.service';
interface Bank {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#111',
        color: '#111',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '1.2rem',
        '::placeholder': {
          color: '#979494'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'auto'
  };

  panelOpenState = false;
  error;
  stripeTest: FormGroup;
  otherupiForm: FormGroup;
  stripeCardValid: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private cd: ChangeDetectorRef,
    private stripeService: StripeService,) { }

  ngOnInit(): void {
    this.stripeTest = this.formBuilder.group({
      name: ['', [Validators.required]]
    })
    this.otherupiForm = this.formBuilder.group({
      upiid: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}')]]
    })
  }

  onChange({ error }) {
    console.log(error)
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  back() {
    this.router.navigate(['/checkout']);
  }
  submit() {
    const name = this.stripeTest.get('name').value;
    console.log("this.card.element", this.card.element)
    this.stripeService
      .createToken(this.card.element, { name })
      .subscribe((result) => {
        if (result.token) {
          // Use the token
          console.log(result.token.id);
          console.log(result.token.card.brand); 
          // console.log(result.token.card.exp_month); 
          // console.log(result.token.card.exp_year);
          console.log(result.token.card.last4);
          console.log(name)
          //  console.log(result.token.id);
          //  var id =
          // var token = result.token.id;
          var token = result.token.id;
          var amount = 10 * 100
          localStorage.setItem('cardBrand', result.token.card.brand)
          localStorage.setItem('lastFour', result.token.card.last4)
          this.router.navigate(['/place-order'])
        } else if (result.error) {
          // Error creating the token
          console.log(result.error.message);
        }
      });
  }
  selectedValue: string;

  banks: Bank[] = [
    { value: 'Airtel Payments Bank', viewValue: 'Airtel Payments Bank' },
    { value: 'Axis Bank', viewValue: 'Axis Bank' },
    { value: 'HDFC Bank', viewValue: 'HDFC Bank' },
    { value: 'ICICI Bank', viewValue: 'ICICI Bank' },
    { value: 'Kotak Bank', viewValue: 'Kotak Bank' },
    { value: 'State Bank of India', viewValue: 'State Bank of India' },
    { value: 'Bank of Baroda', viewValue: 'Bank of Baroda' },
    { value: 'Canara Bank', viewValue: 'Canara Bank' },
    { value: 'Dena Bank', viewValue: 'Dena Bank' },
    { value: 'Federal Bank', viewValue: 'Federal Bank' },
    { value: 'IndusInd Bank', viewValue: 'IndusInd Bank' },
    { value: 'Punjab National Bank', viewValue: 'Punjab National Bank' },
    { value: 'Union Bank of India', viewValue: 'Union Bank of India' },
  ];
}
