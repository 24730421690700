<h2 mat-dialog-title>
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" fxLayoutGap.lt-sm="10px">
    <p class="mat-headline">Create Review</p>
    <div class="img-height" fxLayout="row" fxLayoutAlign="start center" fxFlex>
      <img src="assets/images/product/review.jpg" alt="">
      <p class="mat-h4">Samsung Galaxy M51, Celestial Black, 6GB RAM, 128GB Storage</p>
    </div>
  </div>
</h2>
<mat-dialog-content class="mat-typography">
  <div class="container cardpadding" [ngClass.gt-sm]="'cardpadding2'">
    <div fxLayout="column" fxLayoutGap="15px">
      <form [formGroup]="reviewFormGroup" novalidate>
        <div fxLayout="column" fxLayoutGap="10px">
          <div class="row" fxLayout="column">
            <div fxLayout="row">
              <p class="mat-title">Overall Rating</p>
            </div>
            <div class="col-sm-12">
              <ul class="list-inline rating-list" *ngFor="let star of stars; let i= index"
                style="display: inline-block">
                <li (click)="countStar(star)" id="{{'starId'+i}}" [ngClass]="{'selected': (star <= selectedValue)}"
                  (mouseover)="addClass(star)" (mouseout)="removeClass(star)"> ★
                </li>
              </ul>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row">
              <p class="mat-title">Rate Features</p>
            </div>
            <div fxLayout="column">
              <div fxLayout="row">
                <p class="mat-h3">Fingerprint reader</p>
              </div>
              <div class="col-sm-12">
                <ul class="list-inline rating-list" *ngFor="let star1 of stars1; let i= index"
                  style="display: inline-block">
                  <li (click)="countStar1(star1)" id="{{'starId1'+i}}"
                    [ngClass]="{'selected': (star1 <= selectedValue1)}" (mouseover)="addClass1(star1)"
                    (mouseout)="removeClass1(star1)"> ★
                  </li>
                </ul>
              </div>
            </div>
            <div fxLayout="column">
              <div fxLayout="row">
                <p class="mat-h3">Battery life</p>
              </div>
              <div class="col-sm-12">
                <ul class="list-inline rating-list" *ngFor="let star2 of stars2; let i= index"
                  style="display: inline-block">
                  <li (click)="countStar2(star2)" id="{{'starId2'+i}}"
                    [ngClass]="{'selected': (star2 <= selectedValue2)}" (mouseover)="addClass2(star2)"
                    (mouseout)="removeClass2(star2)"> ★
                  </li>
                </ul>
              </div>
            </div>
            <div fxLayout="column">
              <div fxLayout="row">
                <p class="mat-h3">Camera quality</p>
              </div>
              <div class="col-sm-12">
                <ul class="list-inline rating-list" *ngFor="let star3 of stars3; let i= index"
                  style="display: inline-block">
                  <li (click)="countStar3(star3)" id="{{'starId3'+i}}"
                    [ngClass]="{'selected': (star3 <= selectedValue3)}" (mouseover)="addClass3(star3)"
                    (mouseout)="removeClass3(star3)"> ★
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column" fxLayoutAlign="start stretch">
            <div fxLayout="column">
              <div fxLayout="row">
                <p class="mat-title">Add a Headline</p>
              </div>
              <div fxLayout="row">
                <mat-form-field appearance="outline" fxFlex="100">
                  <input formControlName="headline" matInput placeholder="What's most important to know?">
                  <mat-error>Please enter your headline</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="column">
              <div fxLayout="row">
                <p class="mat-title">Add a written review</p>
              </div>
              <div fxLayout="row">
                <mat-form-field appearance="outline" fxFlex="100">
                  <textarea matInput formControlName="review"
                    placeholder="What did you like or dislike? What did you use this products for?"></textarea>
                  <mat-error>Please enter your review</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="column">
              <div fxLayout="column">
                <p class="mat-title">Choose your public name</p>
                <p class="color-secondary">This is how you'll appear to other customers</p>
              </div>
              <div fxLayout="row">
                <mat-form-field appearance="outline" fxFlex="100">
                  <input formControlName="fullName" matInput placeholder="John Dean">
                  <mat-error>Please enter your public profile name</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxLayout="row">
            <button mat-raised-button color="primary" mat-dialog-close>Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>