<div class="container">
  <div fxLayout="row" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxLayoutGap="20px" fxHide.lt-sm fxShow>
      <div fxLayout="column" fxLayoutGap="10px">
        <p class="color-secondary mat-h3">Show results for</p>
        <div>
          <div fxLayout="column">
            <p class="mat-small color-hover">Electronics</p>
            <p class="mat-small font-bold">Mobiles & Accessories</p>
          </div>
          <div class="padding-side" fxLayout="column">
            <p class="mat-small color-hover">Mobile Accessories</p>
            <p class="mat-small color-hover">SIM Cards</p>
            <p class="mat-small color-hover">Smartphones & Basic Mobiles</p>
          </div>
        </div>
      </div>
      <div>
        <mat-divider></mat-divider>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        <p class="color-secondary mat-h3">Refind by</p>
        <div fxLayout="column">
          <p class="mat-small font-bold">Pay on Delivery</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Eligible for Pay On
            Delivery</mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Brands</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Boat</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">OnePlus</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">JBL</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">realme</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">PTron</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Redmi</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Samsung</mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Avg. Customers Reviews</p>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <p class="mat-small color-hover"> & up</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <p class="mat-small color-hover"> & up</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <p class="mat-small color-hover"> & up</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <p class="mat-small color-hover"> & up</p>
          </div>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">New Arrivals</p>
          <p class="mat-small color-hover">Last 30 days</p>
          <p class="mat-small color-hover">Last 90 days</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Item Condition <span class="mat-h5">(What's this?)</span></p>
          <p class="mat-small color-hover">New</p>
          <p class="mat-small color-hover">Renewed</p>
          <p class="mat-small color-hover">Used</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">price</p>
          <p class="mat-small color-hover">Under ₹1,000</p>
          <p class="mat-small color-hover">₹1,000 - ₹5,000</p>
          <p class="mat-small color-hover">₹5,000 - ₹10,000</p>
          <p class="mat-small color-hover">₹10,000 - ₹20,000</p>
          <p class="mat-small color-hover">Over ₹20,000</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Deals</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Today's Deals
          </mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Discount</p>
          <p class="mat-small color-hover">10% off or more</p>
          <p class="mat-small color-hover">25% off or more</p>
          <p class="mat-small color-hover">35% off or more</p>
          <p class="mat-small color-hover">50% off or more</p>
        </div>
        <!-- <div fxLayout="column">
          <p class="mat-small font-bold">Seller</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Appario Retails Private Ltd.</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Fashionury</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Spigen India</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cloudtail India</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Khurana Communication</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">PuzzleStore</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">TheGiftKart</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Stylabs</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">ImagineDesign</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">iAccessorize India</mat-checkbox>
        </div> -->
        <div fxLayout="column">
          <p class="mat-small font-bold">Availability</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Include Out of Stock
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
      <div class="slider">
        <div class="slider-img">
          <mat-carousel timings="250ms ease-in-out" [autoplay]="true" interval="4000" proportion="35"
            [useKeyboard]="true" [loop]="true" orientation="ltr">
            <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides; let i = index" [image]="slide.image"
              fxLayoutAlign="center center">
            </mat-carousel-slide>
          </mat-carousel>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-title">Latest launches in smartphones</p>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/mobile-phone/latest1.jpg" alt="" fxFlex="48">
            <img src="assets/images/mobile-phone/latest2.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/mobile-phone/latest3.jpg" alt="" fxFlex="48">
            <img src="assets/images/mobile-phone/latest4.jpg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <div>
          <img src="assets/images/mobile-phone/banner1.jpeg" alt="" class="full-width">
        </div>
        <div>
          <img src="assets/images/mobile-phone/banner2.jpg" alt="" class="full-width">
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-title">Samsung Galaxy Smartphones</p>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/mobile-phone/samsung1.jpg" alt="" fxFlex="31">
            <img src="assets/images/mobile-phone/samsung2.jpg" alt="" fxFlex="31">
            <img src="assets/images/mobile-phone/samsung3.jpg" alt="" fxFlex="31">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/mobile-phone/samsung4.jpg" alt="" fxFlex="31">
            <img src="assets/images/mobile-phone/samsung5.jpg" alt="" fxFlex="31">
            <img src="assets/images/mobile-phone/samsung6.jpg" alt="" fxFlex="31">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-title">Xiaomi Smartphones</p>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/mobile-phone/xiaomi1.jpg" alt="" fxFlex="31">
            <img src="assets/images/mobile-phone/xiaomi2.jpg" alt="" fxFlex="31">
            <img src="assets/images/mobile-phone/xiaomi3.jpg" alt="" fxFlex="31">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/mobile-phone/xiaomi4.jpg" alt="" fxFlex="31">
            <img src="assets/images/mobile-phone/xiaomi5.jpg" alt="" fxFlex="31">
            <img src="assets/images/mobile-phone/xiaomi6.jpg" alt="" fxFlex="31">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-title">Smartphones for all budgets</p>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/mobile-phone/budget1.jpg" alt="" fxFlex="48">
            <img src="assets/images/mobile-phone/budget2.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/mobile-phone/budget3.jpg" alt="" fxFlex="48">
            <img src="assets/images/mobile-phone/budget4.jpg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-title">Bestselling OPPO smartphones</p>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/mobile-phone/oppo1.jpg" alt="" fxFlex="31">
            <img src="assets/images/mobile-phone/oppo2.jpg" alt="" fxFlex="31">
            <img src="assets/images/mobile-phone/oppo3.jpg" alt="" fxFlex="31">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/mobile-phone/oppo4.jpg" alt="" fxFlex="31">
            <img src="assets/images/mobile-phone/oppo5.jpg" alt="" fxFlex="31">
            <img src="assets/images/mobile-phone/oppo6.jpg" alt="" fxFlex="31">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-title">Top selling vivo smartphones</p>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/mobile-phone/vivo1.jpg" alt="" fxFlex="31">
            <img src="assets/images/mobile-phone/vivo2.jpg" alt="" fxFlex="31">
            <img src="assets/images/mobile-phone/vivo3.jpg" alt="" fxFlex="31">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/mobile-phone/vivo4.jpg" alt="" fxFlex="31">
            <img src="assets/images/mobile-phone/vivo5.jpg" alt="" fxFlex="31">
            <img src="assets/images/mobile-phone/vivo6.jpg" alt="" fxFlex="31">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <div>
          <img src="assets/images/mobile-phone/banner3.jpg" alt="" class="full-width">
        </div>
        <div>
          <img src="assets/images/mobile-phone/banner4.jpg" alt="" class="full-width">
        </div>
        <div>
          <img src="assets/images/mobile-phone/banner5.jpg" alt="" class="full-width">
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="40px">
        <div fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
          <p class="mat-title" fxLayoutAlign="center center">Shop by Brand</p>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
            <div fxLayout="row" fxLayoutAlign="space-around start">
              <img src="assets/images/mobile-phone/brand1.jpg" alt="" fxFlex="48">
              <img src="assets/images/mobile-phone/brand2.jpg" alt="" fxFlex="48">
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around start">
              <img src="assets/images/mobile-phone/brand3.jpg" alt="" fxFlex="48">
              <img src="assets/images/mobile-phone/brand4.jpg" alt="" fxFlex="48">
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around start">
              <img src="assets/images/mobile-phone/brand5.jpg" alt="" fxFlex="48">
              <img src="assets/images/mobile-phone/brand6.jpg" alt="" fxFlex="48">
            </div>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
            <div fxLayout="row" fxLayoutAlign="space-around start">
              <img src="assets/images/mobile-phone/brand7.jpg" alt="" fxFlex="48">
              <img src="assets/images/mobile-phone/brand8.jpg" alt="" fxFlex="48">
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around start">
              <img src="assets/images/mobile-phone/brand9.jpg" alt="" fxFlex="48">
              <img src="assets/images/mobile-phone/brand10.png" alt="" fxFlex="48">
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around start">
              <img src="assets/images/mobile-phone/brand11.jpg" alt="" fxFlex="48">
              <img src="assets/images/mobile-phone/brand12.jpg" alt="" fxFlex="48">
            </div>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
          <p class="mat-title" fxLayoutAlign="center center">Choose the phone that fits your pocket</p>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
            <div fxLayout="row" fxLayoutAlign="space-around start">
              <img src="assets/images/mobile-phone/pocket1.jpg" alt="" fxFlex="48">
              <img src="assets/images/mobile-phone/pocket2.jpg" alt="" fxFlex="48">
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around start">
              <img src="assets/images/mobile-phone/pocket3.jpg" alt="" fxFlex="48">
              <img src="assets/images/mobile-phone/pocket4.jpg" alt="" fxFlex="48">
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around start">
              <img src="assets/images/mobile-phone/pocket5.jpg" alt="" fxFlex="48">
              <img src="assets/images/mobile-phone/pocket6.jpg" alt="" fxFlex="48">
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="40px">
        <div fxLayout="row" class="card-shadow cardpadding">
          <p class="mat-small">1-24 of over 90,000 results for <span class="font-bold color-hover">
              Electronics
            </span>: <span class="font-bold color-primary"> Mobiles & Accessories </span></p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div class="details-card" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-secondary">Sponsored </p>
                <p class="mat-h3 color-accent color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="mat-small">Your Save: <span class="color-danger">{{item.saving}}</span></p>
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover">See Details</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items1" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div class="details-card" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-secondary">Sponsored </p>
                <p class="mat-h3 color-accent color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="mat-small">Your Save: <span class="color-danger">{{item.saving}}</span></p>
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover">See Details</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items2" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div class="details-card" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-secondary">Sponsored </p>
                <p class="mat-h3 color-accent color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="mat-small">Your Save: <span class="color-danger">{{item.saving}}</span></p>
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover">See Details</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div *ngFor="let item of items3" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div class="details-card" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-secondary">Sponsored </p>
                <p class="mat-h3 color-accent color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="mat-small">Your Save: <span class="color-danger">{{item.saving}}</span></p>
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover">See Details</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card-shadow" fxHide>
  <mat-card>
    <div class="cardpadding" fxLayout="column" fxLayoutGap="30px">
      <div class="slider-items" fxLayout="column" fxLayoutGap="20px">
        <p class="mat-title">Inspired by your browsing history</p>
        <owl-carousel-o [options]="customOptions" class="cardpadding">
          <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
            <img [src]="slide.src">
            <div fxLayout="column" fxLayoutAlign="start start">
              <span class="color-accent color-hover">{{slide.text}}</span>
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star_border</mat-icon>
                <mat-icon>keyboard_arrow_down</mat-icon>
                <p class="color-accent color-hover">{{slide.reviewno}}</p>
              </div>
              <span class="color-danger">{{slide.price}}</span>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
      <div class="slider-items" fxLayout="column" fxLayoutGap="20px">
        <p class="mat-title">Top subscription apps for you</p>
        <owl-carousel-o [options]="customOptions1" class="cardpadding">
          <ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
            <img [src]="slide.src">
            <div fxLayout="column" fxLayoutAlign="start start">
              <span class="color-accent color-hover">{{slide.text}}</span>
              <span class="mat-small">{{slide.price}}</span>
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star_border</mat-icon>
                <mat-icon>keyboard_arrow_down</mat-icon>
                <p class="color-accent color-hover">{{slide.reviewno}}</p>
              </div>
              <span class="color-danger">FREE</span>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </mat-card>
</div>