import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  yourEmail: string;

  constructor(public router: Router) { }

  ngOnInit(): void {
    this.yourEmail = localStorage.getItem('yourEmail')
  }
  gotohome() {
    // localStorage.removeItem('yourEmail');
    this.router.navigate(['/home'])
  }
}
