import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-fashion',
  templateUrl: './fashion.component.html',
  styleUrls: ['./fashion.component.scss']
})
export class FashionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
     responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/images/fashion/carousel/1.jpg',
    },
    {
      id: 2,
      src: './assets/images/fashion/carousel/2.jpg',
    },
    {
      id: 3,
      src: './assets/images/fashion/carousel/3.jpg',
    },
    {
      id: 4,
      src: './assets/images/fashion/carousel/4.jpg',
    },
    {
      id: 5,
      src: './assets/images/fashion/carousel/5.jpg',
    },
    {
      id: 6,
      src: './assets/images/fashion/carousel/6.jpg',
    },
    {
      id: 7,
      src: './assets/images/fashion/carousel/7.jpg',
    },
    {
      id: 8,
      src: './assets/images/fashion/carousel/8.jpg',
    },
    {
      id: 9,
      src: './assets/images/fashion/carousel/9.jpg',
    },
  ]

  customOptions1: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/fashion/carousel2/1.jpg',
      price: '₹ 399 - ₹ 1,299',
      name: 'Min 60% OFF Sports Shoes from Bourge, Amazon Brand - Symactiv...',
      reviewno: '2'
    },
    {
      id: 2,
      src: './assets/images/fashion/carousel2/2.jpg',
      price: '₹ 373 - ₹ 1,354',
      name: 'Min 65% Off on Sweatshirts and Jackets from Amazon Brand - Sy...',
      reviewno: '2'
    },
    {
      id: 3,
      src: './assets/images/fashion/carousel2/3.jpg',
      price: '₹ 224 - ₹ 449',
      name: 'Upto 75% off on Womens footwear',
      reviewno: '2887'
    },
    {
      id: 4,
      src: './assets/images/fashion/carousel2/4.jpg',
      price: '₹ 311 - ₹ 916',
      name: 'Upto 75% off on Mens footwear',
      reviewno: '4'
    },
    {
      id: 5,
      src: './assets/images/fashion/carousel2/5.jpg',
      price: '₹ 179 - ₹ 899',
      name: 'Min 60% OFF Sandals & Flip Flops from Centrino, Bourge & More ',
      reviewno: '2063'
    },
    {
      id: 6,
      src: './assets/images/fashion/carousel2/6.jpg',
      price: '₹ 227 - ₹ 992',
      name: 'Min 65% Off on Kid’s Apparel from Amazon Brand Jam & Honey, Blin...',
      reviewno: '57'
    },
    {
      id: 7,
      src: './assets/images/fashion/carousel2/7.jpg',
      price: '₹ 119 - ₹ 499',
      name: 'Womens Tops, Dresses & more Under 499: Harpa, Miss Chase, Pl...',
      reviewno: '1'
    },
    {
      id: 8,
      src: './assets/images/fashion/carousel2/8.jpg',
      price: '₹ 254 - ₹ 509',
      name: 'Upto 75% off on Flip flops & Slippers',
      reviewno: '13'
    },
    {
      id: 9,
      src: './assets/images/fashion/carousel2/9.jpg',
      price: '₹ 649 - ₹ 1,699',
      name: 'Upto 75% off on Motherly baby products',
      reviewno: '885'
    },
    {
      id: 10,
      src: './assets/images/fashion/carousel2/10.jpg',
      price: '₹ 625 - ₹ 1,789',
      name: 'Cases and covers from Ringke - Upto 70% off',
      reviewno: '1850'
    },
  ]

    customOptions2: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
     responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore2 = [
    {
      id: 1,
      src: './assets/images/fashion/carousel3/1.jpg',
    },
    {
      id: 2,
      src: './assets/images/fashion/carousel3/2.jpg',
    },
    {
      id: 3,
      src: './assets/images/fashion/carousel3/3.jpg',
    },
    {
      id: 4,
      src: './assets/images/fashion/carousel3/4.jpg',
    },
    {
      id: 5,
      src: './assets/images/fashion/carousel3/5.jpg',
    },
    {
      id: 6,
      src: './assets/images/fashion/carousel3/6.jpg',
    },
    {
      id: 7,
      src: './assets/images/fashion/carousel3/7.jpg',
    },
    {
      id: 8,
      src: './assets/images/fashion/carousel3/8.jpg',
    },
    {
      id: 9,
      src: './assets/images/fashion/carousel3/9.jpg',
    },
  ]
  customOptions3: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
     responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore3 = [
    {
      id: 1,
      src: './assets/images/fashion/carousel4/1.jpg',
      item:'Shop womens jackets'
    },
    {
      id: 2,
      src: './assets/images/fashion/carousel4/2.jpg',
      item:'Shop mens t-shirts'
    },
    {
      id: 3,
      src: './assets/images/fashion/carousel4/3.jpg',
      item:'Shop womens tops & tees'
    },
    {
      id: 4,
      src: './assets/images/fashion/carousel4/4.jpg',
      item:'Shop mens sweatshirts & hoodies'
    },
    {
      id: 5,
      src: './assets/images/fashion/carousel4/5.jpg',
      item:'Shop womens dresses'
    },
    {
      id: 6,
      src: './assets/images/fashion/carousel4/6.jpg',
      item:'Shop mens shirts'
    },
    {
      id: 7,
      src: './assets/images/fashion/carousel4/7.jpg',
      item:'Shop womens jacket'
    },
    {
      id: 8,
      src: './assets/images/fashion/carousel4/8.jpg',
      item:'Shop mens apparel'
    },
    {
      id: 9,
      src: './assets/images/fashion/carousel4/9.jpg',
      item:'Shop womens suits'
    },
    {
      id: 10,
      src: './assets/images/fashion/carousel4/10.jpg',
      item: 'Shop mens knits & sweaters'
    },
    {
      id: 11,
      src: './assets/images/fashion/carousel4/11.jpg',
      item: 'Shop womens sarees'
    },
  ]

  customOptions4: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
     responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore4 = [
    {
      id: 1,
      src: './assets/images/fashion/carousel5/1.gif',
      item: 'Shop now'
    },
    {
      id: 2,
      src: './assets/images/fashion/carousel5/2.jpg',
      item: 'Mens sweatshirts & hoodies '
    },
    {
      id: 3,
      src: './assets/images/fashion/carousel5/3.jpg',
      item: 'Womens swaetshirts & hoodies'
    },
    {
      id: 4,
      src: './assets/images/fashion/carousel5/4.jpg',
      item: 'Jeans'
    },
    {
      id: 5,
      src: './assets/images/fashion/carousel5/5.jpg',
      item: 'Mens Shoes'
    },
    {
      id: 6,
      src: './assets/images/fashion/carousel5/6.jpg',
      item: 'Kurtis'
    },
    {
      id: 7,
      src: './assets/images/fashion/carousel5/7.jpg',
      item: 'T-shirts & polos'
    },
    {
      id: 8,
      src: './assets/images/fashion/carousel5/8.jpg',
      item: 'Tops & tees'
    },
    {
      id: 9,
      src: './assets/images/fashion/carousel5/9.jpg',
      item: 'Dresses'
    },
    {
      id: 10,
      src: './assets/images/fashion/carousel5/11.jpg',
      item: 'Kids clothing'
    },
    {
      id: 11,
      src: './assets/images/fashion/carousel5/12.jpg',
      item: 'Mens Slippers & sandals'
    },
    {
      id: 12,
      src: './assets/images/fashion/carousel5/13.jpg',
      item: 'Bags & laguages'
    },
  ]
  customOptions5: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
     responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore5 = [
    {
      id: 1,
      src: './assets/images/fashion/carousel6/1.jpg',
    },
    {
      id: 2,
      src: './assets/images/fashion/carousel6/2.jpg',
    },
    {
      id: 3,
      src: './assets/images/fashion/carousel6/3.jpg',
    },
    {
      id: 4,
      src: './assets/images/fashion/carousel6/4.jpg',
    },
    {
      id: 5,
      src: './assets/images/fashion/carousel6/5.jpg',
    },
    {
      id: 6,
      src: './assets/images/fashion/carousel6/6.jpg',
    },
    {
      id: 7,
      src: './assets/images/fashion/carousel6/7.jpg',
    },
    {
      id: 8,
      src: './assets/images/fashion/carousel6/8.jpg',
    },
    {
      id: 9,
      src: './assets/images/fashion/carousel6/9.jpg',
    },
  ]

}
