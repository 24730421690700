import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-combo-offer',
  templateUrl: './combo-offer.component.html',
  styleUrls: ['./combo-offer.component.scss']
})
export class ComboOfferComponent implements OnInit {
  items = [
    { image: 'assets/images/combo/1.jpg', name: 'Satya Paul Women\'s Woollen Shawls (Multicolour)', company: '', newprice: '₹ 5,196', oldprice: '₹ 6,495', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '34,264' },
    { image: 'assets/images/combo/2.jpg', name: 'Bourge Men\'s Loire-1 Running Shoes', company: '', newprice: '₹ 649', oldprice: '₹ 1,499', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '2,861' },
    { image: 'assets/images/combo/3.jpg', name: 'PARAGON Men\'s Brown Formal Thong Sandals-8 UK/India (42 EU)(PU6673-68)', company: '', newprice: '₹ 269', oldprice: '₹ 299', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '4,131' },
    { image: 'assets/images/combo/4.jpg', name: 'TIMEWEAR Analogue - Digital Men\'s Watch (Black Dial)', company: '', newprice: '₹ 659', oldprice: '₹ 3,499', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '34,264' },
    { image: 'assets/images/combo/5.jpg', name: 'Mammon Women\'s Handbag With Sling Bag & Wristlet (Set of 3) (3L-bib-Cream_Cream)', company: '', newprice: '₹ 603', oldprice: '₹ 3,499', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '2,861' },
    { image: 'assets/images/combo/6.jpg', name: 'M MEDLER Epoch Nylon 55 litres Waterproof Strolley Duffle Bag- 2 Wheels - Luggage Bag - (Navy Blue)', company: '', newprice: '₹ 549', oldprice: '₹ 2,999', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '4,131' },
    { image: 'assets/images/combo/7.jpg', name: '1 Stop Fashion Women\'s Crepe Straight Kurti', company: '', newprice: '₹ 1,299', oldprice: '₹ 1,599', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '34,264' },
    { image: 'assets/images/combo/8.jpg', name: 'GoSriKi Women\'s Rayon Straight Kurta (Pack of 2)', company: '', newprice: '₹ 499', oldprice: '₹ 3,999', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '2,861' },
    { image: 'assets/images/combo/9.jpg', name: 'Fargo PU Leather Latest Stylish Handbags For Women\'s Ladies Combo Of 3 (Grey_Teddy_FGO-239)', company: '', newprice: '₹ 563', oldprice: '₹ 2,499', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '4,131' },
    { image: 'assets/images/combo/10.jpg', name: 'vaidehi creation Women\'s Silk a-line Dress Material', company: '', newprice: '₹ 337', oldprice: '₹ 999', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '34,264' },
    { image: 'assets/images/combo/11.jpg', name: 'Shoeshine Shoelace Flat Sport Sneaker Laces (Pack of 3 Pair)', company: '', newprice: '₹ 189', oldprice: '₹ 225', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '2,861' },
    { image: 'assets/images/combo/12.jpg', name: 'WORLD WEAR FOOTWEAR Men\'s Loafer & Sneaker (Set of 2 Pairs)', company: '', newprice: '₹ 367', oldprice: '₹ 998', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '4,131' },
    { image: 'assets/images/combo/13.jpg', name: 'Plastic Spin Bucket Mop, Blue (1 Piece)', company: '', newprice: '₹ 548', oldprice: '₹ 799', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '4,131' },
    { image: 'assets/images/combo/14.jpg', name: 'Faaa Soup Plastic Bowl 12 Pc Set', company: '', newprice: '₹ 149', oldprice: '₹ 299', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '34,264' },
    { image: 'assets/images/combo/15.jpg', name: 'Little\'s Junior Ring (Multicolour)', company: '', newprice: '₹ 163', oldprice: '₹ 225', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '2,861' },
    { image: 'assets/images/combo/16.jpg', name: 'Generic Unisex Head Wrap Bandana (Multicolour, Free Size) - Pack of 6', company: '', newprice: '₹ 195', oldprice: '₹ 499', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '4,131' },
    { image: 'assets/images/combo/1.jpg', name: 'Satya Paul Women\'s Woollen Shawls (Multicolour)', company: '', newprice: '₹ 5,196', oldprice: '₹ 6,495', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '34,264' },
    { image: 'assets/images/combo/2.jpg', name: 'Bourge Men\'s Loire-1 Running Shoes', company: '', newprice: '₹ 649', oldprice: '₹ 1,499', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '2,861' },
    { image: 'assets/images/combo/3.jpg', name: 'PARAGON Men\'s Brown Formal Thong Sandals-8 UK/India (42 EU)(PU6673-68)', company: '', newprice: '₹ 269', oldprice: '₹ 299', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '4,131' },
    { image: 'assets/images/combo/4.jpg', name: 'TIMEWEAR Analogue - Digital Men\'s Watch (Black Dial)', company: '', newprice: '₹ 659', oldprice: '₹ 3,499', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '34,264' },
    { image: 'assets/images/combo/5.jpg', name: 'Mammon Women\'s Handbag With Sling Bag & Wristlet (Set of 3) (3L-bib-Cream_Cream)', company: '', newprice: '₹ 603', oldprice: '₹ 3,499', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '2,861' },
    { image: 'assets/images/combo/6.jpg', name: 'M MEDLER Epoch Nylon 55 litres Waterproof Strolley Duffle Bag- 2 Wheels - Luggage Bag - (Navy Blue)', company: '', newprice: '₹ 549', oldprice: '₹ 2,999', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '4,131' },
    { image: 'assets/images/combo/7.jpg', name: '1 Stop Fashion Women\'s Crepe Straight Kurti', company: '', newprice: '₹ 1,299', oldprice: '₹ 1,599', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '34,264' },
    { image: 'assets/images/combo/8.jpg', name: 'GoSriKi Women\'s Rayon Straight Kurta (Pack of 2)', company: '', newprice: '₹ 499', oldprice: '₹ 3,999', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '2,861' },
    { image: 'assets/images/combo/9.jpg', name: 'Fargo PU Leather Latest Stylish Handbags For Women\'s Ladies Combo Of 3 (Grey_Teddy_FGO-239)', company: '', newprice: '₹ 563', oldprice: '₹ 2,499', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '4,131' },
    { image: 'assets/images/combo/10.jpg', name: 'vaidehi creation Women\'s Silk a-line Dress Material', company: '', newprice: '₹ 337', oldprice: '₹ 999', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '34,264' },
    { image: 'assets/images/combo/11.jpg', name: 'Shoeshine Shoelace Flat Sport Sneaker Laces (Pack of 3 Pair)', company: '', newprice: '₹ 189', oldprice: '₹ 225', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '2,861' },
    { image: 'assets/images/combo/12.jpg', name: 'WORLD WEAR FOOTWEAR Men\'s Loafer & Sneaker (Set of 2 Pairs)', company: '', newprice: '₹ 367', oldprice: '₹ 998', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '4,131' },
    { image: 'assets/images/combo/13.jpg', name: 'Plastic Spin Bucket Mop, Blue (1 Piece)', company: '', newprice: '₹ 548', oldprice: '₹ 799', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '4,131' },
    { image: 'assets/images/combo/14.jpg', name: 'Faaa Soup Plastic Bowl 12 Pc Set', company: '', newprice: '₹ 149', oldprice: '₹ 299', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '34,264' },
    { image: 'assets/images/combo/15.jpg', name: 'Little\'s Junior Ring (Multicolour)', company: '', newprice: '₹ 163', oldprice: '₹ 225', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '2,861' },
    { image: 'assets/images/combo/16.jpg', name: 'Generic Unisex Head Wrap Bandana (Multicolour, Free Size) - Pack of 6', company: '', newprice: '₹ 195', oldprice: '₹ 499', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '4,131' },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
