import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {

  constructor( public router: Router,) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }
  
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/images/electronics/camera/carousel/1.jpg',
      price: '₹ 4,990',
      oldprice: '₹ 5,599',
      off: '(11% off)',
      name: 'BINOCULARS UP 8x21 BLACK WITH CASE',
      brand: 'Sold by Appario Retail Private Ltd and Fulfilled by Amazon.',
      reviewno: '2',
      button: 'Add to Cart'
    },
    {
      id: 2,
      src: './assets/images/electronics/camera/carousel/2.jpg',
      price: '₹ 25,490',
      oldprice: '₹ 37,990',
      off: '(33% off)',
      name: 'Panasonic Camera Accy H-FS35100E-K 35-100mm Lense (B...',
      brand: 'Sold by Appario Retail Private Ltd and Fulfilled by Amazon.',
      reviewno: '55',
      button: 'Add to Cart'
    },
    {id: 3},
    {id: 4},
    {id: 5},
    
  ]

  customOptions1: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      },
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/electronics/camera/carousel1/1.jpg',
      save: 'Save 5% ',
      name: 'Save 5% on D3D Face Detection Cameras',
      button: 'Collect Coupon'
    },
    {
      id: 2,
      src: './assets/images/electronics/camera/carousel1/2.jpg',
      save: 'Save ₹ 197.00',
      name: 'Celestron 71256 G2 10x50 Upclose Wide-',
      button: 'Collect Coupen'
    },
    {
      id: 3,
      src: './assets/images/electronics/camera/carousel1/3.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Product',
      button: 'Collect Coupen'
    },
    {
      id: 4,
      src: './assets/images/electronics/camera/carousel1/4.jpg',
      save: 'Save ₹ 417.00',
      name: 'Celestron 71009 15x70 Skymaster Binocular',
      button: 'Collect Coupen'
    },
    {
      id: 5,
      src: './assets/images/electronics/camera/carousel1/5.jpg',
      save: 'Save 5%',
      name: 'Save 5% on special offer',
      button: 'Collect Coupen'
    },
    {
      id: 6,
      src: './assets/images/electronics/camera/carousel1/6.jpg',
      save: 'Save 5%',
      name: 'Save 5% on product of Bosig Mart',
      button: 'Collect Coupen'
    },
    {
      id: 7,
      src: './assets/images/electronics/camera/carousel1/7.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Extra on This Device',
      button: 'Collect Coupen'
    },
    {
      id: 8,
      src: './assets/images/electronics/camera/carousel1/8.jpg',
      save: 'Save 5%',
      name: 'Save 5% on all KF Concept ND and UV ',
      button: 'Collect Coupen'
    },
    {
      id: 9,
      src: './assets/images/electronics/camera/carousel1/9.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Crystal Clear Photography Ball',
      button: 'Collect Coupen'
    },
    {
      id: 10,
      src: './assets/images/electronics/camera/carousel1/10.jpg',
      save: 'Save ₹ 32.00',
      name: 'Photron STEDY 350 Tripod with Mobile ',
      button: 'Collect Coupen'
    },
    {
      id: 11,
      src: './assets/images/electronics/camera/carousel1/11.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Guru08',
      button: 'Collect Coupen'
    },
    {
      id: 12,
      src: './assets/images/electronics/camera/carousel1/12.jpg',
      save: 'Save ₹ 8.00',
      name: 'Everycom Camera Stand Clip Bracket',
      button: 'Collect Coupen'
    },
  ]

}
