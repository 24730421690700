import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  fullName: string;
  flat: string;
  area: string;
  Landmark: string;
  City: string;
  pin: string;
  State: string;
  PhoneNumber: string;
  country: string;
  hide = true;
  hidecon = true;
  checkoutFormGroup: FormGroup;
  public txtEmail;
  public txtMobileno
  public txtPassword;
  error: string;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
  
    this.fullName= localStorage.getItem("fullName");
    this.flat = localStorage.getItem("flat");
    this.area =localStorage.getItem("area");
    this.Landmark =localStorage.getItem("Landmark");
    this.City =localStorage.getItem("City");
    this.pin =localStorage.getItem("pin");
    this.State =localStorage.getItem("State");
    this.PhoneNumber =localStorage.getItem("phoneNumber");
    this.country = localStorage.getItem("country");

    this.checkoutFormGroup = this.formBuilder.group({
      fullName: new FormControl('', Validators.required),
      flat: new FormControl(''),
      area: new FormControl(''),
      Landmark: new FormControl(''),
      City: new FormControl(''),
      pin: new FormControl(''),
      State: new FormControl(''),
      country: new FormControl(''),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('[1-9]\\d{9}'),
      ]),

    });
    this.txtEmail = this.checkoutFormGroup.get('email');
    this.txtMobileno = this.checkoutFormGroup.get('phoneNumber');
  }
  get f() { return this.checkoutFormGroup.controls; }

  checkout() {
    if (this.checkoutFormGroup.invalid) {
      return;
    }
    console.log(this.checkoutFormGroup.value)
    localStorage.setItem("fullName", this.f.fullName.value);
    localStorage.setItem("flat", this.f.flat.value);
    localStorage.setItem("area", this.f.area.value);
    localStorage.setItem("Landmark", this.f.Landmark.value);
    localStorage.setItem("City", this.f.City.value);
    localStorage.setItem("pin", this.f.pin.value);
    localStorage.setItem("State", this.f.State.value);
    localStorage.setItem("country", this.f.country.value);
    localStorage.setItem("phoneNumber", this.f.phoneNumber.value);

    console.log(this.f.fullName.value)
    // this.router.navigate(['/checkout'])
  }
}
