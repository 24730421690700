<div class="container margin-top">
  <div [ngClass.gt-sm]="'cardpadding2'" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutAlign.lt-sm="center center">
      <p class="mat-headline">Your Orders</p>
    </div>
    <div>
      <mat-tab-group>
        <mat-tab label="Orders" class="color-accent">
          <ng-template matTabContent>
            <div [ngClass.gt-sm]="'cardpadding'" fxLayout="column" fxLayoutGap="10px">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="center center" fxLayoutGap="5px">
                <p class="font-bold">7 Orders</p>
                <p class="mat-small">placed in</p>
                <mat-form-field color="accent">
                  <mat-select [(ngModel)]="selectedValuelast" name="last">
                    <mat-option *ngFor="let last of lasts" [value]="last.value">
                      {{last.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxLayout="column" fxLayoutGap="20px" *ngFor="let item of items" fxFlex>
                <mat-card>
                  <div fxLayout="column">
                    <div class="cardpadding bg-secondary" fxLayout="row" fxLayoutAlign="space-between start"
                      fxHide.lt-sm>
                      <div fxLayout="row" fxLayoutGap="20px">
                        <div fxLayout="column">
                          <p>Order Placed</p>
                          <p>{{item.date}}</p>
                        </div>
                        <div fxLayout="column">
                          <p>Total</p>
                          <p>{{item.price}}</p>
                        </div>
                      </div>
                      <div fxLayout="column" fxLayoutAlign="end start">
                        <p>Order # {{item.id}}</p>
                        <div fxLayout="row" fxLayoutGap="20px">
                          <p class="color-accent">Order Details</p>
                          <p class="color-accent">Invoice</p>
                        </div>
                      </div>
                    </div>
                    <div class="cardpadding" fxLayout="column" fxLayoutGap="10px">
                      <div fxLayout="column" fxLayoutGap="5px">
                        <p class="mat-title">Successful</p>
                        <p>Paid on: {{item.date}}</p>
                      </div>
                      <div fxLayout="row" fxLayoutGap="20px" fxFlex>
                        <div [ngClass]="'card-image image-height'" fxLayoutAlign="center center" fxFlex="20"
                          fxFlex.lt-sm="40" (click)="products()">
                          <img src="{{item.img}}" alt="">
                        </div>
                        <div fxLayout="column" fxLayoutGap="5px" fxFlex.lt-sm="60">
                          <p class="mat-h4 color-accent cursor color-hover" (click)="products()">{{item.name}}</p>
                          <p class="color-secondary">Sold by: <span class="color-accent">{{item.sold}}</span></p>
                          <p class="mat-small">Return window closed on {{item.returndate}}</p>
                          <p class="color-danger">{{item.price}}</p>
                          <div fxLayout="row" fxLayoutAlign="start start">
                            <button mat-raised-button color="primary" (click)="products()">Buy it again</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Buy Again">
          <ng-template matTabContent>
            <div fxLayout="row wrap" fxLayout.lt-sm="column">
              <div fxLayout="row" fxLayoutGap="20px" *ngFor="let item of items" fxFlex="25">
                <mat-card>
                  <div fxLayout="column" fxLayoutAlign="space-between" fxLayout.lt-sm="row" fxLayoutGap="15px" fxFlex>
                    <div fxLayout="row" [ngClass]="'card-image image-height'" fxLayoutAlign="center center"
                      fxFlex.lt-md="40" fxFlex="40" (click)="products()">
                      <img src="{{item.img}}" alt="">
                    </div>
                    <div fxLayout="column" fxLayoutAlign.lt-md="space-between" fxFlex="60" fxFlex.lt-sm="60">
                      <div fxLayout="column" fxFlex="80">
                        <p class="mat-h4 color-hover cursor" (click)="products()">{{item.name}}</p>
                        <p class="color-secondary">Currently unavailable</p>
                      </div>
                      <div fxLayout="row" fxFlex="20">
                        <button mat-raised-button class="bg-secondary">Similar items</button>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Open Orders">
          <ng-template matTabContent>
            <div fxLayout="row" fxLayoutAlign="center center" class="cardpadding">
              <p>Looking for an order? All of your orders have been dispatched. <span class="color-accent"> View all
                  orders</span></p>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Cancelled Orders">
          <ng-template matTabContent>
            <div fxLayout="row" fxLayoutAlign="center center" class="cardpadding">
              <p>We aren't finding any cancelled orders (for orders placed in the last 6 months). <span
                  class="color-accent"> View all orders</span></p>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<div class="card-shadow" fxHide>
  <mat-card>
    <div class="cardpadding" fxLayout="column" fxLayoutGap="30px">
      <div class="slider-items1" fxLayout="column" fxLayoutGap="20px">
        <p class="mat-title">Inspired by your browsing history</p>
        <owl-carousel-o [options]="customOptions" class="cardpadding">
          <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
            <img [src]="slide.src">
            <div fxLayout="column" fxLayoutAlign="start start">
              <span class="color-accent color-hover">{{slide.text}}</span>
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star_border</mat-icon>
                <mat-icon>keyboard_arrow_down</mat-icon>
                <p class="color-accent color-hover">{{slide.reviewno}}</p>
              </div>
              <span class="color-danger">{{slide.price}}</span>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
      <div class="slider-items1" fxLayout="column" fxLayoutGap="20px">
        <p class="mat-title">Top subscription apps for you</p>
        <owl-carousel-o [options]="customOptions1" class="cardpadding">
          <ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
            <img [src]="slide.src">
            <div fxLayout="column" fxLayoutAlign="start start">
              <span class="color-accent color-hover">{{slide.text}}</span>
              <span class="mat-small">{{slide.price}}</span>
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star_border</mat-icon>
                <mat-icon>keyboard_arrow_down</mat-icon>
                <p class="color-accent color-hover">{{slide.reviewno}}</p>
              </div>
              <span class="color-danger">FREE</span>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </mat-card>
</div>