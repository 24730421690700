import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-refrigerators',
  templateUrl: './refrigerators.component.html',
  styleUrls: ['./refrigerators.component.scss']
})
export class RefrigeratorsComponent implements OnInit {
  items = [
    { image: 'assets/images/gl-home/refrigerator/card/1.jpg', name: 'LG 190 L 4 Star Direct Cool Single Door Refrigerator(GL-B201APZY, Shiny Steel, Inverter Compressor)', company: 'LG', newprice: '₹ 9,190', oldprice: '₹ 11,250', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '3.064' },
    { image: 'assets/images/gl-home/refrigerator/card/2.jpg', name: 'Whirlpool 240 L Frost Free Multi-Door Refrigerator(FP 263D Protton Roy, Alpha Steel)', company: 'Whirlpool', newprice: '₹ 21,890', oldprice: '₹ 25,290', freedelivery: 'FREE Scheduled Delivery', upto: '.', deliverydate: '', reviewno: '2,264' },
    { image: 'assets/images/gl-home/refrigerator/card/3.jpg', name: 'Whirlpool 190 L 2 Star Direct-Cool Single Door Refrigerator (WDE 205 CLS 2S, Blue)', company: 'Whirlpool', newprice: '₹ 17,990', oldprice: '₹ 20,990', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '131' },
  ];
  items1 = [
    { image: 'assets/images/gl-home/refrigerator/card/4.jpg', name: 'LG 190 L 4 Star Inverter Direct-Cool Single Door Refrigerator (GL-D201ASCY, Scarlet Charm)', company: 'LG', newprice: '₹ 10,890', oldprice: '₹ 13,490', freedelivery: 'FREE Scheduled Delivery', upto: '.', deliverydate: '', reviewno: '377' },
    { image: 'assets/images/gl-home/refrigerator/card/5.jpg', name: 'LG 215 L 4 Star Inverter Direct Cool Single Door Refrigerator (GL-B221APZY, Shiny Steel)', company: 'LG', newprice: '₹ 29,490', oldprice: '₹  38,990', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '596' },
    { image: 'assets/images/gl-home/refrigerator/card/6.jpg', name: 'LG 190 L 4 Star Inverter Direct-Cool Single Door Refrigerator (GL-D201ARGY, Ruby Glow, Base Stand with drawer)', company: 'LG', newprice: '₹ 13,200', oldprice: '₹ 16,990', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '267' },
  ];
  items2 = [
    { image: 'assets/images/gl-home/refrigerator/card/7.jpg', name: 'Whirlpool 245 L 2 Star Frost-Free Double Door Refrigerator (NEOFRESH 258H ROY 2S, Wine Abyss)', company: 'Whirlpool', newprice: '₹ 18,980', oldprice: '₹ 22,990', freedelivery: 'FREE Delivery', upto: '', deliverydate: '', reviewno: '230' },
    { image: 'assets/images/gl-home/refrigerator/card/8.jpg', name: 'LG 190 L 4 Star Inverter Direct Cool Single Door Refrigerator (GL-D201APZY, Shiny Steel)', company: 'LG', newprice: '₹ 9,390', oldprice: '₹ 12,090', freedelivery: 'FREE Delivery', upto: '', deliverydate: '', reviewno: '1,188' },
    { image: 'assets/images/gl-home/refrigerator/card/9.jpg', name: 'LG 260 L 3 Star Inverter Linear Frost Free Double Door Refrigerator (GL-T292RSPN, Scarlet Plumeria, Convertible)', company: 'LG', newprice: '₹ 14,600', oldprice: '₹ 19,450', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '667' },
  ];
  items3 = [
    { image: 'assets/images/gl-home/refrigerator/card/10.jpg', name: 'Samsung 275L 3 Star Inverter Frost Free Double Door Refrigerator (RT30T3743S9/HL, Refined Inox, Convertible)', company: 'Samsung', newprice: '₹ 24,990', oldprice: '₹ 33,990', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '89' },
    { image: 'assets/images/gl-home/refrigerator/card/11.jpg', name: 'LG 235 L 4 Star Inverter Direct Cool Single Door Refrigerator (GL-D241APGY, Purple Glow, base stand with drawer )', company: 'LG', newprice: '₹ 9,399', oldprice: '₹ 11,750', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '1,001' },
    { image: 'assets/images/gl-home/refrigerator/card/12.jpg', name: 'Blue Star CHF100 Single Door Deep Freezer (95 Ltrs, White)', company: 'Blue Star', newprice: '₹ 34,990', oldprice: '₹ 40,990', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '617' },
  ];
 
  constructor( public router: Router,) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 50,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/images/gl-home/refrigerator/carousel/1.jpg',
      price: '₹ 8,790',
      oldprice: '₹ 9,690',
      off: '(7% off)',
      name: 'Godrej ViroShield 4.0-30L UV-C Disinfecting Device | Kills 99%+ V...',
      brand: 'Sold by Cloudtail India and Fulfilled by Amazon.',
      reviewno: '144'
    },
    {
      id: 2,
      src: './assets/images/gl-home/refrigerator/carousel/2.jpg',
      price: '₹ 17,200',
      oldprice: '₹ 20,990',
      off: '(14% off)',
      name: 'LG 215 L 4 Star Inverter Direct Cool Single Door Refrigerator (GL-D2...',
      brand: 'Sold by Cloudtail India and Fulfilled by Amazon.',
      reviewno: '429'
    },
    {
      id: 3,
      src: './assets/images/gl-home/refrigerator/carousel/3.jpg',
      price: '₹ 22,999',
      oldprice: '₹ 28,990',
      off: '(23% off)',
      name: 'Godrej 260 L 3 Star Inverter Frost-Free Double Door Refrigerator (RF...',
      brand: 'Sold by Cloudtail India and Fulfilled by Amazon.',
      reviewno: '144'
    },
    {
      id: 4,
      src: './assets/images/gl-home/refrigerator/carousel/4.jpg',
      price: '₹ 9,825',
      oldprice: '₹ 10,190',
      off: '(10% off)',
      name: 'Lifelong 92 L Direct Cool Single Door Refrigerator (LLMB92, Silver)',
      brand: 'Sold by Cloudtail India and Fulfilled by Amazon.',
      reviewno: '25'
    },
    {
      id: 5,
      src: './assets/images/gl-home/refrigerator/carousel/5.jpg',
      price: '₹ 14,990',
      oldprice: '₹ 22,490',
      off: '(33% off)',
      name: 'Haier 195 L 4 Star Direct-Cool Single Door Refrigerator (HRD-19...',
      brand: 'Sold by Cloudtail India and Fulfilled by Amazon.',
      reviewno: '65'
    },
    {
      id: 6,
      src: './assets/images/gl-home/refrigerator/carousel/6.jpg',
      price: '₹ 70,999',
      oldprice: '₹ 98,990',
      off: '(29% off)',
      name: 'LG 679 L Frost Free Side-by-Side Refrigerator(GC-B247KQDV.ADSQ...',
      brand: 'Sold by Cloudtail India and Fulfilled by Amazon.',
      reviewno: '69'
    },
    {
      id: 7,
      src: './assets/images/gl-home/refrigerator/carousel/7.jpg',
      price: '₹ 15,990',
      oldprice: '₹ 19,990',
      off: '(20% off)',
      name: 'Godrej 190 L 5 Star Inverter Direct-Cool Single Door Refrigerator (RD...',
      brand: 'Sold by Cloudtail India and Fulfilled by Amazon.',
      reviewno: '292'
    },
    {
      id: 8,
      src: './assets/images/gl-home/refrigerator/carousel/8.jpg',
      price: '₹ 26,890',
      oldprice: '₹ 33,890',
      off: '(21% off)',
      name: 'LG 260 L 3 Star Inverter Frost-Free Double Door Refrigerator (GL-T2...',
      brand: 'Sold by Cloudtail India and Fulfilled by Amazon.',
      reviewno: '2'
    },
  ]

  customOptions1: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/gl-home/refrigerator/carousel1/1.jpg',
      price: '₹ 14,000',
      oldprice: '₹ 16,800',
      name: 'Samsung 198 L 5 Star Inverter Direct-Cool Single Door Refrigerator (RR21T2H2WCB/HL, Camellia Black, Base Stand with Drawer)',
      brand: 'Samsung',
      reviewno: '10,121'
    },
    {
      id: 2,
      src: './assets/images/gl-home/refrigerator/carousel1/2.jpg',
      price: '₹ 21,899',
      oldprice: '₹ 24,690',
      name: 'Godrej 190 L 5 Star Inverter Direct-Cool Single Door Refrigerator (RD 1905 PTDI 53 GL BL, Glass Blue, Base stand with drawer, Inverter Compressor)',
      brand: 'Godrej',
      reviewno: '2,264'
    },
    {
      id: 3,
      src: './assets/images/gl-home/refrigerator/carousel1/3.jpg',
      price: '₹ 14,190',
      oldprice: '₹ 18,990',
      name: 'Whirlpool 240 L Frost-Free Multi-Door Refrigerator (FP 263D PROTTON ROY, German Steel)',
      brand: 'Whirlpool',
      reviewno: '876'
    },
    {
      id: 4,
      src: './assets/images/gl-home/refrigerator/carousel1/4.jpg',
      price: '₹ 17,000',
      oldprice: '₹ 19,800',
      name: 'Godrej 260 L 3 Star Inverter Frost-Free Double Door Refrigerator (RF EON 260C 35 RCIF ST RH, Steel Rush, 6 in 1 Convertible)',
      brand: 'Godrej',
      reviewno: '49'
    },
    {
      id: 5,
      src: './assets/images/gl-home/refrigerator/carousel1/5.jpg',
      price: '₹ 29,470',
      oldprice: '₹ 32,490',
      name: 'Samsung 192 L 4 Star Inverter Direct Cool Single Door Refrigerator(RR20T182XR8/HL, Blooming Saffron Red, Base Stand with Drawer)',
      brand: 'Samsung',
      reviewno: '139'
    },
    {
      id: 6,
      src: './assets/images/gl-home/refrigerator/carousel1/6.jpg',
      price: '₹ 13,200',
      oldprice: '₹ 16,990',
      name: 'Godrej 190 L 5 Star Inverter Direct-Cool Single Door Refrigerator with Base Drawer (RD 1905 PTDI 53 JW BL, Jewel Blue)',
      brand: 'Godrej',
      reviewno: '267'
    },
    {
      id: 7,
      src: './assets/images/gl-home/refrigerator/carousel1/7.jpg',
      price: '₹ 21,890',
      oldprice: '₹ 25,290',
      name: 'Samsung 198 L 5 Star Inverter Direct Cool Single Door Refrigerator(RR21T2H2WCU/HL, Camellia Blue, Base Stand with Drawer)',
      brand: 'Samsung',
      reviewno: '2,264'
    },
    {
      id: 8,
      src: './assets/images/gl-home/refrigerator/carousel1/8.jpg',
      price: '₹ 13,100',
      oldprice: '₹ 15,100',
      name: 'Haier 320 L 2 Star Inverter Frost-Free Double Door Refrigerator (HRB-3404BMS-E, Moon Silver,Bottom Freezer)',
      brand: 'Haier',
      reviewno: '10,121'
    },
    {
      id: 9,
      src: './assets/images/gl-home/refrigerator/carousel1/9.jpg',
      price: '₹ 37,490',
      oldprice: '₹ 45,590',
      name: 'Haier 258 L 3 Star Inverter Frost-Free Double Door Refrigerator (HEF-25TDS, Dazzle Steel, Convertible)',
      brand: 'Haier',
      reviewno: '79'
    },
    {
      id: 10,
      src: './assets/images/gl-home/refrigerator/carousel1/10.jpg',
      price: '₹ 8,240',
      oldprice: '₹ 9,900',
      name: 'LG 260 L 3 Star Inverter Linear Frost-Free Double Door Refrigerator (GL-T292SPZ3, Shiny Steel, Convertible)',
      brand: 'LG',
      reviewno: '490'
    },
  ]
  customOptions2: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore2 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel1/1.jpg',
      text: 'Emporium Car Back Pain Relief Lower Back Support for Chair Back Rest for Office Chair Lumbar…',
      reviewno: '242',
      price: '₹ 349.00'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel1/2.jpg',
      text: 'BLUEWUD Clonard Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '242',
      price: '₹ 2,899.00'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel1/3.jpg',
      text: 'BLUEWUD Mallium Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '331',
      price: '₹ 3,339.00'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel1/4.jpg',
      text: 'OGU Om Cotton Round Batti Wicks for Pooja Aarti (White) -2100 Pieces',
      reviewno: '61',
      price: '₹ 250'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel1/5.jpg',
      text: 'pTron Bassbuds Pro in-Ear True Wireless Bluetooth 5.0 Headphones with Deep Bass, Touch Control…',
      reviewno: '8,370',
      price: '₹ 1,299.00'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel1/6.jpg',
      text: 'HealthSense Soft-Spot BC 21 Orthopedic Backrest Cushion with Memory Foam for Study, Home,…',
      reviewno: '931',
      price: '₹ 1,399.00'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel1/7.jpg',
      text: 'Nutriplato-enriching lives Roasted Salted Flax Seeds, 500 g',
      reviewno: '325',
      price: '₹ 199.00'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel1/8.jpg',
      text: 'ADOC Chairs Oren Medium Back Ergonomic Office Chair with Mesh Back, Fixed Comfortable…',
      reviewno: '58',
      price: '₹ 3,049.00'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel1/9.jpg',
      text: 'Angel Bear 1 Cup Toothbrush Toothpaste Stand Holder Bathroom Storage Organizer,Plastic',
      reviewno: '379',
      price: '₹ 249.00'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel1/10.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '165',
      price: '₹ 3,999.00'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel1/11.jpg',
      text: 'TIZUM High Speed HDMI Cable with Ethernet - Supports 3D, 4K and Audio Return (1.8 Meter/ 6 Feet)',
      reviewno: '2,281',
      price: '₹ 145.00'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel1/12.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '30',
      price: '₹ 3,799.00'
    },
  ]
  customOptions3: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore3 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel2/1.jpeg',
      text: 'VPN Software & VPN free unlimited (Fire TV, 4k sticks)',
      reviewno: '1,108',
      price: 'BackySoft'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel2/2.jpg',
      text: 'AirScreen - AirPlay & Google Cast & Miracast & DLNA',
      reviewno: '34,550',
      price: '重庆爱奥尼科技有限公司'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel2/3.jpeg',
      text: 'Trashy Movies Channel',
      reviewno: '1,561',
      price: 'POV Horror'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel2/4.jpeg',
      text: 'Wicked Horror TV',
      reviewno: '3,961',
      price: 'POV Horror'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel2/5.jpeg',
      text: 'Trashy Horror Movies',
      reviewno: '1,375',
      price: 'POV Horror'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel2/6.jpeg',
      text: 'B-Movie Club',
      reviewno: '738',
      price: 'POV Horror'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel2/7.jpeg',
      text: 'VPN Lite & Full Privacy (RVPN)',
      reviewno: '358',
      price: 'BackySoft'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel2/8.jpg',
      text: 'Plex: Stream Movies, Shows, Live TV, Music, and More',
      reviewno: '51,727',
      price: 'Plex'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel2/9.jpeg',
      text: 'Martial Arts and Kung-Fu Movie Channel',
      reviewno: '4,629',
      price: 'POV Horror'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel2/10.jpg',
      text: 'NordVPN - Fast, Secure and Unlimited VPN app for Android. Stay secure and…',
      reviewno: '21,882',
      price: 'nordvpn s.a.'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel2/11.jpeg',
      text: 'Thriller Movie Channel',
      reviewno: '232',
      price: 'POV Horror'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel2/12.jpeg',
      text: 'Cleaner for Fire Tablets & TV (Cleanza)',
      reviewno: '6,219',
      price: 'Quickla Soft'
    },
  ]
}
