<div class="container" fxLayout="column">
  <div class="color-bg cardpadding" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-sm="center center">
    <p class="mat-headline text-center">New Products Arrivals</p>
    <p class="mat-h4 text-center">Our most popular products based on sales.</p>
  </div>
  <div fxLayout="row" fxLayout.lt-sm="column" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxFlex.lt-sm="100" fxLayoutGap="20px"
      fxHide.lt-sm>
      <div fxLayout="column">
        <div>
          <p class="mat-small font-bold">Any Department</p>
        </div>
        <div class="padding-side" fxLayout="column">
          <p class="mat-small color-hover" routerLink="/new-arrival">Audible Audiobooks & Originals</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Baby Products</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Bags, Wallets and Luggages</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Beauty</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Books</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Car & Motorbike</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Clothings & Accessories</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Computers & Accessories</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Electronics</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Garden & Outdoors</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Gift Cards</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Grocery & Gourment Foods</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Health & personal Care</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Home & Kitchen</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Home Improvement</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Industrial & Scientific</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Jewellery</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Kindle Store</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Movie & TV Shows</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Music</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Music Instruments</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Office Products</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Pet Supplies</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Shoes & Handbags</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Software</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Sports, Fitness & Outdoors</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Toys & Games</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Video Games</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">Watches</p>
        </div>
      </div>
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
      <div fxLayout="column" fxLayoutGap="40px">
        <div fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="column">
            <p class="mat-title font-bold">New Arrivals in Electronics</p>
            <!-- <p class="mat-small ">> <span class="color-accent text-underline">See more Bestsellers in Books </span></p> -->
          </div>
          <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="start" fxFlex>
            <div fxLayout="row" *ngFor="let item of items" fxFlex="33" fxFlex.lt-sm="100">
              <div fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="10px" fxFlex>
                <div>
                  <mat-divider class="full-width"></mat-divider>
                  <div class="color-bg1" [ngClass.lt-sm]="'color-bg2'">
                    <p class="font-bold text-center">{{item.number}}</p>
                  </div>
                </div>
                <div class="cardpadding card-image" fxFlex.lt-sm="30">
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'" (click)="products()">
                  </div>
                </div>
                <div class="cardpadding" fxFlex.lt-sm="70">
                  <p class="color-accent color-hover" (click)="products()">{{item.name}}</p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{item.reviewno}}</p>
                  </div>
                  <p class="color-danger">{{item.price}}</p>
                </div>
              </div>
              <div>
                <mat-divider [vertical]="true" class="full-height"></mat-divider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div fxLayout="column" fxLayoutAlign="start start">
				<p class="mat-h4 font-bold">About Hot New Releases</p>
				<p class="mat-small">These lists, Updated hourly, contain bestselling items.</p>
			</div> -->
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxFlex.lt-sm="100" fxLayoutGap="20px"
      fxShow.lt-sm fxHide>
      <div fxLayout="column">
        <div>
          <p class="mat-small font-bold">Any Department</p>
        </div>
        <div class="padding-side" fxLayout="column">
          <p class="mat-small color-hover" routerLink="/new-arrival">> Audible Audiobooks & Originals</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Baby Products</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Bags, Wallets and Luggages</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Beauty</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Books</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Car & Motorbike</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Clothings & Accessories</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Computers & Accessories</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Electronics</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Garden & Outdoors</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Gift Cards</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Grocery & Gourment Foods</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Health & personal Care</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Home & Kitchen</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Home Improvement</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Industrial & Scientific</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Jewellery</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Kindle Store</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Movie & TV Shows</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Music</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Music Instruments</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Office Products</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Pet Supplies</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Shoes & Handbags</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Software</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Sports, Fitness & Outdoors</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Toys & Games</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Video Games</p>
          <p class="mat-small color-hover" routerLink="/new-arrival">> Watches</p>
        </div>
      </div>
    </div>
  </div>
</div>