import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryComponent implements OnInit {

  inquiryFormGroup: FormGroup;
  public txtEmail;
  public txtMobileno
  // public txtPassword;
  error: string;
  yourEmail: any;
  constructor(private formBuilder: FormBuilder,
    public router: Router) { }

  ngOnInit(): void {

    this.inquiryFormGroup = this.formBuilder.group({
      yourName: new FormControl('', Validators.required),
      subject: new FormControl(''),
      message: new FormControl(''),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('[1-9]\\d{9}'),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$'),
      ]),

    });
    // this.txtPassword = this.inquiryFormGroup.get('Password');
    this.txtEmail = this.inquiryFormGroup.get('email');
    this.txtMobileno = this.inquiryFormGroup.get('phoneNumber');
    this.yourEmail = localStorage.getItem('yourEmail')
  }
  get f() { return this.inquiryFormGroup.controls; }

  inquiry() {
    if (this.inquiryFormGroup.invalid) {
      return;
    }
    console.log(this.inquiryFormGroup.value)

    this.router.navigate(['/thank-you'])
    // localStorage.setItem('feedbackName', this.f.yourName.value);
    // console.log(this.f.yourName.value)
  }
}
