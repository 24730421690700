<div class="bg-footer">
  <div class="padding-side" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="20px">
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around" fxLayoutGap.lt-sm="20px" fxFlex="grow">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-evenly" fxLayoutGap.lt-sm="20px" fxFlex="grow">
        <div fxLayout="column" fxFlex="30" fxLayoutGap="10px" fxFlex.lt-sm="100">
          <p class="mat-title color-white">Know Us</p>
          <p class="mat-h4 color-white country">About Us</p>
          <p class="mat-h4 color-white country">Contact US</p>
          <p class="mat-h4 color-white country">Career</p>
        </div>
        <div fxLayout="column" fxFlex="50" fxLayoutGap="10px" fxFlex.lt-sm="100">
          <p class="mat-title color-white">Our Policies</p>

          <p class="mat-h4 color-white country">Terms & Condition</p>
          <p class="mat-h4 color-white country">Privacy Policy</p>
          <p class="mat-h4 color-white country">Cancellation Policy</p>
          <p class="mat-h4 color-white country">Return Policy</p>
          <p class="mat-h4 color-white country">Shipping Policy</p>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-evenly" fxLayoutGap.lt-sm="20px" fxFlex="grow">
        <div fxLayout="column" fxLayoutGap="10px" fxFlex="50" fxFlex.lt-sm="100">
          <div fxLayout="column" fxLayoutGap="10px">
            <p class="mat-title color-white">Contact us</p>
            <p class="mat-h4 color-white">ProgressiveRx
              PO Box 192
              Vancouver, <br> WA 98666
              USA</p>
            <p class="mat-h4 color-white">Phone: 1-800-400-0707</p>
            <p class="mat-h4 color-white">Email: questions@progressiverx.com</p>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="10px" fxFlex="50" fxFlex.lt-sm="100">
          <div fxLayout="column" fxLayoutGap="10px">
            <div>
              <p class="mat-title color-white">Get in Touch:</p>
            </div>
            <div fxLayout="row" fxLayoutGap="10px">
              <div class="bg-secondary round-icon" fxLayoutAlign="center center">
                <i class="fa fa-twitter color-primary" aria-hidden="true"></i>
              </div>
              <div class="bg-secondary round-icon" fxLayoutAlign="center center">
                <i class="fa fa-google-plus color-primary" aria-hidden="true"></i>
              </div>
              <div class="bg-secondary round-icon" fxLayoutAlign="center center">
                <i class="fa fa-facebook color-primary" aria-hidden="true"></i>
              </div>
              <div class="bg-secondary round-icon" fxLayoutAlign="center center">
                <i class="fa fa-youtube color-primary" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center" fxFlex="grow">
      <p class="color-white mat-h4">Copyright 2021 <span class="color-primary hover-color" routerLink="/home">Medical
          E-COMMERCE.</span> All Rights
        Reserved.</p>
    </div>
  </div>
</div>