import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AirConditionersComponent } from './components/gl-home/air-conditioners/air-conditioners.component';
import { ClothingEssentialsComponent } from './components/gl-home/clothing-essentials/clothing-essentials.component';
import { DailyEssentialsComponent } from './components/gl-home/daily-essentials/daily-essentials.component';
import { HomeEssentialsComponent } from './components/gl-home/home-essentials/home-essentials.component';
import { KitchenDiningComponent } from './components/gl-home/kitchen-dining/kitchen-dining.component';
import { RefrigeratorsComponent } from './components/gl-home/refrigerators/refrigerators.component';
import { WashingMachinesComponent } from './components/gl-home/washing-machines/washing-machines.component';
import { BestSellerComponent } from './components/best-seller/best-seller.component';
import { AudioComponent } from './components/electronics/audio/audio.component';
import { CameraComponent } from './components/electronics/camera/camera.component';
import { ComputerLaptopComponent } from './components/electronics/computer-laptop/computer-laptop.component';
import { ElectronicsComponent } from './components/electronics/electronics.component';
import { MobilePhonesComponent } from './components/electronics/mobile-phones/mobile-phones.component';
import { MusicInstrumentsComponent } from './components/electronics/music-instruments/music-instruments.component';
import { StationeryComponent } from './components/electronics/stationery/stationery.component';
import { FashionComponent } from './components/fashion/fashion.component';
import { BirthdayComponent } from './components/gift-card/birthday/birthday.component';
import { GiftCardComponent } from './components/gift-card/gift-card.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { NewReleasesComponent } from './components/new-releases/new-releases.component';
import { SignupComponent } from './components/signup/signup.component';
import { TodaysDealComponent } from './components/todays-deal/todays-deal.component';
import { BlankLayoutComponent } from './layout/blank-layout/blank-layout.component';
import { CommonLayoutComponent } from './layout/common-layout/common-layout.component';
import { LayoutModule } from './layout/layout.module';
import { ChangePasswordComponent } from './shared/components/pages/change-password/change-password.component';
import { EmailVerifyComponent } from './shared/components/pages/email-verify/email-verify.component';
import { ForgotPasswordComponent } from './shared/components/pages/forgot-password/forgot-password.component';
import { PhoneVerifyComponent } from './shared/components/pages/phone-verify/phone-verify.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { AddToCartComponent } from './shared/components/pages/add-to-cart/add-to-cart.component';
import { ProductsComponent } from './shared/components/pages/products/products.component';
import { ReturnOrdersComponent } from './shared/components/pages/return-orders/return-orders.component';
import { CategoryComponent } from './shared/components/pages/category/category.component';
import { HomeEntertainmentComponent } from './components/electronics/home-entertainment/home-entertainment.component';
import { YourAccountComponent } from './shared/components/pages/your-account/your-account.component';
import { CustomerServiceComponent } from './shared/components/pages/customer-service/customer-service.component';
import { ContactUsComponent } from './shared/components/pages/contact-us/contact-us.component';
import { InquiryComponent } from './shared/components/pages/inquiry/inquiry.component';
import { ThankyouComponent } from './shared/components/pages/inquiry/thankyou/thankyou.component';
import { WishListComponent } from './shared/components/pages/wish-list/wish-list.component';
import { ErrorComponent } from './shared/components/pages/error/error.component';
import { CheckoutComponent } from './shared/components/pages/checkout/checkout.component';
import { PaymentComponent } from './shared/components/pages/checkout/payment/payment.component';
import { AddaddressComponent } from './shared/components/pages/products/addaddress/addaddress.component';
import { ProductReviewComponent } from './shared/components/pages/products/product-review/product-review.component';
import { FaqComponent } from './shared/components/pages/faq/faq.component';
import { PrivacyPolicyComponent } from './shared/components/pages/privacy-policy/privacy-policy.component';
import { ReturnPolicyComponent } from './shared/components/pages/return-policy/return-policy.component';
import { TermsandconditionComponent } from './shared/components/pages/termsandcondition/termsandcondition.component';
import { PlaceOrderComponent } from './shared/components/pages/checkout/place-order/place-order.component';
import { ReferToFriendComponent } from './shared/components/pages/refer-to-friend/refer-to-friend.component';
import { ComboOfferComponent } from './components/combo-offer/combo-offer.component';
import { CouponsComponent } from './components/coupons/coupons.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '',
    component: CommonLayoutComponent,

    children: [
      { path: 'home', component: HomeComponent, pathMatch: 'full' },
      { path: 'mobile-phone', component: MobilePhonesComponent, pathMatch: 'full' },
      { path: 'gift-card', component: GiftCardComponent, pathMatch: 'full' },
      { path: 'best-seller', component: BestSellerComponent, pathMatch: 'full' },
      { path: 'todays-deal', component: TodaysDealComponent, pathMatch: 'full' },
      { path: 'new-arrival', component: NewReleasesComponent, pathMatch: 'full' },
      { path: 'electronics', component: ElectronicsComponent, pathMatch: 'full' },
      { path: 'customer-service', component: CustomerServiceComponent, pathMatch: 'full' },
      { path: 'home-entertainment', component: HomeEntertainmentComponent, pathMatch: 'full' },
      { path: 'fashion', component: FashionComponent, pathMatch: 'full' },
      { path: 'birthday', component: BirthdayComponent, pathMatch: 'full' },
      { path: 'computer-laptop', component: ComputerLaptopComponent, pathMatch: 'full' },
      { path: 'audio', component: AudioComponent, pathMatch: 'full' },
      { path: 'camera', component: CameraComponent, pathMatch: 'full' },
      { path: 'music-instruments', component: MusicInstrumentsComponent, pathMatch: 'full' },
      { path: 'stationery', component: StationeryComponent, pathMatch: 'full' },
      { path: 'air-conditioners', component: AirConditionersComponent, pathMatch: 'full' },
      { path: 'washing-machines', component: WashingMachinesComponent, pathMatch: 'full' },
      { path: 'refrigerators', component: RefrigeratorsComponent, pathMatch: 'full' },
      { path: 'home-essentials', component: HomeEssentialsComponent, pathMatch: 'full' },
      { path: 'kitchen-dining', component: KitchenDiningComponent, pathMatch: 'full' },
      { path: 'daily-essentials', component: DailyEssentialsComponent, pathMatch: 'full' },
      { path: 'clothing-essentials', component: ClothingEssentialsComponent, pathMatch: 'full' },
      { path: 'about-us', component: AboutUsComponent, pathMatch: 'full' },
      { path: 'returns-orders', component: ReturnOrdersComponent, pathMatch: 'full' },
      { path: 'add-to-cart', component: AddToCartComponent, pathMatch: 'full' },
      { path: 'products', component: ProductsComponent, pathMatch: 'full' },
      { path: 'category', component: CategoryComponent, pathMatch: 'full' },
      { path: 'your-account', component: YourAccountComponent, pathMatch: 'full' },
      { path: 'contact-us', component: ContactUsComponent, pathMatch: 'full' },
      { path: 'inquiry', component: InquiryComponent, pathMatch: 'full' },
      { path: 'wish-list', component: WishListComponent, pathMatch: 'full' },
      { path: 'product-review', component: ProductReviewComponent, pathMatch: 'full' },
      { path: 'faq', component: FaqComponent, pathMatch: 'full' },
      { path: 'privacy-policy', component: PrivacyPolicyComponent, pathMatch: 'full' },
      { path: 'return-policy', component: ReturnPolicyComponent, pathMatch: 'full' },
      { path: 'terms-condition', component: TermsandconditionComponent, pathMatch: 'full' },
      { path: 'checkout', component: CheckoutComponent, pathMatch: 'full' },
      { path: 'payment', component: PaymentComponent, pathMatch: 'full' },
      { path: 'add-address', component: AddaddressComponent, pathMatch: 'full' },
      { path: 'place-order', component: PlaceOrderComponent, pathMatch: 'full' },
      { path: 'refer-to-friend', component: ReferToFriendComponent, pathMatch: 'full' },
      { path: 'combo-offer', component: ComboOfferComponent, pathMatch: 'full' },
      { path: 'coupons', component: CouponsComponent, pathMatch: 'full' }
    ]
  },
  {
    path: '', component: BlankLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent, pathMatch: 'full' },
      { path: 'signup', component: SignupComponent, pathMatch: 'full' },
      { path: 'forgot-password', component: ForgotPasswordComponent, pathMatch: 'full' },
      { path: 'phone-verify', component: PhoneVerifyComponent, pathMatch: 'full' },
      { path: 'email-verify', component: EmailVerifyComponent, pathMatch: 'full' },
      { path: 'change-password', component: ChangePasswordComponent, pathMatch: 'full' },
      { path: 'thank-you', component: ThankyouComponent, pathMatch: 'full' },
      { path: '404', component: ErrorComponent, pathMatch: 'full' },
    ]
  },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }),
    LayoutModule,
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
