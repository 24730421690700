<div class="container bg-secondary" fxLayout="column" fxLayoutGap="20px">
  <div [ngClass.gt-sm]="'cardpadding'" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start start"
    fxLayoutGap="15px" fxFlex>
    <div class="card cardpadding" fxLayout="column" fxLayoutGap="10px" fxFlex="70" fxFlex.lt-sm="100">
      <div fxLayout="column" fxLayoutGap="10px">
        <p class="mat-headline">Shopping Cart</p>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="column" fxLayoutGap="20px" *ngFor="let item of items" fxFlex>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxFlex="85">
          <div fxLayout="row" fxLayoutGap.gt-sm="15px" fxLayoutGap.lt-sm="5px" fxLayoutAlign="start" fxFlex>
            <div fxLayout="row" [ngClass]="'card-image image-height'" fxLayoutAlign="center center" fxFlex="30"
              fxFlex.lt-sm="40" (click)="products()">
              <img src="{{item.img}}" alt="">
            </div>
            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="70" fxFlex.lt-sm="50">
              <p class="mat-h3 color-accent hover-red" (click)="products()">{{item.name}}</p>
              <div fxLayout="row" fxHide fxShow.lt-sm>
                <p class="mat-h3 font-bold">Price: {{this.price1}}</p>
              </div>
              <p class="color-green">In stock</p>
              <p class="color-secondary">{{item.eligible}}</p>
              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="start start"
                fxLayoutGap="20px">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" fxFlex="70">
                  <p>Qty: </p>
                  <div class="input-group" fxLayout="row">
                    <input type="button" (click)="decrease(item)" value="-" class="button-minus" data-field="quantity">
                    <input type="number" step="1" value="{{total}}" [(ngModel)]="total" class="quantity-field">
                    <input type="button" (click)="increase(item)" value="+" class="button-plus" data-field="quantity">
                  </div>
                </div>
                <div fxLayout="row" fxFlex="25" fxFlex.lt-sm="100" fxLayoutGap="10px">
                  <p class="color-accent hover-red">Delete</p>
                  <!-- <p class="color-accent hover-red" fxHide.lt-sm>Save for Later</p> -->
                  <!-- <p class="color-accent hover-red">See more like this</p> -->
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxFlex="15" fxHide.lt-sm fxShow>
            <p class="mat-h3 font-bold">₹ {{this.price1}}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
    <div fxLayout="column" fxFlex="28" fxFlex.lt-sm="100" fxLayoutGap="15px">
      <div class="card cardpadding" fxLayout="column" fxLayoutAlign="start" fxLayoutAlign.lt-md="center"
        fxLayoutGap="10px">
        <div fxLayout="column">
          <p class="color-green">Your order is eligible for Free Delievery.</p>
          <p class="mat-small color-secondary">Select this option at checkout. <span class="color-accent">Details</span>
          </p>
        </div>
        <div fxLayout="column">
          <p class="mat-h4 font-bold">Subtotal ({{this.total}} items): ₹ {{this.price1}} </p>
          <!-- <mat-checkbox>This order contains a gift</mat-checkbox> -->
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <button mat-raised-button color="primary" fxFlex="50" fxFlex.lt-md="100" (click)="buynow()">Proceed to
            Buy</button>
        </div>
      </div>
      <mat-card fxHide.lt-md fxShow fxShow.lt-sm>
        <div fxLayout="column">
          <div fxLayout="column">
            <p class="font-bold">Sponsored Products related to items in your cart</p>
          </div>
          <div fxLayout="column" fxLayoutGap="20px" *ngFor="let item of items1" fxFlex>
            <div fxLayout="row" fxLayoutGap="20px" fxFlex>
              <div [ngClass]="'card-image1'" fxLayoutAlign="center center" fxFlex.lt-sm="40" (click)="products()">
                <img src="{{item.img}}" alt="">
              </div>
              <div fxLayout="column" fxLayoutAlign="center start" fxFlex="60">
                <p class="color-accent hover-red" (click)="products()">{{item.name}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="color-danger">{{item.price}}</p>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div [ngClass.gt-sm]="'cardpadding'" fxLayout="column">
    <div fxLayout="row" fxFlex>
      <div class="card cardpadding" fxFlex="70" fxFlex.lt-md="100">
        <div fxLayout="row" fxLayoutAlign.lt-sm="center center">
          <p class="mat-headline">Your Orders</p>
        </div>
        <div>
          <mat-tab-group>
            <mat-tab label="No items saved for later" class="color-accent">
              <ng-template matTabContent>

              </ng-template>
            </mat-tab>
            <mat-tab label="Buy it again" class="color-accent">
              <ng-template matTabContent>
                <p>No items to Buy again.</p>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
    <div class="padding-top" fxLayout="row">
      <div fxLayout="column" fxLayoutAlign="start start" fxFlex="70" fxFlex.lt-md="100">
        <p>The price and availability of items at Global Local are subject to change. The shopping cart is a temporary
          place
          to store a list of your items and reflects each item's most recent price.</p>
        <p>Do you have a promotional code? We'll ask you to enter your claim code when it's time to pay.</p>
      </div>
    </div>
  </div>
</div>

<div class="card-shadow" fxHide>
  <mat-card>
    <div class="cardpadding" fxLayout="column" fxLayoutGap="30px">
      <div class="slider-items1" fxLayout="column" fxLayoutGap="20px">
        <p class="mat-title">Inspired by your browsing history</p>
        <owl-carousel-o [options]="customOptions" class="cardpadding">
          <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
            <img [src]="slide.src">
            <div fxLayout="column" fxLayoutAlign="start start">
              <span class="color-accent color-hover">{{slide.text}}</span>
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star_border</mat-icon>
                <mat-icon>keyboard_arrow_down</mat-icon>
                <p class="color-accent color-hover">{{slide.reviewno}}</p>
              </div>
              <span class="color-danger">{{slide.price}}</span>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
      <div class="slider-items1" fxLayout="column" fxLayoutGap="20px">
        <p class="mat-title">Top subscription apps for you</p>
        <owl-carousel-o [options]="customOptions1" class="cardpadding">
          <ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
            <img [src]="slide.src">
            <div fxLayout="column" fxLayoutAlign="start start">
              <span class="color-accent color-hover">{{slide.text}}</span>
              <span class="mat-small">{{slide.price}}</span>
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star_border</mat-icon>
                <mat-icon>keyboard_arrow_down</mat-icon>
                <p class="color-accent color-hover">{{slide.reviewno}}</p>
              </div>
              <span class="color-danger">FREE</span>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </mat-card>
</div>