<div fxLayout="row wrap" fxLayout="column" fxLayoutAlign="center center" class="row-height">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayoutGap="0" fxLayoutGap.xs="15px">
    <mat-card class="cardsize card-shadow">
      <div class="cardpadding">
        <mat-card-title class="padding-top">
          <span fxLayoutAlign="center">Change password</span>
        </mat-card-title>

        <mat-card-content class="spacediv">
          <form [formGroup]="forgotForm" (submit)="Send()" autocomplete="off" (keyup.enter)="Send()">
            <!-- Enter email id or mobile number field -->

            <mat-form-field appearance="outline">
              <mat-label>Create Password</mat-label>
              <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" type="password"
                id="password" required>
              <mat-icon class="color-secondary" matSuffix (click)="hide = !hide">
                {{hide ? 'visibility_off' : 'visibility'}}
              </mat-icon>
              <mat-error *ngIf="password.errors?.required">Password is
                required. </mat-error>
              <mat-error *ngIf="password.errors?.pattern">At least 8 characters in
                length
                Lowercase and
                Uppercase letters
                Numbers Special characters
              </mat-error>
            </mat-form-field>
            <br>
            <mat-form-field appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <input matInput formControlName="c_password" [type]="hidecon ? 'password' : 'text'" id="c_password"
                required>
              <mat-icon class="color-secondary" matSuffix (click)="hidecon = !hidecon">
                {{hidecon ? 'visibility_off' : 'visibility'}}
              </mat-icon>
              <mat-error *ngIf="c_password.errors?.required"> Confirm Password is
                required. </mat-error>
              <mat-error *ngIf="c_password.errors?.pattern">At least 8 characters in
                length
                Lowercase and
                Uppercase letters
                Numbers Special characters
              </mat-error>
              <mat-error *ngIf="forgotForm.get('c_password').hasError('mismatch')">
                must matcher
              </mat-error>
            </mat-form-field>
            <!-- Send button -->
            <div class="spacediv" fxLayoutAlign="center">
              <button mat-button mat-raised-button color="primary" [disabled]="forgotForm.invalid"
                fxFlex="90">Submit</button>
            </div>
          </form>
        </mat-card-content>
      </div>
    </mat-card>
  </div>
  <!-- Copyright -->
  <!-- <div class="color-secondary mat-small" fxLayoutAlign="center center"> Copyright &copy; 2020 All
        Rights Reserved.</div> -->
</div>