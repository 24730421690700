<div class="container cardpadding" fxLayout="column" fxLayoutGap="10px">
  <div fxLayout="row">
    <p class="mat-title">Explore all Categories</p>
  </div>
  <mat-accordion class="example-headers-align" multi>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around">
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
        fxFlex.lt-md="100">
        <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-around center">
          <mat-panel-title>
            Mobiles, Computers
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div fxLayout="row wrap">
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px" *ngFor="let item of mobitems" fxFlex="50">
              <mat-card class="full-width">
                <div class="cursor" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" fxFlex
                  routerLink="/{{item.link}}">
                  <div class="card-image" fxFlex="30">
                    <img src="{{item.img}}" alt="">
                  </div>
                  <div fxFlex="68">
                    <p>{{item.name}}</p>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
        fxFlex.lt-md="100">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Home, Kitchen, Pets, Furniture
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div fxLayout="row wrap">
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px" *ngFor="let item of homeitems" fxFlex="50">
              <mat-card class="full-width">
                <div class="cursor" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" fxFlex
                  routerLink="/{{item.link}}">
                  <div class="card-image" fxFlex="30">
                    <img src="{{item.img}}" alt="">
                  </div>
                  <div fxFlex="68">
                    <p>{{item.name}}</p>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
        fxFlex.lt-md="100">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Grocery & Household Supplies
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div fxLayout="row wrap">
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px" *ngFor="let item of groceryitems" fxFlex="50">
              <mat-card class="full-width">
                <div class="cursor" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" fxFlex
                  routerLink="/{{item.link}}">
                  <div class="card-image" fxFlex="30">
                    <img src="{{item.img}}" alt="">
                  </div>
                  <div fxFlex="68">
                    <p>{{item.name}}</p>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around">
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
        fxFlex.lt-md="100">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Men's Fashion
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div fxLayout="row wrap">
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px" *ngFor="let item of menitems" fxFlex="50">
              <mat-card class="full-width">
                <div class="cursor" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" fxFlex
                  routerLink="/{{item.link}}">
                  <div class="card-image" fxFlex="30">
                    <img src="{{item.img}}" alt="">
                  </div>
                  <div fxFlex="68">
                    <p>{{item.name}}</p>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
        fxFlex.lt-md="100">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Women's Fashion
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div fxLayout="row wrap">
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px" *ngFor="let item of womenitems" fxFlex="50">
              <mat-card class="full-width">
                <div class="cursor" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" fxFlex
                  routerLink="/{{item.link}}">
                  <div class="card-image" fxFlex="30">
                    <img src="{{item.img}}" alt="">
                  </div>
                  <div fxFlex="68">
                    <p>{{item.name}}</p>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
        fxFlex.lt-md="100">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Beauty, Health
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div fxLayout="row wrap">
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px" *ngFor="let item of beautyitems" fxFlex="50">
              <mat-card class="full-width">
                <div class="cursor" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" fxFlex>
                  <div class="card-image" fxFlex="30">
                    <img src="{{item.img}}" alt="">
                  </div>
                  <div fxFlex="68">
                    <p>{{item.name}}</p>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around" fxHide>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
        fxFlex.lt-md="100">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Book & Audiable
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div fxLayout="row wrap">
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px" *ngFor="let item of bookitems" fxFlex="50">
              <mat-card class="full-width">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" fxFlex>
                  <div class="card-image" fxFlex="30">
                    <img src="{{item.img}}" alt="">
                  </div>
                  <div fxFlex="68">
                    <p>{{item.name}}</p>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
        fxFlex.lt-md="100">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Recharges & Bill Payments
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div fxLayout="row wrap">
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px" *ngFor="let item of rechargeitems" fxFlex="50">
              <mat-card class="full-width">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" fxFlex>
                  <div class="card-image" fxFlex="30">
                    <img src="{{item.img}}" alt="">
                  </div>
                  <div fxFlex="68">
                    <p>{{item.name}}</p>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
        fxFlex.lt-md="100">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Gift Card
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div fxLayout="row wrap">
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px" *ngFor="let item of giftitems" fxFlex="50">
              <mat-card class="full-width">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" fxFlex>
                  <div class="card-image" fxFlex="30">
                    <img src="{{item.img}}" alt="">
                  </div>
                  <div fxFlex="68">
                    <p>{{item.name}}</p>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
  </mat-accordion>
</div>