import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-product-review',
  templateUrl: './product-review.component.html',
  styleUrls: ['./product-review.component.scss']
})
export class ProductReviewComponent implements OnInit {
  stars: number[] = [1, 2, 3, 4, 5];
  stars1: number[] = [1, 2, 3, 4, 5];
  stars2: number[] = [1, 2, 3, 4, 5];
  stars3: number[] = [1, 2, 3, 4, 5];
  selectedValue: number = 0;
  selectedValue1: number = 0;
  selectedValue2: number = 0;
  selectedValue3: number = 0;
  reviewFormGroup: FormGroup;

  constructor(private formBuilder: FormBuilder,) { }

  ngOnInit() {

    this.reviewFormGroup = this.formBuilder.group({
      fullName: new FormControl('', Validators.required),
      headline: new FormControl('', Validators.required),
      review: new FormControl('', Validators.required),
      overall: new FormControl('', Validators.required),
      rating1: new FormControl('', Validators.required),
      rating2: new FormControl('', Validators.required),
      rating3: new FormControl('', Validators.required),
      
    });
  }


  countStar(star) {
    this.selectedValue = star;
    console.log('Value of star', star);
  }

  addClass(star) {
    let ab1 = "";
    for (let i1 = 0; i1 < star; i1++) {
      ab1 = "starId" + i1;
      document.getElementById(ab1).classList.add("selected");
    }
  }
  removeClass(star) {
    let ab1 = "";
    for (let i1 = star - 1; i1 >= this.selectedValue; i1--) {
      ab1 = "starId" + i1;
      document.getElementById(ab1).classList.remove("selected");
    }
  }

  countStar1(star1) {
    this.selectedValue1 = star1;
    console.log('Value of star', star1);
  }

  addClass1(star1) {
    let ab = "";
    for (let i = 0; i < star1; i++) {
      ab = "starId1" + i;
      document.getElementById(ab).classList.add("selected");
    }
  }
  removeClass1(star1) {
    let ab = "";
    for (let i = star1 - 1; i >= this.selectedValue1; i--) {
      ab = "starId1" + i;
      document.getElementById(ab).classList.remove("selected");
    }
  }

  countStar2(star2) {
    this.selectedValue2 = star2;
    console.log('Value of star', star2);
  }

  addClass2(star2) {
    let ab = "";
    for (let i = 0; i < star2; i++) {
      ab = "starId2" + i;
      document.getElementById(ab).classList.add("selected");
    }
  }
  removeClass2(star2) {
    let ab = "";
    for (let i = star2 - 1; i >= this.selectedValue2; i--) {
      ab = "starId2" + i;
      document.getElementById(ab).classList.remove("selected");
    }
  }
  countStar3(star3) {
    this.selectedValue3 = star3;
    console.log('Value of star', star3);
  }

  addClass3(star3) {
    let ab = "";
    for (let i = 0; i < star3; i++) {
      ab = "starId3" + i;
      document.getElementById(ab).classList.add("selected");
    }
  }
  removeClass3(star3) {
    let ab = "";
    for (let i = star3 - 1; i >= this.selectedValue3; i--) {
      ab = "starId3" + i;
      document.getElementById(ab).classList.remove("selected");
    }
  }
}
