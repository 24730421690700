import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-computer-laptop',
  templateUrl: './computer-laptop.component.html',
  styleUrls: ['./computer-laptop.component.scss']
})
export class ComputerLaptopComponent implements OnInit {
  items = [
    { image: 'assets/images/electronics/computer/card/1.jpg', name: 'Mi Smart Band 5-1.1” AMOLED Color Display, 2 Weeks Battery Life, 5ATM Water Resistant', company: 'MI', newprice: '₹ 2,499', oldprice: '₹ 2,999', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Sunday, Dec 27', reviewno: '5,903' },
    { image: 'assets/images/electronics/computer/card/2.jpg', name: 'pTron Bassbuds Lite V2 In-Ear True Wireless Bluetooth 5.0 Headphones with HiFi Deep Bass, Total 20Hrs Playtime, Ergonomic Sweatproof...', company: 'pTron', newprice: '₹ 849', oldprice: '₹ 2,000', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Friday, Dec 25', reviewno: '10,882' },
    { image: 'assets/images/electronics/computer/card/3.jpg', name: 'Noise Colorfit Pro 2 Full Touch Control Smart Watch Jet Black', company: 'Noise', newprice: '₹ 2,799', oldprice: '₹ 4,999', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '14,741' },
  ];
  items1 = [
    { image: 'assets/images/electronics/computer/card/4.jpg', name: 'SanDisk Cruzer Blade 32GB USB Flash Drive', company: 'SanDisk', newprice: '₹ 349', oldprice: '₹ 650', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00', deliverydate: 'Thursday, Dec 24', reviewno: '108,974' },
    { image: 'assets/images/electronics/computer/card/5.jpg', name: 'Samsung Galaxy M31 (Ocean Blue, 6GB RAM, 128GB Storage)', company: 'Samsung', newprice: '₹ 16,499', oldprice: '₹ 19,999', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '120,958' },
    { image: 'assets/images/electronics/computer/card/6.jpg', name: 'boAt Rugged v3 Extra Tough Unbreakable Braided Micro USB Cable 1.5 Meter (Black)', company: 'boAt', newprice: '₹ 299', oldprice: '₹ 799', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00 ', deliverydate: 'Thursday, Dec 24', reviewno: '20,349' },
  ];
  items2 = [
    { image: 'assets/images/electronics/computer/card/7.jpg', name: 'Logitech B170 Wireless Mouse, 2.4 GHz with USB Nano Receiver, Optical Tracking, 12-Months Battery Life, Ambidextrous, PC/Mac/Laptop', company: 'Logitech', newprice: '₹ 549', oldprice: '₹ 795', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '13,929' },
    { image: 'assets/images/electronics/computer/card/8.jpg', name: 'boAt Airdopes 441 TWS Ear-Buds with IWP Technology, Immersive Audio, Up to 30H Total Playback, IPX7 Water Resistance, Super Touch...', company: 'Boat', newprice: '₹ 1,999', oldprice: '₹  5,999', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00 ', deliverydate: 'Thursday, Dec 24', reviewno: '18,075' },
    { image: 'assets/images/electronics/computer/card/9.jpg', name: 'Ambrane 10000mAh Li-Polymer Powerbank with Compact Size & Fast Charging for Smartphones, Smart Watches, Neckbands & Other Devices... ', company: 'Ambrance', newprice: '₹ 499', oldprice: '₹ 1,499', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00 ', deliverydate: 'Thursday, Dec 24', reviewno: '16,786' },
  ];
  items3 = [
    { image: 'assets/images/electronics/computer/card/10.jpg', name: 'Boya BYM1 Omnidirectional Lavalier Condenser Microphone with 20ft Audio Cable (Black)', company: 'Boya', newprice: '₹ 711', oldprice: '₹ 1,995', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '14,517' },
    { image: 'assets/images/electronics/computer/card/11.jpg', name: 'Mi Smart Band 4- India No.1 Fitness Band, Up-to 20 Days Battery Life, Color AMOLED Full-Touch Screen, Waterproof with Music Control and...', company: 'MI', newprice: '₹ 1,999', oldprice: '2,499', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Friday, Dec 25', reviewno: '53,758' },
    { image: 'assets/images/electronics/computer/card/12.jpg', name: 'SanDisk Ultra Dual 64GB USB 3.0 OTG Pen Drive', company: 'SanDisk', newprice: '₹ 849', oldprice: '₹ 1,400', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '48,854' },
  ];

  constructor( public router: Router,) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }
  

  customOptions1: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/electronics/computer/carousel/1.jpg',
      price: '₹ 5,999',
      oldprice: '₹ 14,399',
      name: 'Samsung T5 500GB Up to 540MB/s USB 3.1 Gen 2',
      brand: 'samsung',
      reviewno: '2'
    },
    {
      id: 2,
      src: './assets/images/electronics/computer/carousel/2.jpg',
      price: '₹ 4,299',
      oldprice: '₹ 5,899',
      name: 'Seagate Expansion 1.5 TB External HDD - USB 3.0',
      brand: 'Seagate',
      reviewno: '76,763'
    },
    {
      id: 3,
      src: './assets/images/electronics/computer/carousel/3.jpg',
      price: '₹ 10,999',
      oldprice: '₹ 23,999',
      name: 'Samsung T7 1TB Up to 1,050MB/s USB 3.2 Gen 2',
      brand: 'Samsung',
      reviewno: '1,151'
    },
    {
      id: 4,
      src: './assets/images/electronics/computer/carousel/4.jpg',
      price: '₹ 10,999',
      oldprice: '₹ 23,999',
      name: 'Samsung T7 1TB Up to 1,050MB/s USB 3.2 Gen 2',
      brand: 'Samsung',
      reviewno: '1,151'
    },
    {
      id: 5,
      src: './assets/images/electronics/computer/carousel/5.jpg',
      price: '₹ 8,199',
      oldprice: '₹ 11,200',
      name: 'Western Digital WD 4TB My Passport Portable ',
      brand: 'Western Digital',
      reviewno: '22,053'
    },
    {
      id: 6,
      src: './assets/images/electronics/computer/carousel/6.jpg',
      price: '₹ 9,999',
      oldprice: '₹ 28,599',
      name: 'Samsung T5 1TB Up to 540MB/s USB 3.1 Gen 2',
      brand: 'Samsung',
      reviewno: '1,729'
    },
    {
      id: 7,
      src: './assets/images/electronics/computer/carousel/7.jpg',
      price: '₹ 8,999',
      oldprice: '₹ 16,499',
      name: 'Seagate Backup Plus Portable 5 TB External ',
      brand: 'Seagate',
      reviewno: '12,681'
    },
    {
      id: 8,
      src: './assets/images/electronics/computer/carousel/8.jpg',
      price: '₹ 6,999',
      oldprice: '₹ 12,999',
      name: 'Samsung T7 500GB Up to 1,050MB/s USB 3.2 Gen 2',
      brand: 'Samsung',
      reviewno: '1,151'
    },
    {
      id: 9,
      src: './assets/images/electronics/computer/carousel/9.jpg',
      price: '₹ 10,999',
      oldprice: '₹ 23,999',
      name: 'Samsung T7 1TB Up to 1,050MB/s USB 3.2 Gen 2',
      brand: 'Samsung',
      reviewno: '1,151'
    },
    {
      id: 10,
      src: './assets/images/electronics/computer/carousel/10.jpg',
      price: '₹ 5,599',
      oldprice: '₹ 7,999',
      name: 'Seagate Backup Plus Ultra Touch 2 TB External Hard',
      brand: 'Seagate',
      reviewno: '246'
    },
  ]

  customOptions2: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore2 = [
    {
      id: 1,
      src: './assets/images/electronics/computer/carousel1/1.jpg',
      price: '₹ 5,499',
      oldprice: '₹ 5,891',
      name: 'HP DeskJet 1212 Single Function Inkjet Colour Printer ',
      brand: 'HP',
      reviewno: '16'
    },
    {
      id: 2,
      src: './assets/images/electronics/computer/carousel1/2.jpg',
      price: '₹ 6,299',
      oldprice: '₹ 7,106',
      name: 'HP DeskJet 2778 All-in-One Ink Advantage Wireless Colour Printer',
      brand: 'HP',
      reviewno: '113'
    },
    {
      id: 3,
      src: './assets/images/electronics/computer/carousel1/3.jpg',
      price: '₹ 2,999',
      oldprice: '₹ 3,445',
      name: 'Canon Pixma TS307 Single Function Wireless Inkjet Colour Printer (Black) ',
      brand: 'Canon',
      reviewno: '726'
    },
    {
      id: 4,
      src: './assets/images/electronics/computer/carousel1/4.jpg',
      price: '₹ 14,699',
      oldprice: '₹ 16,875',
      name: 'HP Smart Tank 515 All-in-One Wireless Ink Tank',
      brand: 'HP',
      reviewno: '102'
    },
    {
      id: 5,
      src: './assets/images/electronics/computer/carousel1/5.jpg',
      price: '₹ 792',
      oldprice: '₹ 794',
      name: 'HP 682 Black Original Ink Cartridge',
      brand: 'HP',
      reviewno: '42'
    },
    {
      id: 6,
      src: './assets/images/electronics/computer/carousel1/6.jpg',
      price: '₹ 1,949',
      oldprice: '₹ 2,358',
      name: 'HP DeskJet 12112 Single Function Inkjet Colour Printer ',
      brand: 'HP',
      reviewno: '213'
    },
    {
      id: 7,
      src: './assets/images/electronics/computer/carousel1/7.jpg',
      price: '₹ 4,576',
      oldprice: '₹ 5,873',
      name: 'HP DeskJet 2335 All-in-One Ink Advantage Colour Printer ',
      brand: 'HP',
      reviewno: '63'
    },
    {
      id: 8,
      src: './assets/images/electronics/computer/carousel1/8.jpg',
      price: '₹ 3,299',
      oldprice: '₹ 3,869',
      name: 'HP DeskJet 2332 All-in-One Inkjet Colour Printer ',
      brand: 'HP',
      reviewno: '231'
    },
    {
      id: 9,
      src: './assets/images/electronics/computer/carousel1/9.jpg',
      price: '₹ 4,999',
      oldprice: '₹ 5,891',
      name: 'HP DeskJet 2732 All-in-One Wireless Inkjet Printer ',
      brand: 'HP',
      reviewno: '158'
    },
    {
      id: 10,
      src: './assets/images/electronics/computer/carousel1/10.jpg',
      price: '₹ 5,999',
      oldprice: '₹ 7,378',
      name: 'HP DeskJet 4123 All-in-One Inkjet Colour Printer',
      brand: 'HP',
      reviewno: '97'
    },
  ]
}
