import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})

export class SignupComponent implements OnInit {
  hide = true;
  hidecon = true;
  signupFormGroup: FormGroup;
  public txtEmail;
  public txtMobileno
  public txtPassword;
  error: string;
  constructor(
    private formBuilder: FormBuilder,
    public router: Router) { }

  ngOnInit(): void {
    this.signupFormGroup = this.formBuilder.group({
      yourName: new FormControl('', Validators.required),
      Street1: new FormControl(''),
      City: new FormControl(''),
      Zip: new FormControl(''),
      State: new FormControl(''),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('[1-9]\\d{9}'),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$'),
      ]),
      Password: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[a-zA-Z0-9\d$@$!%*?&].{8,}')

      ]),

    });
    this.txtPassword = this.signupFormGroup.get('Password');
    this.txtEmail = this.signupFormGroup.get('email');
    this.txtMobileno = this.signupFormGroup.get('phoneNumber');
  }


  get f() { return this.signupFormGroup.controls; }

  register() {
    if (this.signupFormGroup.invalid) {
      return;
    }
    console.log(this.signupFormGroup.value)

    this.router.navigate(['/login'])
  }
}
