<div class="container" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column">
    <div class="cardpadding" fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="center center">
      <p class="mat-title" fxLayoutAlign="start start">The Electronics Store</p>
      <p>At Global Local India, you will be able to find a wide selection of Electronics from top
        brands. Shop for
        <a class="color-accent text-underline" routerLink="/mobile-phone">Mobile Phones</a>,
        <a class="color-accent text-underline" routerLink="/mobile-phone">Tablets</a>,
        <a class="color-accent text-underline" routerLink="/camera">Cameras</a>,
        <a class="color-accent text-underline" routerLink="/home-entertainment">Televisions</a>,
        <a class="color-accent text-underline" routerLink="/audio">Headphones</a>,
        <a class="color-accent text-underline" routerLink="/audio">Speakers</a>,
        <a class="color-accent text-underline" routerLink="/computer-laptop">Laptops</a>,
        <a class="color-accent text-underline" routerLink="/computer-laptop">Computers & Accessories</a>,
        <a class="color-accent text-underline" routerLink="/computer-laptop">Wearables</a>,
        <a class="color-accent text-underline" routerLink="/stationery">Office Products</a>,
        <a class="color-accent text-underline" routerLink="/home-entertainment">Data Storages</a>,
        <a class="color-accent text-underline" routerLink="/home-entertainment">Gaming Accessories</a>,
        <a class="color-accent text-underline" routerLink="/music-instruments">Musical Instruments</a>, and much more at
        the best price on Global Local.
      </p>
    </div>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxLayoutGap="20px" fxHide.lt-sm fxShow>
      <div fxLayout="column" fxLayoutGap="10px">
        <p class="color-secondary mat-h3">Show results for</p>
        <div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Electronics</p>
          </div>
          <div class="padding-side" fxLayout="column">
            <p class="mat-small color-hover">Accessories</p>
            <p class="mat-small color-hover">Cameras & Photography</p>
            <p class="mat-small color-hover">Car & Vehicle Electronics</p>
            <p class="mat-small color-hover">Computers & Accessories</p>
            <p class="mat-small color-hover">GPS & Accessories</p>
            <p class="mat-small color-hover">Headphones</p>
            <p class="mat-small color-hover">Home Audio</p>
            <p class="mat-small color-hover">Home Theater, TV & Video</p>
            <p class="mat-small color-hover">Mobile & Accessories</p>
            <p class="mat-small color-hover">Portable Media Players</p>
            <p class="mat-small color-hover">Tablets</p>
            <p class="mat-small color-hover">Telephones & Accessories</p>
            <p class="mat-small color-hover">Warranties</p>
            <p class="mat-small color-hover">Wearable Technology</p>
          </div>
        </div>
      </div>
      <div>
        <mat-divider></mat-divider>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        <p class="color-secondary mat-h3">Refind by</p>
        <div fxLayout="column">
          <p class="mat-small font-bold">Pay on Delivery</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Eligible for Pay On
            Delivery</mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Brands</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Boat</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">OnePlus</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">JBL</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">SanDisk</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Amazon</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Redmi</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">MI</mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Avg. Customers Reviews</p>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <p class="mat-small color-hover"> & up</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <p class="mat-small color-hover"> & up</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <p class="mat-small color-hover"> & up</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <p class="mat-small color-hover"> & up</p>
          </div>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">New Arrivals</p>
          <p class="mat-small color-hover">Last 30 days</p>
          <p class="mat-small color-hover">Last 90 days</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Item Condition <span class="mat-h5">(What's this?)</span></p>
          <p class="mat-small color-hover">New</p>
          <p class="mat-small color-hover">Renewed</p>
          <p class="mat-small color-hover">Used</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">price</p>
          <p class="mat-small color-hover">Under ₹1,000</p>
          <p class="mat-small color-hover">₹1,000 - ₹5,000</p>
          <p class="mat-small color-hover">₹5,000 - ₹10,000</p>
          <p class="mat-small color-hover">₹10,000 - ₹20,000</p>
          <p class="mat-small color-hover">Over ₹20,000</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Deals</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Today's Deals
          </mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Discount</p>
          <p class="mat-small color-hover">10% off or more</p>
          <p class="mat-small color-hover">25% off or more</p>
          <p class="mat-small color-hover">35% off or more</p>
          <p class="mat-small color-hover">50% off or more</p>
        </div>
        <!-- <div fxLayout="column">
          <p class="mat-small font-bold">Seller</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Appario Retails Private Ltd.
          </mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cloudtail India</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cart2India Online</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Card2India SLP</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Global Klamp</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">BENEDICTIONS</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Computeronics Multivision Pvt. Ltd.
          </mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Spigen India</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">STS Shop</mat-checkbox>
        </div> -->
        <div fxLayout="column">
          <p class="mat-small font-bold">Availability</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Include Out of Stock
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div>
      <mat-divider [vertical]="true" class="full-height"></mat-divider>
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/1.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/2.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/3.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/4.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/5.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/6.png" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/7.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/8.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px"
        fxShow.lt-sm fxHide>
        <img src="assets/images/electronics/banner3.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/9.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/10.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/11.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/12.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/13.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/14.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/15.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/16.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/17.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/18.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/19.gif" alt="" fxFlex="48">
          <img src="assets/images/electronics/20.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/21.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/22.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/23.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/24.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/25.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/26.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/27.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/28.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/29.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/30.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/31.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/32.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/electronics/banner1.jpg" alt="" fxFlex="48" class="full-width">
        <img src="assets/images/electronics/banner2.jpg" alt="" fxFlex="48" class="full-width">
      </div>
    </div>
  </div>
</div>

<!-- <mat-tab-group>
  <mat-tab label="Electronics"> Content 1 </mat-tab>
  <mat-tab label="Mobile & Accessories"><app-mobile-phones></app-mobile-phones></mat-tab>
  <mat-tab label="Tv & Home Entertainment"><app-home-entertainment></app-home-entertainment></mat-tab>
  <mat-tab label= "Computer & Laptop"><app-computer-laptop></app-computer-laptop></mat-tab>
  <mat-tab label= "Audio"><app-audio></app-audio></mat-tab>
  <mat-tab label= "Cameras"><app-camera></app-camera></mat-tab>
  <mat-tab label= "Music Instruments"><app-music-instruments></app-music-instruments></mat-tab>
  <mat-tab label= "Office & Stationery"><app-stationery></app-stationery></mat-tab>
</mat-tab-group> -->