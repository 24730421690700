import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-stationery',
  templateUrl: './stationery.component.html',
  styleUrls: ['./stationery.component.scss']
})
export class StationeryComponent implements OnInit {

  items = [
    { image: 'assets/images/electronics/stationery/card/1.jpg', name: 'HP 680 Original Ink Advantage Cartridge (Black)', company: 'HP', newprice: '₹ 792', oldprice: '₹ 794', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Sunday, Dec 27', reviewno: '5,976' },
    { image: 'assets/images/electronics/stationery/card/2.jpg', name: 'HP 803 Small Ink Cartridge (Black)', company: 'HP', newprice: '₹ 753', oldprice: '', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Friday, Dec 25', reviewno: '2,241' },
    { image: 'assets/images/electronics/stationery/card/3.jpg', name: 'Mi 360° 1080p Full HD WiFi Smart Security Camera| 360° Viewing Area |Intruder Alert | Night Vision | Two-Way Audio |Inverted Installation', company: 'MI', newprice: '₹ 2,899', oldprice: '', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '10,141' },
  ];
  items1 = [
    { image: 'assets/images/electronics/stationery/card/4.jpg', name: 'Faber-Castell Connector Pen Set - Pack of 25 (Assorted)', company: 'Faber-Castell', newprice: '₹ 133', oldprice: '₹ 499', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Thursday, Dec 24', reviewno: '2,030' },
    { image: 'assets/images/electronics/stationery/card/5.jpg', name: 'HP 678 Black Ink Advantage Cartridge (CZ107AA)', company: 'HP', newprice: '₹ 753', oldprice: '', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '4,059' },
    { image: 'assets/images/electronics/stationery/card/6.jpg', name: 'Camlin Kokuyo Brush Pens, 24 Shades (Multicolor)', company: 'Camlin', newprice: '₹ 313', oldprice: '₹ 350', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Thursday, Dec 24', reviewno: '2,342' },
  ];
  items2 = [
    { image: 'assets/images/electronics/stationery/card/7.jpg', name: 'Gizga Essentials GZ-CK-104 Professional 6-in-1 Cleaning Kit (Air Blower, Cotton Swabs, Suede + Plush Micro-Fiber Cloth, Brush,Cleaning Solution)', company: 'GIZGA essentials', newprice: '₹ 279', oldprice: '₹ 499', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Thursday, Dec 24', reviewno: '4,400' },
    { image: 'assets/images/electronics/stationery/card/8.jpg', name: 'JK Copier Paper - A4, 500 Sheets, 75 GSM, 1 Ream', company: 'JK', newprice: '₹ 225', oldprice: '₹  310', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Thursday, Dec 24', reviewno: '2,544' },
    { image: 'assets/images/electronics/stationery/card/9.jpg', name: 'Cello Finegrip Ball Pen (25 Pens Jar - Blue) | Comfortable & Smooth writing ball pens| School & Office Stationery|Ideal for Work from Home', company: 'Cello', newprice: '₹ 105', oldprice: '₹ 175', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Thursday, Dec 24', reviewno: '6,377' },
  ];
  items3 = [
    { image: 'assets/images/electronics/stationery/card/10.jpg', name: 'DOMS Magnetic Whiteboard Duster With 2 Whiteboard Marker', company: 'DOMS', newprice: '₹ 150', oldprice: '₹ 499', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Thursday, Dec 24', reviewno: '1,813' },
    { image: 'assets/images/electronics/stationery/card/11.jpg', name: 'Casio FX-991EX Classwiz Non-Programmable Scientific Calculator, 552 Functions with Menu Driven Interface', company: 'Casio', newprice: '₹ 1,230', oldprice: '1,295', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Friday, Dec 25', reviewno: '2,528' },
    { image: 'assets/images/electronics/stationery/card/12.jpg', name: 'Camlin Painting Kit 199 Combo - Multicolor', company: 'Camel', newprice: '₹ 179', oldprice: '₹ 199', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Thursday, Dec 24', reviewno: '1,945' },
  ];
  
  constructor( public router: Router,) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }
  
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      },
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/images/electronics/stationery/carousel/1.jpg',
      save: 'Save 5% ',
      name: 'Save 5% on Limited Edition A5 Notebooks',
      button: 'Collect Coupon'
    },
    {
      id: 2,
      src: './assets/images/electronics/stationery/carousel/2.jpg',
      save: 'Save 10%',
      name: 'Save 10% on Acrylic Colours',
      button: 'Collect Coupen'
    },
    {
      id: 3,
      src: './assets/images/electronics/stationery/carousel/3.jpg',
      save: 'Save ₹ 17.00',
      name: 'Parker Anti Microbial Classic Ball Pen (CION',
      button: 'Collect Coupen'
    },
    {
      id: 4,
      src: './assets/images/electronics/stationery/carousel/4.jpg',
      save: 'Save ₹ 37.00',
      name: 'Sheaffer Blue Ballpoint Pen with A6 Notebook',
      button: 'Collect Coupen'
    },
    {
      id: 5,
      src: './assets/images/electronics/stationery/carousel/5.jpg',
      save: 'Save 5%',
      name: 'Save 5% on VCR A4 Size Label Stickers',
      button: 'Collect Coupen'
    },
    {
      id: 6,
      src: './assets/images/electronics/stationery/carousel/6.jpg',
      save: 'Save ₹ 31.00',
      name: 'Parker Vector Camouflage Gift Set',
      button: 'Collect Coupen'
    },
    {
      id: 7,
      src: './assets/images/electronics/stationery/carousel/7.jpg',
      save: 'Save ₹ 21.00',
      name: 'Cello Signature Creme Ebony Roller Pen',
      button: 'Collect Coupen'
    },
    {
      id: 8,
      src: './assets/images/electronics/stationery/carousel/8.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Sketch Kit',
      button: 'Collect Coupen'
    },
    {
      id: 9,
      src: './assets/images/electronics/stationery/carousel/9.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Kitabby Premium Products',
      button: 'Collect Coupen'
    },
    {
      id: 10,
      src: './assets/images/electronics/stationery/carousel/10.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Hook and loop tape',
      button: 'Collect Coupen'
    },
    {
      id: 11,
      src: './assets/images/electronics/stationery/carousel/11.jpg',
      save: 'Save ₹ 19.00',
      name: 'Save ₹ 19 on Initial Notebook Diary',
      button: 'Collect Coupen'
    },
    {
      id: 12,
      src: './assets/images/electronics/stationery/carousel/12.jpg',
      save: 'Save 10%',
      name: 'Save 10% on 2021 Calendars',
      button: 'Collect Coupen'
    },
  ]
  customOptions1: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 10,
    margin: 40,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      230: {
        items: 2
      },
      500: {
        items: 3
      },
      750: {
        items: 4
      },
      1000: {
        items: 5
      },
      1250: {
        items: 6
      },
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/electronics/stationery/carousel/1.jpg',
      save: 'Save 5% ',
      name: 'Save 5% on Limited Edition A5 Notebooks',
      button: 'Collect Coupon'
    },
    {
      id: 2,
      src: './assets/images/electronics/stationery/carousel/2.jpg',
      save: 'Save 10%',
      name: 'Save 10% on Acrylic Colours',
      button: 'Collect Coupen'
    },
    {
      id: 3,
      src: './assets/images/electronics/stationery/carousel/3.jpg',
      save: 'Save ₹ 17.00',
      name: 'Parker Anti Microbial Classic Ball Pen (CION',
      button: 'Collect Coupen'
    },
    {
      id: 4,
      src: './assets/images/electronics/stationery/carousel/4.jpg',
      save: 'Save ₹ 37.00',
      name: 'Sheaffer Blue Ballpoint Pen with A6 Notebook',
      button: 'Collect Coupen'
    },
    {
      id: 5,
      src: './assets/images/electronics/stationery/carousel/5.jpg',
      save: 'Save 5%',
      name: 'Save 5% on VCR A4 Size Label Stickers',
      button: 'Collect Coupen'
    },
    {
      id: 6,
      src: './assets/images/electronics/stationery/carousel/6.jpg',
      save: 'Save ₹ 31.00',
      name: 'Parker Vector Camouflage Gift Set',
      button: 'Collect Coupen'
    },
    {
      id: 7,
      src: './assets/images/electronics/stationery/carousel/7.jpg',
      save: 'Save ₹ 21.00',
      name: 'Cello Signature Creme Ebony Roller Pen',
      button: 'Collect Coupen'
    },
    {
      id: 8,
      src: './assets/images/electronics/stationery/carousel/8.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Sketch Kit',
      button: 'Collect Coupen'
    },
    {
      id: 9,
      src: './assets/images/electronics/stationery/carousel/9.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Kitabby Premium Products',
      button: 'Collect Coupen'
    },
    {
      id: 10,
      src: './assets/images/electronics/stationery/carousel/10.jpg',
      save: 'Save 5%',
      name: 'Save 5% on Hook and loop tape',
      button: 'Collect Coupen'
    },
    {
      id: 11,
      src: './assets/images/electronics/stationery/carousel/11.jpg',
      save: 'Save ₹ 19.00',
      name: 'Save ₹ 19 on Initial Notebook Diary',
      button: 'Collect Coupen'
    },
    {
      id: 12,
      src: './assets/images/electronics/stationery/carousel/12.jpg',
      save: 'Save 10%',
      name: 'Save 10% on 2021 Calendars',
      button: 'Collect Coupen'
    },
  ]

  customOptions2: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore2 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel1/1.jpg',
      text: 'Emporium Car Back Pain Relief Lower Back Support for Chair Back Rest for Office Chair Lumbar…',
      reviewno: '242',
      price: '₹ 349.00'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel1/2.jpg',
      text: 'BLUEWUD Clonard Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '242',
      price: '₹ 2,899.00'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel1/3.jpg',
      text: 'BLUEWUD Mallium Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '331',
      price: '₹ 3,339.00'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel1/4.jpg',
      text: 'OGU Om Cotton Round Batti Wicks for Pooja Aarti (White) -2100 Pieces',
      reviewno: '61',
      price: '₹ 250'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel1/5.jpg',
      text: 'pTron Bassbuds Pro in-Ear True Wireless Bluetooth 5.0 Headphones with Deep Bass, Touch Control…',
      reviewno: '8,370',
      price: '₹ 1,299.00'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel1/6.jpg',
      text: 'HealthSense Soft-Spot BC 21 Orthopedic Backrest Cushion with Memory Foam for Study, Home,…',
      reviewno: '931',
      price: '₹ 1,399.00'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel1/7.jpg',
      text: 'Nutriplato-enriching lives Roasted Salted Flax Seeds, 500 g',
      reviewno: '325',
      price: '₹ 199.00'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel1/8.jpg',
      text: 'ADOC Chairs Oren Medium Back Ergonomic Office Chair with Mesh Back, Fixed Comfortable…',
      reviewno: '58',
      price: '₹ 3,049.00'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel1/9.jpg',
      text: 'Angel Bear 1 Cup Toothbrush Toothpaste Stand Holder Bathroom Storage Organizer,Plastic',
      reviewno: '379',
      price: '₹ 249.00'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel1/10.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '165',
      price: '₹ 3,999.00'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel1/11.jpg',
      text: 'TIZUM High Speed HDMI Cable with Ethernet - Supports 3D, 4K and Audio Return (1.8 Meter/ 6 Feet)',
      reviewno: '2,281',
      price: '₹ 145.00'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel1/12.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '30',
      price: '₹ 3,799.00'
    },
  ]
  customOptions3: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore3 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel2/1.jpeg',
      text: 'VPN Software & VPN free unlimited (Fire TV, 4k sticks)',
      reviewno: '1,108',
      price: 'BackySoft'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel2/2.jpg',
      text: 'AirScreen - AirPlay & Google Cast & Miracast & DLNA',
      reviewno: '34,550',
      price: '重庆爱奥尼科技有限公司'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel2/3.jpeg',
      text: 'Trashy Movies Channel',
      reviewno: '1,561',
      price: 'POV Horror'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel2/4.jpeg',
      text: 'Wicked Horror TV',
      reviewno: '3,961',
      price: 'POV Horror'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel2/5.jpeg',
      text: 'Trashy Horror Movies',
      reviewno: '1,375',
      price: 'POV Horror'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel2/6.jpeg',
      text: 'B-Movie Club',
      reviewno: '738',
      price: 'POV Horror'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel2/7.jpeg',
      text: 'VPN Lite & Full Privacy (RVPN)',
      reviewno: '358',
      price: 'BackySoft'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel2/8.jpg',
      text: 'Plex: Stream Movies, Shows, Live TV, Music, and More',
      reviewno: '51,727',
      price: 'Plex'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel2/9.jpeg',
      text: 'Martial Arts and Kung-Fu Movie Channel',
      reviewno: '4,629',
      price: 'POV Horror'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel2/10.jpg',
      text: 'NordVPN - Fast, Secure and Unlimited VPN app for Android. Stay secure and…',
      reviewno: '21,882',
      price: 'nordvpn s.a.'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel2/11.jpeg',
      text: 'Thriller Movie Channel',
      reviewno: '232',
      price: 'POV Horror'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel2/12.jpeg',
      text: 'Cleaner for Fire Tablets & TV (Cleanza)',
      reviewno: '6,219',
      price: 'Quickla Soft'
    },
  ]
}
