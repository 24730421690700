import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home-essentials',
  templateUrl: './home-essentials.component.html',
  styleUrls: ['./home-essentials.component.scss']
})
export class HomeEssentialsComponent implements OnInit {

  items = [
    { image: 'assets/images/gl-home/essential/card/1.jpg', name: 'Amazon Brand - Solimo 2000-Watt Room Heater (ISI certified, White colour, Ideal for small to medium room/area)', company: 'Amazon Brand - Solimo', newprice: '₹ 1,089', oldprice: '₹ 2,000', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '3,264' },
    { image: 'assets/images/gl-home/essential/card/2.jpg', name: 'Amazon Brand - Solimo Wax Tealight Candles (Set of 100, Unscented)', company: 'Amazon Brand - Solimo', newprice: '₹ 329', oldprice: '₹ 500', freedelivery: 'FREE Scheduled Delivery', upto: 'No Minimum order value for first order in this category', deliverydate: 'Monday, Dec 28', reviewno: '18,264' },
    { image: 'assets/images/gl-home/essential/card/3.jpg', name: 'Amazon Brand - Solimo Microfiber Reversible Comforter, Single (Aqua Blue & Olive Green, 200 GSM)', company: 'Amazon Brand - Solimo', newprice: '₹ 999', oldprice: '₹ 2,000', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '10,755' },
  ];
  items1 = [
    { image: 'assets/images/gl-home/essential/card/4.jpg', name: 'Amazon Brand - Solimo 12-inch Wall Clock - Classic Roulette (Silent Movement, Black Frame)', company: 'Amazon Brand - Solimo', newprice: '₹ 629', oldprice: '₹ 1,100', freedelivery: 'FREE Scheduled Delivery', upto: '.', deliverydate: 'Monday, Dec 28', reviewno: '3,690' },
    { image: 'assets/images/gl-home/essential/card/5.jpg', name: 'Amazon Brand - Solimo Microfibre Reversible Comforter, Single (Sandy Beige & Walnut Brown, 200 GSM)', company: 'Amazon Brand - Solimo', newprice: '₹ 999', oldprice: '₹ 2,000', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '5,296' },
    { image: 'assets/images/gl-home/essential/card/6.jpg', name: 'Amazon Brand - Solimo 2-Piece Bed Pillow Set - 40 x 60 cm, White', company: 'Amazon Brand - Solimo', newprice: '₹ 499', oldprice: '₹ 1,000', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '1,570' },
  ];
  items2 = [
    { image: 'assets/images/gl-home/essential/card/7.jpg', name: 'Amazon Brand - Solimo Water Resistant Cotton Mattress Protector 78"x72" - King Size, Grey', company: 'Amazon Brand - Solimo', newprice: '₹ 879', oldprice: '₹ 1,900', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '1,689' },
    { image: 'assets/images/gl-home/essential/card/8.jpg', name: 'Amazon Brand - Solimo Microfibre Reversible Comforter, Single (Plum Purple and Moody Mauve, 200 GSM)', company: 'Amazon Brand - Solimo', newprice: '₹ 999', oldprice: '₹ 2,000', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '3,645' },
    { image: 'assets/images/gl-home/essential/card/9.jpg', name: 'Amazon Brand - Solimo Wall Sticker for Living Room(Ride through Nature, ideal size on wall: 140 cm x 100 cm),Multicolour', company: 'Amazon Brand - Solimo', newprice: '₹ 249', oldprice: '₹ 499', freedelivery: 'FREE Scheduled Delivery', upto: 'No Minimum order value for first order in this category', deliverydate: 'Monday, Dec 28', reviewno: '2,612' },
  ];
  items3 = [
    { image: 'assets/images/gl-home/essential/card/10.jpg', name: 'AmazonBasics Halo Rechargeable Table Lamp, 9W, Dimming, 3 Colors (Cool Day Light, Neutral White and Warm White)', company: 'AmazonBasics', newprice: '₹ 899', oldprice: '₹ 1,500', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '57' },
    { image: 'assets/images/gl-home/essential/card/11.jpg', name: 'Amazon Brand - Solimo 100% Cotton 2 Piece Bath Towel Set, 500 GSM (Turquoise Blue and Baby Pink)', company: 'Amazon Brand - Solimo', newprice: '₹ 799', oldprice: '₹ 1,250', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '4,914' },
    { image: 'assets/images/gl-home/essential/card/12.jpg', name: 'Amazon Brand - Solimo Wall Sticker for Living Room (Birdie House, Ideal Size on Wall - 133 cm x 90 cm)', company: 'Amazon Brand - Solimo', newprice: '₹ 149', oldprice: '₹ 499', freedelivery: 'FREE Scheduled Delivery', upto: 'No Minimum order value for first order in this category', deliverydate: 'Monday, Dec 28', reviewno: '969' },
  ];


  constructor( public router: Router,) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }
  
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/images/gl-home/essential/carousel/1.jpg',
      price: '₹ 999',
      oldprice: '₹ 2,000',
      name: 'Amazon Brand - Solimo Microfiber Reversible Comforter, Single (Aqua Blue & Olive Green, 200 GSM)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '10,121'
    },
    {
      id: 2,
      src: './assets/images/gl-home/essential/carousel/2.jpg',
      price: '₹ 999',
      oldprice: '₹ 2,000',
      name: 'Amazon Brand - Solimo Microfibre Reversible Comforter, Single (Sandy Beige & Walnut Brown, 200 GSM)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '5,264'
    },
    {
      id: 3,
      src: './assets/images/gl-home/essential/carousel/3.jpg',
      price: '₹ 999',
      oldprice: '₹ 2,000',
      name: 'Amazon Brand - Solimo Microfibre Reversible Comforter, Single (Plum Purple and Moody Mauve, 200 GSM)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '3,624'
    },
    {
      id: 4,
      src: './assets/images/gl-home/essential/carousel/4.jpg',
      price: '₹ 499',
      oldprice: '₹ 1,000',
      name: 'Amazon Brand - Solimo 2-Piece Bed Pillow Set - 40 x 60 cm, White',
      brand: 'Amazon Brand - Solimo',
      reviewno: '1,561'
    },
    {
      id: 5,
      src: './assets/images/gl-home/essential/carousel/5.jpg',
      price: '₹ 879',
      oldprice: '₹ 1,900',
      name: 'Amazon Brand - Solimo Water Resistant Cotton Mattress Protector 78"x72" - King Size, Grey',
      brand: 'Amazon Brand - Solimo',
      reviewno: '1,673'
    },
    {
      id: 6,
      src: './assets/images/gl-home/essential/carousel/6.jpg',
      price: '₹ 249',
      oldprice: '₹ 400',
      name: 'Amazon Brand - Solimo Solid 144 TC 100% Cotton 2 Piece Pillow Covers(Not Pillow), 18"x 27", Ash Grey',
      brand: 'Amazon Brand - Solimo',
      reviewno: '968'
    },
    {
      id: 7,
      src: './assets/images/gl-home/essential/carousel/7.jpg',
      price: '₹ 829',
      oldprice: '₹ 1,500',
      name: 'Amazon Brand - Solimo Waterproof Terry Cotton Mattress Protector, 78x72 inches, King Size (White)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '2,330'
    },
    {
      id: 8,
      src: './assets/images/gl-home/essential/carousel/8.jpg',
      price: '₹ 919',
      oldprice: '₹ 1,800',
      name: 'AmazonBasics Microfiber Duvet Cover Set - Twin/Twin XL, Navy Blue (167.6 cm X 228.6 cm) Duvet Cover and (50.8 cm X 66 cm) Pillow Cover',
      brand: 'Amazon Brand - Solimo',
      reviewno: '15,121'
    },
    {
      id: 9,
      src: './assets/images/gl-home/essential/carousel/9.jpg',
      price: '₹ 849',
      oldprice: '₹ 1.300',
      name: 'Amazon Brand - Solimo Microfibre Reversible Quilt Blanket, Single, 120 GSM, Sandy Beige and Deep Teal',
      brand: 'Amazon Brand - Solimo',
      reviewno: '436'
    },
    {
      id: 10,
      src: './assets/images/gl-home/essential/carousel/10.jpg',
      price: '₹ 1,599',
      oldprice: '₹ 3,000',
      name: 'Amazon Brand - Solimo Microfibre Reversible Comforter, Double (Stone Blue & Silver Grey, 200 GSM)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '377'
    },
  ]
  customOptions1: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/gl-home/essential/carousel1/1.jpg',
      price: '₹ 759',
      oldprice: '₹ 1,400',
      name: 'Amazon Brand - Solimo Paisley Preen 144 TC 100% Cotton Double Bedsheet with 2 Pillow Covers, Green and Orange',
      brand: 'Amazon Brand - Solimo',
      reviewno: '10,121'
    },
    {
      id: 2,
      src: './assets/images/gl-home/essential/carousel1/2.jpg',
      price: '₹ 759',
      oldprice: '₹ 1,400',
      name: 'Amazon Brand - Solimo Paisley Preen 144 TC 100% Cotton Double Bedsheet with 2 Pillow Covers, Green and Orange',
      brand: 'Amazon Brand - Solimo',
      reviewno: '2,264'
    },
    {
      id: 3,
      src: './assets/images/gl-home/essential/carousel1/3.jpg',
      price: '₹ 759',
      oldprice: '₹ 1,400',
      name: 'Amazon Brand - Solimo Paisley Preen 144 TC 100% Cotton Double Bedsheet with 2 Pillow Covers, Green and Orange',
      brand: 'Amazon Brand - Solimo',
      reviewno: '876'
    },
    {
      id: 4,
      src: './assets/images/gl-home/essential/carousel1/4.jpg',
      price: '₹ 759',
      oldprice: '₹ 1,400',
      name: 'Amazon Brand - Solimo Paisley Preen 144 TC 100% Cotton Double Bedsheet with 2 Pillow Covers, Green and Orange',
      brand: 'Amazon Brand - Solimo',
      reviewno: '49'
    },
    {
      id: 5,
      src: './assets/images/gl-home/essential/carousel1/5.jpg',
      price: '₹ 759',
      oldprice: '₹ 1,400',
      name: 'Amazon Brand - Solimo Paisley Preen 144 TC 100% Cotton Double Bedsheet with 2 Pillow Covers, Green and Orange',
      brand: 'Amazon Brand - Solimo',
      reviewno: '139'
    },
    {
      id: 6,
      src: './assets/images/gl-home/essential/carousel1/6.jpg',
      price: '₹ 759',
      oldprice: '₹ 1,400',
      name: 'Amazon Brand - Solimo Paisley Preen 144 TC 100% Cotton Double Bedsheet with 2 Pillow Covers, Green and Orange',
      brand: 'Amazon Brand - Solimo',
      reviewno: '267'
    },
    {
      id: 7,
      src: './assets/images/gl-home/essential/carousel1/7.jpg',
      price: '₹ 759',
      oldprice: '₹ 1,400',
      name: 'Amazon Brand - Solimo Paisley Preen 144 TC 100% Cotton Double Bedsheet with 2 Pillow Covers, Green and Orange',
      brand: 'Amazon Brand - Solimo',
      reviewno: '2,264'
    },
    {
      id: 8,
      src: './assets/images/gl-home/essential/carousel1/8.jpg',
      price: '₹ 759',
      oldprice: '₹ 1,400',
      name: 'Amazon Brand - Solimo Paisley Preen 144 TC 100% Cotton Double Bedsheet with 2 Pillow Covers, Green and Orange',
      brand: 'Amazon Brand - Solimo',
      reviewno: '10,121'
    },
    {
      id: 9,
      src: './assets/images/gl-home/essential/carousel1/9.jpg',
      price: '₹ 759',
      oldprice: '₹ 1,400',
      name: 'Amazon Brand - Solimo Paisley Preen 144 TC 100% Cotton Double Bedsheet with 2 Pillow Covers, Green and Orange',
      brand: 'Amazon Brand - Solimo',
      reviewno: '79'
    },
    {
      id: 10,
      src: './assets/images/gl-home/essential/carousel1/10.jpg',
      price: '₹ 759',
      oldprice: '₹ 1,400',
      name: 'Amazon Brand - Solimo Paisley Preen 144 TC 100% Cotton Double Bedsheet with 2 Pillow Covers, Green and Orange',
      brand: 'Amazon Brand - Solimo',
      reviewno: '490'
    },
  ]
  customOptions2: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore2 = [
    {
      id: 1,
      src: './assets/images/gl-home/essential/carousel2/1.jpg',
      price: '₹ 799',
      oldprice: '₹ 1,250',
      name: 'Amazon Brand - Solimo 100% Cotton 2 Piece Ba…e',
      brand: 'Amazon Brand - Solimo',
      reviewno: '4,121'
    },
    {
      id: 2,
      src: './assets/images/gl-home/essential/carousel2/2.jpg',
      price: '₹ 599',
      oldprice: '₹ 2,000',
      name: 'AmazonBasics Water Repellant Shower Curtai…',
      brand: 'Amazon Brand - Solimo',
      reviewno: '8,264'
    },
    {
      id: 3,
      src: './assets/images/gl-home/essential/carousel2/3.jpg',
      price: '',
      oldprice: '',
      name: 'AmazonBasics Microfiber 3-Piece Quilt/Duvet/Co…',
      brand: 'Amazon Brand - Solimo',
      reviewno: '2,901'
    },
    {
      id: 4,
      src: './assets/images/gl-home/essential/carousel2/4.jpg',
      price: '₹ 499',
      oldprice: '₹ 1,000',
      name: 'Amazon Brand - Solimo 2-Piece Bed Pillow Set - 40…',
      brand: 'Amazon Brand - Solimo',
      reviewno: '1,567'
    },
    {
      id: 5,
      src: './assets/images/gl-home/essential/carousel2/5.jpg',
      price: '',
      oldprice: '',
      name: 'Amazon Brand - Solimo 100% Cotton 2 Piece Ba…',
      brand: 'Amazon Brand - Solimo',
      reviewno: '68'
    },
    {
      id: 6,
      src: './assets/images/gl-home/essential/carousel2/6.jpg',
      price: '',
      oldprice: '',
      name: 'Amazon Brand - Solimo 100% Cotton 4 Piece Hand Towel Set, 500 GSM (Iris Blue and Spring Green)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '2,367'
    },
    {
      id: 7,
      src: './assets/images/gl-home/essential/carousel2/7.jpg',
      price: '₹ 1,090',
      oldprice: '₹ 1,500',
      name: 'Amazon Brand - Solimo 100% Cotton 6 Piece Towel Set, 500 GSM (Sepia Brown and Olive Green)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '1,778'
    },
    {
      id: 8,
      src: './assets/images/gl-home/essential/carousel2/8.jpg',
      price: '₹ 829',
      oldprice: '₹ 1,500',
      name: 'Amazon Brand - Solimo Waterproof Terry Cotton Mattress Protector, 78x72 inches, King Size (White)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '2,121'
    },
    {
      id: 9,
      src: './assets/images/gl-home/essential/carousel2/9.jpg',
      price: '₹ 759',
      oldprice: '₹ 1,250',
      name: 'Amazon Brand - Solimo 100% Cotton 2 Piece Bath Towel Set, 500 GSM (Turquoise Blue and Baby Pink)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '4,898'
    },
    {
      id: 10,
      src: './assets/images/gl-home/essential/carousel2/10.jpg',
      price: '₹ 169',
      oldprice: '₹ 499',
      name: 'Urban Magic® 104 TC Pure Cotton 2 Piece Pillow Covers (Pack of 2, 44 cm*66 cm_Brown)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '44'
    },
  ]
  customOptions3: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore3 = [
    {
      id: 1,
      src: './assets/images/gl-home/essential/carousel3/1.jpg',
      price: '',
      oldprice: '',
      name: 'AmazonBasics Security Safe - 0.5-Cubic Feet,Bla…',
      brand: 'Amazon Brand - Solimo',
      reviewno: '12,121'
    },
    {
      id: 2,
      src: './assets/images/gl-home/essential/carousel3/2.jpg',
      price: '',
      oldprice: '',
      name: 'AmazonBasics Security Safe - 0.5-Cubic Feet,Bla…',
      brand: 'Amazon Brand - Solimo',
      reviewno: '1,059'
    },
    {
      id: 3,
      src: './assets/images/gl-home/essential/carousel3/3.jpg',
      price: '₹ 1,699',
      oldprice: '₹ 7,000',
      name: 'Happer Premium Foldable Step Ladder, Clamber, 3…',
      brand: 'Happer',
      reviewno: '56'
    },
    {
      id: 4,
      src: './assets/images/gl-home/essential/carousel3/4.jpg',
      price: '',
      oldprice: '',
      name: 'Happer Premium 6-Tiers Shoe Rack/Multipurpose…',
      brand: 'Happer',
      reviewno: '11'
    },
    {
      id: 5,
      src: './assets/images/gl-home/essential/carousel3/5.jpg',
      price: '₹ 1,569',
      oldprice: '₹ 3,000',
      name: 'AmazonBasics Mechanics Socket Set (Pack of 40)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '451'
    },
    {
      id: 6,
      src: './assets/images/gl-home/essential/carousel3/6.jpg',
      price: '',
      oldprice: '',
      name: 'Amazon Brand - Solimo Carbon Steel Combination Spanner Set (Pack of 6)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '15'
    },
    {
      id: 7,
      src: './assets/images/gl-home/essential/carousel3/7.jpg',
      price: '₹ 4,499',
      oldprice: '₹ 7,000',
      name: 'AmazonBasics Desk Drawer Safe with Keypad & Back-up key, 2.7 Liters - Black',
      brand: 'Amazon Brand - Solimo',
      reviewno: '934'
    },
    {
      id: 8,
      src: './assets/images/gl-home/essential/carousel3/8.jpg',
      price: '',
      oldprice: '',
      name: 'AmazonBasics Biometric Fingerprint Home Safe, 43 Liters',
      brand: 'Amazon Brand - Solimo',
      reviewno: '171'
    },
    {
      id: 9,
      src: './assets/images/gl-home/essential/carousel3/9.jpg',
      price: '₹ 3,599',
      oldprice: '₹ 6,600',
      name: 'AmazonBasics Household Tool Set, 65 Pieces',
      brand: 'Amazon Brand - Solimo',
      reviewno: '96'
    },
    {
      id: 10,
      src: './assets/images/gl-home/essential/carousel3/10.jpg',
      price: '₹ 2,499',
      oldprice: '₹ 7,000',
      name: 'Happer Premium Foldable Aluminium Step Ladder, Clamber Pro, 4 Steps (Blue & Satin)',
      brand: 'Happer',
      reviewno: '9'
    },
  ]
  customOptions4: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore4 = [
    {
      id: 1,
      src: './assets/images/gl-home/essential/carousel4/1.jpg',
      price: '₹ 3298',
      oldprice: '₹ 500',
      name: 'Amazon Brand - Solimo Wax Tealight Candles (S…',
      brand: 'Amazon Brand - Solimo',
      reviewno: '18,121'
    },
    {
      id: 2,
      src: './assets/images/gl-home/essential/carousel4/2.jpg',
      price: '₹ 629',
      oldprice: '₹ 1,100',
      name: 'Amazon Brand - Solimo 12-inch Wall Clock - Clas…',
      brand: 'Amazon Brand - Solimo',
      reviewno: '3,664'
    },
    {
      id: 3,
      src: './assets/images/gl-home/essential/carousel4/3.jpg',
      price: '₹ 239',
      oldprice: '₹ 499',
      name: 'Amazon Brand - Solimo Wall Sticker for Living Room(Ride through Nature, ideal size on wall: 140 cm x 100 cm),Multicolour',
      brand: 'Amazon Brand - Solimo',
      reviewno: '2,606'
    },
    {
      id: 4,
      src: './assets/images/gl-home/essential/carousel4/4.jpg',
      price: '₹ 149',
      oldprice: '₹ 499',
      name: 'Amazon Brand - Solimo Wall Sticker for Living R…',
      brand: 'Amazon Brand - Solimo',
      reviewno: '966'
    },
    {
      id: 5,
      src: './assets/images/gl-home/essential/carousel4/5.jpg',
      price: '₹ 250',
      oldprice: '₹ 500',
      name: 'Amazon Brand - Presto! Naphthalene Balls - 200…',
      brand: 'Amazon Brand - Solimo',
      reviewno: '47'
    },
    {
      id: 6,
      src: './assets/images/gl-home/essential/carousel4/6.jpg',
      price: '₹ 379',
      oldprice: '₹ 1,000',
      name: 'Amazon Brand - Solimo Brass Diya, Medium',
      brand: 'Amazon Brand - Solimo',
      reviewno: '29'
    },
    {
      id: 7,
      src: './assets/images/gl-home/essential/carousel4/7.jpg',
      price: '₹ 629',
      oldprice: '₹ 1,200',
      name: 'Amazon Brand - Solimo Collage Photo Frames (Set of 6, Wall Hanging),Black',
      brand: 'Amazon Brand - Solimo',
      reviewno: '1,595'
    },
    {
      id: 8,
      src: './assets/images/gl-home/essential/carousel4/8.jpg',
      price: '₹ 629',
      oldprice: '₹ 1,000',
      name: 'Amazon Brand - Solimo 12-inch Wall Clock - Different Strokes (Step Movement, Black Frame)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '981'
    },
    {
      id: 9,
      src: './assets/images/gl-home/essential/carousel4/9.jpg',
      price: '₹ 149',
      oldprice: '₹ 499',
      name: 'Amazon Brand - Solimo Wall Sticker for Kids Room (Happy Growth Giraffe,  Ideal Size on Wall: 126 cm x 183 cm)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '1,144'
    },
    {
      id: 10,
      src: './assets/images/gl-home/essential/carousel4/10.jpg',
      price: '₹ 239',
      oldprice: '₹ 499',
      name: 'Amazon Brand - Solimo Wall Sticker for Living Room (Light on!, Ideal Size on Wall: 175 cm x 85 cm)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '921'
    },
  ]

  customOptions5: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore5 = [
    {
      id: 1,
      src: './assets/images/gl-home/essential/carousel5/1.jpg',
      price: '₹ 2,799',
      oldprice: '₹ 6,000',
      name: 'Happer Premium Wall Mounted Mirror and Storage Cabinet, New Look (White)',
      brand: 'Happer',
      reviewno: '12'
    },
    {
      id: 2,
      src: './assets/images/gl-home/essential/carousel5/2.jpg',
      price: '₹ 2,339',
      oldprice: '₹ 4,800',
      name: 'AmazonBasics 4-Tier Iron Tower Shelf',
      brand: 'Amazon Brand - Solimo',
      reviewno: '466'
    },
    {
      id: 3,
      src: './assets/images/gl-home/essential/carousel5/3.jpg',
      price: '₹ 1,699',
      oldprice: '₹ 5,000',
      name: 'Happer Ace Large Foldable Metal Mesh Ironing Board (White)',
      brand: 'Happer',
      reviewno: '24'
    },
    {
      id: 4,
      src: './assets/images/gl-home/essential/carousel5/4.jpg',
      price: '₹ 2,699',
      oldprice: '₹ 6,000',
      name: 'Happer Ace Plus Large Foldable Metal Ironing Board with Wire Manager and Multi-Function Tray (Silver)',
      brand: 'Happer',
      reviewno: '7'
    },
    {
      id: 5,
      src: './assets/images/gl-home/essential/carousel5/5.jpg',
      price: '₹ 1,699',
      oldprice: '₹ 5,000',
      name: 'Happer Ace Large Foldable Metal Mesh Ironing Board (Silver)',
      brand: 'Happer',
      reviewno: '8'
    },
    {
      id: 6,
      src: './assets/images/gl-home/essential/carousel5/6.jpg',
      price: '₹ 1,499',
      oldprice: '₹ 7,000',
      name: 'Happer Premium Wall Mounted Mirror and Storage Cabinet, New Look (White)',
      brand: 'Happer',
      reviewno: '93'
    },
    {
      id: 7,
      src: './assets/images/gl-home/essential/carousel5/7.jpg',
      price: '₹ 1,299',
      oldprice: '₹ 4,000',
      name: 'Happer Premium 2 Layer Cloth Drying Stand with Breaking Wheels, Compact Jumbo (Orange)',
      brand: 'Happer',
      reviewno: '71'
    },
    {
      id: 8,
      src: './assets/images/gl-home/essential/carousel5/8.jpg',
      price: '₹ 1,399',
      oldprice: '₹ 5,000',
      name: 'Happer Premium Cloth Drying Stand, Exquisite Designed, Winsome (White & Blue)',
      brand: 'Happer',
      reviewno: '63'
    },
    {
      id: 9,
      src: './assets/images/gl-home/essential/carousel5/9.jpg',
      price: '₹ 699',
      oldprice: '₹ 2,500',
      name: 'Happer Premium 4-Tiers Shoe Rack/Multipurpose Storage Rack with Dustproof Cover, (Grey)',
      brand: 'Happer',
      reviewno: '6'
    },
    {
      id: 10,
      src: './assets/images/gl-home/essential/carousel5/10.jpg',
      price: '₹ 699',
      oldprice: '₹ 2,250',
      name: 'Happer Premium 4-Tiers Shoe Rack/Multipurpose Storage Rack with Dustproof Cover, (Blue)',
      brand: 'Happer',
      reviewno: '7'
    },
  ]

  customOptions6: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore6 = [
    {
      id: 1,
      src: './assets/images/gl-home/essential/carousel6/1.jpg',
      price: '₹ 899',
      oldprice: '₹ 1,500',
      name: 'AmazonBasics Halo Rechargeable Table Lamp, 9W, Dimming, 3 Colors (Cool Day Light, Neutral White and Warm White)',
      brand: 'AmazonBasics',
      reviewno: '57'
    },
    {
      id: 2,
      src: './assets/images/gl-home/essential/carousel6/2.jpg',
      price: '₹ 799',
      oldprice: '₹ 1,300',
      name: 'AmazonBasics Classic Rechargeable Table Lamp, 9W, Dimming, 3 Colors (Cool Day Light, Neutral White and Warm White)',
      brand: 'AmazonBasics',
      reviewno: '26'
    },
    {
      id: 3,
      src: './assets/images/gl-home/essential/carousel6/3.jpg',
      price: '₹ 799',
      oldprice: '₹ 1,300',
      name: 'AmazonBasics Blaze 360 degree Rechargeable LED Light, Red',
      brand: 'AmazonBasics',
      reviewno: '31'
    },
    {
      id: 4,
      src: './assets/images/gl-home/essential/carousel6/4.jpg',
      price: '₹ 369',
      oldprice: '₹ 600',
      name: 'Amazon Brand - Solimo 10 Metre 100 LED Copper String Light for Decoration, Battery Powered, Warm White',
      brand: 'Amazon Brand - Solimo',
      reviewno: '38'
    },
    {
      id: 5,
      src: './assets/images/gl-home/essential/carousel6/5.jpg',
      price: '₹ 749',
      oldprice: '₹ 1,200',
      name: 'AmazonBasics Hue Rechargeable Portable LED Light, Blue',
      brand: 'AmazonBasics',
      reviewno: '17'
    },
    {
      id: 6,
      src: './assets/images/gl-home/essential/carousel6/6.jpg',
      price: '₹ 379',
      oldprice: '₹ 600',
      name: 'Amazon Brand - Solimo 10 Metre 100 LED Copper String Light for Decoration, USB Powered, Warm White',
      brand: 'Amazon Brand - Solimo',
      reviewno: '62'
    },
    {
      id: 7,
      src: './assets/images/gl-home/essential/carousel6/7.jpg',
      price: '₹ 529',
      oldprice: '₹ 1,000',
      name: 'Amazon Brand - Solimo Wi-Fi Smart Light, 12W, B22 Holder, Alexa Enabled (Yellow/Light Yellow/White)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '47'
    },
    {
      id: 8,
      src: './assets/images/gl-home/essential/carousel6/8.jpg',
      price: '₹ 369',
      oldprice: '₹ 600',
      name: 'Amazon Brand - Solimo Photo Clip String Light for Decoration, Battery Powered, Warm White, 20 Clips, 3.2 Metre',
      brand: 'Amazon Brand - Solimo',
      reviewno: '9'
    },
    {
      id: 9,
      src: './assets/images/gl-home/essential/carousel6/9.jpg',
      price: '₹ 319',
      oldprice: '₹ 500',
      name: 'AmazonBasics Gleam Rechargeable Torch, Golden & Black',
      brand: 'AmazonBasics',
      reviewno: '14'
    },
    {
      id: 10,
      src: './assets/images/gl-home/essential/carousel6/10.jpg',
      price: '₹ 799',
      oldprice: '₹ 1,300',
      name: 'AmazonBasics Glow 180 degree Rechargeable LED Light, Blue',
      brand: 'AmazonBasics',
      reviewno: '10'
    },
  ]

  customOptions7: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore7 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel1/1.jpg',
      text: 'Emporium Car Back Pain Relief Lower Back Support for Chair Back Rest for Office Chair Lumbar…',
      reviewno: '242',
      price: '₹ 349.00'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel1/2.jpg',
      text: 'BLUEWUD Clonard Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '242',
      price: '₹ 2,899.00'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel1/3.jpg',
      text: 'BLUEWUD Mallium Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '331',
      price: '₹ 3,339.00'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel1/4.jpg',
      text: 'OGU Om Cotton Round Batti Wicks for Pooja Aarti (White) -2100 Pieces',
      reviewno: '61',
      price: '₹ 250'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel1/5.jpg',
      text: 'pTron Bassbuds Pro in-Ear True Wireless Bluetooth 5.0 Headphones with Deep Bass, Touch Control…',
      reviewno: '8,370',
      price: '₹ 1,299.00'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel1/6.jpg',
      text: 'HealthSense Soft-Spot BC 21 Orthopedic Backrest Cushion with Memory Foam for Study, Home,…',
      reviewno: '931',
      price: '₹ 1,399.00'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel1/7.jpg',
      text: 'Nutriplato-enriching lives Roasted Salted Flax Seeds, 500 g',
      reviewno: '325',
      price: '₹ 199.00'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel1/8.jpg',
      text: 'ADOC Chairs Oren Medium Back Ergonomic Office Chair with Mesh Back, Fixed Comfortable…',
      reviewno: '58',
      price: '₹ 3,049.00'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel1/9.jpg',
      text: 'Angel Bear 1 Cup Toothbrush Toothpaste Stand Holder Bathroom Storage Organizer,Plastic',
      reviewno: '379',
      price: '₹ 249.00'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel1/10.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '165',
      price: '₹ 3,999.00'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel1/11.jpg',
      text: 'TIZUM High Speed HDMI Cable with Ethernet - Supports 3D, 4K and Audio Return (1.8 Meter/ 6 Feet)',
      reviewno: '2,281',
      price: '₹ 145.00'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel1/12.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '30',
      price: '₹ 3,799.00'
    },
  ]
  customOptions8: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore8 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel2/1.jpeg',
      text: 'VPN Software & VPN free unlimited (Fire TV, 4k sticks)',
      reviewno: '1,108',
      price: 'BackySoft'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel2/2.jpg',
      text: 'AirScreen - AirPlay & Google Cast & Miracast & DLNA',
      reviewno: '34,550',
      price: '重庆爱奥尼科技有限公司'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel2/3.jpeg',
      text: 'Trashy Movies Channel',
      reviewno: '1,561',
      price: 'POV Horror'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel2/4.jpeg',
      text: 'Wicked Horror TV',
      reviewno: '3,961',
      price: 'POV Horror'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel2/5.jpeg',
      text: 'Trashy Horror Movies',
      reviewno: '1,375',
      price: 'POV Horror'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel2/6.jpeg',
      text: 'B-Movie Club',
      reviewno: '738',
      price: 'POV Horror'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel2/7.jpeg',
      text: 'VPN Lite & Full Privacy (RVPN)',
      reviewno: '358',
      price: 'BackySoft'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel2/8.jpg',
      text: 'Plex: Stream Movies, Shows, Live TV, Music, and More',
      reviewno: '51,727',
      price: 'Plex'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel2/9.jpeg',
      text: 'Martial Arts and Kung-Fu Movie Channel',
      reviewno: '4,629',
      price: 'POV Horror'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel2/10.jpg',
      text: 'NordVPN - Fast, Secure and Unlimited VPN app for Android. Stay secure and…',
      reviewno: '21,882',
      price: 'nordvpn s.a.'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel2/11.jpeg',
      text: 'Thriller Movie Channel',
      reviewno: '232',
      price: 'POV Horror'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel2/12.jpeg',
      text: 'Cleaner for Fire Tablets & TV (Cleanza)',
      reviewno: '6,219',
      price: 'Quickla Soft'
    },
  ]
}
