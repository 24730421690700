import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-mobile-phones',
  templateUrl: './mobile-phones.component.html',
  styleUrls: ['./mobile-phones.component.scss']
})
export class MobilePhonesComponent implements OnInit {
  slides = [
    { 'image': './assets/images/carousel2/1.jpg' },
    { 'image': './assets/images/carousel2/2.jpg' },
    { 'image': './assets/images/carousel2/3.jpg' },
    { 'image': './assets/images/carousel2/4.jpg' },
    { 'image': './assets/images/carousel2/5.jpg' },
    { 'image': './assets/images/carousel2/6.jpg' }];

  items= [
    {image: 'assets/images/mobile-phone/card/1.webp', name: 'Samsung Galaxy M51 (Electric Blue, 6GB RAM, 128GB Storage)',company:'Samsung', newprice: '₹ 22,999', oldprice: '₹ 28,999', saving: '₹ 6,000 (20%)', deliverydate:'Tommorrow, Dec 18', reviewno:'23,331'},
    {image: 'assets/images/mobile-phone/card/2.webp', name: 'Apple iPhone 7 (32GB) - Black',company:'Apple', newprice: '₹ 23,990', oldprice: '₹ 29,900', saving: '₹ 5,910 (19%)', deliverydate:'Tommorrow, Dec 18', reviewno:'5,244'},
    {image: 'assets/images/mobile-phone/card/3.webp', name: 'Spigen Ultra Hybrid Back Cover Case Designed for iPhone 11 - Black',company:'Spigen', newprice: '₹ 1,199', oldprice: '₹ 1,899', saving: '₹ 700 (36%)', deliverydate:'Sun, Dec 20', reviewno:'1,168'},
  ];
  items1= [
    {image: 'assets/images/mobile-phone/card/4.webp', name: 'boAt Bassheads 100 in Ear Wired Earphones with Mic(Black)',company:'Boat', newprice: '₹ 349', oldprice: '₹ 999', saving: '₹ 650 (65%)', deliverydate:'Tommorrow, Dec 18', reviewno:'1,01,148'},
    {image: 'assets/images/mobile-phone/card/5.webp', name: 'OnePlus Bullets Wireless Z in-Ear Bluetooth Earphones with Mic (Black)',company:'OnePlus', newprice: '₹ 1,999', oldprice: '₹ 2,190', saving: '₹ 191 (8%)', deliverydate:'Tommorrow, Dec 18', reviewno:'41,793'},
    {image: 'assets/images/mobile-phone/card/6.webp', name: 'OnePlus Bullets Wireless Z Bass Edition (Reverb Red)',company:'OnePlus', newprice: '₹ 1,799', oldprice: '₹ 2,190', saving: '₹ 371 (17%)', deliverydate:'Tommorrow, Dec 18', reviewno:'41,789'},
  ];
  items2= [
    {image: 'assets/images/mobile-phone/card/7.webp', name: 'OnePlus Nord 5G (Gray Onyx, 8GB RAM, 128GB Storage)',company:'OnePlus', newprice: '₹ 27,999', oldprice: '₹ ', saving: '', deliverydate:'Tommorrow, Dec 18', reviewno:'6,889'},
    {image: 'assets/images/mobile-phone/card/8.webp', name: 'OnePlus Buds Z (White)',company:'OnePlus', newprice: '₹ 2,699', oldprice: '₹ 3,190', saving: '₹ 491 (15%)', deliverydate:'Sat, Dec 19', reviewno:'1,113'},
    {image: 'assets/images/mobile-phone/card/9.webp', name: 'JBL C100SI In-Ear Deep Bass Headphones with Mic (Black)',company:'JBL', newprice: '₹ 599', oldprice: '₹ 1,290', saving: '₹ 700 (53%)', deliverydate:'Tommorrow, Dec 18', reviewno:'82,127'},
  ];
  items3= [
    {image: 'assets/images/mobile-phone/card/10.webp', name: 'boAt Rockerz 255 Sports in-Ear Bluetooth Neckband Earphone with Mic (Active Black)',company:'Boat', newprice: '₹ 1,199', oldprice: '₹ 2,990', saving: '₹ 1,791 (59%)', deliverydate:'Tommorrow, Dec 18', reviewno:'1,49,029'},
    {image: 'assets/images/mobile-phone/card/11.webp', name: 'realme Buds 2 with Mic for Android Smartphones (Black)',company:'realme', newprice: '₹ 599', oldprice: '₹ 799', saving: '₹ 200 (25%)', deliverydate:'Tommorrow, Dec 18', reviewno:'34,305'},
    {image: 'assets/images/mobile-phone/card/12.webp', name: 'pTron Bassbuds in-Ear True Wireless Bluetooth 5.0 Headphones with Hi-Fi Deep Bass, 20Hrs Playtime with Case, Ergonomic Sweatproof Earbuds, Noise Isolation, Voice Assistance & Built-in Mic - (Black)',company:'PTron', newprice: '₹ 899', oldprice: '₹ 1,900', saving: '₹ 1,001 (52%)', deliverydate:'Tommorrow, Dec 18', reviewno:'23,355'},
  ]
  constructor( public router: Router,) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }


  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad:true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel1/1.jpg',
      text: 'Emporium Car Back Pain Relief Lower Back Support for Chair Back Rest for Office Chair Lumbar…',
      reviewno: '242',
      price: '₹ 349.00'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel1/2.jpg',
      text: 'BLUEWUD Clonard Engineered Wood Study Table, Laptop, Computer Table Desk for Home &…',
      reviewno: '242',
      price: '₹ 2,899.00'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel1/3.jpg',
      text: 'BLUEWUD Mallium Engineered Wood Study Table, Laptop, Computer Table Desk for Home &…',
      reviewno: '331',
      price: '₹ 3,339.00'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel1/4.jpg',
      text: 'OGU Om Cotton Round Batti Wicks for Pooja Aarti (White) -2100 Pieces',
      reviewno: '61',
      price: '₹ 250'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel1/5.jpg',
      text: 'pTron Bassbuds Pro in-Ear True Wireless Bluetooth 5.0 Headphones with Deep Bass, Touch Control…',
      reviewno: '8,370',
      price: '₹ 1,299.00'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel1/6.jpg',
      text: 'HealthSense Soft-Spot BC 21 Orthopedic Backrest Cushion with Memory Foam for Study, Home,…',
      reviewno: '931',
      price: '₹ 1,399.00'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel1/7.jpg',
      text: 'Nutriplato-enriching lives Roasted Salted Flax Seeds, 500 g',
      reviewno: '325',
      price: '₹ 199.00'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel1/8.jpg',
      text: 'ADOC Chairs Oren Medium Back Ergonomic Office Chair with Mesh Back, Fixed Comfortable…',
      reviewno: '58',
      price: '₹ 3,049.00'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel1/9.jpg',
      text: 'Angel Bear 1 Cup Toothbrush Toothpaste Stand Holder Bathroom Storage Organizer,Plastic',
      reviewno: '379',
      price: '₹ 249.00'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel1/10.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '165',
      price: '₹ 3,999.00'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel1/11.jpg',
      text: 'TIZUM High Speed HDMI Cable with Ethernet - Supports 3D, 4K and Audio Return (1.8 Meter/ 6 Feet)',
      reviewno: '2,281',
      price: '₹ 145.00'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel1/12.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '30',
      price: '₹ 3,799.00'
    },
  ]
  customOptions1: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad:true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel2/1.jpeg',
      text: 'VPN Software & VPN free unlimited (Fire TV, 4k sticks)',
      reviewno: '1,108',
      price: 'BackySoft'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel2/2.jpg',
      text: 'AirScreen - AirPlay & Google Cast & Miracast & DLNA',
      reviewno: '34,550',
      price: '重庆爱奥尼科技有限公司'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel2/3.jpeg',
      text: 'Trashy Movies Channel',
      reviewno: '1,561',
      price: 'POV Horror'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel2/4.jpeg',
      text: 'Wicked Horror TV',
      reviewno: '3,961',
      price: 'POV Horror'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel2/5.jpeg',
      text: 'Trashy Horror Movies',
      reviewno: '1,375',
      price: 'POV Horror'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel2/6.jpeg',
      text: 'B-Movie Club',
      reviewno: '738',
      price: 'POV Horror'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel2/7.jpeg',
      text: 'VPN Lite & Full Privacy (RVPN)',
      reviewno: '358',
      price: 'BackySoft'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel2/8.jpg',
      text: 'Plex: Stream Movies, Shows, Live TV, Music, and More',
      reviewno: '51,727',
      price: 'Plex'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel2/9.jpeg',
      text: 'Martial Arts and Kung-Fu Movie Channel',
      reviewno: '4,629',
      price: 'POV Horror'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel2/10.jpg',
      text: 'NordVPN - Fast, Secure and Unlimited VPN app for Android. Stay secure and…',
      reviewno: '21,882',
      price: 'nordvpn s.a.'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel2/11.jpeg',
      text: 'Thriller Movie Channel',
      reviewno: '232',
      price: 'POV Horror'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel2/12.jpeg',
      text: 'Cleaner for Fire Tablets & TV (Cleanza)',
      reviewno: '6,219',
      price: 'Quickla Soft'
    },
  ]
  
}
