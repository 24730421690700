<div class="container cardpadding" [ngClass.gt-sm]="'cardpadding2'" fxLayout="column" fxLayoutGap="30px">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <p class="mat-headline color-primary">Welcome to Global Local Store</p>
    <p class="mat-h4 text-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
      incididunt ut labore et dolore
      gna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.</p>
  </div>
  <div class="bg-secondary cardpadding" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutAlign="center center">
      <p class="mat-headline color-secondary">Why Choose Us?</p>
    </div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxFlex>
      <div fxLayout="column" fxLayoutAlign="space-around start" fxFlex="40">
        <!-- <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="47">
        <img src="assets/images/aboutus/card1.jpeg" alt="" [ngClass.gt-sm]="'image-size'"
          [ngClass.lt-md]="'full-width'">
      </div> -->
        <div class="cardpadding" [ngClass.gt-sm]="'cardpadding2'" fxLayout="column" fxLayoutGap="20px" fxFlex="47">
          <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutGap="10px">
              <span class="mat-headline letter-spacing  color-primary"><i class="fa fa-truck"></i></span>
              <p class="font-bold mat-headline"> Free Delivery </p>
            </div>
            <div fxLayout="row">
              <p class="mat-h4" fxFlex="85">Lorem ipsum dolor sit amet consect adipisic elit sed do eiusmod tempor
                incididunt ut labore et dolore gna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit
                esse cillum dolore eu fugiat.</p>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-around start" fxFlex="40">
        <!-- <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="47">
        <img src="assets/images/aboutus/card6.jpeg" alt="" [ngClass.gt-sm]="'image-size'"
          [ngClass.lt-md]="'full-width'">
      </div> -->
        <div class="cardpadding" [ngClass.gt-sm]="'cardpadding2'" fxLayout="column" fxLayoutGap="20px" fxFlex="47">
          <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutGap="10px">
              <span class="mat-headline letter-spacing  color-primary"><i class="fa fa-heart-o"></i></span>
              <p class="font-bold mat-headline"> Free Gift Box </p>
            </div>
            <div fxLayout="row">
              <p class="mat-h4" fxFlex="85">Lorem ipsum dolor sit amet consect adipisic elit sed do eiusmod tempor
                incididunt ut labore et dolore gna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit
                esse cillum dolore eu fugiat.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxFlex>
      <div fxLayout="column" fxLayoutAlign="space-around start" fxFlex="40">
        <!-- <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="47">
          <img src="assets/images/aboutus/card10.jpeg" alt="" [ngClass.gt-sm]="'image-size'"
            [ngClass.lt-md]="'full-width'">
        </div> -->
        <div class="cardpadding" [ngClass.gt-sm]="'cardpadding2'" fxLayout="column" fxLayoutGap="20px" fxFlex="47">
          <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutGap="10px">
              <span class="mat-headline letter-spacing  color-primary"><i class="fa fa-refresh"></i></span>
              <p class="font-bold mat-headline"> Money Back </p>
            </div>
            <div fxLayout="row">
              <p class="mat-h4" fxFlex="85">Lorem ipsum dolor sit amet consect adipisic elit sed do eiusmod tempor
                incididunt ut labore et dolore gna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit
                esse cillum dolore eu fugiat.</p>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-around start" fxFlex="40">
        <!-- <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="47">
          <img src="assets/images/aboutus/card8.jpeg" alt="" [ngClass.gt-sm]="'image-size'"
            [ngClass.lt-md]="'full-width'">
        </div> -->
        <div class="cardpadding" [ngClass.gt-sm]="'cardpadding2'" fxLayout="column" fxLayoutGap="20px" fxFlex="47">
          <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutGap="10px">
              <span class="mat-headline letter-spacing  color-primary"><i class="fa fa-clock-o"></i></span>
              <p class="font-bold mat-headline">
                Support 24/7 </p>
            </div>
            <div fxLayout="row">
              <p class="mat-h4" fxFlex="85">Lorem ipsum dolor sit amet consect adipisic elit sed do eiusmod tempor
                incididunt ut labore et dolore gna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit
                esse cillum dolore eu fugiat.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <p class="mat-headline  color-primary">Our Staff</p>
    <p class="mat-h4 text-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
      incididunt ut labore et dolore
      gna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.</p>
  </div>
  <div fxLayout="row" fxLayout.lt-sm="column" fxFlex>
    <img src="assets/images/aboutus/team1.jpg" alt="" class="full-width" fxFlex="33">
    <img src="assets/images/aboutus/team2.jpg" alt="" class="full-width" fxFlex="33">
    <img src="assets/images/aboutus/team4.jpg" alt="" class="full-width" fxFlex="33">
  </div>
</div>