<div class="container" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column">
    <div class="cardpadding" fxLayout="column" fxLayoutAlign="start start" fxHide fxShow.gt-sm>
      <p class="mat-title">Musical Instruments & Professional Audio</p>
      <p><a class="color-accent text-underline" routerLink="/best-seller">Bestsellers</a> | Shop for all kind of Musical
        Instruments such as
        <a class="color-accent text-underline">Guitars</a>(
        <a class="color-accent text-underline">Bass</a>,
        <a class="color-accent text-underline">Acoustic</a>,
        <a class="color-accent text-underline">Classic & more</a>),
        <a class="color-accent text-underline">Gear</a> and other
        <a class="color-accent text-underline">Pioano</a>,
        <a class="color-accent text-underline">Keayboard & Synthesizers</a>,
        <a class="color-accent text-underline">Drums & Percussion</a>, diffrent kinds of
        <a class="color-accent text-underline">String Instruments</a>,
        <a class="color-accent text-underline">Wind Instruments</a>,
        <a class="color-accent text-underline">Indian Instruments</a>,
        <a class="color-accent text-underline">Violins</a>,
        <a class="color-accent text-underline">Cajon's</a>,
        <a class="color-accent text-underline">Ukulele</a>, of different kinds across various regions and supporting
        Musical Instrument
        <a class="color-accent text-underline">Accessories</a> for various instruments like
        <a class="color-accent text-underline">Synthesizer & Sampler</a>,
        <a class="color-accent text-underline">Studio Monitors</a>,
        <a class="color-accent text-underline"> Microphones</a>,
        <a class="color-accent text-underline">Portable Recording Devices</a>,
        <a class="color-accent text-underline">Karaoke Equipment</a>, and
        <a class="color-accent text-underline">more</a>.
      </p>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxShow.lt-sm fxHide>
      <p class="mat-headline text-center">New & upcoming launches in Music Instruments & Accessories </p>
    </div>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxLayoutGap="20px" fxHide.lt-sm fxShow>
      <div fxLayout="column" fxLayoutGap="5px">
        <p class="mat-small font-bold">Department</p>
        <div class="padding-side " fxLayout="column">
          <p class="mat-small font-bold">Musical Instruments</p>
          <div class="padding-side" fxLayout="column">
            <p class="mat-small color-hover">DJ & VJ equipments</p>
            <p class="mat-small color-hover">Drums & Percussion</p>
            <p class="mat-small color-hover">Guitars, Basses & Gear</p>
            <p class="mat-small color-hover">Karako Equipment</p>
            <p class="mat-small color-hover">Microphones</p>
            <p class="mat-small color-hover">PA & Stage</p>
            <p class="mat-small color-hover">Plano & kayboard</p>
            <p class="mat-small color-hover">Recording & Computer</p>
            <p class="mat-small color-hover">String Instruments</p>
            <p class="mat-small color-hover">Synthesizers, Samplers</p>
            <p class="mat-small color-hover">Digital Instruments</p>
            <p class="mat-small color-hover">Wind Instruments</p>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="5px">
          <div fxLayout="column">
            <p class="mat-small font-bold">Pay on Delivery</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Eligible for Pay On
              Delivery</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Brands</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Boya</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Tygot</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Duracell</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Syvo</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Digitek</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Logitech</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">JUAREZ</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Avg. Customers Reviews</p>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">New Arrivals</p>
            <p class="mat-small color-hover">Last 30 days</p>
            <p class="mat-small color-hover">Last 90 days</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Item Condition <span class="mat-h5">(What's this?)</span></p>
            <p class="mat-small color-hover">New</p>
            <p class="mat-small color-hover">Renewed</p>
            <p class="mat-small color-hover">Used</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">price</p>
            <p class="mat-small color-hover">Under ₹1,000</p>
            <p class="mat-small color-hover">₹1,000 - ₹5,000</p>
            <p class="mat-small color-hover">₹5,000 - ₹10,000</p>
            <p class="mat-small color-hover">₹10,000 - ₹20,000</p>
            <p class="mat-small color-hover">Over ₹20,000</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Deals</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Today's Deals
            </mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Discount</p>
            <p class="mat-small color-hover">10% off or more</p>
            <p class="mat-small color-hover">25% off or more</p>
            <p class="mat-small color-hover">35% off or more</p>
            <p class="mat-small color-hover">50% off or more</p>
          </div>
          <!-- <div fxLayout="column">
            <p class="mat-small font-bold">Seller</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cart2India Online
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Card2India SLP
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">WSSTORE</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Global Klamp
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">STS Shop
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">HoneyMoney-IN
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Andoerin
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">ovskyer
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Rosmo
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Sai Musical 2
            </mat-checkbox>
          </div> -->
          <div fxLayout="column">
            <p class="mat-small font-bold">Availability</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Include Out of Stock
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div>
      <mat-divider [vertical]="true" fxFlex="100"></mat-divider>
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
      <div class="card cardpadding slider-items" fxHide>
        <p class="mat-h2 padding-top">Top deals on storage devices</p>
        <mat-divider class="full-width"></mat-divider>
        <div>
          <owl-carousel-o [options]="customOptions1">
            <ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
              <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="5px" fxFlex>
                <div fxLayout="row" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div fxLayout="column" fxLayoutAlign="start start">
                  <p class="mat-h4 color-accent color-hover" (click)="products()">{{slide.name}}</p>
                  <p class="mat-small">{{slide.brand}}</p>
                  <p class="mat-h4">{{slide.price}} <span class="mat-small text-line-through">{{slide.oldprice}}</span>
                  </p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{slide.reviewno}}</p>
                  </div>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row">
          <img src="assets/images/electronics/music/banner1.jpg" alt="" class="full-width">
        </div>
        <div fxLayout="row">
          <img src="assets/images/electronics/music/banner2.jpg" alt="" class="full-width">
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around center" fxLayoutGap.lt-sm="10px">
          <img src="assets/images/electronics/music/banner3.jpg" alt="" class="full-width" fxFlex="49">
          <img src="assets/images/electronics/music/banner4.jpg" alt="" class="full-width" fxFlex="49">
        </div>
        <div fxLayout="row">
          <img src="assets/images/electronics/music/banner5.jpg" alt="" class="full-width">
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/music/1.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/music/2.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/music/3.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/music/4.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/music/5.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/music/6.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/music/7.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/music/8.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/music/9.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/music/10.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/music/11.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/music/12.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row">
          <img src="assets/images/electronics/music/banner6.jpg" alt="" class="full-width">
        </div>
        <div fxLayout="row">
          <img src="assets/images/electronics/music/banner7.jpg" alt="" class="full-width">
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/music/small1.jpg" alt="" fxFlex="31">
            <img src="assets/images/electronics/music/small2.jpg" alt="" fxFlex="31">
            <img src="assets/images/electronics/music/small3.jpg" alt="" fxFlex="31">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/music/small4.jpg" alt="" fxFlex="31">
            <img src="assets/images/electronics/music/small5.jpg" alt="" fxFlex="31">
            <img src="assets/images/electronics/music/small6.jpg" alt="" fxFlex="31">
          </div>
        </div>
        <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
          <p class="mat-h2 padding-top">Musical instruments coupons</p>
          <div>
            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
                <div class="hover-border" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px" fxFlex>
                  <div fxLayout="row">
                    <img [src]="slide.src">
                  </div>
                  <div class="cardpadding" fxLayout="column" fxLayoutAlign="start start">
                    <p class="mat-title color-green">{{slide.save}} </p>
                    <p class="mat-small color-accent">{{slide.name}}</p>
                  </div>
                  <div fxLayout="column" fxLayoutAlign="start stretch">
                    <button mat-raised-button color="primary">{{slide.button}}</button>
                    <p class="mat-small color-secondary">Discount at checkout</p>
                  </div>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="40px">
          <div fxLayout="row" class="card-shadow cardpadding">
            <p class="mat-small">1-24 of over 50,000 results for : <span class="font-bold color-primary"> Musical
                Instruments </span></p>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
            <div fxLayoutGap="20px" *ngFor="let item of items" fxFlex="33" fxFlex.lt-sm="100">
              <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
                <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                  <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
                </div>
                <div fxFlex.lt-sm="70">
                  <p class="mat-h4 color-secondary">Sponsored </p>
                  <p class="mat-h3 color-accent color-hover" (click)="products()">{{item.name}}</p>
                  <p class="mat-small">by {{item.company}}</p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{item.reviewno}}</p>
                  </div>
                  <p class="font-bold color-danger"> {{item.newprice}} <span
                      class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                  <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                  
                  <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                  <p class="color-accent color-hover">See Details</p>
                </div>
              </div>
            </div>
          </div>
          <div fxHide.lt-sm>
            <mat-divider></mat-divider>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
            <div fxLayoutGap="20px" *ngFor="let item of items1" fxFlex="33" fxFlex.lt-sm="100">
              <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
                <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                  <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
                </div>
                <div fxFlex.lt-sm="70">
                  <p class="mat-h4 color-secondary">Sponsored </p>
                  <p class="mat-h3 color-accent color-hover" (click)="products()">{{item.name}}</p>
                  <p class="mat-small">by {{item.company}}</p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{item.reviewno}}</p>
                  </div>
                  <p class="font-bold color-danger"> {{item.newprice}} <span
                      class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                  <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                  
                  <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                  <p class="color-accent color-hover">See Details</p>
                </div>
              </div>
              <div fxShow.lt-sm fxHide>
                <mat-divider></mat-divider>
              </div>
            </div>
          </div>
          <div fxHide.lt-sm>
            <mat-divider></mat-divider>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
            <div fxLayoutGap="20px" *ngFor="let item of items2" fxFlex="33" fxFlex.lt-sm="100">
              <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
                <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                  <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
                </div>
                <div fxFlex.lt-sm="70">
                  <p class="mat-h4 color-secondary">Sponsored </p>
                  <p class="mat-h3 color-accent color-hover" (click)="products()">{{item.name}}</p>
                  <p class="mat-small">by {{item.company}}</p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{item.reviewno}}</p>
                  </div>
                  <p class="font-bold color-danger"> {{item.newprice}} <span
                      class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                  <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                  
                  <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                  <p class="color-accent color-hover">See Details</p>
                </div>
              </div>
              <div fxShow.lt-sm fxHide>
                <mat-divider></mat-divider>
              </div>
            </div>
          </div>
          <div fxHide.lt-sm>
            <mat-divider></mat-divider>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
            <div *ngFor="let item of items3" fxFlex="33" fxFlex.lt-sm="100">
              <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
                <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                  <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
                </div>
                <div fxFlex.lt-sm="70">
                  <p class="mat-h4 color-secondary">Sponsored </p>
                  <p class="mat-h3 color-accent color-hover" (click)="products()">{{item.name}}</p>
                  <p class="mat-small">by {{item.company}}</p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{item.reviewno}}</p>
                  </div>
                  <p class="font-bold color-danger"> {{item.newprice}} <span
                      class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                  <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                  
                  <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                  <p class="color-accent color-hover">See Details</p>
                </div>
              </div>
              <div fxShow.lt-sm fxHide>
                <mat-divider></mat-divider>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row">
          <button mat-raised-button class="color-accent bg-secondary" fxFlex="100">See all results</button>
        </div>
      </div>
    </div>
  </div>
  <div class="cardpadding" fxLayout="column" fxLayoutGap="10px" fxHide.lt-sm>
    <div fxLayout="column" fxLayoutGap="5px">
      <p class="mat-title color-primary">Explore Musical Instruments & Professional Audio Products Online at Global Local
        India</p>
      <p class="mat-small">Whether you need a guitar with active or passive pickups, some recording equipment for your
        home studio, a PA system or DJ equipment to perform for an audience, you will find all these and more under one
        roof at Global Local! Our Musical Instruments category lists down a wide range of instruments, accessories and gear
        you need for your next performance. We have a huge selection of guitars, keyboards, drums, home recording
        equipment, cymbals and more. Browse through the accessories collection like strings, reeds, picks, stands and
        tuners to get you ready to perform. Right from guitar amps, microphones, multi-track recorders and speakers to
        recording equipment, you will find everything at our Musical Instruments section. We offer reliable products
        from popular brands such as Yamaha, Casio, Fender, Behringer, Alesis, Shure, Roland and D'Addario.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Guitars</p>
      <p class="mat-small">Our Guitars section has you covered with electric, bass, acoustic and classical guitars. This
        section also includes complete guitar kits that include guitar polish, microfiber cleaning cloth, strings,
        string cleaner, picks, winder, cutters and Allen keys. You will find guitars of all styles like those with
        active or passive pickups, steel string guitars, left-handed guitars, and beginner’s kits. There are also guitar
        accessories like guitar cases, replacement parts, strings, tuners, picks, stands, amp accessories and
        capotastos. We have the best acoustic guitars, electric guitars, bass guitars, classical guitars, guitar
        amplifiers, pedals, effects, strings, capos, guitar stands, bags and other accessories with fast delivery and
        lowest price from best brands like JUAREZ, Intern, Photron, RockJam, Ibanez, Kadence, Yamaha, Fender, Mustang,
        Cort, Vault, Breve, Arctic, Marshall, D’addario, Planet Waves, Ernie Ball, Jim Dunlop and more.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Piano & Keyboards</p>
      <p class="mat-small">This section is all about digital pianos, keyboards and synthesizers that are available in
        various styles. Find the perfect electronic keyboard for you from midi, portable, full-size and mini models. Our
        pianos are available with features such as weighted keys, hammer action, sustain pedal and USB interface. We
        have the best Digital Pianos, Portable keyboards, mini keyboards and keyboard accessories with fast delivery and
        lowest price from best brands from best brands like Yamaha, Arctic, Casio RockJam, Roland, Korg, Alesis, Akai
        Professional, M-audio and more</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Drums & Percussions</p>
      <p class="mat-small">Drum up your musical skills with the right set of drums and percussions available to you at
        Global Local India! We house a range of drum sets starting from 3-piece to large 12-peice sets for juniors, beginners
        as well as professionals. You will also find drums accessories such as wood tip drumsticks, drumstick stand,
        microphone stands, drum stool, drum keys, damper pads, cymbal felts, snares, drum bags and drum heads. We have
        the best We have the best Acoustic Drums, Electronic drum kits, Drum pads, Drumsticks, Cajons, Cymbals, Djembes,
        Bongos, singing bowls and other percussion instruments and accessories with fast delivery and lowest price from
        best brands like DronaIndia, Mustang, ProMark, Evans, TAMA, Vic Firth, Pearl, ClapBox, Remo, Evans, Promark,
        Alesis, Roland, Behringer, RockJam, Kadence and more.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Wind Instruments</p>
      <p class="mat-small">Whether you are in search of woodwinds or brasswinds, you are sure to find what you want at
        Global Local India! We have a huge selection of wind instruments like flutes, trumpets and nadaswaram in a variety of
        styles and sizes. We have the best (flutes, harmonicas, mouth organs, melodicas, saxophones and recorders) with
        fast delivery and lowest price from best brands like Vault, Arctic, Punam flutes, Hohner, Yamaha, SG Musicals,
        Rico, Foxit, and more.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Indian Music Instruments</p>
      <p class="mat-small">Indian music is a classical tradition and an individualistic art that is all about creating
        personal harmony with the soul! Hone your skills in Indian classical music with the right product from Global Local’s
        Musical Instruments section. Here, you will uncover a range of Indian instruments like the ghatam, harmonium,
        flute, jal tarang, mandolin, nadaswaram, rudra veena, sarangi, sitar, tabla and violin, Dhol, Dholak and more,
        with fast delivery and lowest price from best brands like SG Musical, Punam flutes, Arctic and more.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Speakers</p>
      <p class="mat-small">Speakers are an integral part of any DJ party. At Global Local India, you will find a multitude of
        speakers in different types, sizes and specifications to suit each individual’s needs. From wireless speakers to
        wired speakers to stereo speakers and tower speakers, this online store has everything you need to amp up the
        party. You can also find speakers with subwoofers that offer thumping music performance.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Power Amplifiers</p>
      <p class="mat-small">A vital item in any DJ professional’s equipment list, power amplifiers ensure superior sound
        performance. They offer enhanced power to offer great audio experience. Shop for power amplifiers with modern,
        lightweight and compact design, stunning looks and improved specs at Global Local India. They are designed with
        advanced functionalities and controls for reliable performance. We have the best Power Amplifiers with fast
        delivery and lowest price from best brands like Boya, Maono, Kadence, Dynatech, Studiomaster, Crown, NX Audio.
      </p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">DJ & VJ Equipment</p>
      <p class="mat-small">Are you a DJ looking for professional audio equipment for your events? Then you are at the
        right place. Global Local India is the one-stop shop for all your professional audio equipment needs. This online
        store offers a wide and new range of professional audio instruments from top and popular brands that meet your
        requirements. There is a good news for DJs and VJs out there! We also offer quality DJ and VJ equipment
        including mixers, controllers, CD players and headphones. There are also accessories available like bags and
        cases, DJ effects, drive belts, turntables, racks and cartridges and needles. You will also find speakers and
        monitors, lighting accessories, mixing decks, midi controllers, PA and stage equipment and microphones. We have
        the best DJ and VJ Equipment with fast delivery and lowest price from best brands like Audio-Technica, OneOdio,
        MAONO, YAMAHA, CLAW, Pioneer DJ, Numark, Denon DJ, AKG, JBL Professional, Behringer, Mackie and more.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Karaoke Equipment</p>
      <p class="mat-small">What better way to end a long day than to relax by enjoying a tune your familiar with at
        home. Do you own a club and want people to walk-in and sing to the tunes? Global Local India is the one-stop shop for
        all your professional Karaoke Equipment needs. We have the best Karaoke Equipment with fast delivery and lowest
        price from best brands like VRJTEC, Persang, MAONO, Takara and more.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Recording & Computer</p>
      <p class="mat-small">Do you have a Recording Studio and want multiple recording equipments? Buy recording devices
        like Audio Interfaces, Digital Audio Workstation Sets, Effects & Signal Processor, In-Ear-Monitoring,
        Microphones, MIDI Controller, MIDI Interfaces, Multitrack Recorders, Portable Recording Devices, Power
        Conditioners, Studio Accessories and Studio Monitors from Global Local India. We have the best Recording & Computer
        with fast delivery and lowest price from best brands like Boya, MAONO, JUAREZ, JT, JBL Professional, Focusrite,
        Behringer, Akai Professional, M-Audio, Alesis, Zoom, Shure, Tascam and more.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">String Instruments</p>
      <p class="mat-small">If you like to play String Instruments and listen to sound created by the vibration of the
        Strings? Purchase String instruments like Classical Guitars, Sarangi, Sitars, Tamburas, Ukulele, Veenas and
        Violins in Global Local India and create your own Music. We have the best String Instruments with fast delivery and
        lowest price from best brands JUAREZ, Kadence, Martin Smith, ARCTIC, Kala Yamaha and more.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Microphones</p>
      <p class="mat-small">Microphones are a must-have in a DJ’s instrument kit. You will find a huge selection of
        microphones from well-known brands and at a great price. Microphones available at this online store are of
        different types such as dynamic microphones, USB microphones, camera microphones, retro-style microphones,
        condenser microphones and more. Choose the one that best suits your requirement. We have the best Microphones
        like condenser, dynamic, Ribbon, wireless, lavalier, lapel, karaoke, omnidirectional, unidirectional and
        cardioid for all applications like recording vocals, instruments, podcasting, vlogging, youtube videos,
        on-camera, stage and PA with fast delivery and lowest price from best brands like Boya, Maono, Blue Microphones,
        Sennheiser, AKG, Shure, Rode, Ahuja, Behringer, Samson, JTS and Beyerdynamic and more.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Mixers</p>
      <p class="mat-small">Redefine the audio experience with a plethora of audio mixers or mixing consoles/desks sold
        at Global Local India. This online store has both analog and digital mixers from popular brands like Yamaha that
        speaks of quality and reliability. We have the best Mixers with fast delivery and lowest price from best brands
        like Behringer, Yamaha, Dynatech, Mackie, Kadence, Ahuja and more.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Cables</p>
      <p class="mat-small">Global Local India offers different types of cables such as USB to Micro USB cables, adapter
        cables, antenna extension cables, video cable adapters, headset extension cables, power cords and more. These
        cables ensure no distortion, signal interference and signal loss</p>
    </div>
    <div fxLayout="column" fxLayoutGap="5px">
      <p class="mat-small">Browse through the wide collection of professional audio equipment at Global Local India and make
        your pick</p>
      <p class="mat-small">Simply narrow down your search based on your requirement and place your order today! Enjoy
        Global Local’s fast shipping and have happy shopping experience!</p>
    </div>
  </div>
</div>
<div class="card-shadow" fxHide>
	<mat-card>
		<div class="cardpadding" fxLayout="column" fxLayoutGap="30px">
			<div class="slider-items" fxLayout="column" fxLayoutGap="20px">
				<p class="mat-title">Inspired by your browsing history</p>
				<owl-carousel-o [options]="customOptions2" class="cardpadding">
					<ng-template carouselSlide *ngFor="let slide of slidesStore2" [id]="slide.id">
						<img [src]="slide.src">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="color-accent color-hover">{{slide.text}}</span>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star_border</mat-icon>
								<mat-icon>keyboard_arrow_down</mat-icon>
								<p class="color-accent color-hover">{{slide.reviewno}}</p>
							</div>
							<span class="color-danger">{{slide.price}}</span>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
			<div class="slider-items" fxLayout="column" fxLayoutGap="20px">
				<p class="mat-title">Top subscription apps for you</p>
				<owl-carousel-o [options]="customOptions3" class="cardpadding">
					<ng-template carouselSlide *ngFor="let slide of slidesStore3" [id]="slide.id">
						<img [src]="slide.src">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="color-accent color-hover">{{slide.text}}</span>
							<span class="mat-small">{{slide.price}}</span>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star_border</mat-icon>
								<mat-icon>keyboard_arrow_down</mat-icon>
								<p class="color-accent color-hover">{{slide.reviewno}}</p>
							</div>
							<span class="color-danger">FREE</span>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
		</div>
	</mat-card>
</div>