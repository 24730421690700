import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-daily-essentials',
  templateUrl: './daily-essentials.component.html',
  styleUrls: ['./daily-essentials.component.scss']
})
export class DailyEssentialsComponent implements OnInit {

  items = [
    { image: 'assets/images/gl-home/daily/card/1.jpg', name: 'Supples Baby Pants Diapers, Large, 62 Count', company: 'Supples', newprice: '₹ 610', oldprice: '₹ 949', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '34,264' },
    { image: 'assets/images/gl-home/daily/card/2.jpg', name: 'Amazon Brand - Solimo 3 Ply Toilet Paper/Tissue Roll - 12 Rolls (160 Pulls Per Roll)', company: 'Amazon Brand - Solimo', newprice: '₹ 289', oldprice: '₹ 350', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '2,861' },
    { image: 'assets/images/gl-home/daily/card/3.jpg', name: 'Amazon Brand - Solimo 2 Ply Kitchen Tissue Paper Roll - 4 Rolls (60 Pulls Per Roll)', company: 'Amazon Brand - Solimo', newprice: '₹ 252', oldprice: '₹ 280', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '4,131' },
  ];
  items1 = [
    { image: 'assets/images/gl-home/daily/card/4.jpg', name: 'Amazon Brand - Solimo Almonds, 500g', company: 'Amazon Brand - Solimo', newprice: '₹ 469', oldprice: '₹ 705', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '11,690' },
    { image: 'assets/images/gl-home/daily/card/5.jpg', name: 'Amazon Brand - Presto! 2 Ply Facial Tissue Soft Pack - 200 Pulls (Pack of 3)', company: 'Presto!', newprice: '₹ 299', oldprice: '₹ 350', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '1,480' },
    { image: 'assets/images/gl-home/daily/card/6.jpg', name: 'Amazon Brand - Solimo Baking Paper & Cooking Parchment Paper (20 m)', company: 'Amazon Brand - Solimo', newprice: '₹ 199', oldprice: '₹ 250', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '279' },
  ];
  items2 = [
    { image: 'assets/images/gl-home/daily/card/7.jpg', name: 'AmazonBasics Tie-Out Cable/Leash for Dogs up to 57 Kg, 30 Feet', company: 'AmazonBasics', newprice: '₹ 399', oldprice: '₹ 795', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '8,689' },
    { image: 'assets/images/gl-home/daily/card/8.jpg', name: 'Supples Baby Diaper Pants, Monthly Mega-Box, Large, 124 Count', company: 'Supples', newprice: '₹ 1,095', oldprice: '₹ 1,200', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '2,903' },
    { image: 'assets/images/gl-home/daily/card/9.jpg', name: 'Vouch Bria Multipocket and Multipurpose Diaper Bag, Pink', company: 'Vouch', newprice: '₹ 519', oldprice: '₹ 1,500', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Monday, Dec 28', reviewno: '43' },
  ];
  items3 = [
    { image: 'assets/images/gl-home/daily/card/10.jpg', name: 'Amazon Brand - Vedaka Popular Raisins, 1kg', company: 'Vedaka', newprice: '₹ 365', oldprice: '₹ 500', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '2,119' },
    { image: 'assets/images/gl-home/daily/card/11.jpg', name: 'Divine India Hand Sanitiser Enriched with Neem, Basil Extracts & Lavender Oil (70% Isopropyl Alcohol) - 210 ml (Pack of 2)', company: 'Divine India', newprice: '₹ 179', oldprice: '₹ 500', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '188' },
    { image: 'assets/images/gl-home/daily/card/12.jpg', name: 'Amazon Brand - Solimo 2 Ply Facial Tissues Carton Box - 100 Pulls', company: 'Amazon Brand - Solimo', newprice: '₹ 79', oldprice: '₹ 100', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00.', deliverydate: 'Monday, Dec 28', reviewno: '358' },
  ];

  constructor( public router: Router,) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/images/gl-home/daily/carousel/1.jpg',
      price: '₹ 315',
      oldprice: '₹ 400',
      name: 'Nature Crest 100% Pure Coconut Oil, 1L',
      brand: 'Nature Crest',
      reviewno: '504'
    },
    {
      id: 2,
      src: './assets/images/gl-home/daily/carousel/2.jpg',
      price: '₹ 145',
      oldprice: '₹ 400',
      name: 'Amazon Brand - Vedaka Canola Oil Bottle, 1L',
      brand: 'Vedaka',
      reviewno: '328'
    },
    {
      id: 3,
      src: './assets/images/gl-home/daily/carousel/3.jpg',
      price: '₹ ',
      oldprice: '₹ ',
      name: 'Amazon Brand - Solimo Extra Light Olive Oil, 2L',
      brand: 'Amazon Brand - Solimo',
      reviewno: '124'
    },
    {
      id: 4,
      src: './assets/images/gl-home/daily/carousel/4.jpg',
      price: '₹ ',
      oldprice: '₹ ',
      name: 'Nature Crest Extra Light Olive Oil - 250ml',
      brand: 'Nature Crest',
      reviewno: '28'
    },
    {
      id: 5,
      src: './assets/images/gl-home/daily/carousel/5.jpg',
      price: '₹ 879',
      oldprice: '₹ 1,900',
      name: 'Nature Crest 100% Cold Pressed Virgin Coconut…',
      brand: 'Nature Crest',
      reviewno: '43'
    },
    {
      id: 6,
      src: './assets/images/gl-home/daily/carousel/6.jpg',
      price: '₹ 699',
      oldprice: '₹ 1,990',
      name: 'Amazon Brand - Vedaka Canola Oil Jar, 5L',
      brand: 'Vedaka',
      reviewno: '328'
    },
    {
      id: 7,
      src: './assets/images/gl-home/daily/carousel/7.jpg',
      price: '₹ 263',
      oldprice: '₹ 450',
      name: 'Nature Crest 100% Cold Pressed Virgin Coconut Oil - 500 ml',
      brand: 'Nature Crest',
      reviewno: '43'
    },
    {
      id: 8,
      src: './assets/images/gl-home/daily/carousel/8.jpg',
      price: '₹ ',
      oldprice: '₹ ',
      name: 'Vedaka Cold Pressed Virgin Coconut Oil 500ml',
      brand: 'Vedaka',
      reviewno: '338'
    },
    {
      id: 9,
      src: './assets/images/gl-home/daily/carousel/9.jpg',
      price: '₹ 340',
      oldprice: '₹ 500',
      name: 'Vedaka Cold Pressed Peanut (Groundnut) Oil 1l',
      brand: 'Vedaka',
      reviewno: '114'
    },
    {
      id: 10,
      src: './assets/images/gl-home/daily/carousel/10.jpg',
      price: '₹ 729',
      oldprice: '₹ 1,350',
      name: 'Nature Crest Extra Light Olive Oil, 1L',
      brand: 'Nature Crest',
      reviewno: '28'
    },
  ]
  customOptions1: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/gl-home/daily/carousel1/1.jpg',
      price: '₹ 469',
      oldprice: '₹ 705',
      name: 'Amazon Brand - Solimo Almonds, 500g',
      brand: 'Amazon Brand - Solimo',
      reviewno: '11,121'
    },
    {
      id: 2,
      src: './assets/images/gl-home/daily/carousel1/2.jpg',
      price: '₹ 350',
      oldprice: '₹ 435',
      name: 'Amazon Brand - Solimo Premium Cashews, 250g',
      brand: 'Amazon Brand - Solimo',
      reviewno: '3,276'
    },
    {
      id: 3,
      src: './assets/images/gl-home/daily/carousel1/3.jpg',
      price: '₹ 279',
      oldprice: '₹ 500',
      name: 'Amazon Brand – Solimo dates 500g',
      brand: 'Amazon Brand - Solimo',
      reviewno: '119'
    },
    {
      id: 4,
      src: './assets/images/gl-home/daily/carousel1/4.jpg',
      price: '₹ 529',
      oldprice: '₹ 800',
      name: 'Amazon Brand - Solimo figs 500g',
      brand: 'Amazon Brand - Solimo',
      reviewno: '167'
    },
    {
      id: 5,
      src: './assets/images/gl-home/daily/carousel1/5.jpg',
      price: '₹ 88',
      oldprice: '₹ 125',
      name: 'Amazon Brand - Solimo Tomato Ketchup, 950 g',
      brand: 'Amazon Brand - Solimo',
      reviewno: '1,826'
    },
    {
      id: 6,
      src: './assets/images/gl-home/daily/carousel1/6.jpg',
      price: '₹ 330',
      oldprice: '₹ 400',
      name: 'Amazon Brand - Vedaka Premium Tea, 1kg',
      brand: 'Vedaka',
      reviewno: '2,373'
    },
    {
      id: 7,
      src: './assets/images/gl-home/daily/carousel1/7.jpg',
      price: '₹ 240',
      oldprice: '₹ 250',
      name: 'Amazon Brand - Vedaka Gold Tea, 500g',
      brand: 'Vedaka',
      reviewno: '2,843'
    },
    {
      id: 8,
      src: './assets/images/gl-home/daily/carousel1/8.jpg',
      price: '₹ 299',
      oldprice: '₹ 500',
      name: 'Amazon Brand - Solimo Choco Flakes, 1.2kg',
      brand: 'Amazon Brand - Solimo',
      reviewno: '375'
    },
    {
      id: 9,
      src: './assets/images/gl-home/daily/carousel1/9.jpg',
      price: '₹ 135',
      oldprice: '₹ 190',
      name: 'Amazon Brand - Solimo Corn Flakes, 500g',
      brand: 'Amazon Brand - Solimo',
      reviewno: '203'
    },
    {
      id: 10,
      src: './assets/images/gl-home/daily/carousel1/10.jpg',
      price: '₹ 199',
      oldprice: '₹ 250',
      name: 'Amazon Brand - Vedaka Amazon Brand Popular Raisins, 500g',
      brand: 'Vedaka',
      reviewno: '1,480'
    },
  ]
  customOptions2: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore2 = [
    {
      id: 1,
      src: './assets/images/gl-home/daily/carousel2/1.jpg',
      price: '₹ 249',
      oldprice: '₹ 315',
      name: 'Montagne Fruit & Vegetable Cleaner Liquid 500 ML (Removes Germs, Bacteria & Fungus | 100% Bio-Degradable & Eco-Friendly | Chlorine & Salt Free)',
      brand: 'Montagne',
      reviewno: '3'
    },
    {
      id: 2,
      src: './assets/images/gl-home/daily/carousel2/2.jpg',
      price: '₹ 1,999',
      oldprice: '₹ 2,500',
      name: 'Amazon Brand - Solimo Instant Hand Disinfectant/Sanitizer Gel (70% Ethyl Alcohol), 5 L',
      brand: 'Amazon Brand - Solimo',
      reviewno: '30'
    },
    {
      id: 3,
      src: './assets/images/gl-home/daily/carousel2/3.jpg',
      price: '₹ 259',
      oldprice: '₹ 540',
      name: 'Amazon Brand - Solimo Hand Sanitizer Gel - 100 ml (Pack of 6, Lemon Oil)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '1,401'
    },
    {
      id: 4,
      src: './assets/images/gl-home/daily/carousel2/4.jpg',
      price: '₹ 225',
      oldprice: '₹ 250',
      name: 'Amazon Brand - Solimo Hand Sanitizer Gel (72% Ethanol Absolute) - 500 ml',
      brand: 'Amazon Brand - Solimo',
      reviewno: '430'
    },
    {
      id: 5,
      src: './assets/images/gl-home/daily/carousel2/5.jpg',
      price: '₹ 189',
      oldprice: '₹ 249',
      name: 'Amazon Brand - Solimo Antibacterial Handwash Liquid Refill, Rose - 1500 ml',
      brand: 'Amazon Brand - Solimo',
      reviewno: '99'
    },
    {
      id: 6,
      src: './assets/images/gl-home/daily/carousel2/6.jpg',
      price: '₹ 189',
      oldprice: '₹ 249',
      name: 'Amazon Brand - Solimo Handwash Liquid Refill, Honey Almond - 1500 ml',
      brand: 'Amazon Brand - Solimo',
      reviewno: '329'
    },
    {
      id: 7,
      src: './assets/images/gl-home/daily/carousel2/7.jpg',
      price: '₹ 395',
      oldprice: '₹ 500',
      name: 'Amazon Brand - Solimo Hand Sanitizer Gel (72% Ethanol Absolute) - 500 ml (Pack of 2)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '430'
    },
    {
      id: 8,
      src: './assets/images/gl-home/daily/carousel2/8.jpg',
      price: '₹ 169',
      oldprice: '₹ 200',
      name: 'Amazon Brand - Solimo Hand Sanitizer Gel (72% Ethanol Absolute) - 100 ml (Pack of 4)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '430'
    },
    {
      id: 9,
      src: './assets/images/gl-home/daily/carousel2/9.jpg',
      price: '₹ 199',
      oldprice: '₹ 250',
      name: 'Amazon Brand - Solimo Hand Sanitizer Gel - 500 ml (Lemon Oil)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '1,419'
    },
    {
      id: 10,
      src: './assets/images/gl-home/daily/carousel2/10.jpg',
      price: '₹ 189',
      oldprice: '₹ 249',
      name: 'Amazon Brand - Solimo Handwash Liquid Refill, Sea Minerals - 1500 ml',
      brand: 'Amazon Brand - Solimo',
      reviewno: '329'
    },
  ]
  customOptions3: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore3 = [
    {
      id: 1,
      src: './assets/images/gl-home/daily/carousel3/1.jpg',
      price: '₹ 610',
      oldprice: '₹ 949',
      name: 'Supples Baby Pants Diapers, Large, 62 Count',
      brand: 'Supples',
      reviewno: '34,121'
    },
    {
      id: 2,
      src: './assets/images/gl-home/daily/carousel3/2.jpg',
      price: '₹ 385',
      oldprice: '₹ 744',
      name: 'Supples Baby Wet Wipes with Aloe Vera and Vitamin E, 72 Wipes/Pack, (Pack of 6)',
      brand: 'Supples',
      reviewno: '15,059'
    },
    {
      id: 3,
      src: './assets/images/gl-home/daily/carousel3/3.jpg',
      price: '₹ 583',
      oldprice: '₹ 999',
      name: 'Papimo Baby Pants Diapers with Aloe Vera, Large, 64 Count',
      brand: 'Papimo',
      reviewno: '1,988'
    },
    {
      id: 4,
      src: './assets/images/gl-home/daily/carousel3/4.jpg',
      price: '₹ 185',
      oldprice: '₹ 387',
      name: 'Roo & Boo Baby Wet Wipes - Paraben Free 99% Water Wipes (72 pcs/pack) (Pack of 3)',
      brand: 'Roo & Boo',
      reviewno: '1,130'
    },
    {
      id: 5,
      src: './assets/images/gl-home/daily/carousel3/5.jpg',
      price: '',
      oldprice: '',
      name: 'Amazon Brand - Mama Bear Baby Diaper Pants, Medium (M) - 72 Count',
      brand: 'Mama Bear',
      reviewno: '167'
    },
    {
      id: 6,
      src: './assets/images/gl-home/daily/carousel3/6.jpg',
      price: '₹ 665',
      oldprice: '₹ 1,099',
      name: 'Amazon Brand - Mama Bear Baby Diaper Pants, Small (S) - 78 Count',
      brand: 'Mama Bear',
      reviewno: '57'
    },
    {
      id: 7,
      src: './assets/images/gl-home/daily/carousel3/7.jpg',
      price: '₹ 210',
      oldprice: '₹ 450',
      name: 'Papimo Ultrasoft Baby Wet Wipes With Aloe Vera NO Parabens NO Alcohol - 72 Count (Pack of 3)',
      brand: 'Papimo',
      reviewno: '109'
    },
    {
      id: 8,
      src: './assets/images/gl-home/daily/carousel3/8.jpg',
      price: '₹ 839',
      oldprice: '₹ 1,136',
      name: 'Supples Baby Pants Diapers, Large, 62 Count with Wet Wipes (Pack of 3)',
      brand: 'Supples',
      reviewno: '194'
    },
    {
      id: 9,
      src: './assets/images/gl-home/daily/carousel3/9.jpg',
      price: '₹ 1,299',
      oldprice: '₹ 2,198',
      name: 'Amazon Brand - Mama Bear Baby Diaper Pants Monthly Mega-Box - Medium (M), 144 Count',
      brand: 'Mama Bear',
      reviewno: '96'
    },
    {
      id: 10,
      src: './assets/images/gl-home/daily/carousel3/10.jpg',
      price: '₹ 649',
      oldprice: '₹ 1,200',
      name: 'AmazonBasics Diaper Caddy for Nursery or Car',
      brand: 'AmazonBasics',
      reviewno: '999'
    },
  ]
  customOptions4: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore4 = [
    {
      id: 1,
      src: './assets/images/gl-home/daily/carousel4/1.jpg',
      price: '₹ 289',
      oldprice: '₹ 350',
      name: 'Amazon Brand - Solimo 3 Ply Toilet Paper/Tissue Roll - 12 Rolls (160 Pulls Per Roll)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '2,821'
    },
    {
      id: 2,
      src: './assets/images/gl-home/daily/carousel4/2.jpg',
      price: '₹ 252',
      oldprice: '₹ 280',
      name: 'Amazon Brand - Solimo 2 Ply Kitchen Tissue Paper Roll - 4 Rolls (60 Pulls Per Roll)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '4,164'
    },
    {
      id: 3,
      src: './assets/images/gl-home/daily/carousel4/3.jpg',
      price: '₹ 259',
      oldprice: '₹ 349',
      name: 'Amazon Brand - Presto! Dish Wash Gel - 2 L (Lemon)',
      brand: 'Presto!',
      reviewno: '6,313'
    },
    {
      id: 4,
      src: './assets/images/gl-home/daily/carousel4/4.jpg',
      price: '₹ 299',
      oldprice: '₹ 350',
      name: 'Amazon Brand - Presto! 2 Ply Facial Tissue Soft Pack - 200 Pulls (Pack of 3)',
      brand: 'Presto!',
      reviewno: '1,485'
    },
    {
      id: 5,
      src: './assets/images/gl-home/daily/carousel4/5.jpg',
      price: '₹ 144',
      oldprice: '₹ 160',
      name: 'Amazon Brand - Presto! 2 Ply Kitchen Tissue/Towel Paper Roll - 2 Rolls (60 Pulls Per Roll)',
      brand: 'Presto!',
      reviewno: '752'
    },
    {
      id: 6,
      src: './assets/images/gl-home/daily/carousel4/6.jpg',
      price: '₹ 379',
      oldprice: '₹ 420',
      name: 'Amazon Brand - Presto! 2 Ply Kitchen Tissue/Towel Paper Roll - 6 Rolls (60 Pulls Per Roll)',
      brand: 'Presto!',
      reviewno: '1,081'
    },
    {
      id: 7,
      src: './assets/images/gl-home/daily/carousel4/7.jpg',
      price: '₹ 235',
      oldprice: '₹ 315',
      name: 'Amazon Brand - Presto! Disinfectant Floor Cleaner Citrus, 2 L',
      brand: 'Presto!',
      reviewno: '2,414'
    },
    {
      id: 8,
      src: './assets/images/gl-home/daily/carousel4/8.jpg',
      price: '₹ 325',
      oldprice: '₹ 480',
      name: 'Amazon Brand - Presto! Oxo-Biodegradable Garbage Bags, Medium (19 x 21 inches) - 30 bags/roll (Pack of 6)',
      brand: 'Presto!',
      reviewno: '275'
    },
    {
      id: 9,
      src: './assets/images/gl-home/daily/carousel4/9.jpg',
      price: '₹ 79',
      oldprice: '₹ 100',
      name: 'Amazon Brand - Solimo 2 Ply Facial Tissues Carton Box - 100 Pulls',
      brand: 'Amazon Brand - Solimo',
      reviewno: '358'
    },
    {
      id: 10,
      src: './assets/images/gl-home/daily/carousel4/10.jpg',
      price: '₹ 199',
      oldprice: '₹ 366',
      name: 'Amazon Brand - Presto! Toilet Cleaner - 1 L (Pack of 2)',
      brand: 'Presto!',
      reviewno: '3,454'
    },
  ]

  customOptions5: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore5 = [
    {
      id: 1,
      src: './assets/images/gl-home/daily/carousel5/1.jpg',
      price: '₹ 319',
      oldprice: '₹ 599',
      name: 'Ross Hair Scalp Massager Shampoo Brush',
      brand: 'Ross',
      reviewno: '1,487'
    },
    {
      id: 2,
      src: './assets/images/gl-home/daily/carousel5/2.jpg',
      price: '₹ 209',
      oldprice: '₹ 400',
      name: 'Amazon Brand - Solimo Bhringraj Hair Oil 2 X 100ml, 100% Natural, Ayurvedic Proprietary Medicine, Free from Parabens, Sulphates & Mineral Oils',
      brand: 'Amazon Brand - Solimo',
      reviewno: '54'
    },
    {
      id: 3,
      src: './assets/images/gl-home/daily/carousel5/3.jpg',
      price: '₹ 240',
      oldprice: '₹ 400',
      name: 'Amazon Brand - Solimo Neem Facewash with Aloe Vera & Cucumber Extracts, SLES & Paraben Free, 2 X 150 ml',
      brand: 'Amazon Brand - Solimo',
      reviewno: '47'
    },
    {
      id: 4,
      src: './assets/images/gl-home/daily/carousel5/4.jpg',
      price: '₹ 152',
      oldprice: '₹ 400',
      name: 'Amazon Brand - Solimo Handmade Black Pin-Tail Comb, 23 cm x 3 cm',
      brand: 'Amazon Brand - Solimo',
      reviewno: '1'
    },
    {
      id: 5,
      src: './assets/images/gl-home/daily/carousel5/5.jpg',
      price: '₹ 349',
      oldprice: '₹ 350',
      name: 'Amazon Brand - Solimo Body Butter - Chocolate - 200 gms',
      brand: 'Amazon Brand - Solimo',
      reviewno: '6'
    },
    {
      id: 6,
      src: './assets/images/gl-home/daily/carousel5/6.jpg',
      price: '₹ 359',
      oldprice: '',
      name: 'Amazon Brand - Solimo Hair fall Control Shampoo with Bhringraj Extracts, 500 ml (Pack of 2)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '5'
    },
    {
      id: 7,
      src: './assets/images/gl-home/daily/carousel5/7.jpg',
      price: '₹ 159',
      oldprice: '₹ 350',
      name: 'Amazon Brand - Solimo Micellar Water, 200ml',
      brand: 'Amazon Brand - Solimo',
      reviewno: '5'
    },
    {
      id: 8,
      src: './assets/images/gl-home/daily/carousel5/8.jpg',
      price: '₹ 229',
      oldprice: '₹ 500',
      name: 'Amazon Brand - The Shavery Beard Growth Oil - 50ml',
      brand: 'Amazon Brand - The Shavery',
      reviewno: '6'
    },
    {
      id: 9,
      src: './assets/images/gl-home/daily/carousel5/9.jpg',
      price: '₹ 225',
      oldprice: '₹ 400',
      name: 'Amazon Brand - Solimo Musk Body Lotion, 500 ml',
      brand: 'Amazon Brand - Solimo',
      reviewno: '59'
    },
    {
      id: 10,
      src: './assets/images/gl-home/daily/carousel5/10.jpg',
      price: '₹ 159',
      oldprice: '₹ 350',
      name: 'Amazon Brand - Solimo Henna Hair Cream (300 ml)',
      brand: 'Amazon Brand - Solimo',
      reviewno: '134'
    },
  ]

  customOptions6: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore6 = [
    {
      id: 1,
      src: './assets/images/gl-home/daily/carousel6/1.jpg',
      price: '₹ 399',
      oldprice: '₹ 795',
      name: 'AmazonBasics Tie-Out Cable/Leash for Dogs up to 57 Kg, 30 Feet',
      brand: 'AmazonBasics',
      reviewno: '8,845'
    },
    {
      id: 2,
      src: './assets/images/gl-home/daily/carousel6/2.jpg',
      price: '₹ 399',
      oldprice: '₹ 999',
      name: 'Mellifluous Reversible Super Soft Velvet Round Cat Dog Pet Bed Diameter 61 cms Height 15 cms S (Small, Brown Cream)',
      brand: 'Mellifluous',
      reviewno: '3'
    },
    {
      id: 3,
      src: './assets/images/gl-home/daily/carousel6/3.jpg',
      price: '₹ 389',
      oldprice: '₹ 999',
      name: 'Mellifluous Small Size Dog and Cat Fur Pet Bed, Black',
      brand: 'Mellifluous',
      reviewno: '245'
    },
    {
      id: 4,
      src: './assets/images/gl-home/daily/carousel6/4.jpg',
      price: '₹ 659',
      oldprice: '₹ 1,000',
      name: 'AmazonBasics Self-Dispensing Gravity Pet Waterer, Small',
      brand: 'AmazonBasics',
      reviewno: '12,707'
    },
    {
      id: 5,
      src: './assets/images/gl-home/daily/carousel6/5.jpg',
      price: '₹ 999',
      oldprice: '₹ 1,900',
      name: 'AmazonBasics Round Bolster Pet Dog Bed - 20 x 6 Inches, Brown',
      brand: 'AmazonBasics',
      reviewno: '7,038'
    },
    {
      id: 6,
      src: './assets/images/gl-home/daily/carousel6/6.jpg',
      price: '₹ 999',
      oldprice: '₹ 1,900',
      name: 'AmazonBasics Waterproof Car Back Bench Seat Cover Protector for Pets - 56 x 47, Black',
      brand: 'AmazonBasics',
      reviewno: '6,356'
    },
    {
      id: 7,
      src: './assets/images/gl-home/daily/carousel6/7.jpg',
      price: '₹ 2,999',
      oldprice: '₹ 5,500',
      name: 'AmazonBasics Foldable Metal Pet Dog Exercise Fence Pen With Gate - 60 x 60 x 24 Inches',
      brand: 'AmazonBasics',
      reviewno: '2,567'
    },
    {
      id: 8,
      src: './assets/images/gl-home/daily/carousel6/8.jpg',
      price: '₹ 1,899',
      oldprice: '₹ 4,299',
      name: 'Mellifluous XX-Large Size Cat and Dog Dual Color Pet Bed, Grey-Black',
      brand: 'Mellifluous',
      reviewno: '80'
    },
    {
      id: 9,
      src: './assets/images/gl-home/daily/carousel6/9.jpg',
      price: '₹ 899',
      oldprice: '₹ 2,500',
      name: 'AmazonBasics Dog Diaper, Large - Pack of 30',
      brand: 'AmazonBasics',
      reviewno: '701'
    },
    {
      id: 10,
      src: './assets/images/gl-home/daily/carousel6/10.jpg',
      price: '₹ 2,400',
      oldprice: '',
      name: 'AmazonBasics Elevated Portable Pet House - Medium, Black',
      brand: 'AmazonBasics',
      reviewno: '218'
    },
  ]
  customOptions7: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore7 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel1/1.jpg',
      text: 'Emporium Car Back Pain Relief Lower Back Support for Chair Back Rest for Office Chair Lumbar…',
      reviewno: '242',
      price: '₹ 349.00'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel1/2.jpg',
      text: 'BLUEWUD Clonard Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '242',
      price: '₹ 2,899.00'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel1/3.jpg',
      text: 'BLUEWUD Mallium Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '331',
      price: '₹ 3,339.00'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel1/4.jpg',
      text: 'OGU Om Cotton Round Batti Wicks for Pooja Aarti (White) -2100 Pieces',
      reviewno: '61',
      price: '₹ 250'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel1/5.jpg',
      text: 'pTron Bassbuds Pro in-Ear True Wireless Bluetooth 5.0 Headphones with Deep Bass, Touch Control…',
      reviewno: '8,370',
      price: '₹ 1,299.00'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel1/6.jpg',
      text: 'HealthSense Soft-Spot BC 21 Orthopedic Backrest Cushion with Memory Foam for Study, Home,…',
      reviewno: '931',
      price: '₹ 1,399.00'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel1/7.jpg',
      text: 'Nutriplato-enriching lives Roasted Salted Flax Seeds, 500 g',
      reviewno: '325',
      price: '₹ 199.00'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel1/8.jpg',
      text: 'ADOC Chairs Oren Medium Back Ergonomic Office Chair with Mesh Back, Fixed Comfortable…',
      reviewno: '58',
      price: '₹ 3,049.00'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel1/9.jpg',
      text: 'Angel Bear 1 Cup Toothbrush Toothpaste Stand Holder Bathroom Storage Organizer,Plastic',
      reviewno: '379',
      price: '₹ 249.00'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel1/10.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '165',
      price: '₹ 3,999.00'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel1/11.jpg',
      text: 'TIZUM High Speed HDMI Cable with Ethernet - Supports 3D, 4K and Audio Return (1.8 Meter/ 6 Feet)',
      reviewno: '2,281',
      price: '₹ 145.00'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel1/12.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '30',
      price: '₹ 3,799.00'
    },
  ]
  customOptions8: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore8 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel2/1.jpeg',
      text: 'VPN Software & VPN free unlimited (Fire TV, 4k sticks)',
      reviewno: '1,108',
      price: 'BackySoft'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel2/2.jpg',
      text: 'AirScreen - AirPlay & Google Cast & Miracast & DLNA',
      reviewno: '34,550',
      price: '重庆爱奥尼科技有限公司'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel2/3.jpeg',
      text: 'Trashy Movies Channel',
      reviewno: '1,561',
      price: 'POV Horror'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel2/4.jpeg',
      text: 'Wicked Horror TV',
      reviewno: '3,961',
      price: 'POV Horror'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel2/5.jpeg',
      text: 'Trashy Horror Movies',
      reviewno: '1,375',
      price: 'POV Horror'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel2/6.jpeg',
      text: 'B-Movie Club',
      reviewno: '738',
      price: 'POV Horror'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel2/7.jpeg',
      text: 'VPN Lite & Full Privacy (RVPN)',
      reviewno: '358',
      price: 'BackySoft'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel2/8.jpg',
      text: 'Plex: Stream Movies, Shows, Live TV, Music, and More',
      reviewno: '51,727',
      price: 'Plex'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel2/9.jpeg',
      text: 'Martial Arts and Kung-Fu Movie Channel',
      reviewno: '4,629',
      price: 'POV Horror'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel2/10.jpg',
      text: 'NordVPN - Fast, Secure and Unlimited VPN app for Android. Stay secure and…',
      reviewno: '21,882',
      price: 'nordvpn s.a.'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel2/11.jpeg',
      text: 'Thriller Movie Channel',
      reviewno: '232',
      price: 'POV Horror'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel2/12.jpeg',
      text: 'Cleaner for Fire Tablets & TV (Cleanza)',
      reviewno: '6,219',
      price: 'Quickla Soft'
    },
  ]
  customOptions9: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 10,
    margin: 20,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      230: {
        items: 2
      },
      500: {
        items: 3
      },
      750: {
        items: 4
      },
      1000: {
        items: 5
      },
      1250: {
        items: 6
      },
    },
  }
  slidesStore9 = [
    {
      id: 1,
      src: './assets/images/gl-home/daily/carousel7/1.jpg',
      save: 'Save 5% ',
      name: 'Amazon Brand - Vedaka Premium Raw Chia Seeds, 200g',
      button: 'Collect Coupon'
    },
    {
      id: 2,
      src: './assets/images/gl-home/daily/carousel7/2.jpg',
      save: 'Save 5%',
      name: 'Amazon Brand - Vedaka Premium Whole Candied Blueberries, 200g',
      button: 'Collect Coupen'
    },
    {
      id: 3,
      src: './assets/images/gl-home/daily/carousel7/3.jpg',
      save: 'Save 5%',
      name: ' Hand Sanitizer Gel (72% Ethanol Absolute) - 500 ml (Pack of 2)',
      button: 'Collect Coupen'
    },
    {
      id: 4,
      src: './assets/images/gl-home/daily/carousel7/4.jpg',
      save: 'Save 5%',
      name: ' Hand Sanitizer Gel - 100 ml (Pack of 6, Lemon Oil)',
      button: 'Collect Coupen'
    },
    {
      id: 5,
      src: './assets/images/gl-home/daily/carousel7/5.jpg',
      save: 'Save 10%',
      name: 'Amazon Brand - Presto! Vegetable & Fruit Wash - 1 L',
      button: 'Collect Coupen'
    },
    {
      id: 6,
      src: './assets/images/gl-home/daily/carousel7/6.jpg',
      save: 'Save 5%',
      name: ' Acne Control Charcoal Peel-off Face Mask 100g',
      button: 'Collect Coupen'
    },
    {
      id: 7,
      src: './assets/images/gl-home/daily/carousel7/7.jpg',
      save: 'Save 5%',
      name: 'Amazon Brand - Mama Bear Natural Baby Cream - 200 gm',
      button: 'Collect Coupen'
    },
    {
      id: 8,
      src: './assets/images/gl-home/daily/carousel7/8.jpg',
      save: 'Save 5%',
      name: 'Amazon Brand - Mama Bear Natural Baby Shampoo - 400 ml',
      button: 'Collect Coupen'
    },
    {
      id: 9,
      src: './assets/images/gl-home/daily/carousel7/9.jpg',
      save: 'Save 5%',
      name: ' Bhringraj Hair Oil 2 X 100ml, 100% Natural, Ayurvedic Proprietary Medicine, Free from Parabens, Sulphates & Mineral Oils',
      button: 'Collect Coupen'
    },
    {
      id: 10,
      src: './assets/images/gl-home/daily/carousel7/10.jpg',
      save: 'Save 5%',
      name: ' Body Butter - Chocolate - 200 gms',
      button: 'Collect Coupen'
    },
    {
      id: 11,
      src: './assets/images/gl-home/daily/carousel7/11.jpg',
      save: 'Save 5%',
      name: ' Body Butter - Green Tea - 200 gms',
      button: 'Collect Coupen'
    },
    {
      id: 12,
      src: './assets/images/gl-home/daily/carousel7/12.jpg',
      save: 'Save 5%',
      name: 'Amazon Brand - Mama Bear Plant Based Baby Liquid Cleanser - 1 L (For baby bottles, accessories, toys, fruits & vegetables)',
      button: 'Collect Coupen'
    },
  ]

  customOptions10: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 10,
    margin: 40,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      230: {
        items: 2
      },
      500: {
        items: 3
      },
      750: {
        items: 4
      },
      1000: {
        items: 5
      },
      1250: {
        items: 6
      },
    },
  }
  slidesStore10 = [
    {
      id: 1,
      src: './assets/images/gl-home/daily/carousel7/1.jpg',
      save: 'Save 5% ',
      name: 'Amazon Brand - Vedaka Premium Raw Chia Seeds, 200g',
      button: 'Collect Coupon'
    },
    {
      id: 2,
      src: './assets/images/gl-home/daily/carousel7/2.jpg',
      save: 'Save 5%',
      name: 'Amazon Brand - Vedaka Premium Whole Candied Blueberries, 200g',
      button: 'Collect Coupen'
    },
    {
      id: 3,
      src: './assets/images/gl-home/daily/carousel7/3.jpg',
      save: 'Save 5%',
      name: ' Hand Sanitizer Gel (72% Ethanol Absolute) - 500 ml (Pack of 2)',
      button: 'Collect Coupen'
    },
    {
      id: 4,
      src: './assets/images/gl-home/daily/carousel7/4.jpg',
      save: 'Save 5%',
      name: ' Hand Sanitizer Gel - 100 ml (Pack of 6, Lemon Oil)',
      button: 'Collect Coupen'
    },
    {
      id: 5,
      src: './assets/images/gl-home/daily/carousel7/5.jpg',
      save: 'Save 10%',
      name: 'Amazon Brand - Presto! Vegetable & Fruit Wash - 1 L',
      button: 'Collect Coupen'
    },
    {
      id: 6,
      src: './assets/images/gl-home/daily/carousel7/6.jpg',
      save: 'Save 5%',
      name: ' Acne Control Charcoal Peel-off Face Mask 100g',
      button: 'Collect Coupen'
    },
    {
      id: 7,
      src: './assets/images/gl-home/daily/carousel7/7.jpg',
      save: 'Save 5%',
      name: 'Amazon Brand - Mama Bear Natural Baby Cream - 200 gm',
      button: 'Collect Coupen'
    },
    {
      id: 8,
      src: './assets/images/gl-home/daily/carousel7/8.jpg',
      save: 'Save 5%',
      name: 'Amazon Brand - Mama Bear Natural Baby Shampoo - 400 ml',
      button: 'Collect Coupen'
    },
    {
      id: 9,
      src: './assets/images/gl-home/daily/carousel7/9.jpg',
      save: 'Save 5%',
      name: ' Bhringraj Hair Oil 2 X 100ml, 100% Natural, Ayurvedic Proprietary Medicine, Free from Parabens, Sulphates & Mineral Oils',
      button: 'Collect Coupen'
    },
    {
      id: 10,
      src: './assets/images/gl-home/daily/carousel7/10.jpg',
      save: 'Save 5%',
      name: ' Body Butter - Chocolate - 200 gms',
      button: 'Collect Coupen'
    },
    {
      id: 11,
      src: './assets/images/gl-home/daily/carousel7/11.jpg',
      save: 'Save 5%',
      name: ' Body Butter - Green Tea - 200 gms',
      button: 'Collect Coupen'
    },
    {
      id: 12,
      src: './assets/images/gl-home/daily/carousel7/12.jpg',
      save: 'Save 5%',
      name: 'Amazon Brand - Mama Bear Plant Based Baby Liquid Cleanser - 1 L (For baby bottles, accessories, toys, fruits & vegetables)',
      button: 'Collect Coupen'
    },
  ]

}
