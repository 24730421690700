<div class="container" fxLayout="column" fxLayoutGap="30px">
  <div class="cardpadding" fxLayout="column" fxLayoutAlign="start start">
    <p class="mat-title">Today's Deals</p>
    <p class="color-secondary">Great saving. Every Day. Shop from our Deal of the Day, Lightning Deals and avail
      other great offers.</p>
  </div>
  <div class="slider-items cardpadding" fxLayout="column" fxLayoutGap="20px">
    <p class="mat-title">Deal of the Day</p>
    <owl-carousel-o [options]="customOptions" class="cardpadding">
      <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
        <div fxLayout="column">
          <img [src]="slide.src" (click)="products()">
          <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" fxFlex="70" fxFlex>
            <div fxLayout="column">
              <span class="mat-title color-danger">{{slide.price}}</span>
              <span class="color-accent color-hover" (click)="products()">{{slide.text}}</span>
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star_border</mat-icon>
                <mat-icon>keyboard_arrow_down</mat-icon>
                <p class="color-accent color-hover">{{slide.reviewno}}</p>
              </div>
            </div>
            <div fxLayout="row" fxFlex="20">
              <button mat-raised-button color="primary">View Deal</button>
            </div>
          </div>
          <!-- <div fxLayout="column" fxLayoutAlign="space-between" fxFlex>
            <div fxLayout="column">
              <span class="mat-title color-danger">{{slide.price}}</span>
              <span class="color-accent color-hover">{{slide.text}}</span>
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star_border</mat-icon>
                <mat-icon>keyboard_arrow_down</mat-icon>
                <p class="color-accent color-hover">{{slide.reviewno}}</p>
              </div>
            </div>
            <div fxLayout="column">
              <button mat-raised-button color="primary">View Deal</button>
            </div>
          </div> -->
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
  <div>
    <mat-divider></mat-divider>
  </div>
  <div class="cardpadding" fxLayout="column" fxLayoutGap="30px">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
      <p class="mat-title">All Deals</p>
      <p class="mat-small padding-side"> Showing 1-32 of 9990 results for <span class="font-bold">Lightning Deals
          or Savings & Sales </span> </p>
    </div>
    <div fxLayout="row" fxFlex>
      <div class="cardpadding bg-secondary" fxLayout="column" fxFlex="22" fxLayoutGap="20px" fxHide.lt-md fxShow>
        <div fxLayout="column" fxLayoutGap="10px">
          <div fxLayout="column">
            <p class="mat-small font-bold">Department</p>
            <div *ngFor="let checkbox of checkboxes">
              <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">{{checkbox.name}}</mat-checkbox>
            </div>
          </div>
          <div fxLayout="column" fxHide>
            <p class="mat-small font-bold">Deal Type</p>
            <p class="mat-small color-accent">Clear</p>
            <p class="mat-small color-accent">Deal of the Day</p>
            <p class="mat-small color-accent font-bold">Lightning Deals</p>
            <p class="mat-small color-accent font-bold">Savings & Sales</p>
            <p class="mat-small color-accent">Coupons</p>
            <p class="mat-small color-accent">Prime Early Access Deals</p>
            <p class="mat-small color-accent">App Only Access Deals</p>
            <p class="mat-small color-accent">App Early Access Deals</p>
          </div>
          <div fxLayout="column">
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Active</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Missed</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Upcoming</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">price</p>
            <p class="mat-small color-hover">Under ₹1,000</p>
            <p class="mat-small color-hover">₹1,000 - ₹5,000</p>
            <p class="mat-small color-hover">₹5,000 - ₹10,000</p>
            <p class="mat-small color-hover">₹10,000 - ₹20,000</p>
            <p class="mat-small color-hover">Over ₹20,000</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Discount</p>
            <p class="mat-small color-hover">10% off or more</p>
            <p class="mat-small color-hover">25% off or more</p>
            <p class="mat-small color-hover">35% off or more</p>
            <p class="mat-small color-hover">50% off or more</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Avg. Customers Reviews</p>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex="78" fxFlex.lt-sm="100" fxFlex.lt-md="100">
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items1" fxFlex="33" fxFlex.lt-sm="100">
            <div class="cardpadding" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="10px"
              fxFlex="100">
              <div fxFlex.lt-sm="30" (click)="products()"> 
                <img src="{{item.image}}" alt="" class="full-width">
              </div>
              <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" fxFlex.lt-sm="70" fxFlex>
                <div fxLayout="column" fxFlex="80">
                  <p class="mat-h4 font-bold color-danger"> {{item.newprice}} </p>
                  <p class="mat-small"><span class="text-line-through mat-small">{{item.oldprice}}</span> <span
                      class="mat-small">{{item.saving}}</span></p>
                  <p class="color-accent color-hover" (click)="products()">{{item.name}}</p>
                  
                </div>
                <div fxLayout="row" fxFlex="20">
                  <button mat-raised-button class="bg-secondary full-width" (click)="products()">Learn More</button>
                </div>
              </div>
            </div>
            <div>
              <mat-divider [vertical]="true" class="full-height"></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items2" fxFlex="33" fxFlex.lt-sm="100">
            <div class="cardpadding" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="10px"
              fxFlex="100">
              <div fxFlex.lt-sm="30" (click)="products()"> 
                <img src="{{item.image}}" alt="" class="full-width">
              </div>
              <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" fxFlex.lt-sm="70" fxFlex>
                <div fxLayout="column" fxFlex="80">
                  <p class="mat-h4 font-bold color-danger"> {{item.newprice}} </p>
                  <p class="mat-small"><span class="text-line-through mat-small">{{item.oldprice}}</span> <span
                      class="mat-small">{{item.saving}}</span></p>
                  <p class="color-accent color-hover" (click)="products()">{{item.name}}</p>
                  
                </div>
                <div fxLayout="row" fxFlex="20">
                  <button mat-raised-button class="bg-secondary full-width" (click)="products()">Learn More</button>
                </div>
              </div>
            </div>
            <div>
              <mat-divider [vertical]="true" class="full-height"></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items3" fxFlex="33" fxFlex.lt-sm="100">
            <div class="cardpadding" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="10px"
              fxFlex="100">
              <div fxFlex.lt-sm="30" (click)="products()"> 
                <img src="{{item.image}}" alt="" class="full-width">
              </div>
              <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" fxFlex.lt-sm="70" fxFlex>
                <div fxLayout="column" fxFlex="80">
                  <p class="mat-h4 font-bold color-danger"> {{item.newprice}} </p>
                  <p class="mat-small"><span class="text-line-through mat-small">{{item.oldprice}}</span> <span
                      class="mat-small">{{item.saving}}</span></p>
                  <p class="color-accent color-hover" (click)="products()">{{item.name}}</p>
                  
                </div>
                <div fxLayout="row" fxFlex="20">
                  <button mat-raised-button class="bg-secondary full-width" (click)="products()">Learn More</button>
                </div>
              </div>
            </div>
            <div>
              <mat-divider [vertical]="true" class="full-height"></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items4" fxFlex="33" fxFlex.lt-sm="100">
            <div class="cardpadding" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="10px"
              fxFlex="100">
              <div fxFlex.lt-sm="30" (click)="products()"> 
                <img src="{{item.image}}" alt="" class="full-width">
              </div>
              <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" fxFlex.lt-sm="70" fxFlex>
                <div fxLayout="column" fxFlex="80">
                  <p class="mat-h4 font-bold color-danger"> {{item.newprice}} </p>
                  <p class="mat-small"><span class="text-line-through mat-small">{{item.oldprice}}</span> <span
                      class="mat-small">{{item.saving}}</span></p>
                  <p class="color-accent color-hover" (click)="products()">{{item.name}}</p>
                  
                </div>
                <div fxLayout="row" fxFlex="20">
                  <button mat-raised-button class="bg-secondary full-width" (click)="products()">Learn More</button>
                </div>
              </div>
            </div>
            <div>
              <mat-divider [vertical]="true" class="full-height"></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items1" fxFlex="33" fxFlex.lt-sm="100">
            <div class="cardpadding" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="10px"
              fxFlex="100">
              <div fxFlex.lt-sm="30" (click)="products()"> 
                <img src="{{item.image}}" alt="" class="full-width">
              </div>
              <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" fxFlex.lt-sm="70" fxFlex>
                <div fxLayout="column" fxFlex="80">
                  <p class="mat-h4 font-bold color-danger"> {{item.newprice}} </p>
                  <p class="mat-small"><span class="text-line-through mat-small">{{item.oldprice}}</span> <span
                      class="mat-small">{{item.saving}}</span></p>
                  <p class="color-accent color-hover" (click)="products()">{{item.name}}</p>
                  
                </div>
                <div fxLayout="row" fxFlex="20">
                  <button mat-raised-button class="bg-secondary full-width" (click)="products()">Learn More</button>
                </div>
              </div>
            </div>
            <div>
              <mat-divider [vertical]="true" class="full-height"></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items2" fxFlex="33" fxFlex.lt-sm="100">
            <div class="cardpadding" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="10px"
              fxFlex="100">
              <div fxFlex.lt-sm="30" (click)="products()"> 
                <img src="{{item.image}}" alt="" class="full-width">
              </div>
              <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" fxFlex.lt-sm="70" fxFlex>
                <div fxLayout="column" fxFlex="80">
                  <p class="mat-h4 font-bold color-danger"> {{item.newprice}} </p>
                  <p class="mat-small"><span class="text-line-through mat-small">{{item.oldprice}}</span> <span
                      class="mat-small">{{item.saving}}</span></p>
                  <p class="color-accent color-hover" (click)="products()">{{item.name}}</p>
                  
                </div>
                <div fxLayout="row" fxFlex="20">
                  <button mat-raised-button class="bg-secondary full-width" (click)="products()">Learn More</button>
                </div>
              </div>
            </div>
            <div>
              <mat-divider [vertical]="true" class="full-height"></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items3" fxFlex="33" fxFlex.lt-sm="100">
            <div class="cardpadding" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="10px"
              fxFlex="100">
              <div fxFlex.lt-sm="30" (click)="products()"> 
                <img src="{{item.image}}" alt="" class="full-width">
              </div>
              <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" fxFlex.lt-sm="70" fxFlex>
                <div fxLayout="column" fxFlex="80">
                  <p class="mat-h4 font-bold color-danger"> {{item.newprice}} </p>
                  <p class="mat-small"><span class="text-line-through mat-small">{{item.oldprice}}</span> <span
                      class="mat-small">{{item.saving}}</span></p>
                  <p class="color-accent color-hover" (click)="products()">{{item.name}}</p>
                  
                </div>
                <div fxLayout="row" fxFlex="20">
                  <button mat-raised-button class="bg-secondary full-width" (click)="products()">Learn More</button>
                </div>
              </div>
            </div>
            <div>
              <mat-divider [vertical]="true" class="full-height"></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items4" fxFlex="33" fxFlex.lt-sm="100">
            <div class="cardpadding" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="10px"
              fxFlex="100">
              <div fxFlex.lt-sm="30" (click)="products()"> 
                <img src="{{item.image}}" alt="" class="full-width">
              </div>
              <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="20px" fxFlex.lt-sm="70" fxFlex>
                <div fxLayout="column" fxFlex="80">
                  <p class="mat-h4 font-bold color-danger"> {{item.newprice}} </p>
                  <p class="mat-small"><span class="text-line-through mat-small">{{item.oldprice}}</span> <span
                      class="mat-small">{{item.saving}}</span></p>
                  <p class="color-accent color-hover" (click)="products()">{{item.name}}</p>
                  
                </div>
                <div fxLayout="row" fxFlex="20">
                  <button mat-raised-button class="bg-secondary full-width" (click)="products()">Learn More</button>
                </div>
              </div>
            </div>
            <div>
              <mat-divider [vertical]="true" class="full-height"></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
  </div>
  <div class="cardpadding" fxLayout="row" fxHide>
    <img src="assets/images/today-deal/lastbanner.png" alt="" fxFlex="30">
  </div>
</div>