<div class="container" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column">
    <div class="cardpadding" fxLayout="column" fxLayoutAlign="center center" fxHide fxShow.gt-sm>
      <p class="mat-title">Computers & Accessories</p>
      <p class="text-center">Welcome to the computers and accessories store. Shop from a wide selection of devices like
        <a class="color-accent text-underline">Laptops</a>,
        <a class="color-accent text-underline">Printers</a>,
        <a class="color-accent text-underline">Routers</a> and
        <a class="color-accent text-underline">Tablets</a>, to accessories like
        <a class="color-accent text-underline">Hard Drives</a>,
        <a class="color-accent text-underline">Memory Cards</a>,
        <a class="color-accent text-underline">Pen Drives</a>,
        <a class="color-accent text-underline">Keyboards</a>,
        <a class="color-accent text-underline">Mics</a> and more. Find the latest in
        <a class="color-accent text-underline">technology</a> with
        <a class="color-accent text-underline">Gaming</a>,
        <a class="color-accent text-underline">Solid state drives</a>,
        <a class="color-accent text-underline">Wearable devices</a>.
      </p>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxShow.lt-sm fxHide>
      <p class="mat-headline text-center">New & upcoming launches in Computers, Laptops & Accessories</p>
    </div>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxLayoutGap="20px" fxHide.lt-sm fxShow>
      <div fxLayout="column" fxLayoutGap="10px">
        <p class="mat-small font-bold">Department</p>
        <div class="padding-side">
          <div fxLayout="column">
            <p class="mat-small font-bold">Computers & Accessories</p>
          </div>
          <div class="padding-side" fxLayout="column">
            <p class="mat-small color-hover">Accessories & Peripherals</p>
            <p class="mat-small color-hover">Components</p>
            <p class="mat-small color-hover">Desktops</p>
            <p class="mat-small color-hover">External Devices & Data</p>
            <p class="mat-small color-hover">Storage</p>
            <p class="mat-small color-hover">Laptops</p>
            <p class="mat-small color-hover">Monitors</p>
            <p class="mat-small color-hover">Networking Devices</p>
            <p class="mat-small color-hover">Printers, Inks & Accessories</p>
            <p class="mat-small color-hover">Scanners</p>
            <p class="mat-small color-hover">Tablets</p>
            <p class="mat-small color-hover">Warranties</p>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="column">
          <p class="mat-small font-bold">Pay on Delivery</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Eligible for Pay On
            Delivery</mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Brands</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">MI</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Noise</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">SanDisk</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Boat</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">PTron</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Logitech</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Boya</mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Avg. Customers Reviews</p>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <p class="mat-small color-hover"> & up</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <p class="mat-small color-hover"> & up</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <p class="mat-small color-hover"> & up</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <p class="mat-small color-hover"> & up</p>
          </div>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">New Arrivals</p>
          <p class="mat-small color-hover">Last 30 days</p>
          <p class="mat-small color-hover">Last 90 days</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Item Condition <span class="mat-h5">(What's this?)</span></p>
          <p class="mat-small color-hover">New</p>
          <p class="mat-small color-hover">Renewed</p>
          <p class="mat-small color-hover">Used</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">price</p>
          <p class="mat-small color-hover">Under ₹1,000</p>
          <p class="mat-small color-hover">₹1,000 - ₹5,000</p>
          <p class="mat-small color-hover">₹5,000 - ₹10,000</p>
          <p class="mat-small color-hover">₹10,000 - ₹20,000</p>
          <p class="mat-small color-hover">Over ₹20,000</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Deals</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Today's Deals
          </mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Discount</p>
          <p class="mat-small color-hover">10% off or more</p>
          <p class="mat-small color-hover">25% off or more</p>
          <p class="mat-small color-hover">35% off or more</p>
          <p class="mat-small color-hover">50% off or more</p>
        </div>
        <!-- <div fxLayout="column">
          <p class="mat-small font-bold">Seller</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Appario Retails Private Ltd.
          </mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cloudtail India</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cart2India Online</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Card2India SLP</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Global Klamp</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">BENEDICTIONS</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Computeronics Multivision Pvt. Ltd.
          </mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Spigen India</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">STS Shop</mat-checkbox>
        </div> -->
        <div fxLayout="column">
          <p class="mat-small font-bold">Availability</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Include Out of Stock
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div>
      <mat-divider [vertical]="true" fxFlex="100"></mat-divider>
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
      <div fxLayout="row">
        <img src="assets/images/electronics/computer/banner1.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <p class="mat-headline">FESTIVE DHAMAKA DEALS</p>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/computer/1.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/computer/2.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/computer/3.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/computer/4.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/computer/5.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/computer/6.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/computer/7.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/computer/8.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/computer/9.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/computer/10.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/computer/11.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/computer/12.jpg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="row">
        <img src="assets/images/electronics/computer/banner2.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row">
        <img src="assets/images/electronics/computer/banner3.gif" alt="" class="full-width">
      </div>
      <div fxLayout="row">
        <img src="assets/images/electronics/computer/banner4.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
          <p class="mat-h2 padding-top">Top deals on storage devices</p>
          <mat-divider class="full-width"></mat-divider>
          <div>
            <owl-carousel-o [options]="customOptions1">
              <ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
                <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="5px" fxFlex>
                  <div fxLayout="row" (click)="products()">
                    <img [src]="slide.src">
                  </div>
                  <div fxLayout="column" fxLayoutAlign="start start">
                    <p class="mat-h4 color-accent" (click)="products()">{{slide.name}}</p>
                    <p class="mat-small">{{slide.brand}}</p>
                    <p class="mat-h4">{{slide.price}} <span
                        class="mat-small text-line-through">{{slide.oldprice}}</span>
                    </p>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <mat-icon color="primary">star</mat-icon>
                      <mat-icon color="primary">star</mat-icon>
                      <mat-icon color="primary">star</mat-icon>
                      <mat-icon color="primary">star</mat-icon>
                      <mat-icon color="primary">star_border</mat-icon>
                      <mat-icon>keyboard_arrow_down</mat-icon>
                      <p class="color-accent color-hover">{{slide.reviewno}}</p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>

        <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
          <p class="mat-h2 padding-top">Top deals on printers & ink supplies</p>
          <mat-divider class="full-width"></mat-divider>
          <div>
            <owl-carousel-o [options]="customOptions2">
              <ng-template carouselSlide *ngFor="let slide of slidesStore2" [id]="slide.id">
                <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="5px" fxFlex>
                  <div fxLayout="row" (click)="products()">
                    <img [src]="slide.src">
                  </div>
                  <div fxLayout="column" fxLayoutAlign="start start">
                    <p class="mat-h4 color-accent" (click)="products()">{{slide.name}}</p>
                    <p class="mat-small">{{slide.brand}}</p>
                    <p class="mat-h4">{{slide.price}} <span
                        class="mat-small text-line-through">{{slide.oldprice}}</span>
                    </p>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <mat-icon color="primary">star</mat-icon>
                      <mat-icon color="primary">star</mat-icon>
                      <mat-icon color="primary">star</mat-icon>
                      <mat-icon color="primary">star</mat-icon>
                      <mat-icon color="primary">star_border</mat-icon>
                      <mat-icon>keyboard_arrow_down</mat-icon>
                      <p class="color-accent color-hover">{{slide.reviewno}}</p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/computer/13.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/computer/14.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/computer/15.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/computer/16.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="row">
            <img src="assets/images/electronics/computer/banner5.jpg" alt="" class="full-width">
          </div>
          <div fxLayout="row">
            <img src="assets/images/electronics/computer/banner6.jpg" alt="" class="full-width">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/computer/17.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/computer/18.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="row">
            <img src="assets/images/electronics/computer/banner7.jpg" alt="" class="full-width">
          </div>
          <div fxLayout="row">
            <img src="assets/images/electronics/computer/banner8.gif" alt="" class="full-width">
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="40px">
          <div fxLayout="row" class="card-shadow cardpadding">
            <p class="mat-small">1-24 of over 50,000 results for <span class="font-bold color-hover">
                Electronics
              </span>: <span class="font-bold color-primary"> Computer & Accessories </span></p>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
            <div fxLayoutGap="20px" *ngFor="let item of items" fxFlex="33" fxFlex.lt-sm="100">
              <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
                <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                  <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
                </div>
                <div fxFlex.lt-sm="70">
                  <p class="mat-h4 color-secondary">Sponsored </p>
                  <p class="mat-h3 color-accent color-hover" (click)="products()">{{item.name}}</p>
                  <p class="mat-small">by {{item.company}}</p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{item.reviewno}}</p>
                  </div>
                  <p class="font-bold color-danger"> {{item.newprice}} <span
                      class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                  <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                  <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                  <p class="color-accent color-hover">See Details</p>
                </div>
              </div>
            </div>
          </div>
          <div fxHide.lt-sm>
            <mat-divider></mat-divider>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
            <div fxLayoutGap="20px" *ngFor="let item of items1" fxFlex="33" fxFlex.lt-sm="100">
              <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
                <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                  <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
                </div>
                <div fxFlex.lt-sm="70">
                  <p class="mat-h4 color-secondary">Sponsored </p>
                  <p class="mat-h3 color-accent color-hover" (click)="products()">{{item.name}}</p>
                  <p class="mat-small">by {{item.company}}</p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{item.reviewno}}</p>
                  </div>
                  <p class="font-bold color-danger"> {{item.newprice}} <span
                      class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                  <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                  <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                  <p class="color-accent color-hover">See Details</p>
                </div>
              </div>
              <div fxShow.lt-sm fxHide>
                <mat-divider></mat-divider>
              </div>
            </div>
          </div>
          <div fxHide.lt-sm>
            <mat-divider></mat-divider>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
            <div fxLayoutGap="20px" *ngFor="let item of items2" fxFlex="33" fxFlex.lt-sm="100">
              <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
                <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                  <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
                </div>
                <div fxFlex.lt-sm="70">
                  <p class="mat-h4 color-secondary">Sponsored </p>
                  <p class="mat-h3 color-accent color-hover" (click)="products()">{{item.name}}</p>
                  <p class="mat-small">by {{item.company}}</p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{item.reviewno}}</p>
                  </div>
                  <p class="font-bold color-danger"> {{item.newprice}} <span
                      class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                  <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                  <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                  <p class="color-accent color-hover">See Details</p>
                </div>
              </div>
              <div fxShow.lt-sm fxHide>
                <mat-divider></mat-divider>
              </div>
            </div>
          </div>
          <div fxHide.lt-sm>
            <mat-divider></mat-divider>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
            <div *ngFor="let item of items3" fxFlex="33" fxFlex.lt-sm="100">
              <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
                <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                  <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
                </div>
                <div fxFlex.lt-sm="70">
                  <p class="mat-h4 color-secondary">Sponsored </p>
                  <p class="mat-h3 color-accent color-hover" (click)="products()">{{item.name}}</p>
                  <p class="mat-small">by {{item.company}}</p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{item.reviewno}}</p>
                  </div>
                  <p class="font-bold color-danger"> {{item.newprice}} <span
                      class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                  <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                  <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                  <p class="color-accent color-hover">See Details</p>
                </div>
              </div>
              <div fxShow.lt-sm fxHide>
                <mat-divider></mat-divider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cardpadding" fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
      <p class="mat-title color-primary">Explore Computer & Accessories on Global Local</p>
      <p class="mat-small">Global Local offers a wide range of Computers and Accessories that you can <span class="color-accent color-hover"> buy online</span>.. Explore
        Laptops, Tablets, Data Storage, Printers, Routers and many more computing devices. We make your online shopping
        experience easier as you can buy the laptop of your choice by filtering on the basis of price, brands and
        features. Find tablets with exciting features such as 3G, Android operating system, voice calling or many more.
        We bring tablets from popular brands such as Apple, Samsung, Micromax and others. Global Local offers a selection
        of computer accessories such as Keyboards, Mice, Networking Devices, Memory cards, Webcams, Pen Drives, Laptop
        Covers and much more. Find a convenient way to store your music, pictures and other data with our wide selection
        of Pen Drives and External Hard Drives from brands such as Seagate, Transcend, Western Digital (WD), Sony,
        Buffalo, Kingston and more. Global Local believe in enhancing your shopping experience through customer reviews and
        personalised recommendations based on your browsing and purchasing history.</p>
    </div>
  </div>
</div>