import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  hidecon = true;
  hide = true;
  public forgotForm: FormGroup;
  public password;
  public c_password;

  constructor(
    public fb: FormBuilder,
    public router: Router) { }

  ngOnInit(): void {
    this.forgotForm = this.fb.group({

      password: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[a-zA-Z0-9\d$@$!%*?&].{8,}')

      ]),
      c_password: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[a-zA-Z0-9\d$@$!%*?&].{8,}'),
        this.checkPasswords()
      ]),
    });
    this.password = this.forgotForm.get('password');
    this.c_password = this.forgotForm.get('c_password');

  }
  checkPasswords(): ValidatorFn {
    //see that the argument is a FormControl
    return (control: FormControl): ValidationErrors => {
      //the formGroup is control.parent
      const group = control.parent as FormGroup;
      //but we must sure that is defined
      if (!group) return null;
      console.log(group.get("password").value);
      let pass = group.get("password").value;
      let confirmPass = group.get("c_password").value;

      return confirmPass ? pass === confirmPass ? null : { mismatch: true } : null;
    };
  }
  get f() { return this.forgotForm.controls; }
  public Send() {
    if (this.forgotForm.invalid) {
      return;
    }
    this.router.navigate(['/login'])
    localStorage.removeItem("forgotusername")

  }

  public onInputChange(event) {
    event.target.required = true;
  }

}
