import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-best-seller',
  templateUrl: './best-seller.component.html',
  styleUrls: ['./best-seller.component.scss']
})
export class BestSellerComponent implements OnInit {
  items = [
    { number: '1', image: 'assets/images/best-seller/book1.jpg', name: 'Ikigai: The Japanese secret to a long and happy life', company: 'Héctor García', reviewno: '6,689' },
    { number: '2', image: 'assets/images/best-seller/book2.jpg', name: 'My First Library: Boxset of 10 Board Books for Kids', company: 'Wonder House Books', reviewno: '10,458' },
    { number: '3', image: 'assets/images/best-seller/book3.jpg', name: 'Think Like a Monk', company: 'Jay Shetty', reviewno: '5,197' },
  ];
  items1 = [
    { image: 'assets/images/gift-card/card/1.webp', name: 'Google Play recharge code', company: 'Google Play', price: '₹ 100', reviewno: '1,652' },
    { image: 'assets/images/gift-card/card/2.webp', name: 'Amazon Pay eGift Card', company: 'Amazon Pay', price: '₹ 10 - ₹ 10,000', reviewno: '31,817' },
    { image: 'assets/images/gift-card/card/3.webp', name: 'Google Play Gift Code - Digital Voucher', company: 'Google Play', price: '₹ 100 - ₹ 1,500', reviewno: '6,685' },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
