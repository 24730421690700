import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-clothing-essentials',
  templateUrl: './clothing-essentials.component.html',
  styleUrls: ['./clothing-essentials.component.scss']
})
export class ClothingEssentialsComponent implements OnInit {

  items = [
    { image: 'assets/images/gl-home/clothing/card/1.jpg', name: 'Bourge Men\'s Vega-1 Running Shoes', company: 'Bourge', newprice: '₹ 539', oldprice: '₹ 999', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Sunday, Dec 27', reviewno: '4,569' },
    { image: 'assets/images/gl-home/clothing/card/2.jpg', name: 'Bourge Men\'s Loire-63 Running Shoes', company: 'Bourge', newprice: '₹ 479', oldprice: '₹ 1,499', freedelivery: 'FREE Delivery', upto: 'No Minimum order value for first order in this category', deliverydate: 'Sunday, Dec 27', reviewno: '1,382' },
    { image: 'assets/images/gl-home/clothing/card/3.jpg', name: 'Antonella Rossi Analog Blue Dial Unisex\'s Watch-SS190356', company: 'Antonella Rossi', newprice: '₹ 291', oldprice: '₹ 1,599', freedelivery: 'FREE Delivery', upto: 'No Minimum order value for first order in this category', deliverydate: 'Sunday, Dec 27', reviewno: '256' },
  ];
  items1 = [
    { image: 'assets/images/gl-home/clothing/card/4.jpg', name: 'Amazon Brand - Eden & Ivy Women\'s Handbag (Pink)', company: 'Eden & Ivy', newprice: '₹ 699', oldprice: '₹ 1,999', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Sunday, Dec 27', reviewno: '22' },
    { image: 'assets/images/gl-home/clothing/card/5.jpg', name: 'Centrino Men\'s Formal Shoes', company: 'Centrino', newprice: '₹ 499', oldprice: '₹ 1,399', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Sunday, Dec 27', reviewno: '521' },
    { image: 'assets/images/gl-home/clothing/card/6.jpg', name: 'Diverse Men\'s Printed Regular Fit Full Sleeve Cotton Formal Shirt', company: 'Diverse', newprice: '₹ 499', oldprice: '₹ 1,199', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Sunday, Dec 27', reviewno: '5,550' },
  ];
  items2 = [
    { image: 'assets/images/gl-home/clothing/card/7.jpg', name: 'Amazon Brand - Jam & Honey Boy\'s Regular Fit T-Shirt', company: 'Jam & Honey', newprice: '₹ 299', oldprice: '₹ 998', freedelivery: 'FREE Delivery', upto: ' No Minimum order value for first order in this category', deliverydate: 'Sunday, Dec 27', reviewno: '244' },
    { image: 'assets/images/gl-home/clothing/card/8.jpg', name: 'Gully by Timex Swirl Analog White Dial Women\'s Watch-TW000U608', company: 'Timex', newprice: '₹ 699', oldprice: '₹ 1,799', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Sunday, Dec 27', reviewno: '61' },
    { image: 'assets/images/gl-home/clothing/card/9.jpg', name: 'Amazon Brand - Symbol Men\'s Slim Fit Stretchable Formal Trousers', company: 'Symbol', newprice: '₹ 599', oldprice: '₹ 1,399', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Sunday, Dec 27', reviewno: '565' },
  ];
  items3 = [
    { image: 'assets/images/gl-home/clothing/card/10.jpg', name: 'Koel by Lavie Rhone Women\'s Sling Bag with No (Pewter)', company: 'Koel by Lavie', newprice: '₹ 673', oldprice: '₹ 2,299', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Sunday, Dec 27', reviewno: '26' },
    { image: 'assets/images/gl-home/clothing/card/11.jpg', name: 'Bourge Women\'s Micam-115 Running Shoes', company: 'Bourge', newprice: '₹ 669', oldprice: '₹ 1,499', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Sunday, Dec 27', reviewno: '515' },
    { image: 'assets/images/gl-home/clothing/card/12.jpg', name: 'Centrino Men\'s 9383 Formal Shoes', company: 'Centrino', newprice: '₹ 499', oldprice: '₹ 1,399', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Sunday, Dec 27', reviewno: '1,835' },
  ];

  constructor( public router: Router,) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }

  checkboxes = [
    { name: 'Bourge' }, { name: 'Diverse' }, { name: 'Symbol' }, { name: 'AKA CHIC' }, { name: 'Inkast Denim Co.' }, { name: 'Cazibe' }, { name: 'KRAVE' }, { name: 'Longies' }, { name: 'Neostreak' },
    { name: 'Antonella Rossi' }, { name: 'Koel by Lavie' }, { name: 'Isle Locada by Hidesign' }, { name: 'Fusefit' }, { name: 'Centrino' }, { name: 'Hang & Wear' }, { name: 'Eden & Ivy' }, { name: 'COTTON SHOPY' }, { name: 'Styleville.in' },
    { name: 'Gully by Timex' }, { name: 'Nelle Harper' }, { name: 'Symactive' }, { name: 'Myx' }, { name: 'Indigo' }, { name: 'Deniklo' }, { name: 'House & Shields' }, { name: 'Flavia' }, { name: 'Miss Olive' },
    { name: 'Nia & Nicole' }, { name: 'Tavasya' }, { name: 'KLOOK' }, { name: 'Jam & Honey' }, { name: 'Burwood' }, { name: 'Extacy By Red Chief' }, { name: 'Nayak' }, { name: 'Indi lite' }, { name: 'Ayukta' },
    { name: 'Gubbarey' }, { name: 'MyBlush' }, { name: 'Safana' }, { name: 'ELISE' }, { name: 'Novarc' }, { name: 'Avah' }, { name: 'Arthur Harvey' }, { name: 'Buttercups' }, { name: 'SLVETE' },
    { name: 'Elfin' }, { name: '6 Degrees' }, { name: 'Slyck' }, { name: 'GET IN' }, { name: 'Zemyra' }, { name: 'Endeavor' }, { name: 'WOKNIT' }, { name: 'BRITTON' }, { name: 'Womanista' },
    { name: 'Molly & Sue' }, { name: 'Aalia' }, { name: 'IN TRY' }, { name: 'Acteo' }, { name: 'Distrito Urbano' }, { name: 'Camelio' }, { name: 'Momstory' }, { name: 'Smiling Bows' }, { name: 'VIBTAG' },
    { name: 'WEATTIRE' }, { name: 'GLORYBOYZ' }, { name: 'Pinkmint' }, { name: 'Plan A' }, { name: 'RJCo' }, { name: 'Accelrun' }, { name: 'Glammore' }, { name: 'CLOTHAM' },
    { name: 'Old Coast' }, { name: 'Pink by Juniper' }, { name: 'MALIQUA' }, { name: 'Drapish' }, { name: 'IDIKA' }, { name: 'FashionRiti' },
  ]

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel1/1.jpg',
      text: 'Emporium Car Back Pain Relief Lower Back Support for Chair Back Rest for Office Chair Lumbar…',
      reviewno: '242',
      price: '₹ 349.00'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel1/2.jpg',
      text: 'BLUEWUD Clonard Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '242',
      price: '₹ 2,899.00'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel1/3.jpg',
      text: 'BLUEWUD Mallium Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '331',
      price: '₹ 3,339.00'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel1/4.jpg',
      text: 'OGU Om Cotton Round Batti Wicks for Pooja Aarti (White) -2100 Pieces',
      reviewno: '61',
      price: '₹ 250'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel1/5.jpg',
      text: 'pTron Bassbuds Pro in-Ear True Wireless Bluetooth 5.0 Headphones with Deep Bass, Touch Control…',
      reviewno: '8,370',
      price: '₹ 1,299.00'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel1/6.jpg',
      text: 'HealthSense Soft-Spot BC 21 Orthopedic Backrest Cushion with Memory Foam for Study, Home,…',
      reviewno: '931',
      price: '₹ 1,399.00'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel1/7.jpg',
      text: 'Nutriplato-enriching lives Roasted Salted Flax Seeds, 500 g',
      reviewno: '325',
      price: '₹ 199.00'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel1/8.jpg',
      text: 'ADOC Chairs Oren Medium Back Ergonomic Office Chair with Mesh Back, Fixed Comfortable…',
      reviewno: '58',
      price: '₹ 3,049.00'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel1/9.jpg',
      text: 'Angel Bear 1 Cup Toothbrush Toothpaste Stand Holder Bathroom Storage Organizer,Plastic',
      reviewno: '379',
      price: '₹ 249.00'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel1/10.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '165',
      price: '₹ 3,999.00'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel1/11.jpg',
      text: 'TIZUM High Speed HDMI Cable with Ethernet - Supports 3D, 4K and Audio Return (1.8 Meter/ 6 Feet)',
      reviewno: '2,281',
      price: '₹ 145.00'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel1/12.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '30',
      price: '₹ 3,799.00'
    },
  ]
  customOptions1: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel2/1.jpeg',
      text: 'VPN Software & VPN free unlimited (Fire TV, 4k sticks)',
      reviewno: '1,108',
      price: 'BackySoft'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel2/2.jpg',
      text: 'AirScreen - AirPlay & Google Cast & Miracast & DLNA',
      reviewno: '34,550',
      price: '重庆爱奥尼科技有限公司'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel2/3.jpeg',
      text: 'Trashy Movies Channel',
      reviewno: '1,561',
      price: 'POV Horror'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel2/4.jpeg',
      text: 'Wicked Horror TV',
      reviewno: '3,961',
      price: 'POV Horror'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel2/5.jpeg',
      text: 'Trashy Horror Movies',
      reviewno: '1,375',
      price: 'POV Horror'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel2/6.jpeg',
      text: 'B-Movie Club',
      reviewno: '738',
      price: 'POV Horror'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel2/7.jpeg',
      text: 'VPN Lite & Full Privacy (RVPN)',
      reviewno: '358',
      price: 'BackySoft'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel2/8.jpg',
      text: 'Plex: Stream Movies, Shows, Live TV, Music, and More',
      reviewno: '51,727',
      price: 'Plex'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel2/9.jpeg',
      text: 'Martial Arts and Kung-Fu Movie Channel',
      reviewno: '4,629',
      price: 'POV Horror'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel2/10.jpg',
      text: 'NordVPN - Fast, Secure and Unlimited VPN app for Android. Stay secure and…',
      reviewno: '21,882',
      price: 'nordvpn s.a.'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel2/11.jpeg',
      text: 'Thriller Movie Channel',
      reviewno: '232',
      price: 'POV Horror'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel2/12.jpeg',
      text: 'Cleaner for Fire Tablets & TV (Cleanza)',
      reviewno: '6,219',
      price: 'Quickla Soft'
    },
  ]
}
