import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  lat: number;
  lng: number;
  getAddress: number;

  constructor() { }

  ngOnInit(): void {
  }
  location() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(pos => {
        this.lng = +pos.coords.longitude;
        this.lat = +pos.coords.latitude;
        this.getAddress = (this.lat, this.lng) 
      });
    }
  }
}
