<div class="container" [ngClass.gt-sm]="'cardpadding2'" [ngClass.lt-md]="'cardpadding'" fxLayout="column"
  fxLayoutGap="30px" fxHide.lt-sm>
  <div>
    <p class="mat-headline">Hello. What can we help you with?</p>
  </div>
  <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px" fxLayoutGap.lt-sm="0px">
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between" fxFlex>
      <div class="card-border cardpadding" fxLayout="row" fxFlex="40" fxLayoutGap="20px" routerLink="/returns-orders">
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="30">
          <img src="assets/images/account/1.png" alt="" class="full-width">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
          <p class="mat-title">Your Orders</p>
          <p class="mat-small">Track, return, or buy things again</p>
        </div>
      </div>
      <div class="card-border cardpadding" fxLayout="row" fxFlex="40" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="30">
          <img src="assets/images/account/2.png" alt="" class="full-width">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
          <p class="mat-title">Login & Security</p>
          <p class="mat-small">Edit login, name, and mobile number</p>
        </div>
      </div>
      <div class="card-border cardpadding" fxLayout="row" fxFlex="32" fxLayoutGap="20px" fxHide>
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="30">
          <img src="assets/images/account/3.png" alt="" class="full-width">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
          <p class="mat-title">Prime</p>
          <p class="mat-small">View benefits & payment setting</p>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between" fxFlex>
      <div class="card-border cardpadding" fxLayout="row" fxFlex="40" fxLayoutGap="20px" routerLink="/add-address">
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="30">
          <img src="assets/images/account/4.png" alt="" class="full-width">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
          <p class="mat-title">Your Addresss</p>
          <p class="mat-small">Edit addressses for orders and gifts</p>
        </div>
      </div>
      <div class="card-border cardpadding" fxLayout="row" fxFlex="40" fxLayoutGap="20px" routerLink="/payment">
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="30">
          <img src="assets/images/account/5.png" alt="" class="full-width">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
          <p class="mat-title">Payment Options</p>
          <p class="mat-small">Add or edit payment methods</p>
        </div>
      </div>
      <div class="card-border cardpadding" fxLayout="row" fxFlex="32" fxLayoutGap="20px" fxHide>
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="30">
          <img src="assets/images/account/6.png" alt="" class="full-width">
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
          <p class="mat-title">Pay Balance</p>
          <p class="mat-small">Add money to your account</p>
        </div>
      </div>
    </div>
  </div>
  <div>
    <mat-divider></mat-divider>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-around" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <div class="card-border1 cardpadding" fxLayout="column" fxFlex="32">
        <p class="mat-h3">Digital content and devices</p>
        <p class="mat-h4 color-accent color-hover">Apps and more</p>
        <p class="mat-h4 color-accent color-hover">Content and devices</p>
        <p class="mat-h4 color-accent color-hover">Digital gifts you've received</p>
      </div>
      <div class="card-border1 cardpadding" fxLayout="column" fxFlex="32">
        <p class="mat-h3">Email alerts, messages, and ads</p>
        <p class="mat-h4 color-accent color-hover">Advertising preferences</p>
        <p class="mat-h4 color-accent color-hover">Communication preferences</p>
        <p class="mat-h4 color-accent color-hover">SMS alert preferences</p>
        <p class="mat-h4 color-accent color-hover">Message center</p>
        <p class="mat-h4 color-accent color-hover">Alexa shopping notifications</p>
        <p class="mat-h4 color-accent color-hover">Deals Notifications</p>
      </div>
      <div class="card-border1 cardpadding" fxLayout="column" fxFlex="32">
        <p class="mat-h3">More ways to pay</p>
        <p class="mat-h4 color-accent color-hover">Default Purchase Settings</p>
        <p class="mat-h4 color-accent color-hover">Global Local Pay</p>
        <p class="mat-h4 color-accent color-hover">Bank accounts for refunds</p>
        <p class="mat-h4 color-accent color-hover">Coupons</p>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" fxFlex>
      <div class="card-border1 cardpadding" fxLayout="column" fxFlex="32">
        <p class="mat-h3">Ordering and shopping preferences</p>
        <p class="mat-h4 color-accent color-hover">Leave packaging feedback</p>
        <p class="mat-h4 color-accent color-hover">Lists</p>
        <p class="mat-h4 color-accent color-hover">Photo ID proofs</p>
        <p class="mat-h4 color-accent color-hover">Profile</p>
        <p class="mat-h4 color-accent color-hover">Langauge settings</p>
      </div>
      <div class="card-border1 cardpadding" fxLayout="column" fxFlex="32">
        <p class="mat-h3">Other accounts</p>
        <p class="mat-h4 color-accent color-hover">Global Local Bussiness registration</p>
        <p class="mat-h4 color-accent color-hover">Seller account</p>
        <p class="mat-h4 color-accent color-hover">Global Local web services</p>
        <p class="mat-h4 color-accent color-hover">Login with Global Local</p>
      </div>
      <div class="card-border1 cardpadding" fxLayout="column" fxFlex="32">
        <p class="mat-h3">More ways to pay</p>
        <p class="mat-h4 color-accent color-hover">Shopping programs and rentals</p>
        <p class="mat-h4 color-accent color-hover">Manage your profiles</p>
        <p class="mat-h4 color-accent color-hover">Subscribe & Save</p>
        <p class="mat-h4 color-accent color-hover">Shop the Kid's Store by age</p>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxFlex fxHide>
      <div class="card-border1 cardpadding" fxLayout="column" fxFlex="32">
        <p class="mat-h3">Subscriptions</p>
        <p class="mat-h4 color-accent color-hover">Email</p>
        <p class="mat-h4 color-accent color-hover">Membership & Subscriptions</p>
      </div>
    </div>
  </div>
</div>

<mat-accordion class="example-headers-align" multi fxShow.lt-sm fxHide>
  <div fxLayout="column" fxLayoutAlign="space-around">
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-around center">
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <p class="mat-h3">Your Order</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover" routerLink="/returns-orders">Track, return, or buy things again</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header>
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <p class="mat-h3">Login & Security</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover">Edit login, name, and mobile number</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header>
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <p class="mat-h3">Prime</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover">View benefits & payment setting</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-around center">
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <p class="mat-h3">Your Addresss</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover" routerLink="/add-address">Edit addressses for orders and gifts</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header>
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <p class="mat-h3">Payment Options</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover">Add or edit payment methods</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header>
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <p class="mat-h3">Pay Balance</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover">Add money to your account</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-around center">
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <p class="mat-h3">Digital content and devices</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover">Apps and more</p>
        <p class="mat-h4 color-accent color-hover">Content and devices</p>
        <p class="mat-h4 color-accent color-hover">Digital gifts you've received</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header>
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <p class="mat-h3">Email alerts, messages, and ads</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover">Advertising preferences</p>
        <p class="mat-h4 color-accent color-hover">Communication preferences</p>
        <p class="mat-h4 color-accent color-hover">SMS alert preferences</p>
        <p class="mat-h4 color-accent color-hover">Message center</p>
        <p class="mat-h4 color-accent color-hover">Alexa shopping notifications</p>
        <p class="mat-h4 color-accent color-hover">Deals Notifications</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header>
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <p class="mat-h3">More ways to pay</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover">Default Purchase Settings</p>
        <p class="mat-h4 color-accent color-hover">Global Local Pay</p>
        <p class="mat-h4 color-accent color-hover">Bank accounts for refunds</p>
        <p class="mat-h4 color-accent color-hover">Coupons</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header fxLayout="row" fxLayoutAlign="space-around center">
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <p class="mat-h3">Ordering and shopping preferences</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover">Leave packaging feedback</p>
        <p class="mat-h4 color-accent color-hover">Lists</p>
        <p class="mat-h4 color-accent color-hover">Photo ID proofs</p>
        <p class="mat-h4 color-accent color-hover">Profile</p>
        <p class="mat-h4 color-accent color-hover">Langauge settings</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header>
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <p class="mat-h3">Other accounts</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover">Global Local Bussiness registration</p>
        <p class="mat-h4 color-accent color-hover">Seller account</p>
        <p class="mat-h4 color-accent color-hover">Global Local web services</p>
        <p class="mat-h4 color-accent color-hover">Login with Global Local</p>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" fxFlex="32"
      fxFlex.lt-md="100">
      <mat-expansion-panel-header>
        <mat-panel-title class="card-image1" fxLayoutGap="10px">
          <p class="mat-h3">More ways to pay</p>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="column" fxLayoutGap="20px">
        <p class="mat-h4 color-accent color-hover">Shopping programs and rentals</p>
        <p class="mat-h4 color-accent color-hover">Manage your profiles</p>
        <p class="mat-h4 color-accent color-hover">Subscribe & Save</p>
        <p class="mat-h4 color-accent color-hover">Shop the Kid's Store by age</p>
      </div>
    </mat-expansion-panel>
  </div>
</mat-accordion>