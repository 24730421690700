import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthenticationService } from 'src/app/shared/_services/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  slides = [
    { 'image': './assets/m-ecom/home/carousel4.jpg' },
    { 'image': './assets/m-ecom/home/carousel2.jpg' },
    { 'image': './assets/m-ecom/home/carousel3.jpg' },
    { 'image': './assets/m-ecom/home/carousel1.jpg' },
    { 'image': './assets/m-ecom/home/carousel5.jpg' }];
  slides1 = [
    { 'image': './assets/m-ecom/home/carousel1.jpg' },
    { 'image': './assets/m-ecom/home/carousel2.jpg' },
    { 'image': './assets/m-ecom/home/carousel3.jpg' },
    { 'image': './assets/m-ecom/home/carousel4.jpg' },
    { 'image': './assets/m-ecom/home/carousel5.jpg' },];

  constructor(private authenticationService: AuthenticationService, public router: Router,) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 50,
    margin: 20,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      }
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/m-ecom/home/card1.jpg',
      // text: '₹262.00 - ₹1,871.00'
    },
    {
      id: 2,
      src: './assets/m-ecom/home/card2.png',
      // text: '₹262.00 - ₹1,871.00'
    },
    {
      id: 3,
      src: './assets/m-ecom/home/card3.jpg',
      // text: '₹262.00 - ₹1,871.00'
    },
    {
      id: 4,
      src: './assets/m-ecom/home/card4.jpg',
      // text: '₹262.00 - ₹1,871.00'
    },
    {
      id: 5,
      src: './assets/m-ecom/home/card5.jpg',
      // text: '₹262.00 - ₹1,871.00'
    },
    {
      id: 6,
      src: './assets/m-ecom/home/card6.jpg',
      // text: '₹262.00 - ₹1,871.00'
    },
    // {
    //   id: 7,
    //   src: './assets/m-ecom/home/carousel1.jpg',
    //   text: '₹262.00 - ₹1,871.00'
    // },
    // {
    //   id: 8,
    //   src: './assets/m-ecom/home/carousel1.jpg',
    //   text: '₹262.00 - ₹1,871.00'
    // },
    // {
    //   id: 9,
    //   src: './assets/m-ecom/home/carousel1.jpg',
    //   text: '₹262.00 - ₹1,871.00'
    // },
    // {
    //   id: 10,
    //   src: './assets/m-ecom/home/carousel1.jpg',
    //   text: '₹262.00 - ₹1,871.00'
    // },
    // {
    //   id: 11,
    //   src: './assets/m-ecom/home/carousel1.jpg',
    //   text: '₹262.00 - ₹1,871.00'
    // },
    // {
    //   id: 12,
    //   src: './assets/m-ecom/home/carousel1.jpg',
    //   text: '₹262.00 - ₹1,871.00'
    // },
    // {
    //   id: 13,
    //   src: './assets/m-ecom/home/carousel1.jpg',
    //   text: '₹262.00 - ₹1,871.00'
    // },
  ]
  customOptions1: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      },
      1200: {
        items: 7
      },
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/slider1/1.jpg',
    },
    {
      id: 2,
      src: './assets/images/slider1/2.jpg',
    },
    {
      id: 3,
      src: './assets/images/slider1/18.jpg',
    },
    {
      id: 4,
      src: './assets/images/slider1/4.jpg',
    },
    {
      id: 5,
      src: './assets/images/slider1/16.jpg',
    },
    {
      id: 6,
      src: './assets/images/slider1/6.jpg',
    },
    {
      id: 7,
      src: './assets/images/slider1/7.jpg',
    },
    {
      id: 8,
      src: './assets/images/slider1/8.jpg',
    },
    {
      id: 9,
      src: './assets/images/slider1/9.jpg',
    },
    {
      id: 10,
      src: './assets/images/slider1/10.jpg',
    },
    {
      id: 11,
      src: './assets/images/slider1/11.jpg',
    },
    {
      id: 12,
      src: './assets/images/slider1/12.jpg',
    },
    {
      id: 13,
      src: './assets/images/slider1/13.jpg',
    },
    {
      id: 14,
      src: './assets/images/slider1/14.jpg',
    },
    {
      id: 15,
      src: './assets/images/slider1/15.jpg',
    },
    {
      id: 16,
      src: './assets/images/slider1/16.jpg',
    },
    {
      id: 17,
      src: './assets/images/slider1/1.jpg',
    },
    {
      id: 18,
      src: './assets/images/slider1/18.jpg',
    },
  ]

  customOptions2: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      },
    },
  }
  slidesStore2 = [
    {
      id: 1,
      src: './assets/images/brand/1.png',
    },
    {
      id: 2,
      src: './assets/images/brand/2.png',
    },
    {
      id: 3,
      src: './assets/images/brand/3.png',
    },
    {
      id: 4,
      src: './assets/images/brand/4.png',
    },
    {
      id: 5,
      src: './assets/images/brand/5.png',
    },
    {
      id: 6,
      src: './assets/images/brand/6.png',
    },
    {
      id: 7,
      src: './assets/images/brand/1.png',
    },
    {
      id: 8,
      src: './assets/images/brand/2.png',
    },
    {
      id: 9,
      src: './assets/images/brand/3.png',
    },
  ]

  customOptions3: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    nav: false,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      },
    },
  }
  slidesStore3 = [
    {
      id: 1,
      src: './assets/images/brand/1.png',
    },
    {
      id: 2,
      src: './assets/images/brand/2.png',
    },
    {
      id: 3,
      src: './assets/images/brand/3.png',
    },
    {
      id: 4,
      src: './assets/images/brand/4.png',
    },
    {
      id: 5,
      src: './assets/images/brand/5.png',
    },
    {
      id: 6,
      src: './assets/images/brand/6.png',
    },
    {
      id: 7,
      src: './assets/images/brand/1.png',
    },
    {
      id: 8,
      src: './assets/images/brand/2.png',
    },
    {
      id: 9,
      src: './assets/images/brand/3.png',
    },
  ]

  customOptions4: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 50,
    margin: 40,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      800: {
        items: 3
      },

    },
  }
  slidesStore4 = [
    {
      id: 1,
      src: './assets/images/testimonial2.jpg',
      name: 'Scott Jones',
      post: '-Supervisor',
    },
    {
      id: 2,
      src: './assets/images/testimonial.jpg',
      name: 'Ember Lana',
      position: '-Founder',
    },
    {
      id: 3,
      src: './assets/images/testimonial3.jpg',
      name: 'Amber Holmes',
      post: '-Manager',
    },
    {
      id: 4,
      src: './assets/images/testimonial2.jpg',
      name: 'Scott Jones',
      post: '-Supervisor',
    },
    {
      id: 5,
      src: './assets/images/testimonial.jpg',
      name: 'Ember Lana',
      post: '-Founder',
    },
    {
      id: 6,
      src: './assets/images/testimonial3.jpg',
      name: 'Amber Holmes',
      post: '-Manager',
    },
    {
      id: 7,
      src: './assets/images/testimonial2.jpg',
      name: 'Scott Jones',
      post: '-Supervisor',
    },
  ]

}
