import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-entertainment',
  templateUrl: './home-entertainment.component.html',
  styleUrls: ['./home-entertainment.component.scss']
})
export class HomeEntertainmentComponent implements OnInit {
  items = [
    { image: 'assets/images/home-entertainment/card/1.jpg', name: 'All-new Fire TV Stick with Alexa Voice Remote (includes TV controls) | Stream HD Quality Video with Dolby Atmos Audio | 2020 release', company: 'Amazon', newprice: '₹ 3,999', oldprice: '₹ 4,999', freedelivery: 'FREE Delivery', upto:'', deliverydate: 'Friday, Dec 25', reviewno: '2,532' },
    { image: 'assets/images/home-entertainment/card/2.jpg', name: 'boAt Rugged v3 Extra Tough Unbreakable Braided Micro USB Cable 1.5 Meter (Black)', company: 'Boat', newprice: '₹ 199', oldprice: '₹ 799', freedelivery: 'FREE Delivery', upto:'on orders over ₹ 499.00 ', deliverydate: 'Thursday, Dec 24', reviewno: '20,031' },
    { image: 'assets/images/home-entertainment/card/3.jpg', name: 'All-new Fire TV Stick Lite with Alexa Voice Remote Lite | Stream HD Quality Video | No power and volume buttons | 2020 release', company: 'Amazon', newprice: '₹ 2,990', oldprice: '₹ 3,999', freedelivery: 'FREE Delivery', upto:'', deliverydate: 'Thursday, Dec 24', reviewno: '2,007' },
  ];
  items1 = [
    { image: 'assets/images/home-entertainment/card/4.jpg', name: 'boAt AAVANTE BAR 1160 60W Bluetooth Soundbar with 2.0 Channel boAt Signature Sound, Multiple Compatibility Modes, Sleek Design and Entertainment EQ Modes (Active Black)', company: 'Boat', newprice: '₹ 2,999', oldprice: '₹ 9,999', freedelivery: 'FREE Delivery', upto:'', deliverydate: 'Thursday, Dec 24', reviewno: '1,574' },
    { image: 'assets/images/home-entertainment/card/5.jpg', name: 'Mi USB Type-C Cable', company: 'MI', newprice: '₹ 228', oldprice: '₹ 299', freedelivery: 'FREE Delivery', upto:'on orders over ₹ 499.00 ', deliverydate: 'Thursday, Dec 24', reviewno: '2,314' },
    { image: 'assets/images/home-entertainment/card/6.jpg', name: 'Mi Micro USB Cable (120cm, USB Type A, Black)', company: 'MI', newprice: '₹ 228', oldprice: '₹ 299', freedelivery: 'FREE Delivery', upto:'on orders over ₹ 499.00 ', deliverydate: 'Thursday, Dec 24', reviewno: '12,709' },
  ];
  items2 = [
    { image: 'assets/images/home-entertainment/card/7.jpg', name: 'AmazonBasics Nylon Braided USB A to Lightning Compatible Cable - Apple MFi Certified - Dark Grey (3 Feet/0.9 Meter)', company: 'AmazonBasics', newprice: '₹ 759', oldprice: '₹ 1,295', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '125,883' },
    { image: 'assets/images/home-entertainment/card/8.jpg', name: 'AmazonBasics High-Speed HDMI Cable, 6 Feet - Supports Ethernet, 3D, 4K video,Black', company: 'AmazonBasics', newprice: '₹ 359', oldprice: '₹ 800', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00 ', deliverydate: 'Thursday, Dec 24', reviewno: '198,115' },
    { image: 'assets/images/home-entertainment/card/9.jpg', name: 'Zinq Nylon Braided Micro USB Cable - 4.9 Feet (1.5 Meters) - (Silver)', company: 'Zinq Technologies', newprice: '₹ 99', oldprice: '₹ 399', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00 ', deliverydate: 'Thursday, Dec 24', reviewno: '3,903' },
  ];
  items3 = [
    { image: 'assets/images/home-entertainment/card/10.jpg', name: 'Mi 2-in-1 USB Cable (Micro USB to Type-C) 100cm', company: 'MI', newprice: '₹ 268', oldprice: '₹ 399', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00 ', deliverydate: 'Thursday, Dec 24', reviewno: '3,545' },
    { image: 'assets/images/home-entertainment/card/11.jpg', name: 'Fire TV Stick 4K with Alexa Voice Remote | Stream in 4K resolution', company: 'Amazon', newprice: '₹ 5,999', oldprice: '', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Friday, Dec 25', reviewno: '8,646' },
    { image: 'assets/images/home-entertainment/card/12.jpg', name: 'Wayona Nylon Braided WN3LG1 USB Syncing and Charging Cable sync and Charging Cable for iPhone, Ipad, (3 FT Pack of 1, Grey)', company: 'Wayona', newprice: '₹ 369', oldprice: '₹ 1,090', freedelivery: 'FREE Delivery', upto:'on orders over ₹ 499.00 ', deliverydate: 'Thursday, Dec 24', reviewno: '4,166' },
  ];
  items4 = [
    { image: 'assets/images/home-entertainment/slider/1.jpg', newprice: '₹ 28,990.00', oldprice: '₹ 40,990.00', name: 'LG 108 cm (43 inches) Full HD LED Smart TV 43LM5650PTA...', reviewno: '4,451'},
    { image: 'assets/images/home-entertainment/slider/2.jpg', newprice: '₹ 579.00', oldprice: '₹ 999.00', name: 'BlackBass Box 3D Virtual Reality Box Headsets Virtual Reality...', reviewno: '17'},
    { image: 'assets/images/home-entertainment/slider/3.jpg', newprice: '₹ 5,499.00', oldprice: '₹ 13,990.00', name: 'boAt AAVANTE Bar 1250 80W 2.1 Channel Bluetooth Soundbar wi...', reviewno: '480' },
    { image: 'assets/images/home-entertainment/slider/4.jpg', newprice: '₹ 369.00', oldprice: '₹ 999.00', name: 'MYVN 5V/4A Compatible Vooc SuperFast Data Sync Charging...', reviewno: '186' },
  ];
  items5 = [
    { image: 'assets/images/home-entertainment/slider/5.jpg', newprice: '₹ 7,999.00', oldprice: '₹ 17,990.00', name: 'Infinity (JBL) Sonic B200WL 2.1 Channel Bluetooth Sound Bar...', reviewno: '710' },
    { image: 'assets/images/home-entertainment/slider/6.jpg', newprice: '₹ 18,999.00', oldprice: '₹ 39,990.00', name: 'TCL 100 cm (40 inches) Full HD Certified Android Smart LED TV...', reviewno: '544' },
    { image: 'assets/images/home-entertainment/slider/7.jpg', newprice: '₹ 6,499.00', oldprice: '₹ 19,990.00', name: 'boAt AAVANTE Bar 1800 120W 2.1 Channel Bluetooth Soundbar...', reviewno: '550' },
    { image: 'assets/images/home-entertainment/slider/8.jpg', newprice: '₹ 9,499.00', oldprice: '₹ 13,999.00', name: 'JBL Cinema SB 231 2.1 Channel Soundbar with Wired Subwoofer...', reviewno: '19' },
  ];
  items6 = [
    { image: 'assets/images/home-entertainment/slider/9.jpg', newprice: '₹ 6,999.00', oldprice: '₹ 16,990.00', name: 'boAt Aavante Bar 1500 2.1 Channel Home Theatre...', reviewno: '2,912' },
    { image: 'assets/images/home-entertainment/slider/10.jpg', newprice: '₹ 23,990.00', oldprice: '₹ 29,990.00', name: 'Sony HT-RT40 Real 5.1ch Dolby Digital Tall boy Soundbar Home...', reviewno: '777' },
    { image: 'assets/images/home-entertainment/slider/11.jpg', newprice: '₹ 14,799.00', oldprice: '₹ 17,000.00', name: 'Vu 80 cm (32 inches) HD Ready UltraAndroid LED TV 32GA (Bla...', reviewno: '7,921' },
    { image: 'assets/images/home-entertainment/slider/12.jpg', newprice: '₹ 6,990.00', oldprice: '₹ 9,990.00', name: 'Egate i9 HD (3rd Gen - 2021 Pre Launch) I Full HD 1080p suppor...', reviewno: '4,673' },
  ]

  constructor( public router: Router,) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }
}
