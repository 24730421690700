<div class="container" fxLayout="column" fxLayoutGap="20px">
  <div class="color-bg cardpadding" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-sm="start center"
    fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
      <p class="mat-headline" [ngClass.lt-sm]="'mat-title'">Combo Offers</p>
    </div>
 </div>
  <div fxLayout="row" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxLayoutGap="20px" fxHide.lt-sm fxShow>
      <div fxLayout="column" fxLayoutGap="5px">
        <p class="mat-small font-bold">Department</p>
        <div class="padding-side" fxLayout="column">
          <p class="mat-small padding-side font-bold">Toys & Games</p>
          <p class="mat-small">Women's Indian Clothing</p>
          <p class="mat-small">Men's Clothing</p>
          <p class="mat-small">Men's Shoes</p>
          <p class="mat-small">Handbags, Purses & Clutches</p>
          <p class="mat-small">Kitchen & Dining</p>
          <p class="mat-small">Clothing & Accessories</p>
          <p class="mat-small">Health & Personal Care</p>
          <p class="mat-small">Home & Kitchen</p>
          <p class="mat-small">Beauty</p>
          <p class="mat-small">Watches</p>
          <p class="mat-small">Shoes & Handbags</p>
          <p class="mat-small">Jewellery</p>
          <p class="mat-small">Luggage & Bags</p>
          <p class="mat-small">Tools & Home Improvements</p>
        </div>
        <div fxLayout="column" fxLayoutGap="5px">
          <div fxLayout="column">
            <p class="mat-small font-bold">Avg. Customers Reviews</p>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Feature Character</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Teddy Bear</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Barble </mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Brands</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">RATNA'S
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Yashika
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">1 Stop Fashion</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Generic</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">TEMPO</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Mammon
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">JAPP</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">price</p>
            <p class="mat-small color-hover">Under ₹5,00</p>
            <p class="mat-small color-hover">₹5,00 - ₹1,000</p>
            <p class="mat-small color-hover">₹1,000 - ₹2,000</p>
            <p class="mat-small color-hover">₹2,000 - ₹3,000</p>
            <p class="mat-small color-hover">Over ₹3,000</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Age</p>
            <p class="mat-small color-hover">Up to 12 months</p>
            <p class="mat-small color-hover">1 - 2 years</p>
            <p class="mat-small color-hover">3 - 4 years</p>
            <p class="mat-small color-hover">5 - 7 years</p>
            <p class="mat-small color-hover">8 - 11 years</p>
            <p class="mat-small color-hover">12 years & more</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">New Arrivals</p>
            <p class="mat-small color-hover">Last 30 days</p>
            <p class="mat-small color-hover">Last 90 days</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Discount</p>
            <p class="mat-small color-hover">10% off or more</p>
            <p class="mat-small color-hover">25% off or more</p>
            <p class="mat-small color-hover">35% off or more</p>
            <p class="mat-small color-hover">50% off or more</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Availability</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Include Out of Stock
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div>
      <mat-divider [vertical]="true" fxFlex="100"></mat-divider>
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
      <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="space-around">
        <div fxLayoutGap="20px" *ngFor="let item of items" fxFlex="24" fxFlex.lt-md="32" fxFlex.lt-sm="100">
          <div class="cardpadding" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap="10px" fxFlex="100">
            <div [ngClass.gt-sm]="'card-image'" fxLayoutAlign="center center" fxFlex.lt-sm="30" (click)="products()">
              <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
            </div>
            <div fxFlex.lt-sm="70">
              <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star_border</mat-icon>
                <mat-icon>keyboard_arrow_down</mat-icon>
                <p class="color-accent color-hover">{{item.reviewno}}</p>
              </div>
              <p class="font-bold color-danger"> {{item.newprice}} <span
                  class="color-secondary text-line-through mat-small">{{item.oldprice}}</span> </p>
              <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
              <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
              <p class="color-accent color-hover">See Details</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>