import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-compare',
  templateUrl: './product-compare.component.html',
  styleUrls: ['./product-compare.component.scss']
})
export class ProductCompareComponent implements OnInit {

  items = [
    { title: 'Rear Camera Lens 1', one: '64', two: '64', three: '48', four: '48' },
    { title: 'Rear Camera Lens 2', one: '64 MP + 12MP + 5MP + 5MP', two: '32MP', three: '32MP', four: '64MP + 12MP + 5MP + 5MP' },
    { title: 'Screen Size', one: '6.7 in', two: '6.4 in', three: '6.44 in', four: '6.5 in' },
    { title: 'Screen Type', one: 'sAMOLED Plus', two: '2340 x 1080', three: '2400 x 1080', four: 'AMOLED' },
    { title: 'Battery Power (In mAH)', one: '7000', two: '6000', three: '4115', four: '6000 milliamp hours' },
    { title: 'RAM', one: '6 GB', two: '6 GB', three: '12 GB', four: '6 GB' },
    { title: 'Inbuilt Storage (in GB)', one: '128', two: '128', three: '256', four: '128' },
    { title: 'Expandable Storage', one: '128 GB', two: '128 GB', three: '256 GB', four: '128 GB' },
    { title: 'Processor Brand', one: 'Qualcomm | SD730G', two: 'Exynos 9611 Octacore', three: 'Qualcomm Snapdragon 765G 5G mobile platform', four: 'Exynos 9611' },
    { title: 'Operating System', one: 'Android', two: 'Android', three: 'Android', four: 'Android' },
    { title: 'Warranty Details', one: '1 year manufacturer warranty for device and 6 months manufacturer warranty for in-box accessories including batteries from the date of purchase', two: '1 year manufacturer warranty for device and 6 months manufacturer warranty for in-box accessories including batteries from the date of purchase', three: '1 year manufacturer warranty for device and 6 months manufacturer warranty for in-box accessories including batteries from the date of purchase', four: '1 year manufacturer warranty for device and 6 months manufacturer warranty for in-box accessories including batteries from the date of purchase' },
  ]
  items1 = [
    { img: 'assets/images/product/compare/1.jpg', name: 'Samsung Galaxy M51 (Celestial Black, 6GB RAM, 128GB Storage)', price: '₹ 22,900' },
    { img: 'assets/images/product/compare/2.jpg', name: 'Samsung Galaxy M31 Prime Edition (Space Black, 6GB RAM, 128GB Storage)', price: '₹ 16,499' },
    { img: 'assets/images/product/compare/3.jpg', name: 'OnePlus Nord 5G (Gray Ash, 12GB RAM, 256GB Storage)', price: '₹ 29,999' },
    { img: 'assets/images/product/compare/4.jpg', name: 'Samsung Galaxy M31s (Mirage Black, 6GB RAM, 128GB Storage)', price: '₹ 19,499' },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
