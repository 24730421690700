import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class AudioComponent implements OnInit {
  slides = [
    { 'image': './assets/images/electronics/audio/carousel1.jpeg' },
    { 'image': './assets/images/electronics/audio/carousel2.jpg' },
    { 'image': './assets/images/electronics/audio/carousel3.jpg' },
    { 'image': './assets/images/electronics/audio/carousel4.jpeg' },
    { 'image': './assets/images/electronics/audio/carousel5.jpg' },
    { 'image': './assets/images/electronics/audio/carousel6.jpg' },];

  items = [
    { image: 'assets/images/electronics/audio/card/1.jpg', name: 'boAt Rockerz 255 Sports in-Ear Bluetooth Neckband Earphone with Mic (Active Black)', company: 'Boat', newprice: '₹ 999', oldprice: '₹ 2,990', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Sunday, Dec 27', reviewno: '150,903' },
    { image: 'assets/images/electronics/audio/card/2.jpg', name: 'boAt Bassheads 100 in Ear Wired Earphones with Mic(Black)', company: 'Boat', newprice: '₹ 379', oldprice: '₹ 999', freedelivery: 'FREE Delivery', upto: 'on oreders over ₹ 499', deliverydate: 'Friday, Dec 25', reviewno: '101,882' },
    { image: 'assets/images/electronics/audio/card/3.jpg', name: 'OnePlus Bullets Wireless Z in-Ear Bluetooth Earphones with Mic (Black)', company: 'OnePlus', newprice: '₹ 1,999', oldprice: '₹ 2,199', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '43,741' },
  ];

  items1 = [
    { image: 'assets/images/electronics/audio/card/4.jpg', name: 'OnePlus Bullets Wireless Z Bass Edition (Reverb Red)', company: 'OnePlus', newprice: '₹ 1,999', oldprice: '₹ 2,190', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '43,155' },
    { image: 'assets/images/electronics/audio/card/5.jpg', name: 'boAt Bassheads 102 in Ear Wired Earphones with Mic(Charcoal Black)', company: 'Boat', newprice: '₹ 399', oldprice: '₹ 1,290', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '8,773' },
    { image: 'assets/images/electronics/audio/card/6.jpg', name: 'OnePlus Buds Z (White)', company: 'OnePlus', newprice: '₹ 2,999', oldprice: '₹ 3,190', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '1,341' },];

  items2 = [
    { image: 'assets/images/electronics/audio/card/7.jpg', name: 'boAt Rockerz 255 Sports in-Ear Bluetooth Neckband Earphone with Mic(Ocean Blue)', company: 'Boat', newprice: '₹ 999', oldprice: '₹ 2,990', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '150,929' },
    { image: 'assets/images/electronics/audio/card/8.jpg', name: 'boAt Airdopes 121v2 TWS Earbuds with Bluetooth V5.0, Immersive Audio, Up to 14H Total Playback, Instant Voice Assistant, Easy Access Controls wit...', company: 'Boat', newprice: '₹ 1,299', oldprice: '₹  2,990', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '1,075' },
    { image: 'assets/images/electronics/audio/card/9.jpg', name: 'boAt Rockerz 255 Pro in-Ear Bluetooth Neckband Earphone with Mic(Navy Blue)', company: 'Boat', newprice: '₹ 1,199', oldprice: '₹ 3,499', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '150,786' },];

  items3 = [
    { image: 'assets/images/electronics/audio/card/10.jpg', name: 'boAt Bassheads 102 in Ear Wired Earphones with Mic(Jazzy Blue)', company: 'Boat', newprice: '₹ 299', oldprice: '₹ 1,290', freedelivery: 'FREE Delivery', upto: 'on orders over ₹ 499.00', deliverydate: 'Thursday, Dec 24', reviewno: '8,517' },
    { image: 'assets/images/electronics/audio/card/11.jpg', name: 'pTron Bassbuds Lite V2 In-Ear True Wireless Bluetooth 5.0 Headphones with HiFi Deep Bass, Total 20Hrs Playtime, Ergonomic Sweatproof...', company: 'PTron', newprice: '₹ 849', oldprice: '2,000', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Friday, Dec 25', reviewno: '10,758' },
    { image: 'assets/images/electronics/audio/card/12.jpg', name: 'JBL C100SI In-Ear Deep Bass Headphones with Mic (Black)', company: 'JBL', newprice: '₹ 699', oldprice: '₹ 1,299', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 24', reviewno: '82,854' },
  ];

  constructor( public router: Router,) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }
  
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel1/1.jpg',
      text: 'Emporium Car Back Pain Relief Lower Back Support for Chair Back Rest for Office Chair Lumbar…',
      reviewno: '242',
      price: '₹ 349.00'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel1/2.jpg',
      text: 'BLUEWUD Clonard Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '242',
      price: '₹ 2,899.00'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel1/3.jpg',
      text: 'BLUEWUD Mallium Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '331',
      price: '₹ 3,339.00'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel1/4.jpg',
      text: 'OGU Om Cotton Round Batti Wicks for Pooja Aarti (White) -2100 Pieces',
      reviewno: '61',
      price: '₹ 250'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel1/5.jpg',
      text: 'pTron Bassbuds Pro in-Ear True Wireless Bluetooth 5.0 Headphones with Deep Bass, Touch Control…',
      reviewno: '8,370',
      price: '₹ 1,299.00'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel1/6.jpg',
      text: 'HealthSense Soft-Spot BC 21 Orthopedic Backrest Cushion with Memory Foam for Study, Home,…',
      reviewno: '931',
      price: '₹ 1,399.00'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel1/7.jpg',
      text: 'Nutriplato-enriching lives Roasted Salted Flax Seeds, 500 g',
      reviewno: '325',
      price: '₹ 199.00'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel1/8.jpg',
      text: 'ADOC Chairs Oren Medium Back Ergonomic Office Chair with Mesh Back, Fixed Comfortable…',
      reviewno: '58',
      price: '₹ 3,049.00'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel1/9.jpg',
      text: 'Angel Bear 1 Cup Toothbrush Toothpaste Stand Holder Bathroom Storage Organizer,Plastic',
      reviewno: '379',
      price: '₹ 249.00'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel1/10.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '165',
      price: '₹ 3,999.00'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel1/11.jpg',
      text: 'TIZUM High Speed HDMI Cable with Ethernet - Supports 3D, 4K and Audio Return (1.8 Meter/ 6 Feet)',
      reviewno: '2,281',
      price: '₹ 145.00'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel1/12.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '30',
      price: '₹ 3,799.00'
    },
  ]
  customOptions1: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel2/1.jpeg',
      text: 'VPN Software & VPN free unlimited (Fire TV, 4k sticks)',
      reviewno: '1,108',
      price: 'BackySoft'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel2/2.jpg',
      text: 'AirScreen - AirPlay & Google Cast & Miracast & DLNA',
      reviewno: '34,550',
      price: '重庆爱奥尼科技有限公司'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel2/3.jpeg',
      text: 'Trashy Movies Channel',
      reviewno: '1,561',
      price: 'POV Horror'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel2/4.jpeg',
      text: 'Wicked Horror TV',
      reviewno: '3,961',
      price: 'POV Horror'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel2/5.jpeg',
      text: 'Trashy Horror Movies',
      reviewno: '1,375',
      price: 'POV Horror'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel2/6.jpeg',
      text: 'B-Movie Club',
      reviewno: '738',
      price: 'POV Horror'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel2/7.jpeg',
      text: 'VPN Lite & Full Privacy (RVPN)',
      reviewno: '358',
      price: 'BackySoft'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel2/8.jpg',
      text: 'Plex: Stream Movies, Shows, Live TV, Music, and More',
      reviewno: '51,727',
      price: 'Plex'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel2/9.jpeg',
      text: 'Martial Arts and Kung-Fu Movie Channel',
      reviewno: '4,629',
      price: 'POV Horror'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel2/10.jpg',
      text: 'NordVPN - Fast, Secure and Unlimited VPN app for Android. Stay secure and…',
      reviewno: '21,882',
      price: 'nordvpn s.a.'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel2/11.jpeg',
      text: 'Thriller Movie Channel',
      reviewno: '232',
      price: 'POV Horror'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel2/12.jpeg',
      text: 'Cleaner for Fire Tablets & TV (Cleanza)',
      reviewno: '6,219',
      price: 'Quickla Soft'
    },
  ]
  customOptions2: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
    },
  }
  slidesStore2 = [
    {
      id: 1,
      src: './assets/images/electronics/audio/carousel/1.jpg',
      price: '₹ 615 - ₹ 1,799',
      oldprice: '',
      off: '',
      name: 'Up to 70% off on Top Branded Headphones & Speakers',
      brand: '',
      reviewno: '2',
      button: ''
    },
    {
      id: 2,
      src: './assets/images/electronics/audio/carousel/2.jpg',
      price: '₹ 299',
      oldprice: '₹ 1,299',
      off: '(77% off)',
      name: 'boAt Bassheads 102 in Ear Wired Earphones with Mic(Jazzy Blue)',
      brand: 'Sold by Appario Retail Private Ltd and Fulfilled by Amazon.',
      reviewno: '76,763',
      button: 'Add to Cart'
    },
    {
      id: 3,
      src: './assets/images/electronics/audio/carousel/3.jpg',
      price: '₹ 499',
      oldprice: '₹ 999',
      off: '(50% off)',
      name: 'boAt Bassheads 225 in Ear Wired Earphones with Mic(Black)',
      brand: 'Sold by Appario Retail Private Ltd and Fulfilled by Amazon.',
      reviewno: '1,151',
      button: 'Add to Cart'
    },
    {
      id: 4,
      src: './assets/images/electronics/audio/carousel/4.jpg',
      price: '₹ 1,999',
      oldprice: '₹ 5,999',
      off: '(67% off)',
      name: 'boAt Airdopes 441 TWS Ear-Buds with IWP Technology, ...',
      brand: 'Sold by Appario Retail Private Ltd and Fulfilled by Amazon.',
      reviewno: '1,151',
      button: 'Add to Cart'
    },
    {
      id: 5,
      src: './assets/images/electronics/audio/carousel/5.jpg',
      price: '₹ 1,999',
      oldprice: '₹ 5,999',
      off: '(67% off)',
      name: 'boAt Airdopes 441 TWS Ear-Buds with IWP Technology,... ',
      brand: 'Sold by Appario Retail Private Ltd and Fulfilled by Amazon.',
      reviewno: '18,087',
      button: 'Add to Cart'
    },
    {
      id: 6,
      src: './assets/images/electronics/audio/carousel/6.jpg',
      price: '₹ 1,499',
      oldprice: '₹ 3,990',
      off: '(62% off)',
      name: 'boAt Rockerz 335 Wireless Neckband with ASAP Charge...',
      brand: 'Sold by Appario Retail Private Ltd and Fulfilled by Amazon.',
      reviewno: '963',
      button: 'Add to Cart'
    },
    {
      id: 7,
      src: './assets/images/electronics/audio/carousel/7.jpg',
      price: '₹ 499',
      oldprice: '₹ 1,290',
      off: '(65% off)',
      name: ' boAt Bassheads 152 in Ear Wired Earphones with Mic(Active Black) ',
      brand: 'Sold by Appario Retail Private Ltd and Fulfilled by Amazon.',
      reviewno: '21,432',
      button: 'Add to Cart'
    },
    {
      id: 8,
      src: './assets/images/electronics/audio/carousel/8.jpg',
      price: '₹ 499',
      oldprice: '₹ 1,290',
      off: '(65% off)',
      name: 'boAt Bassheads 152 in Ear Wired Earphones with Mic(Jazzy Blue)',
      brand: 'Sold by Appario Retail Private Ltd and Fulfilled by Amazon.',
      reviewno: '21,432',
      button: 'Add to Cart'
    },
  ]

}
