<div class="container" fxLayout="column" fxLayoutGap="10px">
    <div class="card cardpadding name" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon>person_outline</mat-icon>
        <p class="mat-title">{{this.yourEmail}}</p>
    </div>
    <div class="card cardpadding" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="15px">
        <div class="full-width" fxLayout="column">
            <p class="mat-h3" routerLink="/home">Home</p>
            <p class="mat-h3" routerLink="/your-account">Account</p>
            <p class="mat-h3" routerLink="/returns-orders">Orders</p>
            <p class="mat-h3" routerLink="/returns-orders">Buy Again</p>
            <p class="mat-h3" routerLink="/category">Lists</p>
            <p class="mat-h3" routerLink="/customer-service">Customer Service</p>
        </div>
    </div>
    <div class="card cardpadding" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="15px">
        <div class="full-width" fxLayout="column">
            <p class="mat-title">Explore</p>
            <p class="color-secondary">TOP CATOGORIES</p>
            <p class="mat-h3" routerLink="/mobile-phone">Mobiles</p>
            <p class="mat-h3" routerLink="/computer-laptop">Computers</p>
            <p class="mat-h3" routerLink="/home-essentials">Home Products</p>
            <p class="mat-h3" routerLink="/fashion">Fashion</p>
            <p class="mat-h3" routerLink="/category">See All Catogories</p>
        </div>
        <div>
            <mat-divider [inset]="true"></mat-divider>
        </div>
        <div class="full-width" fxLayout="column" fxHide>
            <p class="color-secondary">PROGRAMS & FEATURES</p>
            <p class="mat-h3" routerLink="/todays-deal">Today's Deals</p>
            <p class="mat-h3" routerLink="/home">Amazon Pay</p>
            <p class="mat-h3" routerLink="/home">Try Prime</p>
            <p class="mat-h3" routerLink="/home">Sell on Amazon</p>
        </div>
    </div>
    <div class="card cardpadding" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="15px">
    <div class="full-width" fxLayout="column">
        <p class="mat-title" routerLink="/home">Settings</p>
        <p class="mat-h3" routerLink="/home">Notifications</p>
        <p class="mat-h3" routerLink="/home">Default Phone Settings</p>
        <p class="mat-h3" routerLink="/home">Legal</p>
        <p class="mat-h3" (click)="logout()">Logout</p>
    </div>
    </div>
</div>