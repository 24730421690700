<div class="container cardpadding" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column" fxLayoutGap="20px" fxHide>
    <div fxLayout="row" fxShow.gt-sm fxHide>
      <img src="assets/images/fashion/banner1.gif" alt="" class="full-width">
    </div>
    <div fxLayout="row" fxShow.lt-sm fxHide>
      <img src="assets/images/fashion/banner3.gif" alt="" class="full-width">
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <img src="assets/images/fashion/banner2.jpg" alt="" class="full-width" fxFlex="70" fxFlex.lt-sm="100">
    </div>
  </div>
  <div class="bg-secondary cardpadding" fxLayout="column" fxLayoutGap="20px">
    <div class="card cardpadding slider-items" fxHide.lt-sm>
      <p class="mat-h2">Everyday must-haves | Up to 70% off</p>
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
            <img [src]="slide.src">
            <div fxLayout="row" fxLayoutAlign="center center">
              <span class="mat-h4">Shop Now</span>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
    <div class="card cardpadding" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px" fxShow.lt-sm fxHide>
      <p class="mat-h2">Everyday must-haves | Up to 70% off</p>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel/1.jpg" alt="" fxFlex="100">
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel/2.jpg" alt="" fxFlex="100">
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel/3.jpg" alt="" fxFlex="100">
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel/4.jpg" alt="" fxFlex="100">
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel/5.jpg" alt="" fxFlex="100">
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel/6.jpg" alt="" fxFlex="100">
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel/7.jpg" alt="" fxFlex="100">
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel/8.jpg" alt="" fxFlex="100">
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel/9.jpg" alt="" fxFlex="100">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card cardpadding" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
      <p class="mat-h2">Men's Collections</p>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/men1.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/men2.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/men3.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/men4.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/men5.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/men6.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card cardpadding" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
      <p class="mat-h2">Women's Collections</p>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/women1.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/women2.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/women3.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/women4.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/women5.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 50% off</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/women6.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card cardpadding" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
      <p class="mat-h2">Kids's Collections</p>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/kids1.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/kids2.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/kids3.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/kids4.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/kids5.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/kids6.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card cardpadding" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
      <p class="mat-h2">Explore other collections</p>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/explore1.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 30% off</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/explore2.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/explore3.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 20% off</p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/explore4.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/explore5.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/explore6.jpg" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Up to 70% off</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
    <p class="mat-h2">Deals on clothing, footwear, luggage & more | Up to 70% off</p>
    <owl-carousel-o [options]="customOptions1">
      <ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px" fxFlex>
          <div fxLayout="row">
            <img [src]="slide.src">
          </div>
          <div class="hover-border" fxLayout="column" fxLayoutAlign="start start">
            <p class="mat-h4 color-danger">{{slide.price}}</p>
            <p class="mat-small color-accent">{{slide.name}}</p>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon>keyboard_arrow_down</mat-icon>
              <p class="color-accent color-hover">{{slide.reviewno}}</p>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start stretch">
            <button mat-raised-button color="primary" fxFlex="80">Add to cart</button>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
  <div class="bg-secondary cardpadding" fxLayout="column" fxLayoutGap="20px">
    <div class="card cardpadding slider-items" fxHide.lt-sm>
      <p class="mat-h2">Top Brands to shop now</p>
      <owl-carousel-o [options]="customOptions2">
        <ng-template carouselSlide *ngFor="let slide of slidesStore2" [id]="slide.id">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
            <img [src]="slide.src">
            <div fxLayout="row" fxLayoutAlign="center center">
              <span class="mat-h4">Shop Now</span>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
    <div class="card cardpadding" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px" fxShow.lt-sm fxHide>
      <p class="mat-h2">Top Brands to shop now</p>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel3/1.jpg" alt="" fxFlex="100">
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel3/2.jpg" alt="" fxFlex="100">
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel3/3.jpg" alt="" fxFlex="100">
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel3/4.jpg" alt="" fxFlex="100">
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel3/5.jpg" alt="" fxFlex="100">
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel3/6.jpg" alt="" fxFlex="100">
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel3/7.jpg" alt="" fxFlex="100">
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel3/8.jpg" alt="" fxFlex="100">
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/carousel3/9.jpg" alt="" fxFlex="100">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card cardpadding slider-items" fxHide.lt-sm>
      <p class="mat-h2">Top Brands to shop now</p>
      <owl-carousel-o [options]="customOptions3">
        <ng-template carouselSlide *ngFor="let slide of slidesStore3" [id]="slide.id">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
            <img [src]="slide.src">
            <div fxLayout="row" fxLayoutAlign="center center">
              <span class="mat-h4 text-center">{{slide.item}}</span>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
    <div class="card cardpadding" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
      <p class="mat-h2">Top-rated styles, coupons & more</p>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/top-rate1.gif" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Shop Now</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/top-rate2.gif" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Shop Now</p>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/top-rate3.gif" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Shop Now</p>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="32">
            <div fxLayout="row">
              <img src="assets/images/fashion/top-rate4.gif" alt="" fxFlex="100">
            </div>
            <p class="mat-h4 text-center">Shop Now</p>
          </div>
        </div>
      </div>
    </div>
    <div class="card cardpadding slider-items" fxHide.lt-sm>
      <p class="mat-h2">Made for Amazon brands</p>
      <owl-carousel-o [options]="customOptions4">
        <ng-template carouselSlide *ngFor="let slide of slidesStore4" [id]="slide.id">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
            <img [src]="slide.src">
            <div fxLayout="row" fxLayoutAlign="center center">
              <span class="mat-h4 text-center">{{slide.item}}</span>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
    <div class="card cardpadding slider-items" fxHide.lt-sm>
      <p class="mat-h2">Amazon Specials</p>
      <owl-carousel-o [options]="customOptions5">
        <ng-template carouselSlide *ngFor="let slide of slidesStore5" [id]="slide.id">
          <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
            <img [src]="slide.src">
            <div fxLayout="row" fxLayoutAlign="center center">
              <span class="mat-h4 text-center">Shop Now</span>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>