<div class="container" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column" fxLayoutAlign="center center">
    <p class="mat-headline">Birthday Gift Cards Store</p>
    <p class="mat-small">Make your loved ones feel really special on their birthdays. Shop for Amazon Pay Gift Cards and
      wish your loved ones all the happiness and joy.</p>
  </div>

  <div fxLayout="row" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxLayoutGap="20px" fxHide.lt-sm fxShow>
      <div fxLayout="column" fxLayoutGap="10px">
        <p class="mat-h4 font-bold">Department</p>
        <div>
          <div fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon>keyboard_arrow_left</mat-icon>
              <p class="mat-small color-hover"> Gift Cards</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon>keyboard_arrow_left</mat-icon>
              <p class="mat-small color-hover"> For Occations</p>
            </div>
            <div class="padding-side" fxLayout="column">
              <p class="mat-small color-hover font-bold">Birthday</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <mat-divider></mat-divider>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        <div fxLayout="column">
          <p class="mat-small font-bold">Recipient</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Brother</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Daughter</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">For Him</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">For Her</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Friend</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Sister</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Wife</mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Delievery Types</p>
          <div fxLayout="column" fxLayoutAlign="start start">
            <p class="mat-small color-hover">eGifts Cards</p>
            <p class="mat-small color-hover">Physical Gift Cards</p>
          </div>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Language</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">English</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Bengali</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Gujarati</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Hindi</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Kannada</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Malyalam</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Marathi</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Punjabi</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Tamil</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Telugu</mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Amazon Prime</p>
          <mat-checkbox fxLayoutAlign="start center"><img src="assets/images/checkbox/1.gif" alt="">
          </mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Categories</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Amazon Gift Cards</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Apps</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Clothing</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Fashion Accessories</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Jewellery</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Stores</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Travel & Leisure</mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Availability</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Include Out of Stock
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div>
      <mat-divider [vertical]="true" fxFlex="100"></mat-divider>
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
      <div fxLayout="row">
        <img src="assets/images/gift-card/birthday/logo.png" alt="" class="full-width">
      </div>
      <div fxLayout="row">
        <img src="assets/images/gift-card/birthday/banner1.gif" alt="" class="full-width">
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <p class="mat-h3 letter-spacing">SEND BIRTHDAY WISHES</p>
        <mat-divider class="full-width"></mat-divider>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/send/1.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/send/2.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/send/3.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/send/4.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/send/5.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/send/6.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/send/7.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/send/8.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/send/9.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/send/10.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/send/11.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/send/12.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/send/13.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/send/14.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/send/15.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/send/16.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/send/17.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/send/18.jpg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <p class="mat-h3 letter-spacing">GIFT CARDS FOR YOUR FAMILY MEMBERS</p>
        <mat-divider class="full-width"></mat-divider>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/family/1.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/family/2.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/family/3.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/family/4.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/family/5.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/family/6.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/family/7.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/family/8.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/family/9.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/family/10.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/family/11.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/family/12.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/family/13.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/family/14.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/family/15.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/family/16.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/family/17.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/family/18.jpg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <p class="mat-h3 letter-spacing">FOR THE YEARLY WISHES</p>
        <mat-divider class="full-width"></mat-divider>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/yearly/1.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/yearly/2.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/yearly/3.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/yearly/4.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/yearly/5.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/yearly/6.jpg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <p class="mat-h3 letter-spacing">NEW ARRIVALS</p>
        <mat-divider class="full-width"></mat-divider>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/new-arrived/1.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/new-arrived/2.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/new-arrived/3.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/new-arrived/4.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/new-arrived/5.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/new-arrived/6.jpg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <p class="mat-h3 letter-spacing">MORE OPTIONS TO CHOOSE FROM</p>
        <mat-divider class="full-width"></mat-divider>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/more-option/1.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/more-option/2.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/more-option/3.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/more-option/4.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/more-option/5.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/more-option/6.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/more-option/7.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/more-option/8.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/more-option/9.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/more-option/10.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/more-option/11.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/more-option/12.jpg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
        <p class="mat-h3 letter-spacing">OTHER EMAIL GIFT CARDS</p>
        <mat-divider class="full-width"></mat-divider>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/other/1.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/other/2.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/other/3.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/other/4.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/other/5.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/other/6.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/other/7.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/other/8.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/other/9.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/other/10.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/other/11.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/other/12.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/other/13.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/other/14.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/other/15.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/other/16.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/other/17.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/other/18.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/other/19.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/other/20.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/other/21.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/other/22.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gift-card/birthday/other/23.jpg" alt="" fxFlex="48">
            <img src="assets/images/gift-card/birthday/other/24.jpg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="row">
        <img src="assets/images/gift-card/birthday/banner2.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="column" fxLayoutGap="40px">
        <div fxLayout="row" class="card-shadow cardpadding">
          <p class="mat-small">1-24 of over 2,000 results for: <span class="font-bold color-primary"> Gift Cards </span>
          </p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div class="details-card" fxFlex.lt-sm="30">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h3 color-accent color-hover">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <p class="font-bold color-danger"> {{item.price}}</p>
                <p class="mat-small">In Stock</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items2" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div class="details-card" fxFlex.lt-sm="30">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h3 color-accent color-hover">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <p class="font-bold color-danger"> {{item.price}}</p>
                <p class="mat-small">In Stock</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items3" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div class="details-card" fxFlex.lt-sm="30">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h3 color-accent color-hover">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <p class="font-bold color-danger"> {{item.price}}</p>
                <p class="mat-small">In Stock</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items4" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div class="details-card" fxFlex.lt-sm="30">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h3 color-accent color-hover">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <p class="font-bold color-danger"> {{item.price}}</p>
                <p class="mat-small">In Stock</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
  </div>
  <div class="cardpadding" fxLayout="column" fxLayoutGap="20px" fxHide fxShow.gt-sm>
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-headline">Buy Birthday Gift Cards Online at Amazon.in.</p>
      <p>Birthdays are always special. Make it all the more special and memorable for your near and dear ones with
        carefully
        chosen birthday gifts and birthday greeting cards, bought online on Amazon India. Gift cards and gift vouchers
        are
        perfect gift ideas when you are not sure about what to gift to your loved ones, yet you want to make it special.
        Pick
        from the range of gift packs, birthday gifts, personalized greetings cards, birthday vouchers and more on Amazon
        India
        and wish your loved ones the best of all on their birthday.</p>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-headline">Celebrate Relationships with Personalized Gifts Online</p>
      <p>Wish your loved ones a very happy birthday with personalized gifts like personalized greeting cards with
        pictures and
        expressions that will make them feel special. You can mail gift cards and add a personal touch to it through
        birthday
        messages in your local languages like Hindi, Marathi, Bengali, Kannada and others. There are birthday gift cards
        with
        zodiac signs that can be a great way to wish your loved ones. All you need to do is to buy these e-cards at
        Amazon.in
        and email them to the person</p>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-headline">Birthday Gifts For All</p>
      <p>At Amazon.in, the list of birthday gift cards is easy to buy and deliver instantly. Share joy and happiness for
        a
        memorable birthday celebration with gift items bought online at Amazon.in. If your friend loves shopping, the
        best
        birthday gift would be a shopping voucher. Likewise, somebody who is into mobile apps would love a Google Play
        Gift
        Card. Whatever be your need, you will get the right birthday gift vouchers at amazing prices.</p>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-headline">Popular Searches for Gifts</p>
      <p>
       <span class="color-accent"> Email Gift Cards </span> ||
       <span class="color-accent"> Greeting Cards </span> ||
       <span class="color-accent"> Gift Boxes </span> ||
       <span class="color-accent"> Gifts for her  </span>||
       <span class="color-accent"> Birthday Gift Cards </span> ||
       <span class="color-accent"> Big Bazaar Gift Card  </span> ||
       <span class="color-accent"> Gifts for him Gift Card </span> ||
       <span class="color-accent"> Wedding Gift Cards </span> ||
       <span class="color-accent"> Anniversary Gift Cards </span> || 
       <span class="color-accent"> Big Bazaar Gift Voucher </span> || 
       <span class="color-accent"> Diwali Gift Cards </span> || 
       <span class="color-accent"> Navaratri Gift Cards </span> || 
       <span class="color-accent"> Dussehra Gift Cards </span> || 
       <span class="color-accent"> Durga Puja Gift Cards </span> || 
       <span class="color-accent"> Birthday Gifts </span> || 
       <span class="color-accent"> Gifts </span> ||
       <span class="color-accent"> Personalized Gifts </span> || 
       <span class="color-accent"> Couple Gifts </span> || 
       <span class="color-accent"> Gifts for women </span> || 
       <span class="color-accent"> Gifts for girls </span> || 
       <span class="color-accent"> Rakhi Gift Cards </span> || 
       <span class="color-accent"> Mother's Day Gift Cards </span> || 
       <span class="color-accent"> Father's Day gift cards</span> </p>
    </div>
  </div>
</div>