import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wish-list',
  templateUrl: './wish-list.component.html',
  styleUrls: ['./wish-list.component.scss']
})
export class WishListComponent implements OnInit {
  tabLoadTimes: Date[] = [];
  selectedValue: string;

  items = [
    { date: '28 December 2020', price: '₹ 12,999', img: 'assets/images/mobile-phone/card/1.webp', name: 'Samsung Galaxy M30s (Opal Black, 4GB RAM, 64GB Storage)', reviewno: '546' },
    { date: '12 October 2020', price: '₹ 13,100', img: 'assets/images/gl-home/refrigerator/carousel1/8.jpg', name: 'r 320 L 2 Star Inverter Frost-Free Double Door Refrigerator (HRB-3404BMS-E, Moon Silver,Bottom Freezer)', reviewno: '1,245' },
    { date: '05 July 2020', price: '₹ 23,790', img: 'assets/images/order/3.jpg', name: 'LG 260 L 3 Star Frost Free Double Door Refrigerator (GL-I292RPZL, Shiny Steel, Smart Inverter Compressor)', reviewno: '325' },
    { date: '28 June 2020', price: '₹ 36,900', img: 'assets/images/order/4.jpg', name: 'Mi TV 4X 138.8 cm (55 Inches) Ultra HD Android LED TV (Black)', reviewno: '789' },

  ]
  constructor( public router: Router,) { }

  ngOnInit(): void {
  }
  
  products() {
    this.router.navigate(['/products']);
  }

  gotocart() {

    this.router.navigate(['/add-to-cart'])
  }
}
