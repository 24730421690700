<div class="container" fxLayout="column" fxLayoutGap="20px">
  <div [ngClass.gt-sm]="'cardpadding2'" fxLayout="column" fxLayoutAlign="center">
    <div fxLayout="row" fxLayoutAlign="center" fxLayout.xs="column" fxLayoutGap.xs="10px">
      <mat-card class="container card-shadow" fxFlex="50" fxFlex.lt-md="100">
        <mat-card-title>
          <span fxLayoutAlign="center">Add address</span>
        </mat-card-title>
        <mat-card-content class="padding-top">
          <form [formGroup]="checkoutFormGroup" novalidate>
            <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column">
              <!-- Name field -->
              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Full Name</mat-label>
                <input formControlName="fullName" matInput placeholder="Full Name" maxlength="256" required>
                <mat-error>Name is required.</mat-error>
              </mat-form-field>
               <!-- Mobile number field -->
               <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Mobile number</mat-label>
                <input formControlName="phoneNumber" type="tel" id="mobileno" matInput placeholder="9876543210"
                  maxlength="10" required>
                <mat-error *ngIf="txtMobileno.errors?.required">Mobile Number is required.
                </mat-error>
                <mat-error *ngIf="txtMobileno.errors?.pattern">Invalid Mobile No.
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column">
              <!-- Flat, House no., Building, Company, Apartment field -->
              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Flat, House no., Building, Company, Apartment</mat-label>
                <input formControlName="flat" matInput maxlength="256" required>
                <mat-error>Address is required.</mat-error>
              </mat-form-field>
               <!-- Area, Colony, Street, Sector, Village field -->
               <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Area, Colony, Street, Sector, Village</mat-label>
                <input formControlName="area" matInput maxlength="256">
              </mat-form-field>
            </div>
            <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column">
              <!-- Landmark field -->
              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Landmark</mat-label>
                <input formControlName="Landmark" matInput maxlength="256"
                  placeholder="E.g. Near AIIMS Flyover, Behind Regal Cinema, etc.">
              </mat-form-field>
              <!-- City field -->
              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Town/City</mat-label>
                <input formControlName="City" matInput required>
                <mat-error>Town/City is required.</mat-error>
              </mat-form-field>
            </div>
            <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column">
              <!-- State field -->
              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>State</mat-label>
                <input formControlName="State" matInput required>
                <mat-error>State is required.</mat-error>
              </mat-form-field>
              <!-- Zip field -->
              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>PIN Code</mat-label>
                <input formControlName="pin" matInput required>
                <mat-error>PIN Code is required.</mat-error>
              </mat-form-field>
            </div>
            <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column">
              <!-- Country field -->
              <mat-form-field  appearance="outline" fxFlex="49" fxFlex.lt-sm="100">
                <mat-label>Country/Region</mat-label>
                <input formControlName="country" matInput required>
                <mat-error>Country is required.</mat-error>
              </mat-form-field>
            </div>
          </form>
          <!-- <div *ngIf="error">
            <mat-error>{{this.error}}</mat-error>
          </div> -->
          <!-- Delivery to address button -->
          <div fxLayoutAlign="center center" class="margin-top">
            <button mat-raised-button fxFlex="100" color="primary" (click)="checkout()"
              [disabled]="checkoutFormGroup.invalid">Add Address</button>
          </div>
        </mat-card-content>
        <mat-card-actions>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>