import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public forgotForm: FormGroup;
  public email;
  public error: string;

  constructor(
    public fb: FormBuilder,
    public router: Router) { }

  ngOnInit(): void {
    this.forgotForm = this.fb.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$|^((\\+1-?)|0)?[0-9]{10}$'),
      ]),
    });

    this.email = this.forgotForm.get('email');

    localStorage.removeItem("forgotusername")

  }

  get f() { return this.forgotForm.controls; }
  
  type() {
    var x = '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$'
    var y = this.f.email.value
    var regExp = new RegExp(x);
    if (y !== "") {
      if (regExp.test(y)) {
        const type = "email"
        return type
      } else {
        const type = "phone"
        return type
      }
    }
  }
  public Send() {
    if (this.forgotForm.invalid) {
      return;
    }
    // this.router.navigate(['/phone-verify'])

    if (this.type() == 'email') {
      localStorage.setItem("forgotusername", this.f.email.value)
      this.router.navigate(['/email-verify'])
    } else if (this.type() == 'phone') {
      localStorage.setItem("forgotusername", this.f.email.value)
      this.router.navigate(['/phone-verify'])
    }

  }

  public onInputChange(event) {
    event.target.required = true;
  }

}
