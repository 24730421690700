import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-air-conditioners',
  templateUrl: './air-conditioners.component.html',
  styleUrls: ['./air-conditioners.component.scss']
})
export class AirConditionersComponent implements OnInit {

  items = [
    { image: 'assets/images/gl-home/ac/card/10.jpg', name: 'Voltas 1.5 Ton 5 Star Window AC (Copper 185 LZH/185 LZH R32 White)', company: 'Voltas', newprice: '₹ 25,950', oldprice: '₹ 38,990', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '1,290' },
    { image: 'assets/images/gl-home/ac/card/4.jpg', name: 'LG 1.5 Ton 5 Star Wi-Fi Inverter Window AC (Copper, 2020 Model, JW-Q18WUZA, White)', company: 'LG', newprice: '₹ 31,430', oldprice: '₹ 42,990', freedelivery: 'FREE Scheduled Delivery', upto: '.', deliverydate: '', reviewno: '213' },
    { image: 'assets/images/gl-home/ac/card/7.jpg', name: 'Voltas 1.5 Ton 3 Star Window AC (Copper 183 DZA/ 183 DZA R32 White)', company: 'Voltas', newprice: '₹ 24,990', oldprice: '₹ 29,690', freedelivery: 'FREE Delivery', upto: '', deliverydate: '', reviewno: '838' },
  ];
  items1 = [
    { image: 'assets/images/gl-home/ac/card/2.jpg', name: 'LG 1.5 Ton 3 Star Hot and Cold Inverter Split AC-Ez Clean Filter (Copper, LS-H18VNXD, White)', company: 'LG', newprice: '₹ 40,240', oldprice: '₹ 59,990', freedelivery: 'FREE Scheduled Delivery', upto: '.', deliverydate: '', reviewno: '34' },
    { image: 'assets/images/gl-home/ac/card/8.jpg', name: 'Voltas 1.5 Ton 3 Star Split AC (Copper 183EZA White)', company: 'Voltas', newprice: '₹ 30,999', oldprice: '₹  52,690', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '560' },
    { image: 'assets/images/gl-home/ac/card/5.jpg', name: 'Blue Star 1.5 Ton 3 Star Inverter Split AC (Copper, IC318QATU, White + Champagne Gold)', company: 'Blue Star', newprice: '₹ 34,890', oldprice: '₹ 56,500', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '27' },
  ];
  items2 = [
    { image: 'assets/images/gl-home/ac/card/1.jpg', name: 'Sonia B4 Continuous Video Light with 1000 Watt Halogen Tube for Video Cameras and YouTube Video Shooting', company: 'SONIA', newprice: '₹ 499', oldprice: '₹ 850', freedelivery: 'FREE Delivery', upto: '', deliverydate: 'Thursday, Dec 31', reviewno: '198' },
    { image: 'assets/images/gl-home/ac/card/11.jpg', name: 'Blue Star 1.5 Ton 5 Star Inverter Split AC (Copper, IC518DBTU, Auto Adjustable, 2020 Model, White)', company: 'Blue Star', newprice: '₹ 41,773', oldprice: '₹ 63,000', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '65' },
    { image: 'assets/images/gl-home/ac/card/6.jpg', name: 'Hitachi 1.5 Ton 5 Star Inverter Split AC (Copper RSOG518HDEA Gold)', company: 'Hitachi', newprice: '₹ 41,790', oldprice: '₹ 60,090', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '116' },
  ];
  items3 = [
    { image: 'assets/images/gl-home/ac/card/5.jpg', name: 'Haier 1.5 Ton 3 Star Split AC (Copper,High Density Filter, 2020 Model, HSU18T-NMW3B, White)', company: 'Haier', newprice: '₹ 27,990', oldprice: '₹ 54,500', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '14' },
    { image: 'assets/images/gl-home/ac/card/12.jpg', name: 'Daikin 1.5 Ton 5 Star Inverter Split AC (Copper, Anti Microbial Filter, 2018 Model, FTKG50TV White)', company: 'Daikin', newprice: '₹ 41,990', oldprice: '₹ 55,400', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '195' },
    { image: 'assets/images/gl-home/ac/card/9.jpg', name: 'Voltas 1.5 Ton 3 Star Inverter Split AC (Copper 183VCZS White)', company: 'Voltas', newprice: '₹ 32,475', oldprice: '₹ 59,190', freedelivery: 'FREE Scheduled Delivery', upto: '', deliverydate: '', reviewno: '1,169' },
  ];
  
  constructor( public router: Router,) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 50,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/images/gl-home/ac/carousel/1.jpg',
      price: '₹ 38,890',
      oldprice: '₹ 56,690',
      off: '(31% off)',
      name: 'LG 1.5 Ton 4 Star Inverter Split AC (Copper, LS-Q18YNYA, Convertibl...',
      brand: 'Sold by Cloudtail India.',
      reviewno: '122'
    },
    {
      id: 2,
      src: './assets/images/gl-home/ac/carousel/2.jpg',
      price: '₹ 27,990',
      oldprice: '₹ 54,900',
      off: '(49% off)',
      name: 'Godrej 1.5 Ton 2 Star Hot & Cold Split AC (Copper, Anti Bacterial Fil...',
      brand: 'Sold by Cloudtail India.',
      reviewno: '158'
    },
    {
      id: 3,
      src: './assets/images/gl-home/ac/carousel/3.jpg',
      price: '₹ 30,790',
      oldprice: '₹ 46,900',
      off: '(34% off)',
      name: 'Godrej 1.5 Ton 3 Star Split AC (Copper, Anti Bacterial Filter, 202...',
      brand: 'Sold by Cloudtail India.',
      reviewno: '230'
    },
    {
      id: 4,
      src: './assets/images/gl-home/ac/carousel/4.jpg',
      price: '₹ 32,800',
      oldprice: '₹ 43,502',
      off: '(25% off)',
      name: 'TOSHIBA 1 Ton 3 Star Inverter Split AC (Copper, RAS-13BKCV-IN+RA...',
      brand: 'Sold by Cloudtail India.',
      reviewno: '31'
    },
    {
      id: 5,
      src: './assets/images/gl-home/ac/carousel/5.jpg',
      price: '₹ 25,490',
      oldprice: '₹ 34,900',
      off: '(27% off)',
      name: 'Cruise 1 Ton Portable AC (Copper, CPCATF-PQ3S12, White/Piano Bl...',
      brand: 'Sold by Cloudtail India.',
      reviewno: '3'
    },
    {
      id: 6,
      src: './assets/images/gl-home/ac/carousel/6.jpg',
      price: '₹ 59,500',
      oldprice: '₹ 74,095',
      off: '(20% off)',
      name: 'TOSHIBA 1.8 Ton 5 Star Inverter Split AC (Copper, RAS-22N3KCV-...',
      brand: 'Sold by Cloudtail India.',
      reviewno: '8'
    },
    {
      id: 7,
      src: './assets/images/gl-home/ac/carousel/7.jpg',
      price: '₹ 53,829',
      oldprice: '₹ 68,100',
      off: '(21% off)',
      name: 'Daikin 1.8 Ton 4 Star Inverter Split AC (Copper FTKP60TV16U White)',
      brand: 'Sold by Cloudtail India.',
      reviewno: '26'
    },
    {
      id: 8,
      src: './assets/images/gl-home/ac/carousel/8.jpg',
      price: '₹ 27,890',
      oldprice: '₹ 42,900',
      off: '(35% off)',
      name: 'Godrej 1 Ton 3 Star Inverter Split AC (Copper, Anti Bacterial Filter, 2...',
      brand: 'Sold by Cloudtail India.',
      reviewno: '52'
    },
  ]

  customOptions1: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    stagePadding: 30,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/gl-home/ac/carousel1/1.jpg',
      price: '₹ 30,790',
      oldprice: '₹ 46,900',
      name: 'Godrej 1.5 Ton 3 Star Split AC (Copper, Anti Bacterial ',
      brand: 'Godrej',
      reviewno: '230'
    },
    {
      id: 2,
      src: './assets/images/gl-home/ac/carousel1/2.jpg',
      price: '',
      oldprice: '',
      name: 'Sanyo 1 Ton 3 Star Inverter Split AC (Copper',
      brand: 'Sanyo',
      reviewno: '1,189'
    },
    {
      id: 3,
      src: './assets/images/gl-home/ac/carousel1/3.jpg',
      price: '₹ 34,390',
      oldprice: '₹ 54,900',
      name: 'Godrej 1.5 Ton 5 Star Inverter Split AC (Copper,',
      brand: 'Godrej',
      reviewno: '116'
    },
    {
      id: 4,
      src: './assets/images/gl-home/ac/carousel1/4.jpg',
      price: '₹ 23,490',
      oldprice: '₹ 35,990',
      name: 'TCL Elite Turbo 1 ton 3 Star Ultra-Inverter Split AC',
      brand: 'TCL',
      reviewno: '24'
    },
    {
      id: 5,
      src: './assets/images/gl-home/ac/carousel1/5.jpg',
      price: '₹ 31,490',
      oldprice: '₹ 48,900',
      name: 'Godrej 1.5 Ton 3 Star Inverter Split AC (Copper,',
      brand: 'Godrej',
      reviewno: '113'
    },
    {
      id: 6,
      src: './assets/images/gl-home/ac/carousel1/6.jpg',
      price: '₹ 46,190',
      oldprice: '₹ 63,990',
      name: 'LG 2 Ton 3 Star Inverter Split AC (Copper, 2019',
      brand: 'LG',
      reviewno: '84'
    },
    {
      id: 7,
      src: './assets/images/gl-home/ac/carousel1/7.jpg',
      price: '₹ 25,950',
      oldprice: '₹ 38,990',
      name: 'Voltas 1.5 Ton 5 Star Window AC (Copper 185',
      brand: 'Voltas',
      reviewno: '1,209'
    },
    {
      id: 8,
      src: './assets/images/gl-home/ac/carousel1/8.jpg',
      price: '₹ 23,690',
      oldprice: '₹ 29,890',
      name: 'Hitachi 1.5 Ton 3 Star Window AC (RAW318KUD',
      brand: 'Hitachi',
      reviewno: '295'
    },
    {
      id: 9,
      src: './assets/images/gl-home/ac/carousel1/9.jpg',
      price: '₹ 29,490',
      oldprice: '₹ 47,900',
      name: 'Godrej 1 Ton 5 Star Inverter Split AC (Copper, ',
      brand: 'Godrej',
      reviewno: '79'
    },
    {
      id: 10,
      src: './assets/images/gl-home/ac/carousel1/10.jpg',
      price: '',
      oldprice: '',
      name: 'Samsung 1.5 Ton 3 Star Inverter Split AC (Copper',
      brand: 'Samsung',
      reviewno: '156'
    },
  ]

  customOptions2: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore2 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel1/1.jpg',
      text: 'Emporium Car Back Pain Relief Lower Back Support for Chair Back Rest for Office Chair Lumbar…',
      reviewno: '242',
      price: '₹ 349.00'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel1/2.jpg',
      text: 'BLUEWUD Clonard Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '242',
      price: '₹ 2,899.00'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel1/3.jpg',
      text: 'BLUEWUD Mallium Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '331',
      price: '₹ 3,339.00'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel1/4.jpg',
      text: 'OGU Om Cotton Round Batti Wicks for Pooja Aarti (White) -2100 Pieces',
      reviewno: '61',
      price: '₹ 250'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel1/5.jpg',
      text: 'pTron Bassbuds Pro in-Ear True Wireless Bluetooth 5.0 Headphones with Deep Bass, Touch Control…',
      reviewno: '8,370',
      price: '₹ 1,299.00'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel1/6.jpg',
      text: 'HealthSense Soft-Spot BC 21 Orthopedic Backrest Cushion with Memory Foam for Study, Home,…',
      reviewno: '931',
      price: '₹ 1,399.00'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel1/7.jpg',
      text: 'Nutriplato-enriching lives Roasted Salted Flax Seeds, 500 g',
      reviewno: '325',
      price: '₹ 199.00'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel1/8.jpg',
      text: 'ADOC Chairs Oren Medium Back Ergonomic Office Chair with Mesh Back, Fixed Comfortable…',
      reviewno: '58',
      price: '₹ 3,049.00'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel1/9.jpg',
      text: 'Angel Bear 1 Cup Toothbrush Toothpaste Stand Holder Bathroom Storage Organizer,Plastic',
      reviewno: '379',
      price: '₹ 249.00'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel1/10.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '165',
      price: '₹ 3,999.00'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel1/11.jpg',
      text: 'TIZUM High Speed HDMI Cable with Ethernet - Supports 3D, 4K and Audio Return (1.8 Meter/ 6 Feet)',
      reviewno: '2,281',
      price: '₹ 145.00'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel1/12.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '30',
      price: '₹ 3,799.00'
    },
  ]
  customOptions3: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore3 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel2/1.jpeg',
      text: 'VPN Software & VPN free unlimited (Fire TV, 4k sticks)',
      reviewno: '1,108',
      price: 'BackySoft'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel2/2.jpg',
      text: 'AirScreen - AirPlay & Google Cast & Miracast & DLNA',
      reviewno: '34,550',
      price: '重庆爱奥尼科技有限公司'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel2/3.jpeg',
      text: 'Trashy Movies Channel',
      reviewno: '1,561',
      price: 'POV Horror'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel2/4.jpeg',
      text: 'Wicked Horror TV',
      reviewno: '3,961',
      price: 'POV Horror'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel2/5.jpeg',
      text: 'Trashy Horror Movies',
      reviewno: '1,375',
      price: 'POV Horror'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel2/6.jpeg',
      text: 'B-Movie Club',
      reviewno: '738',
      price: 'POV Horror'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel2/7.jpeg',
      text: 'VPN Lite & Full Privacy (RVPN)',
      reviewno: '358',
      price: 'BackySoft'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel2/8.jpg',
      text: 'Plex: Stream Movies, Shows, Live TV, Music, and More',
      reviewno: '51,727',
      price: 'Plex'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel2/9.jpeg',
      text: 'Martial Arts and Kung-Fu Movie Channel',
      reviewno: '4,629',
      price: 'POV Horror'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel2/10.jpg',
      text: 'NordVPN - Fast, Secure and Unlimited VPN app for Android. Stay secure and…',
      reviewno: '21,882',
      price: 'nordvpn s.a.'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel2/11.jpeg',
      text: 'Thriller Movie Channel',
      reviewno: '232',
      price: 'POV Horror'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel2/12.jpeg',
      text: 'Cleaner for Fire Tablets & TV (Cleanza)',
      reviewno: '6,219',
      price: 'Quickla Soft'
    },
  ]

}
