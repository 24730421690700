import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/_services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  hide = true;
  loginFormGroup: FormGroup;
  public txtEmail;
  public txtPassword;
  returnUrl: any;
  error: string;
  firebaseId: string;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private authenticationService: AuthenticationService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/home']);
    }
  }

  ngOnInit(): void {
    this.loginFormGroup = this.formBuilder.group({
      password: new FormControl('', Validators.required),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$|^((\\+1-?)|0)?[0-9]{10}$'),
      ]),
    });

    this.txtEmail = this.loginFormGroup.get('email');
    this.txtPassword = this.loginFormGroup.get('password');
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    localStorage.removeItem('currentUser')

  }

  get lf() { return this.loginFormGroup.controls; }
  type() {
    var x = '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$'
    var y = this.lf.email.value
    var regExp = new RegExp(x);
    if (y !== "") {
      if (regExp.test(y)) {
        const type = "email"
        return type
      } else {
        const type = "phone"
        return type
      }
    }
  }

  login() {
    if (this.loginFormGroup.invalid) {
      return;
    }
    this.authenticationService.login(this.type() == 'email' ? this.lf.email.value : '', this.lf.password.value, this.type(), this.type() == 'phone' ? this.lf.email.value : '')

      .pipe(first())
      .subscribe(
        data => {
          console.log(this.loginFormGroup.value)
          this.router.navigate([this.returnUrl]);
          localStorage.setItem("yourEmail", this.lf.email.value);
        },
        error => {

          this.error = error.error.message;
        });
  }
}
