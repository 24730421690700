<div class="container cardpadding" fxLayout="column">
  <div fxLayout="row" fxLayout.lt-md="column" fxFlex>
    <div fxLayout="column" fxShow.lt-sm fxHide>
      <p class="mat-h3">Samsung Galaxy M51 ({{selectedValue}}, {{selectedValue1}})</p>
      <p class="mat-small">for "samsung m51"</p>
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <div class="star" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon color="primary">star</mat-icon>
          <mat-icon color="primary">star</mat-icon>
          <mat-icon color="primary">star</mat-icon>
          <mat-icon color="primary">star</mat-icon>
          <mat-icon color="primary">star_border</mat-icon>
          <mat-icon>keyboard_arrow_down</mat-icon>
          <p class="color-accent color-hover">24,525</p>
        </div>
      </div>
    </div>
    <div class="gallery-wrapper" fxLayoutAlign="center start" fxFlex fxFlex.lt-sm="100">
      <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
    </div>
    <div fxLayout="column" fxFlex="58" fxFlex.lt-md="100">
      <div fxLayout="row" fxLayout.lt-sm="column" fxFlex>
        <div class="cardpadding" fxLayout="column" fxLayoutGap="10px" fxFlex="60" fxFlex.lt-md="100">
          <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px" fxShow.lt-sm fxHide>
            <p class="mat-h4 color-secondary"> Colour: <span class="color-black font-bold"> {{selectedValue}}</span>
            </p>
            <div fxLayout="row">
              <mat-form-field color="accent" appearance="outline">
                <mat-label>Colour</mat-label>
                <mat-select [(ngModel)]="selectedValue" name="food">
                  <mat-option value="Celestial Black">
                    <mat-icon class="blk-icon"></mat-icon> Celestial Black
                  </mat-option>
                  <mat-option value="Electric Blue">
                    <mat-icon class="blu-icon"></mat-icon>Electric Blue
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px" fxShow.lt-sm fxHide>
            <p class="mat-h4 color-secondary"> Style Name: <span class="color-black font-bold">
                {{selectedValue1}}</span> </p>
            <div fxLayout="row">
              <mat-form-field color="accent" appearance="outline">
                <mat-label>Style Name</mat-label>
                <mat-select [(ngModel)]="selectedValue1">
                  <mat-option *ngFor="let style of styles" [value]="style.value">
                    {{style.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div fxLayout="column" fxHide.lt-sm fxShow>
            <p class="mat-h3">Samsung Galaxy M51 ({{selectedValue}}, {{selectedValue1}})</p>
            <p class="mat-small">for "samsung m51"</p>
            <div class="star" fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon>keyboard_arrow_down</mat-icon>
              <p class="color-accent color-hover">24,525</p>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column" fxLayoutAlign="center center">
            <p class="mat-h4 color-secondary">M.R.P.: <span class="text-line-through"> ₹ 28,999.00</span></p>
            <p class="mat-h4 color-secondary">Deal of the day: <span class="mat-h3 color-danger">₹ 22,999.00</span>
            </p>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
              <p class="font-bold">Free Delivery.</p>
              <span class="color-accent">Detail</span>
            </div>
            <p class="mat-h4 color-secondary">You Save: <span class="color-danger"> ₹ 6,000.00 (21%)</span></p>
            <p>Inclusive of all taxes</p>
          </div>
          <div fxLayout="row" class="icon-box" fxLayoutAlign="space-between start">
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="20" fxLayoutGap="10px">
              <img src="assets/images/product/icon1.png" alt="">
              <p class="color-accent text-center">Pay on Delivery</p>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="20" fxLayoutGap="10px">
              <img src="assets/images/product/icon2.png" alt="">
              <p class="color-accent text-center">7 Days Replacement</p>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="20" fxLayoutGap="10px">
              <img src="assets/images/product/icon4.png" alt="">
              <p class="color-accent text-center">1 Year Warranty</p>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="5px">
            <p class="mat-h3 color-green">In Stock</p>
            <p class="mat-h4">Sold by <span class="color-accent">STPL Exclusive Online</span></p>
            <div class="margin-top" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" fxFlex="38" fxShow.lt-sm
              fxHide>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                <p class="color-accent">Share</p>
                <mat-icon>mail_outline</mat-icon>
                <mat-icon color="accent">facebook</mat-icon>
              </div>
              <mat-card>
                <div class="cardpadding" fxLayout="column" fxLayoutGap="10px">
                  <div fxLayout="column" fxLayoutGap="20px">
                    <button mat-raised-button color="primary">Add to cart</button>
                    <button mat-raised-button color="primary" (click)="buynow()">Buy Now </button>
                    <button mat-raised-button class="bg-secondary">Add a Wish List</button>
                  </div>
                </div>
              </mat-card>
            </div>
            <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px" fxHide.lt-sm fxShow>
              <p class="mat-h4 color-secondary"> Colour: <span class="color-black font-bold"> {{selectedValue}}</span>
              </p>
              <div fxLayout="row">
                <mat-form-field color="accent" appearance="outline">
                  <mat-label>Colour</mat-label>
                  <mat-select [(ngModel)]="selectedValue" name="food">
                    <mat-option value="Celestial Black">
                      <mat-icon class="blk-icon"></mat-icon> Celestial Black
                    </mat-option>
                    <mat-option value="Electric Blue">
                      <mat-icon class="blu-icon"></mat-icon>Electric Blue
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px" fxHide.lt-sm fxShow>
              <p class="mat-h4 color-secondary"> Style Name: <span class="color-black font-bold">
                  {{selectedValue1}}</span> </p>
              <div fxLayout="row">
                <mat-form-field fxFlex="50" color="accent" appearance="outline">
                  <mat-label>Style Name</mat-label>
                  <mat-select [(ngModel)]="selectedValue1">
                    <mat-option *ngFor="let style of styles" [value]="style.value">
                      {{style.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxLayout="column">
            <p class="mat-h3 font-bold">Features & details</p>
            <ul>
              <li>Quad camera setup - 64MP (F1.8) main camera + 12MP (F2.2) ultra wide camera + 5MP (F2.4) depth camera
                + 5MP (F2.4) macro camera and 32MP (F2.2) front camera</li>
              <li>16.95 cm (6.7-inch) sAMOLED Plus - Infinity-O display, FHD+ capacitive touchscreen with 1080 x 2400
                pixels resolution, Contrast Ratio: 78960:1</li>
              <li>Memory, Storage & SIM: 6GB RAM, 128GB internal memory expandable up to 512GB | Dedicated Memory slot
              </li>
              <li>Android v10.0 operating system with 2.2GHz+1.8GHz Qualcomm | SD730G octa core processor</li>
              <li>7000mAH lithium-ion battery | 25W USB Type-C to C fast charger in the box</li>
              <li>Fast face unlock and Side Fingerprint sensor | Dual SIM (nano+nano) with dual standby and dual VoLTE |
                3.5 mm headphone jack</li>
              <li>1 year manufacturer warranty for device and 6 months manufacturer warranty for in-box accessories
                including batteries from the date of purchase</li>
              <li>Box also includes: Travel adapter, USB Type-C to C, ejection pin and user manual</li>
            </ul>
          </div>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
            <div fxLayout="row" fxHide.lt-sm>
              <button mat-raised-button color="primary" (click)="productCompare()">Compare With Similar Products</button>
            </div>
            <div fxLayout="row">
              <button mat-raised-button class="bg-secondary" (click)="productReview()">Write a product review</button>
            </div>
          </div>
        </div>
        <div class="margin-top" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px" fxFlex="38" fxHide.lt-sm>
          <div class="social-icon" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
            <p class="color-accent">Share</p>
            <mat-icon>mail_outline</mat-icon>
            <mat-icon color="accent">facebook</mat-icon>
          </div>
          <mat-card>
            <div class="cardpadding" fxLayout="column" fxLayoutGap="10px">
              <div fxLayout="column" fxLayoutGap="20px">
                <button mat-raised-button color="primary">Add to cart</button>
                <button mat-raised-button color="primary" (click)="buynow()">Buy Now </button>
                <button mat-raised-button class="bg-secondary">Add a Wish List</button>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <div class="card slider-items" fxShow.gt-sm fxHide>
    <p class="mat-h2 padding-top">Products related to this item</p>
    <mat-divider class="full-width"></mat-divider>
    <div>
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
          <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px" fxFlex>
            <div fxLayout="row">
              <img [src]="slide.src">
            </div>
            <div class="hover-border" fxLayout="column" fxLayoutAlign="start start">
              <p class="mat-small color-accent">{{slide.name}}</p>
              <div class="star" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star_border</mat-icon>
                <mat-icon>keyboard_arrow_down</mat-icon>
                <p class="color-accent color-hover">{{slide.reviewno}}</p>
              </div>
              <p class="mat-h4 color-danger">{{slide.price}}</p>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-title">Product Description</p>
      <p class="mat-small padding-side">The vivo V19 is equipped with 32MP Dual Front Camera, Super Night Selfie, Super
        Wide Selfie,
        Aura Screen Light, 48MP AI Rear Camera, 33W vivo Flash Charge 2.0 with 4500mAh Big Battery, Qualcomm Snapdragon
        712AIE, 16.36cm (6.44-inch) LIV Super AMOLED FHD+ Dual iView Display,8GB RAM with up to 256GB Internal Memory
        Storage expandable up to 512GB and much more.</p>
    </div>
  </div>
</div>


<div class="card-shadow" fxHide>
  <mat-card>
    <div class="cardpadding" fxLayout="column" fxLayoutGap="30px">
      <div class="slider-items1" fxLayout="column" fxLayoutGap="20px">
        <p class="mat-title">Inspired by your browsing history</p>
        <owl-carousel-o [options]="customOptions1" class="cardpadding">
          <ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
            <img [src]="slide.src">
            <div fxLayout="column" fxLayoutAlign="start start">
              <span class="color-accent color-hover">{{slide.text}}</span>
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star</mat-icon>
                <mat-icon color="primary">star_border</mat-icon>
                <mat-icon>keyboard_arrow_down</mat-icon>
                <p class="color-accent color-hover">{{slide.reviewno}}</p>
              </div>
              <span class="color-danger">{{slide.price}}</span>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </mat-card>
</div>