<h2 mat-dialog-title>
  <div fxLayout="row" fxLayoutGap="5px" fxFlex>
    <div fxLayout="row" fxFlex="20">
    </div>
    <div *ngFor="let item of items1" fxLayout="row" fxFlex="80">
      <div fxFlex>
        <div fxLayout="row">
          <div class="img-height" fxLayout="row" fxLayoutAlign="center center">
            <img src="{{item.img}}" alt="">
          </div>
          <div fxLayout="column">
            <p class="overflow-hidden mat-small color-hover">{{item.name}}</p>
            <p class="mat-small font-bold color-danger">{{item.price}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</h2>
<mat-dialog-content class="mat-typography">
  <div fxLayout="column" *ngFor="let item of items">
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutGap="" fxFlex>
      <div class="bg-secondary cardpadding" fxLayout="column" fxFlex="20">
        <p class="mat-h4">{{item.title}}</p>
      </div>
      <mat-divider [vertical]="true">
      </mat-divider>
      <div fxLayout="row" fxFlex="80">
        <div class="bg-secondary cardpadding" fxLayout="row" fxFlex="25">
          <p class="mat-h4">{{item.one}}</p>
        </div>
        <div class="cardpadding" fxLayout="row" fxFlex="25">
          <p class="mat-h4">{{item.two}}</p>
        </div>
        <div class="cardpadding" fxLayout="row" fxFlex="25">
          <p class="mat-h4">{{item.three}}</p>
        </div>
        <div class="cardpadding" fxLayout="row" fxFlex="25">
          <p class="mat-h4">{{item.four}}</p>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>