<div class="container" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column">
    <div class="cardpadding" fxLayout="column" fxLayoutAlign="start start" fxHide fxShow.gt-sm>
      <p class="mat-title">Cameras & Photography</p>
      <p><a class="color-accent text-underline" routerLink="/best-seller">Bestsellers</a> | Shop for popular Digital
        Cameras such as
        <a class="color-accent text-underline">Digital SLRs</a>,
        <a class="color-accent text-underline">Points & Shoots Cameras</a>,
        <a class="color-accent text-underline">Mirrorless Cameras</a>,
        <a class="color-accent text-underline">Camera Lenses</a>,
        <a class="color-accent text-underline">Camera Cases & Bags</a> and other
        <a class="color-accent text-underline">Camera Accessories</a> from Brands such as
        <a class="color-accent text-underline">Canon</a>,
        <a class="color-accent text-underline">Sony</a>,
        <a class="color-accent text-underline">Fuljifilm</a>,
        <a class="color-accent text-underline">Lowepro</a>, and many more. Buy cameras and other photography equipment
        and devices such as
        <a class="color-accent text-underline">Camcorders</a>,
        <a class="color-accent text-underline">Binoculars & Telescopes</a>,
        <a class="color-accent text-underline">CCTV Cameras</a>,
        <a class="color-accent text-underline">Security Cameras</a>,
        <a class="color-accent text-underline">Action Cameras</a>,
        <a class="color-accent text-underline">Spy Cameras</a>, and <a class="color-accent text-underline">Digital Photo
          Frames</a>.
      </p>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxShow.lt-sm fxHide>
      <p class="mat-headline text-center">New & upcoming launches in Cameras and Accessories</p>
    </div>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxLayoutGap="20px" fxHide.lt-sm fxShow>
      <div fxLayout="column" fxLayoutGap="5px">
        <p class="mat-small font-bold">Department</p>
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Electronics</p>
        </div>
        <div class="padding-side " fxLayout="column">
          <p class="mat-small font-bold">Cameras & Photography</p>
          <div class="padding-side" fxLayout="column">
            <p class="mat-small color-hover">Accessories</p>
            <p class="mat-small color-hover">Action Cameras & Accessories</p>
            <p class="mat-small color-hover">Binoculars, Telescopes & Optics/p>
            <p class="mat-small color-hover">Cases & Bags</p>
            <p class="mat-small color-hover">Video Cameras</p>
            <p class="mat-small color-hover">DSLR Cameras</p>
            <p class="mat-small color-hover">Digital Cameras</p>
            <p class="mat-small color-hover">Film Cameras</p>
            <p class="mat-small color-hover">Flashes</p>
            <p class="mat-small color-hover">Photo Studio & Lighting</p>
            <p class="mat-small color-hover">Lenses</p>
            <p class="mat-small color-hover">Simulated Cameras</p>
            <p class="mat-small color-hover">Security Cameras</p>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="5px">
          <div fxLayout="column">
            <p class="mat-small font-bold">Pay on Delivery</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Eligible for Pay On
              Delivery</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Brands</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Tygot</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Fujifilm</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">MI</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Panasonic</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">GIZGA essentials</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">AmazonBasics</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Sketchfab</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Avg. Customers Reviews</p>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">New Arrivals</p>
            <p class="mat-small color-hover">Last 30 days</p>
            <p class="mat-small color-hover">Last 90 days</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Item Condition <span class="mat-h5">(What's this?)</span></p>
            <p class="mat-small color-hover">New</p>
            <p class="mat-small color-hover">Renewed</p>
            <p class="mat-small color-hover">Used</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">price</p>
            <p class="mat-small color-hover">Under ₹1,000</p>
            <p class="mat-small color-hover">₹1,000 - ₹5,000</p>
            <p class="mat-small color-hover">₹5,000 - ₹10,000</p>
            <p class="mat-small color-hover">₹10,000 - ₹20,000</p>
            <p class="mat-small color-hover">Over ₹20,000</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Deals</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Today's Deals
            </mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Discount</p>
            <p class="mat-small color-hover">10% off or more</p>
            <p class="mat-small color-hover">25% off or more</p>
            <p class="mat-small color-hover">35% off or more</p>
            <p class="mat-small color-hover">50% off or more</p>
          </div>
          <!-- <div fxLayout="column">
            <p class="mat-small font-bold">Seller</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">UrCart</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">ShopsGeniune
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cart2India Online
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Card2India SLP
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Global Klamp
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">BENEDICTIONS
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Kiron
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Macron India
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Appario Retails Private
              Ltd.
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">ShopMagics
            </mat-checkbox>
          </div> -->
          <div fxLayout="column">
            <p class="mat-small font-bold">Availability</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Include Out of Stock
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div>
      <mat-divider [vertical]="true" fxFlex="100"></mat-divider>
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
      <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
        <p class="mat-h2 padding-top">Top deals in Audio</p>
        <mat-divider class="full-width"></mat-divider>
        <div>
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
              <div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px" fxFlex>
                <div fxLayout="row" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div fxLayout="column" fxLayoutAlign="start start">
                  <p class="mat-title color-danger">{{slide.price}} </p>
                  <div fxLayout="row" fxLayoutGap="5px">
                    <p class="mat-small text-line-through">{{slide.oldprice}} </p> <span
                      class="mat-small">{{slide.off}}</span>
                  </div>
                  <p class="mat-h4 color-accent" (click)="products()">{{slide.name}}</p>
                  <p class="mat-small">{{slide.brand}}</p>
                  <div fxLayout="row" fxLayoutAlign="start center" fxHide>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{slide.reviewno}}</p>
                  </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="start stretch">
                  <button mat-raised-button color="primary">{{slide.button}}</button>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div fxLayout="row">
        <img src="assets/images/electronics/camera/banner1.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/camera/four/1.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/camera/four/2.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/camera/four/3.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/camera/four/4.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/camera/four/5.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/camera/four/6.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/camera/four/7.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/camera/four/8.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/camera/four/9.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/camera/four/10.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/camera/four/11.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/camera/four/12.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/camera/four/13.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/camera/four/14.jpg" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/electronics/camera/four/15.jpg" alt="" fxFlex="48">
          <img src="assets/images/electronics/camera/four/16.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/electronics/camera/banner2.jpg" alt="" fxFlex="69" fxFlex.lt-sm="100"
          class="full-width">
        <img src="assets/images/electronics/camera/banner3.jpg" alt="" fxFlex="30" fxFlex.lt-sm="100"
          class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/electronics/camera/banner4.jpg" alt="" fxFlex="30" fxFlex.lt-sm="100"
          class="full-width">
        <img src="assets/images/electronics/camera/banner5.jpg" alt="" fxFlex="69" fxFlex.lt-sm="100"
          class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/electronics/camera/banner6.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/electronics/camera/banner7.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/electronics/camera/banner8.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
        <p class="mat-title" fxLayoutAlign="center center">Shop by Brand</p>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/camera/brand/1.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/camera/brand/2.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/camera/brand/3.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/camera/brand/4.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/camera/brand/5.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/camera/brand/6.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/camera/brand/7.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/camera/brand/8.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/camera/brand/9.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/camera/brand/10.jpg   " alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/electronics/camera/brand/11.jpg" alt="" fxFlex="48">
            <img src="assets/images/electronics/camera/brand/12.jpg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/electronics/camera/banner9.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/electronics/camera/banner10.jpg" alt="" class="full-width">
      </div>
      <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
        <p class="mat-h2 padding-top">Save extra with Global Local Coupons</p>
        <div>
          <owl-carousel-o [options]="customOptions1">
            <ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
              <div class="hover-border" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px" fxFlex>
                <div fxLayout="row">
                  <img [src]="slide.src">
                </div>
                <div fxLayout="column" fxLayoutAlign="start start">
                  <p class="mat-title color-green">{{slide.save}} </p>
                  <p class="mat-small color-accent">{{slide.name}}</p>
                </div>
                <div fxLayout="column" fxLayoutAlign="start stretch">
                  <button mat-raised-button color="primary">{{slide.button}}</button>
                  <p class="mat-small color-secondary">Discount at checkout</p>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
  <div class="cardpadding" fxLayout="column" fxLayoutGap="10px" fxHide.lt-sm>
    <div fxLayout="column" fxLayoutGap="5px">
      <p class="mat-headline font-bold">Buy Cameras Online at Global Local India</p>
      <p class="mat-small">Every day, in some way or the other, we create memories that we want to cherish for our
        entire life and the best way to do that is to capture them. A perfect camera is essential to capture these
        perfect memories. Shop online at Global Local India and choose from a wide selection of digital cameras Point & Shoot
        and Digital SLRs, camera accessories, lenses and camcorders. Our online store also features other devices such
        as binoculars & telescopes, CCTV cameras, projectors and more.</p>
    </div>
    <div fxLayout="column" fxLayoutGap="5px">
      <p class="mat-title font-bold">Point & Shoot Digital Cameras</p>
      <p class="mat-small">Point & Shoot digital cameras are good for everyday use as they come with preset settings and
        are easy to operate. These cameras are an ideal choice for simple still photography. </p>
    </div>
    <div fxLayout="column" fxLayoutGap="5px">
      <p class="mat-title font-bold">Digital SLRs</p>
      <p class="mat-small">If you are looking for more advanced features, then you should opt for Digital SLRs,(also
        known as DSLRs) that come with advanced features such as interchangeable lenses, adjustable zoom range and more.
        Designed to offer superior picture quality and performance, DSLR camera are a popular choice among amateur
        photographers and professionals. </p>
    </div>
    <div fxLayout="column" fxLayoutGap="5px">
      <p class="mat-title font-bold">Camera Lenses</p>
      <p class="mat-small">Explore new avenues and enhance the performance of your camera by attaching a superior camera
        lens. From macro lenses to zoom lenses, the collection of lenses available at our online store is sure to meet
        your photography requirements. </p>
    </div>
    <div fxLayout="column" fxLayoutGap="5px">
      <p class="mat-title font-bold">Camcorders</p>
      <p class="mat-small"> Camcorders let you click still images as well as record videos of beautiful memories of your
        life. Whether you wish to create a home video or record a friend’s wedding, this device will always come handy.
      </p>
    </div>
    <div fxLayout="column" fxLayoutGap="5px">
      <p class="mat-title font-bold">Surveillance Cameras</p>
      <p class="mat-small"> Surveillance cameras help in monitoring activities and in ensuring maximum security within
        your home or office premises.</p>
    </div>
    <div fxLayout="column" fxLayoutGap="5px">
      <p class="mat-title font-bold">Binoculars</p>
      <p class="mat-small">Use a pair of binoculars to view distant objects with utmost clarity. Binoculars are
        available in a variety of types and sizes. Choose a pair that best suits your outdoor activity </p>
    </div>
    <div fxLayout="column" fxLayoutGap="5px">
      <p class="mat-title font-bold">Projectors</p>
      <p class="mat-small">Enhance your presentations and bring images and graphs to life with the help of a projector.
        There are generally two types of projectors - multimedia projectors and home theatre projectors. </p>
    </div>
    <div>
      <p class="mat-small">Browse through the cameras,lenses, binoculars, camera accessories, projectors and more at
        Global Local India to choose a device that meets your requirements. You’ll also find a wide selection of surveillance
        cameras, digital picture frames and more from leading brands at our online store.</p>
    </div>
  </div>
</div>