<div class="container" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column">
    <div class="cardpadding" fxLayout="column" fxLayoutAlign="center center">
      <p class="mat-title">Air Conditioners</p>
      <p class="text-center">Planning on buying a new
        air conditioner that suits your needs? Choose from a wide selection of
        <a class="color-accent text-underline">Split</a>,
        <a class="color-accent text-underline">Inverter</a> and
        <a class="color-accent text-underline">Window</a> ACs from
        <a class="color-accent text-underline">Voltas</a>,
        <a class="color-accent text-underline">LG</a>,
        <a class="color-accent text-underline">Carrier</a> and other top brands at
        <a class="color-accent text-underline"> Global Local</a>.
      </p>
    </div>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxLayoutGap="20px" fxHide.lt-sm fxShow>
      <div fxLayout="column" fxLayoutGap="5px">
        <p class="mat-small font-bold">Department</p>
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Home & Kitchen</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Large Appliances</p>
        </div>
        <div class="padding-side" fxLayout="column">
          <p class="mat-small padding-side font-bold">Air Conditioners</p>
        </div>
        <div fxLayout="column" fxLayoutGap="5px">
          <div fxLayout="column">
            <p class="mat-small font-bold">price</p>
            <p class="mat-small color-hover">Under ₹10,000</p>
            <p class="mat-small color-hover">₹10,000 - ₹15,000</p>
            <p class="mat-small color-hover">₹15,000 - ₹20,000</p>
            <p class="mat-small color-hover">₹20,000 - ₹30,000</p>
            <p class="mat-small color-hover">Over ₹30,000</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Large Appliances</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Top Brands</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Brands</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">SONIA</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">LG</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Haier</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Blue Star</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Hitachi</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Voltas</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Daikin</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Air Conditioners Type</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cassette</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Split System</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Window</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Suitable Room Size</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Up to 110 sqft</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">110 to 150 sqft</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">150 to 200 sqft</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Capacity</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Less than 1 Ton</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">1 to 1.3 Tons</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">1.4 to 1.5 Tons</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">1.6 to 1.9 Tons</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">2 Tons & Above</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Energy Star Rating</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">1 Star</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">2 Star</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">3 Star</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">4 Star</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">5 Star</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Coil Material</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Aluminium & Alloy</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cooper</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Features</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Air Purifier</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Antibacterial Coating
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Dehumidifier</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Dust Filter</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Headting & cooling</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">High Density Filter</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Inverter Compressor</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">WiFi</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Refrigerant</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">R22</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">R410A</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">R32</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Avg. Customers Reviews</p>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">New Arrivals</p>
            <p class="mat-small color-hover">Last 30 days</p>
            <p class="mat-small color-hover">Last 90 days</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Discount</p>
            <p class="mat-small color-hover">10% off or more</p>
            <p class="mat-small color-hover">25% off or more</p>
            <p class="mat-small color-hover">35% off or more</p>
            <p class="mat-small color-hover">50% off or more</p>
          </div>
          <!-- <div fxLayout="column">
            <p class="mat-small font-bold">Seller</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Infinite E-sales
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Bluebird_1</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">E-SALES India</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Great Indian Sales</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">TT-Enterprise</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Croma-A TATA Enterprise (Nagpur)
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">COOL ZONE</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">RK VIDEO</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Harshil Home Appliances
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Onsite Electro Service Pvt. Ltd.
            </mat-checkbox>
          </div> -->
          <div fxLayout="column">
            <p class="mat-small font-bold">Availability</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Include Out of Stock
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div>
      <mat-divider [vertical]="true" fxFlex="100"></mat-divider>
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/ac/banner1.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/ac/banner2.png" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/ac/banner3.jpg" alt="" class="full-width" fxFlex="49">
        <img src="assets/images/gl-home/ac/banner4.jpg" alt="" class="full-width" fxFlex="49">
      </div>
      <div class="card slider-items" fxShow.gt-sm fxHide>
        <p class="mat-h2 padding-top">Top deals on storage devices</p>
        <mat-divider class="full-width"></mat-divider>
        <div>
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
              <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px" fxFlex>
                <div fxLayout="row" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div class="hover-border" fxLayout="column" fxLayoutAlign="start start">
                  <p class="mat-h4 color-danger">{{slide.price}}</p>
                  <div fxLayout="row">
                    <p class="font-bold">M.R.P.:<span class="mat-small text-line-through">{{slide.oldprice}}</span></p>
                    <p class="mat-small">{{slide.off}}</p>
                  </div>
                  <p class="mat-small color-accent" (click)="products()">{{slide.name}}</p>
                  <p class="mat-small">{{slide.brand}}</p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{slide.reviewno}}</p>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start stretch">
                  <button mat-raised-button color="primary" fxFlex="80">Add to cart</button>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center center">
          <p class="mat-headline">Trending Now</p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/ac/trend1.png" alt="" fxFlex="48">
            <img src="assets/images/gl-home/ac/trend2.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/ac/trend3.jpg" alt="" fxFlex="48">
            <img src="assets/images/gl-home/ac/trend4.jpg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
        <p class="mat-h2 padding-top">AC | Season’s top picks</p>
        <mat-divider class="full-width"></mat-divider>
        <div>
          <owl-carousel-o [options]="customOptions1">
            <ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
              <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px" fxFlex>
                <div class="image-height" (click)="products()">
                  <img [src]="slide.src">
                </div>
                <div class="hover-border" fxLayout="column" fxLayoutAlign="start start">
                  <p class="color-hover" (click)="products()">{{slide.name}}</p>
                  <p class="mat-small">{{slide.brand}}</p>
                  <p class="mat-h2">{{slide.price}} <span class="mat-small text-line-through">{{slide.oldprice}}</span>
                  </p>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star</mat-icon>
                    <mat-icon color="primary">star_border</mat-icon>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                    <p class="color-accent color-hover">{{slide.reviewno}}</p>
                  </div>
                  
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
        <p class="mat-title" fxLayoutAlign="center center">Explore top brands</p>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/ac/brand1.jpg" alt="" fxFlex="48">
            <img src="assets/images/gl-home/ac/brand2.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/ac/brand3.jpg" alt="" fxFlex="48">
            <img src="assets/images/gl-home/ac/brand4.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/ac/brand5.jpg" alt="" fxFlex="48">
            <img src="assets/images/gl-home/ac/brand6.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/gl-home/ac/brand7.jpg" alt="" fxFlex="48">
            <img src="assets/images/gl-home/ac/brand8.jpg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/ac/banner5.png" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/ac/banner6.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px" fxHide>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gl-home/ac/reason1.jpg" alt="" fxFlex="50">
          <img src="assets/images/gl-home/ac/reason2.jpg" alt="" fxFlex="50">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/gl-home/ac/reason3.jpg" alt="" fxFlex="50">
          <img src="assets/images/gl-home/ac/reason4.jpg" alt="" fxFlex="50">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <img src="assets/images/gl-home/ac/banner7.jpg" alt="" class="full-width">
      </div>
      <div fxLayout="column" fxLayoutGap="40px">
        <div fxLayout="row" class="card-shadow cardpadding">
          <p class="mat-small">1-24 of over 50,000 results for : <span class="font-bold color-primary"> Musical
              Instruments </span></p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                
                <p class="color-accent color-hover" (click)="products()">See Details</p>
              </div>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items1" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                
                <p class="color-accent color-hover" (click)="products()">See Details</p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items2" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                
                <p class="color-accent color-hover" (click)="products()">See Details</p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div *ngFor="let item of items3" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                
                <p class="color-accent color-hover" (click)="products()">See Details</p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row">
        <button mat-raised-button class="color-accent bg-secondary" fxFlex="100">See all results</button>
      </div>
    </div>
  </div>
  <div class="cardpadding" fxLayout="column" fxLayoutGap="10px" fxHide.lt-sm>
    <div fxLayout="column" fxLayoutGap="5px">
      <p class="mat-title color-primary">More About Air Conditioners at Global Local</p>
      <p class="fomt-bold">Shop online for Air Conditioners</p>
      <p class="mat-small">Battling the heat in your home is a thing of past with high-efficiency air conditioners
        available in the market. And, Global Local helps you combat better with its range of air conditioners available in its
        Large Appliances section. It houses air conditioners from all popular brands like LG, Samsung, Voltas,
        Panasonic, Godrej, Blue Star, Videocon, Whirlpool, Hitachi and Lloyd! From split and window style ACs to
        inverter ACs, and capacities ranging from as low as 0.75 ton to more than 2 tons, there are air conditioners of
        all kinds and capacities at Global Local under one roof. Based on your room’s size, choose amongst the different
        styles of air conditioners and stay comfortable year round.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Window AC</p>
      <p class="mat-small">At Global Local, you will find a range of compact window air conditioners that are ideal for
        homes where space is at a premium. From brands like LG, Panasonic, Hitachi and Whirlpool, there is a wide
        selection of window AC units at this online store. Models like Haier 0.8 Ton HW-09CH1N, Voltas 1-ton 123 LY, and
        Blue Star 0.75-ton 2WAE081YB are compact window air conditioner units that take up less space in your room. As
        the compressor, condenser, valve and other components are integrated in a single compact profile, these units
        tend to occupy little of the vertical space. Window air conditioners are perfect for rooms with floor space less
        than 300 square feet like apartments and small offices.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Split AC</p>
      <p class="mat-small">Global Local houses ranges of contemporary split air conditioners that are more aesthetically
        pleasing than their window-mounting counterparts. You will find air conditioning units from brands like Samsung,
        Haier, Voltas and Godrej. With no restrictions on cooling capacity, these ACs are perfect for small to large
        sized rooms. Brands like Samsung offer split air conditioners that do more than just keeping the air cool inside
        the room. They filter the air to leave it clean, fresh and free from dust particles. Most models have the Auto
        Clean feature that helps keep germ dispersal to a minimum. Units with integrated power protection ensure long
        life to the AC and fresh air to the user.</p>
      <p class="mat-small">If you want your AC unit to double as a décor item, split air conditions like Purista Pattern
        AR12HC5TDUR, Dawk Crystal Gloss AR18HC3EXLW, L-Energia and Godrej GSC 12 FV 5 KRLH will be the perfect pick.
        They add to the style factor of your room with their cool colours, patterns and smart design.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Portable AC</p>
      <p class="mat-small">If you are the type of person who frequently changes your home, this section at Global Local has
        what you will need. Global Local India offers portable air conditioners like Lloyd 1-ton LP12HC and Samsung
        AF28FSSDADFNNA that provide cooling wherever you want. These floor-standing portable ACs have all the features
        and functions of a window or split air conditioner including auto-restart, energy saving mode and, of course,
        sleek design.</p>
    </div>
    <div fxLayout="column">
      <p class="fomt-bold">Inverter AC</p>
      <p class="mat-small">If your utility bills are of a great concern, you might probably find a solution in Global Local’s
        Inverter ACs section. Models like Panasonic 1.50-ton CS/CU-YS18PKY, Samsung AR18HV5DAWK, LG 1.5 Ton Inverter
        BS-Q186C8A4 and Daikin 1.5-ton FTKD50 help reduce your energy bills.</p>
      <p class="mat-small">Shopping at Global Local is made easy! Now, all you need to do is narrow down your search based on
        the capacity, your room’s size and budget, and stay comfortable in your home year round.</p>
    </div>
  </div>
</div>
<div class="card-shadow" fxHide>
	<mat-card>
		<div class="cardpadding" fxLayout="column" fxLayoutGap="30px">
			<div class="slider-items1" fxLayout="column" fxLayoutGap="20px">
				<p class="mat-title">Inspired by your browsing history</p>
				<owl-carousel-o [options]="customOptions2" class="cardpadding">
					<ng-template carouselSlide *ngFor="let slide of slidesStore2" [id]="slide.id">
						<img [src]="slide.src">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="color-accent color-hover">{{slide.text}}</span>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star_border</mat-icon>
								<mat-icon>keyboard_arrow_down</mat-icon>
								<p class="color-accent color-hover">{{slide.reviewno}}</p>
							</div>
							<span class="color-danger">{{slide.price}}</span>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
			<div class="slider-items1" fxLayout="column" fxLayoutGap="20px">
				<p class="mat-title">Top subscription apps for you</p>
				<owl-carousel-o [options]="customOptions3" class="cardpadding">
					<ng-template carouselSlide *ngFor="let slide of slidesStore3" [id]="slide.id">
						<img [src]="slide.src">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="color-accent color-hover">{{slide.text}}</span>
							<span class="mat-small">{{slide.price}}</span>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star_border</mat-icon>
								<mat-icon>keyboard_arrow_down</mat-icon>
								<p class="color-accent color-hover">{{slide.reviewno}}</p>
							</div>
							<span class="color-danger">FREE</span>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
		</div>
	</mat-card>
</div>