<div class="container" fxLayout="column" fxLayoutGap="20px">
  <div class="color-bg cardpadding" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-sm="center center"
    fxLayoutGap="10px">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <p class="mat-headline" [ngClass.lt-sm]="'mat-title'">Invite Your Friend</p>
    </div>
  </div>
  <div class="cardpadding" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="card" fxFlex="60" fxFlex.lt-sm="100">
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="refer-img" fxLayout="row" fxLayoutAlign="center center">
          <img src="assets/images/refer/2797357.jpg" alt="">
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
          <div fxLayout="row">
            <p class="mat-title">Share with Link</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
            <mat-form-field appearance="outline" fxFlex="100">
              <input matInput type="text" value="demo.techspinsolutions.com/global-local/" #userinput>
            </mat-form-field>
            <div>
              <button mat-raised-button color="primary" (click)="copyInputMessage(userinput)" value="click to copy">Copy
                Link</button>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <div class="line">
            <mat-divider [inset]="true"></mat-divider>
          </div>
          <div class="mat-small">Or</div>
          <div class="line">
            <mat-divider [inset]="true"></mat-divider>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
          <div fxLayout="row">
            <p class="mat-title">Share with Social Media</p>
          </div>
          <div fxLayout="row" fxLayoutGap="10px">
            <div class="bg-primary round-icon" fxLayoutAlign="center center">
              <i class="fa fa-twitter color-white" aria-hidden="true"></i>
            </div>
            <div class="bg-primary round-icon" fxLayoutAlign="center center">
              <i class="fa fa-google-plus color-white" aria-hidden="true"></i>
            </div>
            <div class="bg-primary round-icon" fxLayoutAlign="center center">
              <i class="fa fa-facebook color-white" aria-hidden="true"></i>
            </div>
            <div class="bg-primary round-icon" fxLayoutAlign="center center">
              <i class="fa fa-youtube color-white" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>