<div class="container" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column">
    <div class="cardpadding" fxLayout="column" fxLayoutAlign="start start" fxHide fxShow.gt-sm>
      <p class="mat-title">Home Entertainment</p>
      <p><a class="color-accent text-underline" routerLink="/best-seller">Bestsellers</a> | Shop for popular
        <a class="color-accent text-underline">Home Theater Systems</a>,
        <a class="color-accent text-underline">Televisions</a>,
        <a class="color-accent text-underline">Projectors</a>,
        <a class="color-accent text-underline">Speakers</a>,
        <a class="color-accent text-underline">Soundbars</a>,
        <a class="color-accent text-underline">DVD players & Recorders</a>,
        <a class="color-accent text-underline">Blu-ryy DVD Players</a>,
        <a class="color-accent text-underline">Streaming Media Players</a>, and
        <a class="color-accent text-underline">Audio Video Accessories</a> across top brands such as
        <a class="color-accent text-underline">Microsoft</a>,
        <a class="color-accent text-underline">Philips</a>,
        <a class="color-accent text-underline">Sony</a>,
        <a class="color-accent text-underline">LG</a>,
        <a class="color-accent text-underline">Bose</a>,
        <a class="color-accent text-underline">Panasonic</a>, and many more.
      </p>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxShow.lt-sm fxHide>
      <p class="mat-headline text-center">New & upcoming launches in electronics</p>
    </div>
  </div>
  <div fxLayout="row" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxLayoutGap="20px" fxHide.lt-sm fxShow>
      <div fxLayout="column" fxLayoutGap="10px">
        <p class="color-secondary mat-h3">Show results for</p>
        <div>
          <div fxLayout="column">
            <p class="mat-small color-hover">Electronics</p>
          </div>
          <div class="padding-side " fxLayout="column">
            <p class="mat-small font-bold">Home, Theater, TV & Video</p>
            <div class="padding-side" fxLayout="column">
              <p class="mat-small color-hover">Accessories</p>
              <p class="mat-small color-hover">Blu-ray Players & Recorders</p>
              <p class="mat-small color-hover">DVD Playres & Recorders</p>
              <p class="mat-small color-hover">HD DVD Playres</p>
              <p class="mat-small color-hover">Portable DVD & Blu-ray Playeres</p>
              <p class="mat-small color-hover">Projectors</p>
              <p class="mat-small color-hover">Soundbar Speakers</p>
              <p class="mat-small color-hover">Televisions</p>
              <p class="mat-small color-hover">TV Recievers</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <mat-divider></mat-divider>
      </div>
      <div fxLayout="column" fxLayoutGap="10px">
        <p class="color-secondary mat-h3">Refind by</p>
        <div fxLayout="column">
          <p class="mat-small font-bold">Pay on Delivery</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Eligible for Pay On
            Delivery</mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Brands</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Global Local</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Boat</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">TP-Link</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">MI</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Global Local Basic</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Samsung</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Zinq Technologies</mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Avg. Customers Reviews</p>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <p class="mat-small color-hover"> & up</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <p class="mat-small color-hover"> & up</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <p class="mat-small color-hover"> & up</p>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon color="primary">star</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <mat-icon color="primary">star_border</mat-icon>
            <p class="mat-small color-hover"> & up</p>
          </div>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">New Arrivals</p>
          <p class="mat-small color-hover">Last 30 days</p>
          <p class="mat-small color-hover">Last 90 days</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Item Condition <span class="mat-h5">(What's this?)</span></p>
          <p class="mat-small color-hover">New</p>
          <p class="mat-small color-hover">Renewed</p>
          <p class="mat-small color-hover">Used</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">price</p>
          <p class="mat-small color-hover">Under ₹1,000</p>
          <p class="mat-small color-hover">₹1,000 - ₹5,000</p>
          <p class="mat-small color-hover">₹5,000 - ₹10,000</p>
          <p class="mat-small color-hover">₹10,000 - ₹20,000</p>
          <p class="mat-small color-hover">Over ₹20,000</p>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Deals</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Today's Deals
          </mat-checkbox>
        </div>
        <div fxLayout="column">
          <p class="mat-small font-bold">Discount</p>
          <p class="mat-small color-hover">10% off or more</p>
          <p class="mat-small color-hover">25% off or more</p>
          <p class="mat-small color-hover">35% off or more</p>
          <p class="mat-small color-hover">50% off or more</p>
        </div>
        <!-- <div fxLayout="column">
          <p class="mat-small font-bold">Seller</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Appario Retails Private Ltd.
          </mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cart2India Online</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cart2India SLP</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">BENEDICTONS</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Kiron</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Global Klamp</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cables & Etc</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">ShopMagics</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">GoSale</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">subala traders</mat-checkbox>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Appario Retail Private Ltd.
          </mat-checkbox>
        </div> -->
        <div fxLayout="column">
          <p class="mat-small font-bold">Availability</p>
          <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Include Out of Stock
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div>
      <mat-divider [vertical]="true" class="full-height"></mat-divider>
    </div>
    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/home-entertainment/2.jpg" alt="" fxFlex="48">
          <img src="assets/images/home-entertainment/3.jpg" alt="" fxFlex="48">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/home-entertainment/4.jpg" alt="" fxFlex="31">
          <img src="assets/images/home-entertainment/5.jpg" alt="" fxFlex="31">
          <img src="assets/images/home-entertainment/6.jpg" alt="" fxFlex="31">
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/home-entertainment/banner1.jpg" alt="" class="full-width">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/home-entertainment/banner2.jpg" alt="" class="full-width">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/home-entertainment/banner3.jpg" alt="" class="full-width">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/home-entertainment/banner4.jpg" alt="" class="full-width">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/home-entertainment/banner5.jpg" alt="" class="full-width">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/home-entertainment/banner6.jpg" alt="" class="full-width">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/home-entertainment/banner7.jpg" alt="" class="full-width">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around start">
          <img src="assets/images/home-entertainment/banner8.jpg" alt="" class="full-width">
        </div>
      </div>
      <div fxLayout="column">
        <p class="mat-headline">Most Gifted <span class="mat-h4 color-accent">see more</span></p>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start">
          <div *ngFor="let item of items5" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start"
            fxFlex="24">
            <div fxLayout="column">
              <div class="section" fxLayoutAlign="center center" (click)="products()">
                <div class="image-bg"></div>
                <img src="{{item.image}}" alt="">
              </div>
              <div class="cardpadding bg-secondary" fxLayout="column">
                <p class="mat-title">{{item.newprice}}</p>
                <p class="text-line-through">{{item.oldprice}}</p>
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column">
        <p class="mat-headline">Hot new releases <span class="mat-h4 color-accent">see more</span></p>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start">
          <div *ngFor="let item of items4" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start"
            fxFlex="24">
            <div fxLayout="column">
              <div class="section" fxLayoutAlign="center center" (click)="products()">
                <div class="image-bg"></div>
                <img src="{{item.image}}" alt="">
              </div>
              <div class="cardpadding bg-secondary" fxLayout="column">
                <p class="mat-title">{{item.newprice}}</p>
                <p class="text-line-through">{{item.oldprice}}</p>
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column">
        <p class="mat-headline">Most wished for <span class="mat-h4 color-accent">see more</span></p>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start">
          <div *ngFor="let item of items6" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start"
            fxFlex="24">
            <div fxLayout="column">
              <div class="section" fxLayoutAlign="center center" (click)="products()">
                <div class="image-bg"></div>
                <img src="{{item.image}}" alt="">
              </div>
              <div class="cardpadding bg-secondary" fxLayout="column">
                <p class="mat-title">{{item.newprice}}</p>
                <p class="text-line-through">{{item.oldprice}}</p>
                <p class="mat-h4 color-hover" (click)="products()">{{item.name}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
        <p class="mat-title" fxLayoutAlign="center center">Brands in Focus</p>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/home-entertainment/brand1.jpg" alt="" fxFlex="48">
            <img src="assets/images/home-entertainment/brand2.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/home-entertainment/brand3.jpg" alt="" fxFlex="48">
            <img src="assets/images/home-entertainment/brand4.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/home-entertainment/brand5.jpg" alt="" fxFlex="48">
            <img src="assets/images/home-entertainment/brand6.jpg" alt="" fxFlex="48">
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/home-entertainment/brand7.jpg" alt="" fxFlex="48">
            <img src="assets/images/home-entertainment/brand8.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/home-entertainment/brand9.jpg" alt="" fxFlex="48">
            <img src="assets/images/home-entertainment/brand10.jpg" alt="" fxFlex="48">
          </div>
          <div fxLayout="row" fxLayoutAlign="space-around start">
            <img src="assets/images/home-entertainment/brand11.jpg" alt="" fxFlex="48">
            <img src="assets/images/home-entertainment/brand12.jpg" alt="" fxFlex="48">
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="40px">
        <div fxLayout="row" class="card-shadow cardpadding">
          <p class="mat-small">1-24 of over 50,000 results for <span class="font-bold color-hover">
              Electronics
            </span>: <span class="font-bold color-primary"> Home Theater, TV & Video </span></p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'slider-items'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-secondary">Sponsored </p>
                <p class="mat-h3 color-accent color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover">See Details</p>

              </div>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items1" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'slider-items'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-secondary">Sponsored </p>
                <p class="mat-h3 color-accent color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover">See Details</p>

              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items2" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'slider-items'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-secondary">Sponsored </p>
                <p class="mat-h3 color-accent color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover">See Details</p>

              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div *ngFor="let item of items3" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'slider-items'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h4 color-secondary">Sponsored </p>
                <p class="mat-h3 color-accent color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover">See Details</p>

              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cardpadding" fxLayout="column" fxLayoutGap="20px" fxHide fxShow.gt-sm>
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-headline">Shop TV and Home Entertainment Systems on Global local India</p>
      <p>Want to turn your living room into a state-of-the-art home theatre? Looking for a television that assures you perfect
      picture quality and outstanding sound? Expand your media experience and enrich your lifestyle by choosing from a wide
      range of TV and home entertainment systems online at Global Local.in. The unbeatable selection of televisions, speakers, home
      theatre systems, Blu-ray players and more from top brands such as Samsung, Philips, Sony, LG and more ensure that you
      find televisions, audio and video products for all your needs. Shop online at Global Local India and find TV and home
      entertainment systems that let you enjoy various media including TV shows, films and music with intense realism.</p>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-headline">Revolutionise Every Aspect of Your Viewing Experience, Find the Perfect TV Online</p>
      <p>Want to bring your home entertainment vision to life? The perfect television for your home largely depends on what you
      love to watch and how you like to watch it. You’ll need to consider the screen type (LCD, LED, Plasma and OLED), screen
      size (from 21 inches to 55 inches and above), display resolution, connectivity and other features. Global Local.in offers you
      a huge selection of TVs, with hundreds of models to choose from the best brands in India. You can refine your search
      results to find the best deals in home entertainment systems online. New-age HDTVs are equipped with a number of
      features such as Wi-Fi connectivity. Choose from the impressive selection of televisions with advanced features, sleek
      designs and innovative technologies. You can read the TV Buying Guide to make sure that you have all the information you
      need to build your ideal entertainment system.</p>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-headline">Transform Your Living Room into a Movie Theatre</p>
      <p>Are you looking forward to enjoy theatre-quality surround sound in the comfort of your home? Want to shop online for
      multi-channel surround sound systems? Whether you are looking for home theatre systems in a compact studio apartment or
      a spacious suburban villa, Global Local India has options to complete your home theatre setup. When you’re at the movies, the
      elements of a giant screen, brilliant visuals and surround sound come together to deliver a stunning viewing experience.
      You can bring this experience into your living room with the right equipment and accessories. Consider DVD players or
      Blu-ray players that make great digital video sources and speakers that provide surround-sound capability. You can
      narrow your search results to find home theatre systems and accessories by price, popularity and other factors. Redefine
      your concept of home entertainment by choosing a home theatre system that delivers great sound and visual output,
      best-suited for your home environment</p>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-headline">Explore All-in-One Entertainment Systems Online</p>
      <p>Seeking space-conscious sound for your home entertainment setup? Want to compare television brands before zeroing in on
      the best TV for your home? Start your search on Global Local.in to find televisions and home theatre systems that help you
      achieve fantastic sound at the best prices in India. You can choose individual components or home theatre systems that
      include all the components. Choose from top brands such as Philips, Sony, LG, Samsung, Bose, Panasonic and more. The
      products have been categorised to facilitate easy search of TV and home entertainment systems online.</p>
    </div>
  </div>
</div>