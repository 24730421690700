<div class="container" fxLayout="column" fxLayoutGap="20px">
	<div fxLayout="row" fxFlex>
		<div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxLayoutGap="20px" fxHide.lt-sm fxShow>
			<div fxLayout="column" fxLayoutGap="5px">
				<p class="mat-small font-bold">Department</p>
				<div fxLayout="row" fxLayoutAlign="start center">
					<mat-icon>keyboard_arrow_left</mat-icon>
					<p class="mat-small color-hover">Electronics</p>
				</div>
				<div class="padding-side " fxLayout="column">
					<p class="mat-small font-bold">Home Audio</p>
					<div class="padding-side" fxLayout="column">
						<p class="mat-small color-hover">Accessories</p>
						<p class="mat-small color-hover">Compact Stereos</p>
						<p class="mat-small color-hover">DVD Playres & Recorders</p>
						<p class="mat-small color-hover">Headphones</p>
						<p class="mat-small color-hover">Radios & Boomboxes</p>
						<p class="mat-small color-hover">Receivers & Separaters</p>
						<p class="mat-small color-hover">Speakers</p>
					</div>
				</div>
				<div fxLayout="column" fxLayoutGap="5px">
					<div fxLayout="column">
						<p class="mat-small font-bold">Pay on Delivery</p>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Eligible for Pay On
							Delivery</mat-checkbox>
					</div>
					<div fxLayout="column">
						<p class="mat-small font-bold">Brands</p>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Boat</mat-checkbox>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">OnePlus</mat-checkbox>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">PTron</mat-checkbox>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">JBL</mat-checkbox>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Wecool</mat-checkbox>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">realme</mat-checkbox>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">INFINITY</mat-checkbox>
					</div>
					<div fxLayout="column">
						<p class="mat-small font-bold">Avg. Customers Reviews</p>
						<div fxLayout="row" fxLayoutAlign="start center">
							<mat-icon color="primary">star</mat-icon>
							<mat-icon color="primary">star</mat-icon>
							<mat-icon color="primary">star</mat-icon>
							<mat-icon color="primary">star</mat-icon>
							<mat-icon color="primary">star_border</mat-icon>
							<p class="mat-small color-hover"> & up</p>
						</div>
						<div fxLayout="row" fxLayoutAlign="start center">
							<mat-icon color="primary">star</mat-icon>
							<mat-icon color="primary">star</mat-icon>
							<mat-icon color="primary">star</mat-icon>
							<mat-icon color="primary">star_border</mat-icon>
							<mat-icon color="primary">star_border</mat-icon>
							<p class="mat-small color-hover"> & up</p>
						</div>
						<div fxLayout="row" fxLayoutAlign="start center">
							<mat-icon color="primary">star</mat-icon>
							<mat-icon color="primary">star</mat-icon>
							<mat-icon color="primary">star_border</mat-icon>
							<mat-icon color="primary">star_border</mat-icon>
							<mat-icon color="primary">star_border</mat-icon>
							<p class="mat-small color-hover"> & up</p>
						</div>
						<div fxLayout="row" fxLayoutAlign="start center">
							<mat-icon color="primary">star</mat-icon>
							<mat-icon color="primary">star_border</mat-icon>
							<mat-icon color="primary">star_border</mat-icon>
							<mat-icon color="primary">star_border</mat-icon>
							<mat-icon color="primary">star_border</mat-icon>
							<p class="mat-small color-hover"> & up</p>
						</div>
					</div>
					<div fxLayout="column">
						<p class="mat-small font-bold">New Arrivals</p>
						<p class="mat-small color-hover">Last 30 days</p>
						<p class="mat-small color-hover">Last 90 days</p>
					</div>
					<div fxLayout="column">
						<p class="mat-small font-bold">Item Condition <span class="mat-h5">(What's this?)</span></p>
						<p class="mat-small color-hover">New</p>
						<p class="mat-small color-hover">Renewed</p>
						<p class="mat-small color-hover">Used</p>
					</div>
					<div fxLayout="column">
						<p class="mat-small font-bold">price</p>
						<p class="mat-small color-hover">Under ₹1,000</p>
						<p class="mat-small color-hover">₹1,000 - ₹5,000</p>
						<p class="mat-small color-hover">₹5,000 - ₹10,000</p>
						<p class="mat-small color-hover">₹10,000 - ₹20,000</p>
						<p class="mat-small color-hover">Over ₹20,000</p>
					</div>
					<div fxLayout="column">
						<p class="mat-small font-bold">Deals</p>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Today's Deals
						</mat-checkbox>
					</div>
					<div fxLayout="column">
						<p class="mat-small font-bold">Discount</p>
						<p class="mat-small color-hover">10% off or more</p>
						<p class="mat-small color-hover">25% off or more</p>
						<p class="mat-small color-hover">35% off or more</p>
						<p class="mat-small color-hover">50% off or more</p>
					</div>
					<!-- <div fxLayout="column">
						<p class="mat-small font-bold">Seller</p>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Appario Retails Private Ltd.
						</mat-checkbox>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">ShopsGeniune</mat-checkbox>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cart2India Online</mat-checkbox>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Card2India SLP</mat-checkbox>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Global Klamp</mat-checkbox>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">BENEDICTIONS</mat-checkbox>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">UrCart</mat-checkbox>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">ShopMagics</mat-checkbox>
					</div> -->
					<div fxLayout="column">
						<p class="mat-small font-bold">Availability</p>
						<mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Include Out of Stock
						</mat-checkbox>
					</div>
				</div>
			</div>
		</div>
		<div>
			<mat-divider [vertical]="true" fxFlex="100"></mat-divider>
		</div>
		<div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
			<div fxLayout="row">
				<img src="assets/images/electronics/audio/banner1.jpg" alt="" class="full-width">
			</div>
			<div class="card cardpadding slider-items" fxHide fxShow.gt-sm>
				<p class="mat-h2 padding-top">Top deals in Audio</p>
				<mat-divider class="full-width"></mat-divider>
				<div>
					<owl-carousel-o [options]="customOptions2">
						<ng-template carouselSlide *ngFor="let slide of slidesStore2" [id]="slide.id">
							<div fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px" fxFlex>
								<div fxLayout="row"  (click)="products()">
									<img [src]="slide.src">
								</div>
								<div fxLayout="column" fxLayoutAlign="start start">
									<p class="mat-title color-danger">{{slide.price}} </p>
									<div fxLayout="row" fxLayoutGap="5px">
										<p class="mat-small text-line-through">{{slide.oldprice}} </p> <span
											class="mat-small">{{slide.off}}</span>
									</div>
									<p class="mat-h4 color-accent color-hover"  (click)="products()">{{slide.name}}</p>
									<p class="mat-small">{{slide.brand}}</p>
									<div fxLayout="row" fxLayoutAlign="start center">
										<mat-icon color="primary">star</mat-icon>
										<mat-icon color="primary">star</mat-icon>
										<mat-icon color="primary">star</mat-icon>
										<mat-icon color="primary">star</mat-icon>
										<mat-icon color="primary">star_border</mat-icon>
										<mat-icon>keyboard_arrow_down</mat-icon>
										<p class="color-accent color-hover">{{slide.reviewno}}</p>
									</div>
								</div>
								<div fxLayout="column" fxLayoutAlign="start stretch">
									<button mat-raised-button color="primary">{{slide.button}}</button>
								</div>
							</div>
						</ng-template>
					</owl-carousel-o>
				</div>
			</div>
			<div class="slider">
				<div class="slider-img">
					<mat-carousel timings="250ms ease-in-out" [autoplay]="true" interval="3000" proportion="35"
						[useKeyboard]="true" [loop]="true" orientation="ltr">
						<mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides; let i = index" [image]="slide.image"
							fxLayoutAlign="center center">
						</mat-carousel-slide>
					</mat-carousel>
				</div>
			</div>
			<div fxLayout="row">
				<img src="assets/images/electronics/audio/banner2.jpg" alt="" class="full-width">
			</div>
			<div fxLayout="row">
				<img src="assets/images/electronics/audio/banner3.jpg" alt="" class="full-width">
			</div>
			<div class="bg-secondary cardpadding" fxLayout="column" fxLayoutGap="20px">
				<div class="card cardpadding" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
					<p class="mat-h2">What are you looking for?</p>
					<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
						<div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
							<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="32">
								<div fxLayout="row">
									<img src="assets/images/electronics/audio/looking/1.jpg" alt="" fxFlex="100">
								</div>
								<p class="mat-h4 text-center">Shop Now</p>
							</div>
							<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="32">
								<div fxLayout="row">
									<img src="assets/images/electronics/audio/looking/2.jpg" alt="" fxFlex="100">
								</div>
								<p class="mat-h4 text-center">Shop Now</p>
							</div>
							<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="32">
								<div fxLayout="row">
									<img src="assets/images/electronics/audio/looking/3.jpg" alt="" fxFlex="100">
								</div>
								<p class="mat-h4 text-center">Shop Now</p>
							</div>
						</div>
						<div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
							<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="32">
								<div fxLayout="row">
									<img src="assets/images/electronics/audio/looking/4.jpg" alt="" fxFlex="100">
								</div>
								<p class="mat-h4 text-center">Shop Now</p>
							</div>
							<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="32">
								<div fxLayout="row">
									<img src="assets/images/electronics/audio/looking/5.jpg" alt="" fxFlex="100">
								</div>
								<p class="mat-h4 text-center">Shop Now</p>
							</div>
							<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="32">
								<div fxLayout="row">
									<img src="assets/images/electronics/audio/looking/6.jpg" alt="" fxFlex="100">
								</div>
								<p class="mat-h4 text-center">Shop Now</p>
							</div>
						</div>
					</div>
				</div>
				<div class="card cardpadding" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
					<p class="mat-h2">What are you looking for?</p>
					<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
						<div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
							<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="32">
								<div fxLayout="row">
									<img src="assets/images/electronics/audio/looking/7.jpg" alt="" fxFlex="100">
								</div>
								<p class="mat-h4 text-center">Shop Now</p>
							</div>
							<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="32">
								<div fxLayout="row">
									<img src="assets/images/electronics/audio/looking/8.jpg" alt="" fxFlex="100">
								</div>
								<p class="mat-h4 text-center">Shop Now</p>
							</div>
							<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="32">
								<div fxLayout="row">
									<img src="assets/images/electronics/audio/looking/9.jpg" alt="" fxFlex="100">
								</div>
								<p class="mat-h4 text-center">Shop Now</p>
							</div>
						</div>
						<div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
							<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="32">
								<div fxLayout="row">
									<img src="assets/images/electronics/audio/looking/10.jpg" alt="" fxFlex="100">
								</div>
								<p class="mat-h4 text-center">Shop Now</p>
							</div>
							<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="32">
								<div fxLayout="row">
									<img src="assets/images/electronics/audio/looking/11.jpg" alt="" fxFlex="100">
								</div>
								<p class="mat-h4 text-center">Shop Now</p>
							</div>
							<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="32">
								<div fxLayout="row">
									<img src="assets/images/electronics/audio/looking/12.jpg" alt="" fxFlex="100">
								</div>
								<p class="mat-h4 text-center">Shop Now</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
				<div fxLayout="row" fxLayoutAlign="space-around start">
					<img src="assets/images/electronics/audio/card1.jpg" alt="" fxFlex="32">
					<img src="assets/images/electronics/audio/card2.gif" alt="" fxFlex="32">
					<img src="assets/images/electronics/audio/card3.jpg" alt="" fxFlex="32">
				</div>
			</div>
			<div class="bg-secondary cardpadding" fxLayout="column" fxLayoutGap="20px">
				<div class="card cardpadding" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
					<p class="mat-h2">What are you looking for?</p>
					<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
						<div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
							<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="49">
								<div fxLayout="row">
									<img src="assets/images/electronics/audio/budget/1.jpg" alt="" fxFlex="100">
								</div>
								<p class="mat-h4 text-center">Shop Now</p>
							</div>
							<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="49">
								<div fxLayout="row">
									<img src="assets/images/electronics/audio/budget/2.jpg" alt="" fxFlex="100">
								</div>
								<p class="mat-h4 text-center">Shop Now</p>
							</div>
						</div>
						<div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
							<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="49">
								<div fxLayout="row">
									<img src="assets/images/electronics/audio/budget/3.jpg" alt="" fxFlex="100">
								</div>
								<p class="mat-h4 text-center">Shop Now</p>
							</div>
							<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlex="49">
								<div fxLayout="row">
									<img src="assets/images/electronics/audio/budget/4.jpg" alt="" fxFlex="100">
								</div>
								<p class="mat-h4 text-center">Shop Now</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
				<div fxLayout="row" fxLayoutAlign="space-around start">
					<img src="assets/images/electronics/audio/banner4.jpg" alt="" fxFlex="48">
					<img src="assets/images/electronics/audio/banner5.jpg" alt="" fxFlex="48">
				</div>
			</div>
			<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
				<div fxLayout="row" fxLayoutAlign="space-around start">
					<img src="assets/images/electronics/audio/banner6.jpg" alt="" fxFlex="48">
					<img src="assets/images/electronics/audio/banner7.jpg" alt="" fxFlex="48">
				</div>
			</div>
			<div fxLayout="row">
				<img src="assets/images/electronics/audio/banner8.jpg" alt="" class="full-width">
			</div>
			<div fxLayout="row">
				<img src="assets/images/electronics/audio/banner9.jpg" alt="" class="full-width">
			</div>
			<div fxLayout="column" fxLayoutGap="40px">
				<div fxLayout="row" class="card-shadow cardpadding">
					<p class="mat-small">1-24 of over 50,000 results for <span class="font-bold color-hover">
							Electronics
						</span>: <span class="font-bold color-primary"> Computer & Accessories </span></p>
				</div>
				<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
					<div fxLayoutGap="20px" *ngFor="let item of items" fxFlex="33" fxFlex.lt-sm="100">
						<div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
							<div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30"  (click)="products()">
								<img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
							</div>
							<div fxFlex.lt-sm="70">
								<p class="mat-h4 color-secondary">Sponsored </p>
								<p class="mat-h3 color-accent color-hover"  (click)="products()">{{item.name}}</p>
								<p class="mat-small">by {{item.company}}</p>
								<div fxLayout="row" fxLayoutAlign="start center">
									<mat-icon color="primary">star</mat-icon>
									<mat-icon color="primary">star</mat-icon>
									<mat-icon color="primary">star</mat-icon>
									<mat-icon color="primary">star</mat-icon>
									<mat-icon color="primary">star_border</mat-icon>
									<mat-icon>keyboard_arrow_down</mat-icon>
									<p class="color-accent color-hover">{{item.reviewno}}</p>
								</div>
								<p class="font-bold color-danger"> {{item.newprice}} <span
										class="text-line-through mat-small">{{item.oldprice}}</span> </p>
								<p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
								<p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
								<p class="color-accent color-hover">See Details</p>
							</div>
						</div>
					</div>
				</div>
				<div fxHide.lt-sm>
					<mat-divider></mat-divider>
				</div>
				<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
					<div fxLayoutGap="20px" *ngFor="let item of items1" fxFlex="33" fxFlex.lt-sm="100">
						<div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
							<div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30"  (click)="products()">
								<img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
							</div>
							<div fxFlex.lt-sm="70">
								<p class="mat-h4 color-secondary">Sponsored </p>
								<p class="mat-h3 color-accent color-hover"  (click)="products()">{{item.name}}</p>
								<p class="mat-small">by {{item.company}}</p>
								<div fxLayout="row" fxLayoutAlign="start center">
									<mat-icon color="primary">star</mat-icon>
									<mat-icon color="primary">star</mat-icon>
									<mat-icon color="primary">star</mat-icon>
									<mat-icon color="primary">star</mat-icon>
									<mat-icon color="primary">star_border</mat-icon>
									<mat-icon>keyboard_arrow_down</mat-icon>
									<p class="color-accent color-hover">{{item.reviewno}}</p>
								</div>
								<p class="font-bold color-danger"> {{item.newprice}} <span
										class="text-line-through mat-small">{{item.oldprice}}</span> </p>
								<p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
								<p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
								<p class="color-accent color-hover">See Details</p>
							</div>
						</div>
						<div fxShow.lt-sm fxHide>
							<mat-divider></mat-divider>
						</div>
					</div>
				</div>
				<div fxHide.lt-sm>
					<mat-divider></mat-divider>
				</div>
				<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
					<div fxLayoutGap="20px" *ngFor="let item of items2" fxFlex="33" fxFlex.lt-sm="100">
						<div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
							<div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30"  (click)="products()">
								<img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
							</div>
							<div fxFlex.lt-sm="70">
								<p class="mat-h4 color-secondary">Sponsored </p>
								<p class="mat-h3 color-accent color-hover"  (click)="products()">{{item.name}}</p>
								<p class="mat-small">by {{item.company}}</p>
								<div fxLayout="row" fxLayoutAlign="start center">
									<mat-icon color="primary">star</mat-icon>
									<mat-icon color="primary">star</mat-icon>
									<mat-icon color="primary">star</mat-icon>
									<mat-icon color="primary">star</mat-icon>
									<mat-icon color="primary">star_border</mat-icon>
									<mat-icon>keyboard_arrow_down</mat-icon>
									<p class="color-accent color-hover">{{item.reviewno}}</p>
								</div>
								<p class="font-bold color-danger"> {{item.newprice}} <span
										class="text-line-through mat-small">{{item.oldprice}}</span> </p>
								<p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
								<p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
								<p class="color-accent color-hover">See Details</p>
							</div>
						</div>
						<div fxShow.lt-sm fxHide>
							<mat-divider></mat-divider>
						</div>
					</div>
				</div>
				<div fxHide.lt-sm>
					<mat-divider></mat-divider>
				</div>
				<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
					<div *ngFor="let item of items3" fxFlex="33" fxFlex.lt-sm="100">
						<div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
							<div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30"  (click)="products()">
								<img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
							</div>
							<div fxFlex.lt-sm="70">
								<p class="mat-h4 color-secondary">Sponsored </p>
								<p class="mat-h3 color-accent color-hover"  (click)="products()">{{item.name}}</p>
								<p class="mat-small">by {{item.company}}</p>
								<div fxLayout="row" fxLayoutAlign="start center">
									<mat-icon color="primary">star</mat-icon>
									<mat-icon color="primary">star</mat-icon>
									<mat-icon color="primary">star</mat-icon>
									<mat-icon color="primary">star</mat-icon>
									<mat-icon color="primary">star_border</mat-icon>
									<mat-icon>keyboard_arrow_down</mat-icon>
									<p class="color-accent color-hover">{{item.reviewno}}</p>
								</div>
								<p class="font-bold color-danger"> {{item.newprice}} <span
										class="text-line-through mat-small">{{item.oldprice}}</span> </p>
								<p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
								<p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
								<p class="color-accent color-hover">See Details</p>
							</div>
						</div>
						<div fxShow.lt-sm fxHide>
							<mat-divider></mat-divider>
						</div>
					</div>
				</div>
			</div>
			<div fxLayout="row">
				<button mat-raised-button class="color-accent bg-secondary" fxFlex="100">See all results</button>
			</div>
		</div>
	</div>
	<div class="cardpadding" fxLayout="column" fxLayoutGap="10px" fxHide.lt-sm>
		<div fxLayout="column" fxLayoutGap="5px">
			<p class="mat-title color-primary">Buy Hi fi and Home audio Online at Global Local.in</p>
			<p class="mat-small">Experiencing acoustic engineering at its finest can be a truly riveting feeling. Whether you
				are watching television, a film or listening to your favourite audio track, the impact with which the audio is
				delivered is supremely important. There have been rapid strides taken in this field, with superior technology
				creating crystal clear sound clarity. Global Local India offers you a wide selection of some of the best brands in the
				world under this category. We also offer numerous compatibility devices that allow you to transfer and
				experience audio visual elements using various other devices.</p>
		</div>
		<div fxLayout="column" fxLayoutGap="5px">
			<p class="fomt-bold">Stunning sound, surreal systems</p>
			<p class="mat-small">The Hi Fi and Home audio selection at Global Local India is designed to offer you unique and varied
				audio devices for different requirements that arise in your life. We offer Wireless Portable Stereo speakers
				that allow you to carry your music and entertainment with you, on the go. You can listen to a high quality
				output, anywhere and anytime, by purchasing wireless speakers such as the JBL Flip II that provides you with
				several hours of playback on a single charge. For those looking to amplify their laptop, desktop or mobile phone
				output, there is a large selection of 2.0 speakers that can serve the purpose of producing the right output for
				one or two listeners. The Phillips MMS4040F 2.1 multimedia speaker set is a perfect example of a home audio
				system that can pack more than its share of a punch. We also provide you with a comprehensive selection of
				complete home entertainment audio systems that can entertain your entire family and acts as the perfect
				accessory to your home entertainment unit. The Phillips DSP 2800 speaker system, featuring a 5.1 output and a 5
				inch woofer has the capability to captivate its audience entirely. We also provide you with various alternatives
				to vital home audio needs that offer assistance in maintaining compatibility and output across various systems.
				The Bluerigger HDMI cable is an excellent example of quality products that can add value to your home
				entertainment system.</p>
		</div>
		<div fxLayout="column" fxLayoutGap="5px">
			<p class="fomt-bold">Sound quality across the best brands</p>
			<p class="mat-small">The audio engineering sector has seen some significant developments over the past few years
				along with the emergence of a few brands that have made a mark on the industry. Global Local India provides you with a
				complete selection of quality Hi Fi and Home audio products across various brands in order to help you find the
				exact product you need. This includes having a wide selection on offer from popular and trusted brands such as
				Phillips, Sony and creative along with having a well-rounded selection of products from relatively new brands
				such as JBL, Intex and Portronics. Shop online at Global Local India and find the right Home audio product for
				yourself.</p>
		</div>
		<div fxLayout="column" fxLayoutGap="5px">
			<p class="fomt-bold">High quality Hi Fi and Home audio products</p>
			<p class="mat-small">Global Local India strives to provide you with a complete and convenient shopping experience. For
				those looking to purchase Hi Fi and Home audio products, we provide a helpful set of parameters to help you find
				what you need. This includes segregating the collection based on categories such as brands, average customer
				reviews, arrival date and price. You can have your selection delivered to your door step and complete the
				transaction using of many easy payment options. Find the best Hi Fi and Home audio products online by shopping
				at Global Local India.</p>
		</div>
	</div>
</div>
<div class="card-shadow" fxHide>
	<mat-card>
		<div class="cardpadding" fxLayout="column" fxLayoutGap="30px">
			<div class="slider-items" fxLayout="column" fxLayoutGap="20px">
				<p class="mat-title">Inspired by your browsing history</p>
				<owl-carousel-o [options]="customOptions" class="cardpadding">
					<ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
						<img [src]="slide.src">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="color-accent color-hover">{{slide.text}}</span>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star_border</mat-icon>
								<mat-icon>keyboard_arrow_down</mat-icon>
								<p class="color-accent color-hover">{{slide.reviewno}}</p>
							</div>
							<span class="color-danger">{{slide.price}}</span>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
			<div class="slider-items" fxLayout="column" fxLayoutGap="20px">
				<p class="mat-title">Top subscription apps for you</p>
				<owl-carousel-o [options]="customOptions1" class="cardpadding">
					<ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
						<img [src]="slide.src">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="color-accent color-hover">{{slide.text}}</span>
							<span class="mat-small">{{slide.price}}</span>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star_border</mat-icon>
								<mat-icon>keyboard_arrow_down</mat-icon>
								<p class="color-accent color-hover">{{slide.reviewno}}</p>
							</div>
							<span class="color-danger">FREE</span>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
		</div>
	</mat-card>
</div>