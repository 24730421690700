import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-birthday',
  templateUrl: './birthday.component.html',
  styleUrls: ['./birthday.component.scss']
})
export class BirthdayComponent implements OnInit {

  items = [
    { image: 'assets/images/gift-card/card/1.webp', name: 'Google Play recharge code', company: 'Google Play', price: '₹ 100', reviewno: '1,652' },
    { image: 'assets/images/gift-card/card/2.webp', name: 'Amazon Pay eGift Card', company: 'Amazon Pay', price: '₹ 10 - ₹ 10,000', reviewno: '31,817' },
    { image: 'assets/images/gift-card/card/3.webp', name: 'Google Play Gift Code - Digital Voucher', company: 'Google Play', price: '₹ 100 - ₹ 1,500', reviewno: '6,685' },
  ];
  items2 = [
    { image: 'assets/images/gift-card/card/4.webp', name: 'Amazon Pay eGift Card', company: 'Amazon Pay  ', price: '₹ 10 - ₹ 10,000', reviewno: '31,817' },
    { image: 'assets/images/gift-card/card/5.webp', name: 'Amazon Pay eGift Card', company: 'Amazon Pay', price: '₹ 10 - ₹ 10,000', reviewno: '31,817' },
    { image: 'assets/images/gift-card/card/6.webp', name: 'Flat 10% off at checkout||Homecentre by Lifestyle E-Gift Card', company: 'Home Center', price: '₹ 100 - ₹ 10,000', reviewno: '81' },
  ];
  items3 = [
    { image: 'assets/images/gift-card/card/7.webp', name: 'Amazon Pay eGift Card', company: 'Amazon Pay', price: '₹ 10 - ₹ 10,000', reviewno: '31,817' },
    { image: 'assets/images/gift-card/card/8.webp', name: 'Amazon Pay eGift Card', company: 'Amazon Pay', price: '₹ 10 - ₹ 10,000', reviewno: '31,818' },
    { image: 'assets/images/gift-card/card/9.webp', name: 'Amazon Pay eGift Card', company: 'Amazon Pay', price: '₹ 10 - ₹ 10,000', reviewno: '31,817' },
  ];
  items4 = [
    { image: 'assets/images/gift-card/card/10.webp', name: 'Flat 10% off at checkout||Central - Digital Voucher', company: 'Central Store', price: '₹ 1,000 - ₹ 3,000', reviewno: '396' },
    { image: 'assets/images/gift-card/card/11.webp', name: 'Flat 8% off at checkout||Lifestyle - Digital Voucher', company: 'Lifestyle', price: '₹ 100 - ₹ 10,000', reviewno: '1,410' },
    { image: 'assets/images/gift-card/card/12.webp', name: 'Amazon Pay eGift Card', company: 'Amazon Pay', price: '₹ 10 - ₹ 10,000', reviewno: '31,817' },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
