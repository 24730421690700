import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CountdownComponent } from 'ngx-countdown';

@Component({
  selector: 'app-phone-verify',
  templateUrl: './phone-verify.component.html',
  styleUrls: ['./phone-verify.component.scss']
})
export class PhoneVerifyComponent implements OnInit {
  @ViewChild('countdown') counter: CountdownComponent;
  otp: string;
  realotp: string;
  showOtpComponent = true;
  selectedValue: number;
  checked = false;
  durationInSeconds = 5;
  mobileno: string;
  userid: string;
  name: string;
  config = {
    allowNumbersOnly: false,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '50px'
    }
  };
  constructor(public router: Router) { }

  onEvent($event): void {
    let timeLeft = $event.left;
    this.selectedValue = timeLeft
  }
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;

  ngOnInit(): void {
    this.mobileno = localStorage.getItem("forgotusername")
  }

  public Send() {
    this.counter.restart();
  }

  onOtpChange(otp) {

    this.otp = otp;

    if (this.otp.length === 5) {
      this.router.navigate(['/change-password'])
    }
  }
}
