<div class="container" fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="column" fxShow.lt-sm fxHide>
    <div class="cardpadding" fxLayout="column" fxLayoutAlign="center center">
      <p class="mat-title">Clothing Essentials</p>
      <p class="text-center">Global Local Brands & more</p>
    </div>
  </div>
  
  <div fxLayout="row" fxFlex>
    <div class="cardpadding" fxLayout="column" fxFlex="18" fxFlex.lt-md="25" fxLayoutGap="20px" fxHide.lt-sm fxShow>
      <div fxLayout="column" fxLayoutGap="5px">
        <p class="mat-small font-bold">Department</p>
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <p class="mat-small color-hover">Fashion</p>
        </div>
        <div class="padding-side" fxLayout="column">
          <p class="mat-small padding-side font-bold">Fashion | Made for Global Local | Apr20</p>
          <p class="mat-small">Women</p>
          <p class="mat-small">Men</p>
          <p class="mat-small">Furniture</p>
          <p class="mat-small">Girls</p>
          <p class="mat-small">Boys</p>
          <p class="mat-small">Bags & Luggage</p>
        </div>
        <div fxLayout="column" fxLayoutGap="5px">
          <div fxLayout="column">
            <p class="mat-small font-bold">Pay on Delivery</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Eligible for Pay On Delivery
            </mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Brands</p>
            <div *ngFor="let checkbox of checkboxes">
              <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">{{checkbox.name}}</mat-checkbox>
            </div>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Avg. Customers Reviews</p>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon color="primary">star</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <mat-icon color="primary">star_border</mat-icon>
              <p class="mat-small color-hover"> & up</p>
            </div>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">New Arrivals</p>
            <p class="mat-small color-hover">Last 30 days</p>
            <p class="mat-small color-hover">Last 90 days</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">price</p>
            <p class="mat-small color-hover">Under ₹1,000</p>
            <p class="mat-small color-hover">₹1,000 - ₹5,000</p>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Deals</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Today's Deal</mat-checkbox>
          </div>
          <div fxLayout="column">
            <p class="mat-small font-bold">Discount</p>
            <p class="mat-small color-hover">10% off or more</p>
            <p class="mat-small color-hover">25% off or more</p>
            <p class="mat-small color-hover">35% off or more</p>
            <p class="mat-small color-hover">50% off or more</p>
          </div>
          <!-- <div fxLayout="column">
            <p class="mat-small font-bold">Seller</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Cloudtail India</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">SAPL INDUSTRIES PVT. LTD.
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Imperial Clothing Company
            </mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Alan Jones Clothing®</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Tm Enterprises</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Ebiz Solutions</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">BUKKL</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Retail Boost</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Viva N Diva Couture</mat-checkbox>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">NiftyJeans</mat-checkbox>
          </div> -->
          <div fxLayout="column">
            <p class="mat-small font-bold">Availability</p>
            <mat-checkbox class="mat-small color-hover" fxLayoutAlign="start center">Include Out of Stock
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div>
      <mat-divider [vertical]="true" fxFlex="100"></mat-divider>
    </div>

    <div class="cardpadding" fxLayout="column" fxFlex="82" fxFlex.lt-sm="100" fxFlex.lt-md="75" fxLayoutGap="20px">
      <div fxLayout="row">
        <img src="assets/images/gl-home/clothing/banner1.jpg" alt="" class="full-width">
      </div>
      <div class="bg-secondary cardpadding" fxLayout="column" fxLayoutGap="20px">
        <div class="card cardpadding" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
          <p class="mat-h2">Stay at home essentials | Men</p>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
            <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="31" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/men1.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Boxers, vests & briefs - Starting ₹399</p>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="31" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/men2.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Shorts - Starting ₹399</p>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="31" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/men3.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">T-shirts & Polos - Starting ₹299</p>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="31" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/men4.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Shirts - Starting ₹449</p>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="31" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/men5.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Trousers - Starting 649</p>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="31" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/men6.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Jeans - Starting ₹599</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card cardpadding" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
          <p class="mat-h2">Stay at home essentials | Women</p>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
            <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="31" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/women1.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Sleepwears - Starting ₹499</p>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="31" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/women2.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Kurtis - Starting ₹299</p>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="31" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/women3.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Tees - Starting ₹299</p>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="49">
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="31" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/women4.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Tops - Starting ₹349</p>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="31" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/women5.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Dresses - Starting ₹599</p>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="31" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/women6.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Trousers - Starting ₹499</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <img src="assets/images/gl-home/clothing/banner2.jpg" alt="" class="full-width">
      </div>

      <div class="bg-secondary cardpadding" fxLayout="column" fxLayoutGap="20px">
        <div class="card cardpadding" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
          <p class="mat-h2">Brands for him</p>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
            <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="50">
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="48" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/brand-men1.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Shop Now</p>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="48" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/brand-men2.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Shop Now</p>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="50">
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="48" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/brand-men3.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Shop Now</p>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="48" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/brand-men4.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Shop Now</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card cardpadding" fxLayout="column" fxLayoutGap="30px" fxLayoutGap.lt-sm="20px">
          <p class="mat-h2">Brands for her</p>
          <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap.lt-sm="10px">
            <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="50">
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="48" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/brand-women1.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Shop Now</p>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="48" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/brand-women2.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Shop Now</p>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around start" fxFlex="50">
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="48" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/brand-women3.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Shop Now</p>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="48" fxLayoutGap="10px">
                <div fxLayout="row">
                  <img src="assets/images/gl-home/clothing/brand-women4.jpg" alt="" fxFlex="100">
                </div>
                <p class="mat-h3 text-center">Shop Now</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="40px">
        <div fxLayout="row" class="card-shadow cardpadding">
          <p class="mat-small">1-24 of over 50,000 results for : <span class="font-bold color-primary"> All Categories
            </span></p>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h3 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover" (click)="products()">See Details</p>
              </div>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items1" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h3 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover" (click)="products()">See Details</p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div fxLayoutGap="20px" *ngFor="let item of items2" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h3 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover" (click)="products()">See Details</p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
        <div fxHide.lt-sm>
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
          <div *ngFor="let item of items3" fxFlex="33" fxFlex.lt-sm="100">
            <div class="doctorcard" fxLayout="column" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="10px" fxFlex="100">
              <div [ngClass.gt-sm]="'card-image'" fxFlex.lt-sm="30" (click)="products()">
                <img src="{{item.image}}" alt="" [ngClass.lt-md]="'full-width'">
              </div>
              <div fxFlex.lt-sm="70">
                <p class="mat-h3 color-hover" (click)="products()">{{item.name}}</p>
                <p class="mat-small">by {{item.company}}</p>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star</mat-icon>
                  <mat-icon color="primary">star_border</mat-icon>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                  <p class="color-accent color-hover">{{item.reviewno}}</p>
                </div>
                <p class="font-bold color-danger"> {{item.newprice}} <span
                    class="text-line-through mat-small">{{item.oldprice}}</span> </p>
                <p class="font-bold">{{item.freedelivery}}<span class="mat-small">{{item.upto}}</span> </p>
                <p class="mat-small">Get it by <span class="font-bold">{{item.deliverydate}}</span></p>
                <p class="color-accent color-hover" (click)="products()">See Details</p>
              </div>
            </div>
            <div fxShow.lt-sm fxHide>
              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row">
        <button mat-raised-button class="color-accent bg-secondary" fxFlex="100">See all results</button>
      </div>
    </div>
  </div>
</div>

<div class="card-shadow" fxHide>
	<mat-card>
		<div class="cardpadding" fxLayout="column" fxLayoutGap="30px">
			<div class="slider-items1" fxLayout="column" fxLayoutGap="20px">
				<p class="mat-title">Inspired by your browsing history</p>
				<owl-carousel-o [options]="customOptions" class="cardpadding">
					<ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
						<img [src]="slide.src">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="color-accent color-hover">{{slide.text}}</span>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star_border</mat-icon>
								<mat-icon>keyboard_arrow_down</mat-icon>
								<p class="color-accent color-hover">{{slide.reviewno}}</p>
							</div>
							<span class="color-danger">{{slide.price}}</span>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
			<div class="slider-items1" fxLayout="column" fxLayoutGap="20px">
				<p class="mat-title">Top subscription apps for you</p>
				<owl-carousel-o [options]="customOptions1" class="cardpadding">
					<ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
						<img [src]="slide.src">
						<div fxLayout="column" fxLayoutAlign="start start">
							<span class="color-accent color-hover">{{slide.text}}</span>
							<span class="mat-small">{{slide.price}}</span>
							<div fxLayout="row" fxLayoutAlign="start center">
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star</mat-icon>
								<mat-icon color="primary">star_border</mat-icon>
								<mat-icon>keyboard_arrow_down</mat-icon>
								<p class="color-accent color-hover">{{slide.reviewno}}</p>
							</div>
							<span class="color-danger">FREE</span>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
		</div>
	</mat-card>
</div>