<div class="container cardpadding row-height" [ngClass.gt-sm]="'cardpadding2'" fxLayout="column" fxLayoutGap="20px">
  <mat-card>
    <div class="cardpadding" fxLayout="column">
      <p class="mat-headline">Select a delivery address</p>
      <p>Is the address you'd like to use displayed below? If so, click the corresponding "Deliver to this address"
        button.</p>
    </div>
    <mat-divider></mat-divider>
    <div class="cardpadding" fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="column">
        <p class="mat-h3 font-bold">{{this.fullName}}</p>
        <p class="mat-small">{{this.flat}}</p>
        <p class="mat-small">{{this.area}}</p>
        <p class="mat-small">{{this.Landmark}}</p>
        <p class="mat-small">{{this.City}}, <span>{{this.State}} {{this.pin}}</span></p>
        <p class="mat-small">{{this.country}}</p>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="15px">
        <button mat-raised-button color="primary" routerLink="/payment">Delivery to this Address</button>
        <button mat-raised-button class="bg-secondary" routerLink="/add-address">Change address</button>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <!-- <button mat-raised-button class="bg-secondary">Edit</button> -->
        <!-- <button mat-raised-button class="bg-secondary">Delete</button> -->
      </div>
    </div>
  </mat-card>
  <div fxLayout="column" fxLayoutGap="20px" fxHide>
    <div>
      <p class="mat-headline"></p>
    </div>
    <div class="row-height" fxLayout="column" fxLayoutAlign="start">
      <div fxLayout="row" fxLayoutAlign="start" fxLayout.xs="column" fxLayoutGap.xs="10px">
        <mat-card class="container card-shadow" fxFlex="50" fxFlex.lt-md="100">
          <mat-card-title>
            <span fxLayoutAlign="center">Add a new address</span>
          </mat-card-title>
          <mat-card-content class="padding-top">
            <form [formGroup]="checkoutFormGroup" novalidate>
              <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column">
                <!-- Name field -->
                <mat-form-field appearance="outline" fxFlex="100">
                  <mat-label>Full Name</mat-label>
                  <input formControlName="fullName" matInput placeholder="Full Name" maxlength="256" required>
                  <mat-error>Name is required.</mat-error>
                </mat-form-field>
              </div>
              <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column">
                <!-- Mobile number field -->
                <mat-form-field appearance="outline" fxFlex="100">
                  <mat-label>Mobile number</mat-label>
                  <input formControlName="phoneNumber" type="tel" id="mobileno" matInput placeholder="9876543210"
                    maxlength="10" required>
                  <mat-error *ngIf="txtMobileno.errors?.required">Mobile Number is required.
                  </mat-error>
                  <mat-error *ngIf="txtMobileno.errors?.pattern">Invalid Mobile No.
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column">
                <!-- Flat, House no., Building, Company, Apartment field -->
                <mat-form-field appearance="outline" fxFlex="100">
                  <mat-label>Flat, House no., Building, Company, Apartment</mat-label>
                  <input formControlName="flat" matInput maxlength="256" required>
                  <mat-error>Address is required.</mat-error>
                </mat-form-field>
              </div>
              <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column">
                <!-- Area, Colony, Street, Sector, Village field -->
                <mat-form-field appearance="outline" fxFlex="100">
                  <mat-label>Area, Colony, Street, Sector, Village</mat-label>
                  <input formControlName="area" matInput maxlength="256">
                </mat-form-field>
              </div>
              <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column">
                <!-- Landmark field -->
                <mat-form-field appearance="outline" fxFlex="100">
                  <mat-label>Landmark</mat-label>
                  <input formControlName="Landmark" matInput maxlength="256"
                    placeholder="E.g. Near AIIMS Flyover, Behind Regal Cinema, etc.">
                </mat-form-field>
              </div>
              <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column">
                <!-- City field -->
                <mat-form-field appearance="outline" fxFlex="100">
                  <mat-label>Town/City</mat-label>
                  <input formControlName="City" matInput required>
                  <mat-error>Town/City is required.</mat-error>
                </mat-form-field>
              </div>
              <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column">
                <!-- State field -->
                <mat-form-field appearance="outline" fxFlex="100">
                  <mat-label>State</mat-label>
                  <input formControlName="State" matInput required>
                  <mat-error>State is required.</mat-error>
                </mat-form-field>
              </div>
              <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column">
                <!-- Zip field -->
                <mat-form-field appearance="outline" fxFlex="100">
                  <mat-label>PIN Code</mat-label>
                  <input formControlName="pin" matInput required>
                  <mat-error>PIN Code is required.</mat-error>
                </mat-form-field>
              </div>
              <div fxLayout fxLayoutGap="10px" fxLayout.gt-sm="row" fxLayout.lt-sm="column">
                <!-- Country field -->
                <!-- <mat-form-field appearance="outline" fxFlex="100">
                  <mat-label>Country</mat-label>
                  <input formControlName="country" value="India" matInput required>
                  <mat-error>Country is required.</mat-error>
                </mat-form-field> -->
                <mat-form-field appearance="outline">
                  <mat-label>Country/Region</mat-label>
                  <input formControlName="country" matInput required>
                  <mat-error>Country is required.</mat-error>
                </mat-form-field>
              </div>
            </form>
            <div *ngIf="error">
              <mat-error>{{this.error}}</mat-error>
            </div>
            <!-- Delivery to address button -->
            <div fxLayoutAlign="center center" class="margin-top">
              <button mat-raised-button fxFlex="100" color="primary" (click)="checkout()"
                [disabled]="checkoutFormGroup.invalid">Add Address</button>
            </div>
            <!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="primary" type="square-jelly-box"
                  [fullScreen]="true">
                  <p style="color: white"> Loading... </p>
                </ngx-spinner> -->
          </mat-card-content>
          <mat-card-actions>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>