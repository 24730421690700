<div class="container" fxLayout="column" fxLayoutGap="10px">
  <div class="color-bg cardpadding" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-sm="start center"
    fxLayoutGap="10px">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
      <img src="assets/images/coupen/header.png" alt="" class="full-width">
      <p class="mat-headline" [ngClass.lt-sm]="'mat-title'">Coupons</p>
    </div>
    <div fxLayout="row">
      <p class="mat-h3">Collected <span class="mat-title"> {{this.count}}</span>  Coupons</p>
    </div>
    <!-- <p class="mat-h4 text-center">Our most popular products based on sales.</p> -->
  </div>
  <mat-divider></mat-divider>
  <div class="cardpadding" fxLayout="column" fxLayoutGap="20px" fxFlex>
    <div fxLayout="column" fxLayoutAlign="center center">
      <p class="mat-headline">Global Local Coupons</p>
    </div>
    <div>
      <mat-divider></mat-divider>
    </div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center" fxLayoutGap.lt-sm="10px">
      <div fxLayout="row" fxLayoutAlign="space-around">
        <img src="assets/images/coupen/c1.jpg" alt="" fxFlex="100" class="full-width">
        <img src="assets/images/coupen/c2.jpg" alt="" fxFlex="100" class="full-width">
      </div>
      <div fxLayout="row" fxLayoutAlign="space-around">
        <img src="assets/images/coupen/c3.jpg" alt="" fxFlex="100" class="full-width">
        <img src="assets/images/coupen/c4.jpg" alt="" fxFlex="100" class="full-width">
      </div>
    </div>
    <div class="card slider-items2">
      <p class="mat-h2 padding-top">Amazon Coupons: Save extra on Laptops & Mobiles</p>
      <div>
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
            <div class="hover-border1" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px" fxFlex>
              <div fxLayout="row" fxLayoutAlign="center center" (click)="products()">
                <img [src]="slide.src">
              </div>
              <div class="cardpadding" fxLayout="column" fxLayoutAlign="center center">
                <p class="mat-title color-green" [ngClass.lt-sm]="'mat-h3'">{{slide.save}} </p>
                <p class="mat-small color-accent overflow-hidden text-center" (click)="products()">{{slide.name}}</p>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center">
                <div fxLayout="row">
                  <button mat-raised-button color="primary" #submitButton
                    (click)="submit();submitButton.disabled = true">{{slide.button}}</button>
                </div>
                <p class="mat-small color-secondary">Discount at checkout</p>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-headline" fxLayoutAlign="center center">Handpicked Coupons</p>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around">
          <img src="assets/images/coupen/coupen1.jpg" alt="" fxFlex="50">
          <img src="assets/images/coupen/coupen2.jpg" alt="" fxFlex="50">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around">
          <img src="assets/images/coupen/coupen3.jpg" alt="" fxFlex="50">
          <img src="assets/images/coupen/coupen4.jpg" alt="" fxFlex="50">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around">
          <img src="assets/images/coupen/coupen5.jpg" alt="" fxFlex="50">
          <img src="assets/images/coupen/coupen6.jpg" alt="" fxFlex="50">
        </div>
      </div>
    </div>
    <div class="card slider-items2">
      <p class="mat-h2 padding-top">Exclusive Coupons : Just for you</p>
      <div>
        <owl-carousel-o [options]="customOptions1">
          <ng-template carouselSlide *ngFor="let slide of slidesStore1" [id]="slide.id">
            <div class="hover-border1" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px" fxFlex>
              <div fxLayout="row" fxLayoutAlign="center center" (click)="products()">
                <img [src]="slide.src">
              </div>
              <div class="cardpadding" fxLayout="column" fxLayoutAlign="center center">
                <p class="mat-title color-green" [ngClass.lt-sm]="'mat-h3'">{{slide.save}} </p>
                <p class="mat-small color-accent overflow-hidden text-center" (click)="products()">{{slide.name}}</p>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center">
                <div fxLayout="row">
                  <button mat-raised-button color="primary" #submitButton
                    (click)="submit();submitButton.disabled = true">{{slide.button}}</button>
                </div>
                <p class="mat-small color-secondary">Discount at checkout</p>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
    <div class="card slider-items2">
      <p class="mat-h2 padding-top">Daily Essentials Coupons</p>
      <div>
        <owl-carousel-o [options]="customOptions2">
          <ng-template carouselSlide *ngFor="let slide of slidesStore2" [id]="slide.id">
            <div class="hover-border1" fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="10px" fxFlex>
              <div fxLayout="row" fxLayoutAlign="center center" (click)="products()">
                <img [src]="slide.src">
              </div>
              <div class="cardpadding" fxLayout="column" fxLayoutAlign="center center">
                <p class="mat-title color-green" [ngClass.lt-sm]="'mat-h3'">{{slide.save}} </p>
                <p class="mat-small color-accent overflow-hidden text-center" (click)="products()">{{slide.name}}</p>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center">
                <div fxLayout="row">
                  <button mat-raised-button color="primary" #submitButton
                    (click)="submit();submitButton.disabled = true">{{slide.button}}</button>
                </div>
                <p class="mat-small color-secondary">Discount at checkout</p>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-headline" fxLayoutAlign="center center">Featured categories</p>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/feature2.png" alt="" fxFlex="48">
          <img src="assets/images/coupen/feature3.png" alt="" fxFlex="48">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/feature4.png" alt="" fxFlex="48">
          <img src="assets/images/coupen/feature5.png" alt="" fxFlex="48">
        </div>
      </div>
    </div>
    <div fxLayout="row">
      <img src="assets/images/coupen/banner1.jpg" alt="" class="full-width">
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <p class="mat-headline" fxLayoutAlign="center center">Collect coupons by category</p>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-title" fxLayoutAlign="center center">FASHION</p>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/fashion/1.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/fashion/2.png" alt="" fxFlex="45">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/fashion/3.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/fashion/4.png" alt="" fxFlex="45">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/fashion/5.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/fashion/6.png" alt="" fxFlex="45">
        </div>
        <!-- <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/fashion/7.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/fashion/8.png" alt="" fxFlex="45">
        </div> -->
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-title" fxLayoutAlign="center center">DAILY ESSENTIALS</p>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/daily/1.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/daily/2.png" alt="" fxFlex="45">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/daily/3.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/daily/4.png" alt="" fxFlex="45">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/daily/5.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/daily/6.png" alt="" fxFlex="45">
        </div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-title" fxLayoutAlign="center center">HOME, KITCHEN & OUTDOORS</p>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/home/1.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/home/2.png" alt="" fxFlex="46">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/home/3.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/home/4.png" alt="" fxFlex="45">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/home/5.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/home/6.png" alt="" fxFlex="45">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/home/7.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/home/8.png" alt="" fxFlex="45">
        </div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-title" fxLayoutAlign="center center">ELECTRONICS, APPLIANCES & ACCESSORIES</p>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/elec/1.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/elec/2.png" alt="" fxFlex="45">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/elec/3.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/elec/4.png" alt="" fxFlex="45">
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/elec/5.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/elec/6.png" alt="" fxFlex="45">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/elec/7.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/elec/8.png" alt="" fxFlex="45">
        </div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-title" fxLayoutAlign="center center">BOOKS & ENTERTAINMENT</p>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/book/1.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/book/2.png" alt="" fxFlex="45">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/book/3.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/book/4.png" alt="" fxFlex="45">
        </div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <p class="mat-title" fxLayoutAlign="center center">EXPLORE MORE COUPONS FROM AMAZON INITIATIVE</p>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start" fxLayoutGap.lt-sm="10px">
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/explore/1.jpg" alt="" fxFlex="45">
          <img src="assets/images/coupen/explore/2.png" alt="" fxFlex="45">
        </div>
        <div fxLayout="row" fxLayoutAlign="space-around" fxFlex="48">
          <img src="assets/images/coupen/explore/3.png" alt="" fxFlex="45">
          <img src="assets/images/coupen/explore/4.jpg" alt="" fxFlex="45">
        </div>
      </div>
    </div>
  </div>
</div>