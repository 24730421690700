import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addaddress',
  templateUrl: './addaddress.component.html',
  styleUrls: ['./addaddress.component.scss']
})
export class AddaddressComponent implements OnInit {
  hide = true;
  hidecon = true;
  checkoutFormGroup: FormGroup;
  public txtEmail;
  public txtMobileno
  public txtPassword;
  error: string;
  constructor(
    private formBuilder: FormBuilder,
    public router: Router) { }

  ngOnInit(): void {
    this.checkoutFormGroup = this.formBuilder.group({
      fullName: new FormControl('', Validators.required),
      flat: new FormControl(''),
      area: new FormControl(''),
      Landmark: new FormControl(''),
      City: new FormControl(''),
      pin: new FormControl(''),
      State: new FormControl(''),
      country: new FormControl(''),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('[1-9]\\d{9}'),
      ]),

    });
    this.txtEmail = this.checkoutFormGroup.get('email');
    this.txtMobileno = this.checkoutFormGroup.get('phoneNumber');
  }


  get f() { return this.checkoutFormGroup.controls; }

  checkout() {
    if (this.checkoutFormGroup.invalid) {
      return;
    }
    console.log(this.checkoutFormGroup.value)
    localStorage.setItem("fullName", this.f.fullName.value);
    localStorage.setItem("flat", this.f.flat.value);
    localStorage.setItem("area", this.f.area.value);
    localStorage.setItem("Landmark", this.f.Landmark.value);
    localStorage.setItem("City", this.f.City.value);
    localStorage.setItem("pin", this.f.pin.value);
    localStorage.setItem("State", this.f.State.value);
    localStorage.setItem("country", this.f.country.value);
    localStorage.setItem("phoneNumber", this.f.phoneNumber.value);

    console.log(this.f.fullName.value)
    this.router.navigate(['/checkout'])
  }
}
