<div class="container" fxLayout="row" fxLayoutGap="20px" fxFlex fxHide>
  <div fxFlex="15" fxFlex.lt-md="20" (click)="location()">
    <div class="hover-border1 color-hover" fxLayout="row" fxLayoutGap="5px">
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-icon>location_on_outlined</mat-icon>
      </div>
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="2px">
        <p class="mat-small ">Hello {{this.getAddress}}</p>
        <p>Select your address</p>
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-md="space-around center" fxFlex="80"
    fxFlex.lt-md="80" fxLayoutGap="25px" fxLayoutGap.lt-md="10px">
    <div class="hover-border color-hover"  routerLink='/electronics'>
      <p class="mat-h4 ">Electronics</p>
    </div>
    <div class="hover-border color-hover" routerLink='/gift-card' fxHide>
      <p class="mat-h4 ">Gift Cards</p>
    </div>
    <div class="hover-border color-hover" routerLink='/best-seller' fxHide>
      <p class="mat-h4 ">Best Sellers</p>
    </div>
    <div class="hover-border color-hover" routerLink='/mobile-phone'>
      <p class="mat-h4 ">Mobiles</p>
    </div>
    <div class="hover-border color-hover" routerLink='/todays-deal'>
      <p class="mat-h4 ">Today's Deal</p>
    </div>
    <div class="hover-border color-hover" fxHide.lt-sm routerLink='/fashion'>
      <p class="mat-h4 ">Fashion</p>
    </div>
    <div class="hover-border color-hover" routerLink='/category'>
      <p class="mat-h4 ">Category</p>
    </div>
    <div class="hover-border color-hover" routerLink='/new-arrival'>
      <p class="mat-h4 ">New Arrivals</p>
    </div>
    <!-- <div class="hover-border color-hover" routerLink='/combo-offer'>
      <p class="mat-h4 ">Combo Offers</p>
    </div> -->
  </div>
</div>

<div class="container1" fxLayout="column" fxLayoutGap="10px" fxFlex fxHide>
  <div fxLayout="row">
    <div class="hover-border1" fxFlex="20">
      <button mat-button>
        <div fxLayout="column" fxLayoutAlign="start start" routerLink="/category">
          <p class="mat-small ">Shop By</p>
          <p>Category</p>
        </div>
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="60">
      <div class="hover-border" routerLink="/wish-list">
        <button mat-button>Wish List</button>
      </div>
      <div class="hover-border" routerLink='/todays-deal'>
        <button mat-button>Deals</button>
      </div>
      <div class="hover-border" routerLink='/new-arrival'>
        <button mat-button>New Arrivals</button>
      </div>
    </div>
  </div>
  <div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-icon>location_on_outlined</mat-icon>
      </div>
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="3px">
        <p class="mat-h4 ">Select a location to see product availibility</p>
      </div>
    </div>
  </div>
</div>