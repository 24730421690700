import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {
  feedbackName: string;

  constructor(public router: Router) { }

  ngOnInit(): void {
    this.feedbackName= localStorage.getItem('yourEmail')
  }
  gotohome(){
    // localStorage.removeItem('yourEmail');
    this.router.navigate(['/home'])
  }
}
