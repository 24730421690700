<div class="container" fxLayout="column" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px" fxLayoutGap.lt-md="-150px">
  <div class="slider cardpadding" fxShow fxHide.lt-sm>
    <div class="slider-img">
      <mat-carousel timings="250ms ease-in-out" [autoplay]="true" interval="3000" proportion="19" [useKeyboard]="true"
        [loop]="true" orientation="ltr" [hideIndicators]="false" color="white">
        <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides; let i = index" fxLayoutAlign="center center">
          <img src="{{slide.image}}" alt="" class="full-width">
        </mat-carousel-slide>
      </mat-carousel>
    </div>
  </div>
  <div class="slider" fxHide fxShow.lt-sm>
    <div class="slider-img">
      <mat-carousel timings="250ms ease-in-out" [autoplay]="true" interval="3000" proportion="45" [useKeyboard]="true"
        [loop]="true" [hideIndicators]="false" color="white" orientation="ltr">
        <mat-carousel-slide #matCarouselSlide *ngFor="let slide of slides1; let i = index" [image]="slide.image"
          fxLayoutAlign="center center">
        </mat-carousel-slide>
      </mat-carousel>
    </div>
  </div>
  <div class="container">
    <div fxLayout="column" fxLayoutGap="25px" fxLayoutGap.lt-sm="0px">
      <div class="cardpadding">
        <div class="card cardpadding" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
          <p class="mat-h1">COVID UPDATE - OCTOBER 19</p>
          <p class="mat-h3 cardpadding text-center">Free Shipping and IFCM (regular mail) are back! We hope for good,
            but
            there is
            nothing
            predictable about 2020, so we recommend placing your order as soon as possible if you want free shipping</p>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around" fxLayoutGap.lt-sm="25px"
        fxLayoutGap.lt-md="5px" fxFlex="grow" fxHide>
        <div class="card cardpadding" fxFlex="31" fxFlex.lt-sm="30" fxFlex.lt-md="33">
          <div fxLayout="column" fxLayoutGap="10px" fxFlex="grow">
            <p class="mat-title" fxFlex="20">New Arrivals</p>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages">
                <img src="assets/images/cardimage2/1.jpg" alt="">
                <p class="mat-small">Helmets</p>
              </div>
              <div class="cardimages">
                <img src="assets/images/cardimage2/2.jpg" alt="">
                <p class="mat-small">tyres</p>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages">
                <img src="assets/images/cardimage2/3.jpg" alt="">
                <p class="mat-small">Pressure washers</p>
              </div>
              <div class="cardimages">
                <img src="assets/images/cardimage2/4.jpg" alt="">
                <p class="mat-small">protective gear</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card cardpadding" fxFlex="31" fxFlex.lt-sm="30" fxFlex.lt-md="33">
          <div fxLayout="column" fxLayoutGap="10px" fxFlex="grow">
            <p class="mat-title" fxFlex="20">Featured Product</p>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages">
                <img src="assets/images/cardimage2/5.jpg" alt="">
                <p class="mat-small">Top realase in 2020</p>
              </div>
              <div class="cardimages">
                <img src="assets/images/cardimage2/6.jpg" alt="">
                <p class="mat-small">Book in buzz in 2020</p>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages">
                <img src="assets/images/cardimage2/7.jpg" alt="">
                <p class="mat-small">Most sold book</p>
              </div>
              <div class="cardimages">
                <img src="assets/images/cardimage2/8.jpg" alt="">
                <p class="mat-small">15000+ deals</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card cardpadding" fxFlex="31" fxFlex.lt-sm="30" fxFlex.lt-md="33">
          <div fxLayout="column" fxLayoutGap="10px" fxFlex="grow">
            <p class="mat-title" fxFlex="20"> Top Selling Product/ Best Selling Product</p>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages" routerLink="/home-essentials">
                <img src="assets/images/cardimage/11.jpg" alt="">
                <p class="mat-small">Home product</p>
              </div>
              <div class="cardimages" routerLink="/kitchen-dining">
                <img src="assets/images/cardimage/12.jpg" alt="">
                <p class="mat-small">Kitchen & dining</p>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages" routerLink="/daily-essentials">
                <img src="assets/images/cardimage/13.jpg" alt="">
                <p class="mat-small">Daily essentials</p>
              </div>
              <div class="cardimages" routerLink="/clothing-essentials">
                <img src="assets/images/cardimage/14.jpg" alt="">
                <p class="mat-small">Clothing essentials</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cardpadding" fxLayout="column" fxLayoutGap="35px">
        <div class="cardpadding slider-items-carousel" fxLayoutGap="10px">
          <!-- <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <p class="mat-h2">Today's Deal</p>
            <p class="color-accent" fxHide>See all deals</p>
          </div> -->
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let slide of slidesStore" [id]="slide.id">
              <div class="cursor">
                <img [src]="slide.src">
                <!-- <div fxLayout="row" fxLayoutAlign="start end">
                  <span class="mat-h4">{{slide.text}}</span>
                </div> -->
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
        <div class="card cardpadding slider-items" fxLayoutGap="10px" fxHide>
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <p class="mat-h2">Up to 60% off | Unboxed & like-new</p>
            <p class="color-accent" fxHide>See all deals</p>
          </div>
          <owl-carousel-o [options]="customOptions1">
            <ng-template carouselSlide *ngFor="let slide1 of slidesStore1" [id]="slide1.id">
              <div class="cursor" (click)="products()">
                <img [src]="slide1.src">
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around" fxLayoutGap.lt-sm="5px"
        fxLayoutGap.lt-md="5px" fxFlex="grow" fxHide>
        <div class="card cardpadding" fxFlex="31" fxFlex.lt-sm="30" fxFlex.lt-md="33">
          <div fxLayout="column" fxLayoutGap="10px" fxFlex="grow">
            <p class="mat-title" fxFlex="15">Electronics</p>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages" routerLink="/air-conditioners">
                <img src="assets/images/cardimage/1.jpg" alt="">
                <p class="mat-small">ACs</p>
              </div>
              <div class="cardimages" routerLink="/washing-machines">
                <img src="assets/images/cardimage/2.jpg" alt="">
                <p class="mat-small">Washing machines</p>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages" routerLink="/home-entertainment">
                <img src="assets/images/cardimage/3.jpg" alt="">
                <p class="mat-small">Televisions</p>
              </div>
              <div class="cardimages" routerLink="/refrigerators">
                <img src="assets/images/cardimage/4.jpg" alt="">
                <p class="mat-small">Refrigerators</p>
              </div>
            </div>
            <div fxLayoutAlign="start end" fxFlex="5" fxHide>
              <a class="color-accent"> See more</a>
            </div>
          </div>
        </div>
        <div class="card cardpadding" fxFlex="31" fxFlex.lt-sm="30" fxFlex.lt-md="33">
          <div fxLayout="column" fxLayoutGap="10px" fxFlex="grow">
            <p class="mat-title" fxFlex="15">Clothings</p>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages" routerLink='/fashion'>
                <img src="assets/images/cardimage/6.jpg" alt="">
                <p class="mat-small">Clothings under ₹599</p>
              </div>
              <div class="cardimages" routerLink='/fashion'>
                <img src="assets/images/cardimage/7.jpg" alt="">
                <p class="mat-small">Footwear under ₹599</p>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages" routerLink='/fashion'>
                <img src="assets/images/cardimage/8.jpg" alt="">
                <p class="mat-small">Bages, watches & more under ₹599</p>
              </div>
              <div class="cardimages" routerLink='/fashion'>
                <img src="assets/images/cardimage/9.jpg" alt="">
                <p class="mat-small">View allofferrs | up to 60% off</p>
              </div>
            </div>
            <div fxLayoutAlign="start end" fxFlex="5" fxHide>
              <a class="color-accent"> View all</a>
            </div>
          </div>
        </div>
        <div class="card cardpadding" fxFlex="31" fxFlex.lt-sm="30" fxFlex.lt-md="33">
          <div fxLayout="column" fxLayoutGap="10px" fxFlex="grow">
            <p class="mat-title" fxFlex="15">Grocery</p>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages">
                <img src="assets/images/cardimage2/9.jpg" alt="">
                <p class="mat-small">Grocery essentials</p>
              </div>
              <div class="cardimages">
                <img src="assets/images/cardimage2/10.jpg" alt="">
                <p class="mat-small">Mask & personal hygiene</p>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="10px" fxFlex="40">
              <div class="cardimages">
                <img src="assets/images/cardimage2/11.jpg" alt="">
                <p class="mat-small">Household supplies & personal care</p>
              </div>
              <div class="cardimages">
                <img src="assets/images/cardimage2/12.jpg" alt="">
                <p class="mat-small">Baby essentials & pet supplies</p>
              </div>
            </div>
            <div fxLayoutAlign="start end" fxFlex="5" fxHide>
              <a class="color-accent">See more</a>
            </div>
          </div>
        </div>
      </div>
      <div class="cardpadding3" fxHide>
        <div class="card1 cardpadding">
          <div fxLayout="column" fxLayoutGap="20px">
            <div [ngClass]="'cardpadding2 slider-items3 testimonial'" fxLayout="column" fxLayoutGap="20px">
              <div fxLayout="column" fxLayoutAlign="center center">
                <p class="mat-title color-primary">Testimonial</p>
                <p class="mat-headline">Our Client Feedback</p>
              </div>
              <owl-carousel-o [options]="customOptions4">
                <ng-template carouselSlide *ngFor="let slide4 of slidesStore4" [id]="slide4.id">
                  <div class="cursor" fxFlex="grow">
                    <div class="card cardpadding" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px"
                      fxFlex="grow">
                      <div fxLayout="column" class="testimonial">
                        <img src="{{slide4.src}}" alt="">
                      </div>
                      <div [ngClass.gt-md]="'cardpadding'" fxLayout="column" fxLayoutAlign="center center">
                        <p class="text-center mat-h4">Ekocart Amazing E-commerce Template, clean code, Crative & Modern
                          design.
                        </p>
                        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                          <p class="color-primary mat-h3">{{slide4.name}}</p>
                          <p class="mat-small text-italic">{{slide4.post}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </owl-carousel-o>
            </div>
          </div>
        </div>
      </div>
      <div class="cardpadding3" fxHide>
        <div class="card cardpadding">
          <div fxLayout="column" fxLayoutGap="20px">
            <div [ngClass]="' slider-items3'" [ngClass.lt-sm]="'slider-item'" fxLayout="column" fxLayoutGap="20px">
              <div fxLayout="row" fxLayoutAlign="center center">
                <p class="color-primary mat-title">Top Brands</p>
              </div>
              <owl-carousel-o [options]="customOptions2">
                <ng-template carouselSlide *ngFor="let slide2 of slidesStore2" [id]="slide2.id">
                  <div class="cursor">
                    <img [src]="slide2.src">
                  </div>
                </ng-template>
              </owl-carousel-o>
            </div>
          </div>
        </div>
      </div>

      <div class="cardpadding4">
        <div class="card1 cardpadding">
          <div class="cardpadding" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
            <div fxLayout="row">
              <p class="mat-headline">How to Order</p>
            </div>
            <div fxLayout="row wrap" fxFlex="grow" fxLayoutGap="10px">
              <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" fxFlex="18">
                <p class="mat-display-2">1</p>
                <p class="mat-h4">Find the medication you need by using the search bar</p>
              </div>
              <div>
                <mat-divider vertical style="height:100%; color:red"></mat-divider>
              </div>
              <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" fxFlex="18">
                <p class="mat-display-2">2</p>
                <p class="mat-h4">Once you find the medication you need, select a 90-day quantity or less and add to
                  cart. Repeat the
                  process until you have all the prescriptions you need.</p>
              </div>
              <div>
                <mat-divider vertical style="height:100%; color:red"></mat-divider>
              </div>
              <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" fxFlex="18">
                <p class="mat-display-2">3</p>
                <p class="mat-h4">Proceed to checkout. At this stage you can request that one or all of your
                  prescriptions be
                  automatically refilled by ProgressiveRx at a future date.</p>
              </div>
              <div>
                <mat-divider vertical style="height:100%; color:red"></mat-divider>
              </div>
              <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" fxFlex="18">
                <p class="mat-display-2">4</p>
                <p class="mat-h4">Send us a copy of your prescription. Either email a picture to fax it to 888-753-6401
                </p>
              </div>
              <div>
                <mat-divider vertical style="height:100%; color:red"></mat-divider>
              </div>
              <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" fxFlex="18">
                <p class="mat-display-2">5</p>
                <p class="mat-h4">Your drugs will arrive via USPS</p>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="cardpadding4">
        <div class="card cardpadding">
          <div class="cardpadding" fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center">
              <p class="mat-headline">Get Most From</p>
              <p class="">Get special discounts and offers on Netmeds services.</p>
            </div>
            <div class="getmore" fxLayout="column" fxLayoutAlign="center " fxLayoutGap="20px">
              <div fxLayout="row" fxLayoutGap="40px" fxFlex="grow">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px" fxFlex="33">
                  <img src="/assets/m-ecom/home/getmore1.svg" alt="">
                  <p class="font-bold">2.5% NMS Cash</p>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px" fxFlex="33">
                  <img src="/assets/m-ecom/home/getmore2.svg" alt="">
                  <p class="font-bold">Free Delivery</p>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px" fxFlex="33">
                  <img src="/assets/m-ecom/home/getmore3.svg" alt="">
                  <p class="font-bold">Priority Processing</p>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="40px" fxFlex="grow">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px" fxFlex="33">
                  <img src="/assets/m-ecom/home/getmore4.svg" alt="">
                  <p class="font-bold">Basic Health Checkup</p>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px" fxFlex="33">
                  <img src="/assets/m-ecom/home/getmore5.svg" alt="">
                  <p class="font-bold">Additional 5% off on Pathology Lab Tests</p>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px" fxFlex="33">
                  <img src="/assets/m-ecom/home/getmore6.svg" alt="">
                  <p class="font-bold">Free Unlimited Consultation</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="cardpadding4">
        <div class="cardpadding">
          <div fxLayout="column" fxLayoutGap="20px">
            <div fxLayout="column" fxLayoutAlign="center center">
              <p class="mat-headline">Medicine Offers</p>
            </div>
            <div class="offer" fxLayout="column" fxLayoutGap="10px">
              <div class="card2 cardpadding" fxLayout="row" fxFlex="grow">
                <div class="border-right" fxFlex="20" fxLayoutAlign="center center">
                  <img src="/assets/m-ecom/home/coupen1.png" alt="">
                </div>
                <div class="cardpadding" fxLayout="column" fxLayoutGap="10px" fxFlex="80">
                  <p class="mat-h3 font-bold">Get 18% off + 20% 1mgCash (up to ₹200) on your first medicine order</p>
                  <div fxLayout="row" fxLayoutGap="10px">
                    <p>Code: <span class="font-bold">NEW38</span></p>
                    <p>|</p>
                    <p>Expiry: <span class="font-bold">30 Apr 21</span></p>
                  </div>
                </div>
              </div>
              <div class="card2 cardpadding" fxLayout="row" fxFlex="grow">
                <div class="border-right" fxFlex="20" fxLayoutAlign="center center">
                  <img src="/assets/m-ecom/home/coupen2.png" alt="">
                </div>
                <div class="cardpadding" fxLayout="column" fxLayoutGap="10px" fxFlex="80">
                  <p class="mat-h3 font-bold">Same day delivery guaranteed, else get double payback on your delivery
                    charges</p>
                  <div fxLayout="row" fxLayoutGap="10px">
                    <p>Expiry: <span class="font-bold">30 Apr 21</span></p>
                  </div>
                </div>
              </div>
              <div class="card2 cardpadding" fxLayout="row" fxFlex="grow">
                <div class="border-right" fxFlex="20" fxLayoutAlign="center center">
                  <img src="/assets/m-ecom/home/coupen3.png" alt="">
                </div>
                <div class="cardpadding" fxLayout="column" fxLayoutGap="10px" fxFlex="80">
                  <p class="mat-h3 font-bold">Get 18% off on your first medicine order</p>
                  <div fxLayout="row" fxLayoutGap="10px">
                    <p>Code: <span class="font-bold">NEW18</span></p>
                    <p>|</p>
                    <p>Expiry: <span class="font-bold">30 Apr 21</span></p>
                  </div>
                </div>
              </div>
              <div class="card2 cardpadding" fxLayout="row" fxFlex="grow">
                <div class="border-right" fxFlex="20" fxLayoutAlign="center center">
                  <img src="/assets/m-ecom/home/coupen4.png" alt="">
                </div>
                <div class="cardpadding" fxLayout="column" fxLayoutGap="10px" fxFlex="80">
                  <p class="mat-h3 font-bold">Get 15% off on allopathy medicines</p>
                  <div fxLayout="row" fxLayoutGap="10px">
                    <p>Code: <span class="font-bold">NEW15</span></p>
                    <p>|</p>
                    <p>Expiry: <span class="font-bold">30 Apr 21</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="cardpadding4">
        <div fxLayout="column" class="cardpadding">
          <div class="card cardpadding" fxLayout="row" fxFlex="grow">
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="25">
              <p class="mat-headline text-center">About <br> Us</p>
            </div>
            <div fxLayoutAlign="center center" fxFlex="5">
              <img src="/assets/m-ecom/home/brace.png" alt="">
            </div>
            <div class="cardpadding" fxLayoutAlign="center center" fxFlex="70">
              <p>In 2004, we founded ProgressiveRx to provide the best possible option for friends and family who were
                out of work and couldn’t afford their medications. As such, quality and efficacy have always been our
                highest concern. Over the last nine years we’ve grown from helping a handful of friends and family, to
                providing medications for thousands of customers each month. Virtually all of that growth we owe to the
                great word of mouth we’ve received from our customers (Thank You!). We’ve also been profiled in the San
                Francisco Chronicle, The Dallas Morning News, The AARP Bulletin, and The Oregonian.</p>
            </div>
          </div>
          <div class="card cardpadding" fxLayout="row" fxFlex="grow">
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="25">
              <img src="/assets/m-ecom/home/hoh.png" alt="">
            </div>
            <div fxLayoutAlign="center center" fxFlex="5">
              <img src="/assets/m-ecom/home/brace.png" alt="">
            </div>
            <div class="cardpadding" fxLayoutAlign="center center" fxFlex="70">
              <p>ProgressiveRx is a proud sponsor of Hands to Hearts International. HHI helps change the lives of tens
                of thousands of the most disadvantaged children in the world every year. To learn more about HHI or to
                make a donation that helps change the life of a child today, visit: www.handstohearts.org.</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>