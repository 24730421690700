import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxGalleryImageSize, NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { AuthenticationService } from 'src/app/shared/_services/authentication.service';
import { Router } from '@angular/router';
import { ProductCompareComponent } from './product-compare/product-compare.component';
import { MatDialog } from '@angular/material/dialog';
import { ProductReviewComponent } from './product-review/product-review.component';

interface Color {
  value: string;
  viewValue: string;
}
interface Style {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  constructor(
    private authenticationService: AuthenticationService,
    public router: Router,
    public dialog: MatDialog,) { }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        width: '500px',
        height: '500px',
        thumbnailsColumns: 5,
        arrowPrevIcon: 'fa fa-chevron-left',
        arrowNextIcon: 'fa fa-chevron-right',
        imageAnimation: NgxGalleryAnimation.Slide,
        imageSize: NgxGalleryImageSize.Contain,
        thumbnailSize: NgxGalleryImageSize.Contain,
        thumbnailsPercent: 20,
        previewCloseOnEsc: true,
        previewKeyboardNavigation: true,
        preview: false
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '300px',
        imagePercent: 80,
        thumbnailsPercent: 25,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        previewCloseOnEsc: true,
        previewKeyboardNavigation: true,
        previewCloseOnClick: true,
        preview: false
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.galleryImages = [
      {
        small: 'assets/images/product/1.jpg',
        medium: 'assets/images/product/1.jpg',
        big: 'assets/images/product/1.jpg'
      },
      {
        small: 'assets/images/product/2.jpg',
        medium: 'assets/images/product/2.jpg',
        big: 'assets/images/product/2.jpg'
      },
      {
        small: 'assets/images/product/3.jpg',
        medium: 'assets/images/product/3.jpg',
        big: 'assets/images/product/3.jpg'
      },
      {
        small: 'assets/images/product/4.jpg',
        medium: 'assets/images/product/4.jpg',
        big: 'assets/images/product/4.jpg'
      },
      {
        small: 'assets/images/product/med5.jpg',
        medium: 'assets/images/product/med5.jpg',
        big: 'assets/images/product/med5.jpg'
      },
    ];
  }

  colors: Color[] = [
    { value: 'Celestial Black', viewValue: 'Celestial Black' },
    { value: 'Electric Blue', viewValue: 'Electric Blue' },
  ];
  selectedValue = this.colors[0].value;

  styles: Style[] = [
    { value: '6GB RAM, 128GB Storage', viewValue: '6GB RAM, 128GB Storage' },
    { value: '8GB RAM, 128GB Storage', viewValue: '8GB RAM, 128GB Storage' },
  ];
  selectedValue1 = this.styles[0].value;


  buynow() {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/add-address']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  productCompare() {
    const dialogRef = this.dialog.open(ProductCompareComponent, {
      width: '100%',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  productReview() {
    const dialogRef = this.dialog.open(ProductReviewComponent, {
      width: '100%',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore = [
    {
      id: 1,
      src: './assets/images/product/carousel/1.jpg',
      name: 'Samsung Galaxy M21 (Midnight Blue, 6GB RAM, 128GB Storage)',
      reviewno: '84,242',
      price: '₹ 15,999.00'
    },
    {
      id: 2,
      src: './assets/images/product/carousel/2.jpg',
      name: 'OPPO F17 Pro (Matte Black, 8GB RAM, 128GB Storage) with No Cost EMI/Additional Exch...',
      reviewno: '722',
      price: '₹ 21,490.00'
    },
    {
      id: 3,
      src: './assets/images/product/carousel/3.jpg',
      name: 'wow imagine galaxy m51 Flip Cover case | premium leather finish | inside tpu with c...',
      reviewno: '225',
      price: '₹ 299.00'
    },
    {
      id: 4,
      src: './assets/images/product/carousel/4.jpg',
      name: 'Knotyy Tempered Glass for Samsung Galaxy M51 (Pack of 2)',
      reviewno: '61',
      price: '₹ 299.00'
    },
    {
      id: 5,
      src: './assets/images/product/carousel/5.jpg',
      name: 'CEDO Samsung M51 Flip Cover | Leather Finish | Inside Pockets & Inbuilt Stand | Sho...',
      reviewno: '50',
      price: '₹ 255.00'
    },
    {
      id: 6,
      src: './assets/images/product/carousel/6.jpg',
      name: 'Thinkzy Artificial Leather Flip Cover Case for Samsung Galaxy m51 (Green Blue)',
      reviewno: '91',
      price: '₹ 299.00'
    },
    {
      id: 7,
      src: './assets/images/product/carousel/7.jpg',
      name: 'Thinkzy Artificial Leather Flip Cover Case for Samsung Galaxy m51 (Green Blue)',
      reviewno: '325',
      price: '₹ 299.00'
    },
    {
      id: 8,
      src: './assets/images/product/carousel/8.jpg',
      name: 'Thinkzy Artificial Leather Flip Cover Case for Samsung Galaxy m51 (Purple Blue)',
      reviewno: '58',
      price: '₹ 299.00'
    },
    {
      id: 9,
      src: './assets/images/product/carousel/9.jpg',
      name: 'Thinkzy Artificial Leather Flip Cover Case for Samsung Galaxy m51 (Pink Blue)',
      reviewno: '379',
      price: '₹ 299.00'
    },
    {
      id: 10,
      src: './assets/images/product/carousel/10.jpg',
      name: 'Thinkzy Artificial Leather Flip Cover Case for Samsung Galaxy m51 (Pink Blue)',
      reviewno: '165',
      price: '₹ 299.00'
    },
    {
      id: 11,
      src: './assets/images/product/carousel/11.jpg',
      name: 'Samsung Galaxy M21 (Raven Black, 6GB RAM, 128GB Storage)',
      reviewno: '82,281',
      price: '₹ 15,999.00'
    },
    {
      id: 12,
      src: './assets/images/product/carousel/12.jpg',
      name: 'OPPO F17 Pro (Metallic White, 8GB RAM, 128GB Storage) with No Cost EMI/Additional…',
      reviewno: '728',
      price: '₹ 21,499.00'
    },
  ]
  customOptions1: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore1 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel1/1.jpg',
      text: 'Emporium Car Back Pain Relief Lower Back Support for Chair Back Rest for Office Chair Lumbar…',
      reviewno: '242',
      price: '₹ 349.00'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel1/2.jpg',
      text: 'BLUEWUD Clonard Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '242',
      price: '₹ 2,899.00'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel1/3.jpg',
      text: 'BLUEWUD Mallium Engineered Wood Study Table, Laptop, computer Table Desk for Home &…',
      reviewno: '331',
      price: '₹ 3,339.00'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel1/4.jpg',
      text: 'OGU Om Cotton Round Batti Wicks for Pooja Aarti (White) -2100 Pieces',
      reviewno: '61',
      price: '₹ 250'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel1/5.jpg',
      text: 'pTron Bassbuds Pro in-Ear True Wireless Bluetooth 5.0 Headphones with Deep Bass, Touch Control…',
      reviewno: '8,370',
      price: '₹ 1,299.00'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel1/6.jpg',
      text: 'HealthSense Soft-Spot BC 21 Orthopedic Backrest Cushion with Memory Foam for Study, Home,…',
      reviewno: '931',
      price: '₹ 1,399.00'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel1/7.jpg',
      text: 'Nutriplato-enriching lives Roasted Salted Flax Seeds, 500 g',
      reviewno: '325',
      price: '₹ 199.00'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel1/8.jpg',
      text: 'ADOC Chairs Oren Medium Back Ergonomic Office Chair with Mesh Back, Fixed Comfortable…',
      reviewno: '58',
      price: '₹ 3,049.00'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel1/9.jpg',
      text: 'Angel Bear 1 Cup Toothbrush Toothpaste Stand Holder Bathroom Storage Organizer,Plastic',
      reviewno: '379',
      price: '₹ 249.00'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel1/10.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '165',
      price: '₹ 3,999.00'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel1/11.jpg',
      text: 'TIZUM High Speed HDMI Cable with Ethernet - Supports 3D, 4K and Audio Return (1.8 Meter/ 6 Feet)',
      reviewno: '2,281',
      price: '₹ 145.00'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel1/12.jpg',
      text: 'Livzing Multipurpose Foldable Study Desk Portable Wooden Utility Laptop Table for Adults…',
      reviewno: '30',
      price: '₹ 3,799.00'
    },
  ]
  customOptions2: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    lazyLoad: true,
    dots: false,
    margin: 10,
    nav: true,
    navText: ["<div class='prew'><img src='assets/images/back.png'></div>", "<div class='next'><img src='assets/images/next.png'></div>"],
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      200: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      800: {
        items: 5
      },
      1000: {
        items: 6
      }
    },
  }
  slidesStore2 = [
    {
      id: 1,
      src: './assets/images/mobile-phone/carousel2/1.jpeg',
      text: 'VPN Software & VPN free unlimited (Fire TV, 4k sticks)',
      reviewno: '1,108',
      price: 'BackySoft'
    },
    {
      id: 2,
      src: './assets/images/mobile-phone/carousel2/2.jpg',
      text: 'AirScreen - AirPlay & Google Cast & Miracast & DLNA',
      reviewno: '34,550',
      price: '重庆爱奥尼科技有限公司'
    },
    {
      id: 3,
      src: './assets/images/mobile-phone/carousel2/3.jpeg',
      text: 'Trashy Movies Channel',
      reviewno: '1,561',
      price: 'POV Horror'
    },
    {
      id: 4,
      src: './assets/images/mobile-phone/carousel2/4.jpeg',
      text: 'Wicked Horror TV',
      reviewno: '3,961',
      price: 'POV Horror'
    },
    {
      id: 5,
      src: './assets/images/mobile-phone/carousel2/5.jpeg',
      text: 'Trashy Horror Movies',
      reviewno: '1,375',
      price: 'POV Horror'
    },
    {
      id: 6,
      src: './assets/images/mobile-phone/carousel2/6.jpeg',
      text: 'B-Movie Club',
      reviewno: '738',
      price: 'POV Horror'
    },
    {
      id: 7,
      src: './assets/images/mobile-phone/carousel2/7.jpeg',
      text: 'VPN Lite & Full Privacy (RVPN)',
      reviewno: '358',
      price: 'BackySoft'
    },
    {
      id: 8,
      src: './assets/images/mobile-phone/carousel2/8.jpg',
      text: 'Plex: Stream Movies, Shows, Live TV, Music, and More',
      reviewno: '51,727',
      price: 'Plex'
    },
    {
      id: 9,
      src: './assets/images/mobile-phone/carousel2/9.jpeg',
      text: 'Martial Arts and Kung-Fu Movie Channel',
      reviewno: '4,629',
      price: 'POV Horror'
    },
    {
      id: 10,
      src: './assets/images/mobile-phone/carousel2/10.jpg',
      text: 'NordVPN - Fast, Secure and Unlimited VPN app for Android. Stay secure and…',
      reviewno: '21,882',
      price: 'nordvpn s.a.'
    },
    {
      id: 11,
      src: './assets/images/mobile-phone/carousel2/11.jpeg',
      text: 'Thriller Movie Channel',
      reviewno: '232',
      price: 'POV Horror'
    },
    {
      id: 12,
      src: './assets/images/mobile-phone/carousel2/12.jpeg',
      text: 'Cleaner for Fire Tablets & TV (Cleanza)',
      reviewno: '6,219',
      price: 'Quickla Soft'
    },
  ]
}
