import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private totalItems: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  getCartItems() {
      return this.totalItems.asObservable();
  }

  updateCartItems(items: number) {
      this.totalItems.next(items);
  }
}
