import { Component, OnInit } from '@angular/core';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  slides = [
    { 'image': './assets/images/aboutus/carousel1.jpeg', para: 'Echo show just became more delighful with addition of Netflix and new video features' },
    { 'image': './assets/images/aboutus/carousel2.jpeg', para: 'Delivering Smiles - Creating new grounds for equitable education and re-imagining learning for all ' },
    { 'image': './assets/images/aboutus/carousel3.jpeg', para: 'Holiday workshop for kids this festive season' },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
