import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-releases',
  templateUrl: './new-releases.component.html',
  styleUrls: ['./new-releases.component.scss']
})
export class NewReleasesComponent implements OnInit {
  items = [
    { number: '#1', image: 'assets/images/new-release/1.jpg', name: 'Mi 10i 5G (Pacific Sunrise, 6GB RAM, 128GB Storage) - 108MP Quad Camera | Snapdragon 750G | Upto 6 Months No Cost EMI', reviewno: '3,585' },
    { number: '#2', image: 'assets/images/new-release/2.jpg', name: 'Mi 10i 5G (Atlantic Blue, 6GB RAM, 128GB Storage) - 108MP Quad Camera | Snapdragon 750G | Upto 6 Months No Cost EMI', reviewno: '87' },
    { number: '#3', image: 'assets/images/new-release/3.jpg', name: 'OnePlus Bullets Wireless Z Bass Edition (Reverb Red)  ', reviewno: '45,056', price: '₹ 1,999.00' },
    { number: '#4', image: 'assets/images/new-release/4.jpg', name: 'Mi 10i 5G (Pacific Sunrise, 8GB RAM, 128GB Storage) - 108MP Quad Camera | Snapdragon 750G | Upto 6 Months No Cost EMI', reviewno: '3,585' },
    { number: '#5', image: 'assets/images/new-release/5.jpg', name: 'Mi 10i 5G (Midnight Black, 8GB RAM, 128GB Storage) - 108MP Quad Camera | Snapdragon 750G | Upto 6 Months No Cost EMI', reviewno: '87' },
    { number: '#6', image: 'assets/images/new-release/6.jpg', name: 'Mi 10i 5G (Atlantic Blue, 8GB RAM, 128GB Storage) - 108MP Quad Camera | Snapdragon 750G | Upto 6 Months No Cost EMI', reviewno: '18,056' },
    { number: '#7', image: 'assets/images/new-release/7.jpg', name: 'Redmi 9A (Nature Green, 2GB Ram, 32GB Storage)', reviewno: '11,585', price: '₹ 6,999.00' },
    { number: '#8', image: 'assets/images/new-release/8.jpg', name: 'Redmi 9A (Sea Blue, 2GB Ram, 32GB Storage)', reviewno: '10,087', price: '₹ 6,999.00' },
    { number: '#9', image: 'assets/images/new-release/9.jpg', name: 'OnePlus Nord 5G (Gray Ash, 12GB RAM, 256GB Storage)', reviewno: '29,056', price: '₹ 29,999.00' },
    { number: '#10', image: 'assets/images/new-release/10.jpg', name: 'boAt Airdopes 121v2 TWS Earbuds with Bluetooth V5.0, Immersive Audio, Up to 14H Total…', reviewno: '2,357', price: '₹ 1,299.00' },
    { number: '#11', image: 'assets/images/new-release/11.jpg', name: 'OnePlus Buds Z (White)', reviewno: '1,947', price: '₹ 2,999.00' },
    { number: '#12', image: 'assets/images/new-release/12.jpg', name: 'Redmi 9 (Sky Blue, 4GB RAM, 64GB Storage)', reviewno: '8,579', price: '₹ 9,199.00' },
    { number: '#13', image: 'assets/images/new-release/13.jpg', name: 'Mi Power Bank 3i 20000mAh (Sandstone Black) Triple Output and Dual Input Port | 18W Fast…', reviewno: '36,168', price: '₹ 1,498.00' },
    { number: '#14', image: 'assets/images/new-release/14.jpg', name: 'Samsung Galaxy M31 Prime Edition (Space Black, 6GB RAM, 128GB Storage)', reviewno: '23,056', price: '₹ 16,499.00' },
    { number: '#15', image: 'assets/images/new-release/15.jpg', name: 'OnePlus Bullets Wireless Z Bass Edition (Bass Blue)', reviewno: '45,585', price: '₹ 1,999.00' },
    { number: '#16', image: 'assets/images/new-release/16.jpg', name: 'Noise Shots Neo 2 Truly Wireless Earbuds with MEMS mic for Crystal Clear Calls, Hyper Sync Technology, Full Touch Controls, Fintips for…', reviewno: '40,789', price: '₹ 1,999.00' },
    { number: '#17', image: 'assets/images/new-release/17.jpg', name: 'Redmi 9A (Sea Blue, 3GB Ram, 32GB Storage)', reviewno: '10,683', price: '₹ 7,499.00' },
    { number: '#18', image: 'assets/images/new-release/18.jpg', name: 'realme Buds Classic Wired Earphones with HD Microphone Black', reviewno: '3,961', price: '₹ 299.00' },
    { number: '#19', image: 'assets/images/new-release/19.jpg', name: 'WeCool Moonwalk Mini TWS Bluetooth Earphones or True Wireless Earbuds with Magnetic Charging…', reviewno: '669', price: '₹ 799.00' },
    { number: '#20', image: 'assets/images/new-release/20.jpg', name: 'RUDRA ZONE Presents Foldable Laptop Table with Cup Holder, Study Table, Bed Table, Breakfast…', reviewno: '10', price: '₹ 409.00' },
    { number: '#21', image: 'assets/images/new-release/21.jpg', name: 'All-new Fire TV Stick with Alexa Voice Remote (includes TV controls) | Stream HD Quality Video…', reviewno: '3,092', price: '₹ 3,999.00' },
    { number: '#22', image: 'assets/images/new-release/22.jpg', name: 'Redmi 9A (Midnight Black, 3GB Ram, 32GB Storage)', reviewno: '10,683', price: '₹ 7,499.00' },
    { number: '#23', image: 'assets/images/new-release/23.jpg', name: 'boAt Airdopes 121v2 TWS Earbuds with Bluetooth V5.0, Immersive Audio, Up to 14H Total…', reviewno: '2,356', price: '₹ 1,299.00' },
    { number: '#24', image: 'assets/images/new-release/24.jpg', name: 'All-new Fire TV Stick Lite with Alexa Voice Remote Lite | Stream HD Quality Video | No power and…', reviewno: '2,319', price: '₹ 2,999.00' },
    { number: '#25', image: 'assets/images/new-release/25.jpg', name: 'Redmi 9A (Nature Green, 3GB Ram, 32GB Storage)', reviewno: '10,683', price: '₹ 7,499.00' },
    { number: '#26', image: 'assets/images/new-release/26.jpg', name: 'boAt Airdopes 441 TWS Ear-Buds with IWP Technology, Immersive Audio, Up to 30H Total…', reviewno: '19,401', price: '₹ 2,499.00' },
    { number: '#27', image: 'assets/images/new-release/27.jpg', name: 'Xiaomi Redmi Hi-Resolution Audio Wired Earphone with Mic (Black, in The Ear)', reviewno: '62,585', price: '₹ 399.00' },
    { number: '#28', image: 'assets/images/new-release/28.jpg', name: 'Mi 10000mAH Li-Polymer Power Bank 3i with 18W Fast Charging (Midnight Black)', reviewno: '36,156', price: '₹ 899.00' },
    { number: '#29', image: 'assets/images/new-release/29.jpg', name: 'Callas Multipurpose Foldable Laptop Table with Cup Holder | Drawer | Mac Holder | Table Holder…', reviewno: '170', price: '₹ 799.00' },
    { number: '#30', image: 'assets/images/new-release/30.jpg', name: 'Redmi 9A (Midnight Black, 2GB RAM, 32GB Storage)', reviewno: '10,683', price: '₹ 6,999.00' },

  ];
  constructor(public router: Router) { }

  ngOnInit(): void {
  }
  products() {
    this.router.navigate(['/products']);
  }
}
